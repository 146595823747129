export const GET_TOURIST_SPOTS_REQUEST = "GET_TOURIST_SPOTS_REQUEST";
export const GET_TOURIST_SPOTS_SUCCESS = "GET_TOURIST_SPOTS_SUCCESS";
export const GET_TOURIST_SPOTS_FAILURE = "GET_TOURIST_SPOTS_FAILURE";

export const GET_TOURIST_SPOT_REQUEST = "GET_TOURIST_SPOT_REQUEST";
export const GET_TOURIST_SPOT_SUCCESS = "GET_TOURIST_SPOT_SUCCESS";
export const GET_TOURIST_SPOT_FAILURE = "GET_TOURIST_SPOT_FAILURE";

export const CREATE_TOURIST_SPOT_REQUEST = "CREATE_TOURIST_SPOT_REQUEST";
export const CREATE_TOURIST_SPOT_SUCCESS = "CREATE_TOURIST_SPOT_SUCCESS";
export const CREATE_TOURIST_SPOT_FAILURE = "CREATE_TOURIST_SPOT_FAILURE";

export const DELETE_TOURIST_SPOT_REQUEST = "DELETE_TOURIST_SPOT_REQUEST";
export const DELETE_TOURIST_SPOT_SUCCESS = "DELETE_TOURIST_SPOT_SUCCESS";
export const DELETE_TOURIST_SPOT_FAILURE = "DELETE_TOURIST_SPOT_FAILURE";

export const DELETE_TOURIST_SPOT_PHOTO_REQUEST =
  "DELETE_TOURIST_SPOT_PHOTO_REQUEST";
export const DELETE_TOURIST_SPOT_PHOTO_SUCCESS =
  "DELETE_TOURIST_SPOT_PHOTO_SUCCESS";
export const DELETE_TOURIST_SPOT_PHOTO_FAILURE =
  "DELETE_TOURIST_SPOT_PHOTO_FAILURE";

export const UPDATE_TOURIST_SPOT_REQUEST = "UPDATE_TOURIST_SPOT_REQUEST";
export const UPDATE_TOURIST_SPOT_SUCCESS = "UPDATE_TOURIST_SPOT_SUCCESS";
export const UPDATE_TOURIST_SPOT_FAILURE = "UPDATE_TOURIST_SPOT_FAILURE";

export const UPDATE_TOURIST_SPOT_PHOTO_REQUEST =
  "UPDATE_TOURIST_SPOT_PHOTO_REQUEST";
export const UPDATE_TOURIST_SPOT_PHOTO_SUCCESS =
  "UPDATE_TOURIST_SPOT_PHOTO_SUCCESS";
export const UPDATE_TOURIST_SPOT_PHOTO_FAILURE = "UPDATE_TOURIST_SPOT_FAILURE";
