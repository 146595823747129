export const GET_FILTERS_WHERE_EAT_REQUEST = "GET_FILTERS_WHERE_EAT_REQUEST";
export const GET_FILTERS_WHERE_EAT_SUCCESS = "GET_FILTERS_WHERE_EAT_SUCCESS";
export const GET_FILTERS_WHERE_EAT_FAILURE = "GET_FILTERS_WHERE_EAT_FAILURE";

export const CREATE_FILTERS_WHERE_EAT_REQUEST =
  "CREATE_FILTERS_WHERE_EAT_REQUEST";
export const CREATE_FILTERS_WHERE_EAT_SUCCESS =
  "CREATE_FILTERS_WHERE_EAT_SUCCESS";
export const CREATE_FILTERS_WHERE_EAT_FAILURE =
  "CREATE_FILTERS_WHERE_EAT_FAILURE";

export const UPDATE_FILTERS_WHERE_EAT_REQUEST =
  "UPDATE_FILTERS_WHERE_EAT_REQUEST";
export const UPDATE_FILTERS_WHERE_EAT_SUCCESS =
  "UPDATE_FILTERS_WHERE_EAT_SUCCESS";
export const UPDATE_FILTERS_WHERE_EAT_FAILURE =
  "UPDATE_FILTERS_WHERE_EAT_FAILURE";

export const DELETE_FILTERS_WHERE_EAT_REQUEST =
  "DELETE_FILTERS_WHERE_EAT_REQUEST";
export const DELETE_FILTERS_WHERE_EAT_SUCCESS =
  "DELETE_FILTERS_WHERE_EAT_SUCCESS";
export const DELETE_FILTERS_WHERE_EAT_FAILURE =
  "DELETE_FILTERS_WHERE_EAT_FAILURE";
