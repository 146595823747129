import { isDate, parse } from "date-fns";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { createUser, getUser, updateUser } from "../../services/user/user-api";

const UserFormComponente = () => {
  const params = useParams();
  const [userData, setUsers] = useState([]);
  const history = useHistory();
  const errornotify = (message) =>
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-danger text-white",
    });
  const successnotify = (message) =>
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-success text-white",
    });
  const today = new Date();

  useEffect(() => {
    if (params.id) {
      getUserData(params.id);
    }
  }, []);

  const user = useFormik({
    enableReinitialize: true,
    initialValues: Object.assign(
      {
        firstname: "",
        lastname: "",
        birth_date: "",
        city: "",
        state: "",
        email: "",
        password: "",
        language: "",
        is_admin: false,
        allow_location: false,
        receive_notifications: false,
      },
      userData
    ),

    validationSchema: Yup.object({
      firstname: Yup.string("O nome deve ser do tipo texto").required(
        "O nome é obrigatório"
      ),
      lastname: Yup.string("O sobrenome deve ser do tipo texto").required(
        "O sobrenome é obrigatório"
      ),
      birth_date: Yup.date("A data de nascimento deve ser do tipo data")
        .transform(parseDateString, "Insira uma data válida")
        .max(today, "A data de nascimento deve ser menor que a data atual"),
      city: Yup.string("A cidade deve ser do tipo texto").required(
        "A cidade é obrigatória"
      ),
      state: Yup.string("O estado deve ser do tipo texto").required(
        "O estado é obrigatório"
      ),
      email: Yup.string("O email deve ser do tipo texto")
        .required("O email é obrigatória")
        .email("O campo email deve ser valido"),
      language: Yup.string("O idioma deve ser do tipo texto").required(
        "O idioma é obrigatório"
      ),
      password: Yup.string("A senha deve ser do tipo texto").required(
        "A senha é obrigatória"
      ),
      is_admin: Yup.bool(),
      allow_location: Yup.bool(),
      receive_notifications: Yup.bool(),
    }),
    onSubmit: (values) => {
      values.birth_date = values.birth_date.split("/").reverse().join("-");
      values.birth_date = moment(values.birth_date)
        .add(3, "h")
        .format("YYYY-MM-DD[T]HH:MM:SS.mmm[Z]");

      isUpdateForm(params.id)
        ? updateUserData(params.id, values)
        : createUserData(values);
    },
  });

  function isUpdateForm(userId) {
    return userId;
  }

  function createUserData(values) {
    createUser(values)
      .then((res) => {
        successnotify("Usuário criado com sucesso!");
        history.push("/users");
      })
      .catch(() => {
        errornotify("Erro ao salvar usuário.");
      });
  }

  function updateUserData(userId, values) {
    updateUser(userId, values)
      .then(() => {
        successnotify("Usuário editado com sucesso!");
        history.push("/users");
      })
      .catch(() => {
        errornotify("Erro ao atualizar usuário.");
      });
  }

  function getUserData(id) {
    getUser(id)
      .then((data) => {
        data.birth_date = moment(new Date(data.birth_date))
          .add(3, "h")
          .format("DD/MM/YYYY");
        setUsers(data);
      })
      .catch((err) => {
        errornotify("Erro ao buscar usuário.");
      });
  }

  function parseDateString(value, originalValue) {
    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, "dd/MM/yyyy", new Date());

    return parsedDate;
  }

  return (
    <Row>
      <ToastContainer />
      <Col lg={12}>
        <Card>
          <div className="p-4">
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => history.push("/users")}
            >
              <i className="ri-arrow-left-s-line align-middle"></i>
              Voltar
            </button>
          </div>
          <CardBody>
            <div className="live-preview">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  user.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Row className="g-3">
                  <Col md={12}>
                    <Row>
                      <Col md={4}>
                        {user.values.is_admin}
                        <div className="form-check form-check-outline form-check-primary">
                          <Input
                            className="form-check-input"
                            name="is_admin"
                            type="checkbox"
                            onChange={user.handleChange}
                            onBlur={user.handleBlur}
                            value={user.values.is_admin}
                            invalid={
                              user.touched.is_admin && user.errors.is_admin
                                ? true
                                : false
                            }
                          />
                          {user.touched.is_admin && user.errors.is_admin ? (
                            <FormFeedback type="invalid">
                              {user.errors.is_admin}
                            </FormFeedback>
                          ) : null}
                          <Label
                            className="form-check-label"
                            htmlFor="is_admin"
                          >
                            É admin?
                          </Label>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-check form-check-outline form-check-secondary">
                          <Input
                            className="form-check-input"
                            name="allow_location"
                            type="checkbox"
                            onChange={user.handleChange}
                            onBlur={user.handleBlur}
                            value={user.values.allow_location}
                            invalid={
                              user.touched.allow_location &&
                              user.errors.allow_location
                                ? true
                                : false
                            }
                          />
                          {user.touched.allow_location &&
                          user.errors.allow_location ? (
                            <FormFeedback type="invalid">
                              {user.errors.allow_location}
                            </FormFeedback>
                          ) : null}
                          <Label
                            className="form-check-label"
                            htmlFor="allow_location"
                          >
                            Permitir localização?
                          </Label>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-check form-check-outline form-check-success">
                          <Input
                            className="form-check-input"
                            name="receive_notifications"
                            type="checkbox"
                            onChange={user.handleChange}
                            onBlur={user.handleBlur}
                            value={user.values.receive_notifications}
                            invalid={
                              user.touched.receive_notifications &&
                              user.errors.receive_notifications
                                ? true
                                : false
                            }
                          />
                          {user.touched.receive_notifications &&
                          user.errors.receive_notifications ? (
                            <FormFeedback type="invalid">
                              {user.errors.receive_notifications}
                            </FormFeedback>
                          ) : null}
                          <Label
                            className="form-check-label"
                            htmlFor="receive_notifications"
                          >
                            Receber notificações?
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={12}>
                    <div className="form-floating">
                      <Input
                        name="firstname"
                        type="text"
                        className="form-control"
                        onChange={user.handleChange}
                        onBlur={user.handleBlur}
                        value={user.values.firstname || ""}
                        invalid={
                          user.touched.firstname && user.errors.firstname
                            ? true
                            : false
                        }
                      />
                      {user.touched.firstname && user.errors.firstname ? (
                        <FormFeedback type="invalid">
                          {user.errors.firstname}
                        </FormFeedback>
                      ) : null}
                      <Label htmlFor="firstname">Nome</Label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-floating">
                      <Input
                        name="lastname"
                        type="text"
                        className="form-control"
                        onChange={user.handleChange}
                        onBlur={user.handleBlur}
                        value={user.values.lastname || ""}
                        invalid={
                          user.touched.lastname && user.errors.lastname
                            ? true
                            : false
                        }
                      />
                      {user.touched.lastname && user.errors.lastname ? (
                        <FormFeedback type="invalid">
                          {user.errors.lastname}
                        </FormFeedback>
                      ) : null}
                      <Label htmlFor="lastname">Sobrenome</Label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-floating">
                      <Input
                        name="birth_date"
                        onChange={user.handleChange}
                        onBlur={user.handleBlur}
                        value={user.values.birth_date || ""}
                        className="form-control"
                        mask="99/99/9999"
                        tag={InputMask}
                        invalid={
                          user.touched.birth_date && user.errors.birth_date
                            ? true
                            : false
                        }
                      />
                      {user.touched.birth_date && user.errors.birth_date ? (
                        <FormFeedback type="invalid">
                          {user.errors.birth_date}
                        </FormFeedback>
                      ) : null}
                      <Label htmlFor="birth_date">Data de nascimento</Label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-floating">
                      <Input
                        name="email"
                        type="text"
                        className="form-control"
                        onChange={user.handleChange}
                        onBlur={user.handleBlur}
                        value={user.values.email || ""}
                        invalid={
                          user.touched.email && user.errors.email ? true : false
                        }
                      />
                      {user.touched.email && user.errors.email ? (
                        <FormFeedback type="invalid">
                          {user.errors.email}
                        </FormFeedback>
                      ) : null}
                      <Label htmlFor="email">Email</Label>
                    </div>
                  </Col>
                  {!params.id && (
                    <Col lg={12}>
                      <div className="form-floating">
                        <Input
                          name="password"
                          type="text"
                          className="form-control"
                          onChange={user.handleChange}
                          onBlur={user.handleBlur}
                          value={user.values.password}
                          invalid={
                            user.touched.password && user.errors.password
                              ? true
                              : false
                          }
                        />
                        {user.touched.password && user.errors.password ? (
                          <FormFeedback type="invalid">
                            {user.errors.password}
                          </FormFeedback>
                        ) : null}
                        <Label htmlFor="password">Senha</Label>
                      </div>
                    </Col>
                  )}
                  <Col lg={12}>
                    <div className="form-floating">
                      <Input
                        name="language"
                        type="text"
                        className="form-control"
                        onChange={user.handleChange}
                        onBlur={user.handleBlur}
                        value={user.values.language || ""}
                        invalid={
                          user.touched.language && user.errors.language
                            ? true
                            : false
                        }
                      />
                      {user.touched.language && user.errors.language ? (
                        <FormFeedback type="invalid">
                          {user.errors.language}
                        </FormFeedback>
                      ) : null}
                      <Label htmlFor="language">Idioma</Label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-floating">
                      <Input
                        name="state"
                        type="text"
                        className="form-control"
                        onChange={user.handleChange}
                        onBlur={user.handleBlur}
                        value={user.values.state}
                        invalid={
                          user.touched.state && user.errors.state ? true : false
                        }
                      />
                      {user.touched.state && user.errors.state ? (
                        <FormFeedback type="invalid">
                          {user.errors.state}
                        </FormFeedback>
                      ) : null}
                      <Label htmlFor="state">Estado</Label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-floating">
                      <Input
                        name="city"
                        type="text"
                        className="form-control"
                        onChange={user.handleChange}
                        onBlur={user.handleBlur}
                        value={user.values.city}
                        invalid={
                          user.touched.city && user.errors.city ? true : false
                        }
                      />
                      {user.touched.city && user.errors.city ? (
                        <FormFeedback type="invalid">
                          {user.errors.city}
                        </FormFeedback>
                      ) : null}
                      <Label htmlFor="city">Cidade</Label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success">
                        Salvar usuário
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default UserFormComponente;
