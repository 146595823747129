import {
  CREATE_STORE_FAILURE,
  CREATE_STORE_REQUEST,
  CREATE_STORE_SUCCESS,
  DELETE_STORE_FAILURE,
  DELETE_STORE_PHOTO_FAILURE,
  DELETE_STORE_PHOTO_REQUEST,
  DELETE_STORE_PHOTO_SUCCESS,
  DELETE_STORE_REQUEST,
  DELETE_STORE_SUCCESS,
  GET_STORES_FAILURE,
  GET_STORES_REQUEST,
  GET_STORES_SUCCESS,
  GET_STORE_FAILURE,
  GET_STORE_REQUEST,
  GET_STORE_SUCCESS,
  UPDATE_STORE_FAILURE,
  UPDATE_STORE_PHOTO_FAILURE,
  UPDATE_STORE_PHOTO_REQUEST,
  UPDATE_STORE_PHOTO_SUCCESS,
  UPDATE_STORE_REQUEST,
  UPDATE_STORE_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: false,
  loading: false,
  data: [],
  success: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STORES_REQUEST:
      state = {
        ...state,
        data: [],
        loading: true,
        error: false,
      };
      break;

    case GET_STORES_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_STORES_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
      };
      break;

    case GET_STORE_REQUEST:
      state = {
        ...state,
        // data: {},
        loading: true,
        error: false,
      };
      break;

    case GET_STORE_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_STORE_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
      };
      break;

    case CREATE_STORE_REQUEST:
      console.log("REQUESTED...");

      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case CREATE_STORE_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
      break;

    case CREATE_STORE_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    case DELETE_STORE_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_STORE_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_STORE_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case DELETE_STORE_PHOTO_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_STORE_PHOTO_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_STORE_PHOTO_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case UPDATE_STORE_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case UPDATE_STORE_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case UPDATE_STORE_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case UPDATE_STORE_PHOTO_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case UPDATE_STORE_PHOTO_SUCCESS:
      console.log("SUCCESS =)", action);

      state = {
        ...state,
        data: { ...action.data },
        success: true,
        loading: false,
      };
      break;

    case UPDATE_STORE_PHOTO_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
