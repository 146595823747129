import {
  CREATE_TOURIST_RECEPTION_FAILURE,
  CREATE_TOURIST_RECEPTION_REQUEST,
  CREATE_TOURIST_RECEPTION_SUCCESS,
  DELETE_TOURIST_RECEPTION_FAILURE,
  DELETE_TOURIST_RECEPTION_PHOTO_FAILURE,
  DELETE_TOURIST_RECEPTION_PHOTO_REQUEST,
  DELETE_TOURIST_RECEPTION_PHOTO_SUCCESS,
  DELETE_TOURIST_RECEPTION_REQUEST,
  DELETE_TOURIST_RECEPTION_SUCCESS,
  GET_TOURIST_RECEPTIONS_FAILURE,
  GET_TOURIST_RECEPTIONS_REQUEST,
  GET_TOURIST_RECEPTIONS_SUCCESS,
  GET_TOURIST_RECEPTION_FAILURE,
  GET_TOURIST_RECEPTION_REQUEST,
  GET_TOURIST_RECEPTION_SUCCESS,
  UPDATE_TOURIST_RECEPTION_FAILURE,
  UPDATE_TOURIST_RECEPTION_PHOTO_FAILURE,
  UPDATE_TOURIST_RECEPTION_PHOTO_REQUEST,
  UPDATE_TOURIST_RECEPTION_PHOTO_SUCCESS,
  UPDATE_TOURIST_RECEPTION_REQUEST,
  UPDATE_TOURIST_RECEPTION_SUCCESS,
} from "./actionTypes";

export const getTouristReceptionsRequest = (data) => {
  return {
    type: GET_TOURIST_RECEPTIONS_REQUEST,
    data,
  };
};

export const getTouristReceptionsSuccess = (data) => {
  return {
    type: GET_TOURIST_RECEPTIONS_SUCCESS,
    data,
  };
};

export const getTouristReceptionsFailure = () => {
  return {
    type: GET_TOURIST_RECEPTIONS_FAILURE,
  };
};

export const getTouristReceptionFailure = () => {
  return {
    type: GET_TOURIST_RECEPTION_FAILURE,
  };
};

export const getTouristReceptionRequest = (data) => {
  return {
    type: GET_TOURIST_RECEPTION_REQUEST,
    data,
  };
};

export const getTouristReceptionSuccess = (data) => {
  return {
    type: GET_TOURIST_RECEPTION_SUCCESS,
    data,
  };
};

export const createTouristReceptionRequest = (data) => {
  return {
    type: CREATE_TOURIST_RECEPTION_REQUEST,
    data,
  };
};

export const createTouristReceptionSuccess = (data) => {
  return {
    type: CREATE_TOURIST_RECEPTION_SUCCESS,
    data,
  };
};

export const createTouristReceptionFailure = () => {
  return {
    type: CREATE_TOURIST_RECEPTION_FAILURE,
  };
};

export const deleteTouristReceptionFailure = () => {
  return {
    type: DELETE_TOURIST_RECEPTION_FAILURE,
  };
};

export const deleteTouristReceptionRequest = (data) => {
  return {
    type: DELETE_TOURIST_RECEPTION_REQUEST,
    data,
  };
};

export const deleteTouristReceptionSuccess = (data) => {
  return {
    type: DELETE_TOURIST_RECEPTION_SUCCESS,
    data,
  };
};

export const deleteTouristReceptionPhotoFailure = () => {
  return {
    type: DELETE_TOURIST_RECEPTION_PHOTO_FAILURE,
  };
};

export const deleteTouristReceptionPhotoRequest = (data) => {
  return {
    type: DELETE_TOURIST_RECEPTION_PHOTO_REQUEST,
    data,
  };
};

export const deleteTouristReceptionPhotoSuccess = (data) => {
  return {
    type: DELETE_TOURIST_RECEPTION_PHOTO_SUCCESS,
    data,
  };
};

export const updateTouristReceptionFailure = () => {
  return {
    type: UPDATE_TOURIST_RECEPTION_FAILURE,
  };
};

export const updateTouristReceptionRequest = (data) => {
  return {
    type: UPDATE_TOURIST_RECEPTION_REQUEST,
    data,
  };
};

export const updateTouristReceptionSuccess = (data) => {
  return {
    type: UPDATE_TOURIST_RECEPTION_SUCCESS,
    data,
  };
};

export const updateTouristReceptionPhotoFailure = () => {
  return {
    type: UPDATE_TOURIST_RECEPTION_PHOTO_FAILURE,
  };
};

export const updateTouristReceptionPhotoRequest = (data) => {
  return {
    type: UPDATE_TOURIST_RECEPTION_PHOTO_REQUEST,
    data,
  };
};

export const updateTouristReceptionPhotoSuccess = (data) => {
  return {
    type: UPDATE_TOURIST_RECEPTION_PHOTO_SUCCESS,
    data,
  };
};
