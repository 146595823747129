import {
  CREATE_RESTAURANT_FAILURE,
  CREATE_RESTAURANT_REQUEST,
  CREATE_RESTAURANT_SUCCESS,
  DELETE_RESTAURANT_FAILURE,
  DELETE_RESTAURANT_PHOTO_FAILURE,
  DELETE_RESTAURANT_PHOTO_REQUEST,
  DELETE_RESTAURANT_PHOTO_SUCCESS,
  DELETE_RESTAURANT_REQUEST,
  DELETE_RESTAURANT_SUCCESS,
  GET_RESTAURANTS_FAILURE,
  GET_RESTAURANTS_REQUEST,
  GET_RESTAURANTS_SUCCESS,
  GET_RESTAURANT_FAILURE,
  GET_RESTAURANT_REQUEST,
  GET_RESTAURANT_SUCCESS,
  UPDATE_RESTAURANT_FAILURE,
  UPDATE_RESTAURANT_PHOTO_FAILURE,
  UPDATE_RESTAURANT_PHOTO_REQUEST,
  UPDATE_RESTAURANT_PHOTO_SUCCESS,
  UPDATE_RESTAURANT_REQUEST,
  UPDATE_RESTAURANT_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: false,
  loading: false,
  data: [],
  success: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESTAURANTS_REQUEST:
      state = {
        ...state,
        data: [],
        loading: true,
        error: false,
      };
      break;

    case GET_RESTAURANTS_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_RESTAURANTS_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
      };
      break;

    case GET_RESTAURANT_REQUEST:
      state = {
        ...state,
        // data: {},
        loading: true,
        error: false,
      };
      break;

    case GET_RESTAURANT_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_RESTAURANT_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        // data: [],
        loading: false,
        error: action.data,
      };
      break;

    case CREATE_RESTAURANT_REQUEST:
      console.log("REQUESTED...");

      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case CREATE_RESTAURANT_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
      break;

    case CREATE_RESTAURANT_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    case DELETE_RESTAURANT_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_RESTAURANT_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_RESTAURANT_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        // data: [],
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case DELETE_RESTAURANT_PHOTO_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_RESTAURANT_PHOTO_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_RESTAURANT_PHOTO_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        // data: [],
        loading: false,
        error: true,
        success: false,
      };
      break;

    case UPDATE_RESTAURANT_REQUEST:
      state = {
        ...state,
        // data: {},
        loading: true,
        error: false,
      };
      break;

    case UPDATE_RESTAURANT_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case UPDATE_RESTAURANT_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        // data: [],
        loading: false,
        error: action.data,
      };
      break;

    case UPDATE_RESTAURANT_PHOTO_REQUEST:
      state = {
        ...state,
        // data: {},
        loading: true,
        error: false,
      };
      break;

    case UPDATE_RESTAURANT_PHOTO_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case UPDATE_RESTAURANT_PHOTO_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        // data: [],
        loading: false,
        error: action.data,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
