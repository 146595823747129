import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
} from "reactstrap";

import * as Yup from "yup";
import states from "../../utils/states.json";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Modal from "../../Components/Common/Modal";
import ModalLoding from "../../Components/Common/ModalLoading";

import UiContent from "../../Components/Common/UiContent";
import {
  CoverUploadImageField,
  InputMask,
  TextField,
} from "../../Components/Forms";
import LazyImage from "../../Components/LazyImage/LazyImage";
import Loading from "../../Components/Loading/Loading";
import PlaceMenu from "../../Components/Places/PlaceMenu";
import { getCitiesRequest } from "../../store/city/actions";
import {
  deleteStorePhotoRequest,
  getStoreRequest,
  updateStorePhotoRequest,
  updateStoreRequest,
} from "../../store/stores/actions";
import { isLatitude, isLongitude } from "../../utils/formats";
import { api } from "../../boot/api";
import showNofity from "../../helpers/toast";

const StoreFormEdit = () => {
  document.title = "Lojas | Ecoguias - Admin Dashboard";
  const dispatch = useDispatch();

  const [modalIsOpenRemove, setModalIsOpenRemove] = useState(false);
  const [modalIsOpenSelect, setModalIsOpenSelect] = useState(false);
  const [photoId, setPhotoId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [legendImage, setLegendImage] = useState([]);

  const { loading, data } = useSelector((state) => state.Store);
  const { data: dataCity, loading: loadingCity } = useSelector(
    (state) => state.City
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(getCitiesRequest());
  }, [dispatch]);
  const { id } = useParams();

  useEffect(
    function handleDefaultValuesEditForm() {
      dispatch(getStoreRequest(id));
    },
    [dispatch, id]
  );

  // alert(JSON.stringify(data.result.store?.cidade?.objectId));

  const store = useFormik({
    enableReinitialize: true,

    initialValues: {
      nome: data?.result?.store ? data?.result?.store?.nome : "",
      telefones: data?.result?.store ? data?.result?.store?.telefones : "",
      endereco: data?.result?.store ? data?.result?.store?.endereco : "",
      logradouro: data?.result?.store ? data?.result?.store?.logradouro : "",
      numero: data?.result?.store ? data?.result?.store?.numero : "",
      bairro: data?.result?.store ? data?.result?.store?.bairro : "",
      cidade: data?.result?.store ? data?.result?.store?.cidade?.objectId : "",
      estado: data?.result?.store ? data?.result?.store?.estado : "",
      images: data?.result?.store ? data?.result?.store?.images : [],
      url_video: data?.result?.store ? data?.result?.store?.url_video : "",
      whatsapp: data?.result?.store ? data?.result?.store?.whatsapp : "",
      instagram: data?.result?.store ? data?.result?.store?.instagram : "",
      descritivo: data?.result?.store ? data?.result?.store?.descritivo : "",
      url_site: data?.result?.store ? data?.result?.store?.url_site : "",
      email: data?.result?.store ? data?.result?.store?.email : "",
      latitude: data?.result?.store ? data?.result?.store?.gps?.latitude : "",
      longitude: data?.result?.store ? data?.result?.store?.gps?.longitude : "",
      // pet_friendly: data?.result?.store
      //   ? data?.result?.store?.pet_friendly
      //   : false,
    },

    validationSchema: Yup.object({
      nome: Yup.string().required("Este campo é obrigatório"),
      descritivo: Yup.string().required("Este campo é obrigatório"),
      url_video: Yup.string().optional(),
      telefones: Yup.string(),
      logradouro: Yup.string(),
      numero: Yup.string(),
      bairro: Yup.string(),
      cidade: Yup.string().required("Este campo é obrigatório"),
      estado: Yup.string(),
      images: Yup.array(Yup.string()),
      whatsapp: Yup.string().optional(),
      instagram: Yup.string().optional(),
      // media_preco: Yup.string(),
      url_site: Yup.string().optional(),
      email: Yup.string(),
      latitude: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      longitude: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      // .required("Este campo é obrigatório"),
      // pet_friendly: Yup.boolean().required("Este campo é obrigatório"),
    }),
    onSubmit: async (values) => {
      // values.pet_friendly = String(values.pet_friendly) === "true";
      values.latitude = values.latitude ? Number(values.latitude) : 0.0;
      values.longitude = values.longitude ? Number(values.longitude) : 0.0;

      console.log(values);

      dispatch(
        updateStoreRequest({
          id: id,
          values,
          onSucess: () => store.setFieldValue("images", []),
        })
      );
    },
  });

  function addLegendInState(index, txt) {
    const copyLegend = [...legendImage];
    copyLegend[index] = txt;

    setLegendImage(copyLegend);
  }

  async function updateLegend(imagemId, legenda) {
    try {
      await api.post("/photo-store-update-legend-admin", { imagemId, legenda });
      showNofity.success("Legenda salva com sucesso");
    } catch (error) {
      showNofity.error("Erro ao salvar legenda");
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Editar loja" pageTitle="Lojas" />
        </Container>
        <Col lg={12}>
          <Card>
            <div className="p-4">
              <button
                className="btn btn-primary"
                onClick={() => history.push("/stores")}
              >
                Voltar
              </button>
            </div>

            <CardBody>
              <div className="live-preview">
                <Row className="g-3">
                  <CoverUploadImageField
                    title="Adicionar imagens"
                    multiple
                    data={store}
                    name="images"
                    isActiveRadioSelect={false}
                  />
                  <TextField label="Nome" name="nome" data={store} />

                  <InputMask
                    type="phone-br"
                    label="Telefone"
                    name="telefones"
                    data={store}
                    size={6}
                  />

                  <TextField
                    label="Logradouro"
                    name="logradouro"
                    data={store}
                  />

                  <TextField
                    label="Número"
                    size={2}
                    name="numero"
                    data={store}
                  />
                  <TextField
                    label="Bairro"
                    size={4}
                    name="bairro"
                    data={store}
                  />

                  <TextField
                    label="Cidade"
                    name="cidade"
                    type="select"
                    data={store}
                  >
                    <option value="" disabled selected>
                      Selecione uma cidade
                    </option>
                    {dataCity?.result?.length > 0
                      ? dataCity?.result.map((item, index) => (
                          <option key={index} value={item.objectId}>
                            {item.nome}
                          </option>
                        ))
                      : undefined}
                  </TextField>

                  <TextField
                    label="Estado"
                    name="estado"
                    type="select"
                    data={store}
                    size={2}
                  >
                    <option value="" disabled selected>
                      Selecione um estado
                    </option>

                    {states.map(({ sigla, id_uf }) => (
                      <option key={id_uf} value={sigla}>
                        {sigla}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    label="Latitude"
                    name="latitude"
                    data={store}
                    size={2}
                    type="number"
                    onKeyDown={(event) => {
                      ["e", "E", "+", ","].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <TextField
                    label="Longitude"
                    name="longitude"
                    data={store}
                    size={2}
                    type="number"
                    onKeyDown={(event) => {
                      ["e", "E", "+", ","].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <TextField
                    label="Vídeo do local (URL)"
                    name="url_video"
                    data={store}
                  />

                  <TextField
                    label="Site"
                    name="url_site"
                    data={store}
                    size={6}
                  />

                  <TextField label="E-mail" name="email" data={store} />

                  <InputMask
                    type="phone-br"
                    label="WhatsApp"
                    name="whatsapp"
                    data={store}
                    size={6}
                  />

                  <TextField label="Instagram" name="instagram" data={store} />

                  {/* <TextField
                    type="select"
                    label="PETs Friendly"
                    name="pet_friendly"
                    data={store}
                  >
                    <option value="" selected disabled>
                      Selecione uma opção
                    </option>
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </TextField> */}

                  <TextField
                    size={12}
                    type="textarea"
                    label="Descrição"
                    name="descritivo"
                    data={store}
                  />

                  {data?.result?.images?.length > 0 && (
                    <>
                      <h3>Imagens do local</h3>
                      <p>
                        Escolha uma opção abaixo para excluir uma imagem ou
                        selecionar a imagem principal do local
                      </p>
                    </>
                  )}

                  {id && (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 10,
                        }}
                      >
                        {data?.result?.images?.map((item, idx) => {
                          return item.imagem?.name ? (
                            <div>
                              <Col key={idx} lg={3} style={styles.imageList}>
                                {item.imagem.url !==
                                data?.result?.store?.imagem.url ? (
                                  <PlaceMenu
                                    remove={() => {
                                      setPhotoId(item.objectId);
                                      setModalIsOpenRemove(true);
                                    }}
                                    select={() => {
                                      setSelectedImage(item?.imagem);
                                      setModalIsOpenSelect(true);
                                    }}
                                  />
                                ) : null}
                                <LazyImage
                                  src={item?.imagem?.url}
                                  width="100%"
                                  height="100%"
                                  className="object-fit-cover rounded"
                                  style={
                                    item.imagem.url ===
                                    data?.result?.store?.imagem.url
                                      ? styles.lazyImage
                                      : null
                                  }
                                />
                              </Col>
                              <div style={{ marginTop: 10 }}></div>
                              <InputGroup>
                                <Input
                                  value={item.legenda}
                                  title="Legenda"
                                  name="Legenda"
                                  placeholder="Legenda"
                                  size="sm"
                                  className="form-control"
                                  onChange={(event) =>
                                    addLegendInState(idx, event.target.value)
                                  }
                                />
                                <Button
                                  size="sm"
                                  className="primary"
                                  onClick={() =>
                                    updateLegend(
                                      item.objectId,
                                      legendImage[idx]
                                    )
                                  }
                                >
                                  <i className="ri-save-2-line fs-13"></i>
                                </Button>
                              </InputGroup>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </React.Fragment>
                  )}

                  <Col lg={12}>
                    <div className="text-end">
                      <button
                        onClick={() => store.handleSubmit()}
                        type="button"
                        className="btn btn-primary"
                      >
                        Salvar
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>

      <Modal
        isOpen={modalIsOpenRemove}
        text="Tem certeza que deseja remover essa imagem?"
        onClick={() => {
          dispatch(
            deleteStorePhotoRequest({
              storeId: id,
              photoId: photoId,
            })
          );

          setModalIsOpenRemove(false);
        }}
        onCancel={() => {
          setModalIsOpenRemove(false);
        }}
      />

      <Modal
        isOpen={modalIsOpenSelect}
        text="Tem certeza que deseja selecionar essa imagem como a principal desta loja?"
        onClick={() => {
          dispatch(
            updateStorePhotoRequest({
              storeId: id,
              imagem: selectedImage,
            })
          );

          setModalIsOpenSelect(false);
        }}
        onCancel={() => {
          setModalIsOpenSelect(false);
        }}
      />
      <ModalLoding loading={loading} />
    </React.Fragment>
  );
};

const styles = {
  lazyImage: {
    borderColor: "#93bf85",
    borderWidth: 5,
    borderStyle: "solid",
    borderRadius: 10,
  },
  imageList: {
    display: "flex",
    position: "relative",
    height: 220,
    width: 260,
  },
};

export default StoreFormEdit;
