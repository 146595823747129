import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { CidadesMaisAcessadasChart } from './DashboardAnalyticsCharts';

const CidadesMaisAcessadas = () => {
  const { data, loading } = useSelector((state) => ({
    data: state.DashboardAnalytics.allTime.data.cidadesPorAcesso,
    loading: state.DashboardAnalytics.allTime.loading
  }));

  const [chartLabels, chartData] = useMemo(() => {
    const labels = [];
    const values = [];
    if (data) {
      data.forEach((entry) => {
        labels.push(entry.label);
        values.push(entry.value);
      });
    }

    return [
      labels.reverse(),
      values.reverse(),
    ]
  }, [data]);

  return (
    <React.Fragment>
      <Col xl={6}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Cidades Mais Acessadas</h4>
          </CardHeader>
          <CardBody>
            <div dir="ltr">
              <CidadesMaisAcessadasChart
                seriesData={chartData}
                seriesLabels={chartLabels}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default CidadesMaisAcessadas;
