import React from "react";
import { Spinner } from "reactstrap";

const Loading = () => {
  return (
    <React.Fragment>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Spinner />
      </div>
    </React.Fragment>
  );
};

export default Loading;
