import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";
import { getContactsUsFailure, getContactsUsSuccess } from "./actions";
import { GET_CONTACTS_US_REQUEST } from "./actionTypes";

function requestContactsUs(data) {
  return post("/contacts-us-by-name", { contactsUsName: data });
}

function* getContactsUs({ data }) {
  try {
    const response = yield call(requestContactsUs, data);
    yield put(getContactsUsSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar informações. " + error);
    yield put(getContactsUsFailure());
  }
}

function* saga() {
  yield takeLatest(GET_CONTACTS_US_REQUEST, getContactsUs);
}

export default saga;
