import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { UsuariosPorInteresseChart } from './DashboardAnalyticsCharts';
import DadosInsuficientes from './DadosInsuficientes';

const UsuariosPorInteresse = () => {
  const { data, loading } = useSelector((state) => ({
    data: state.DashboardAnalytics.google.data.usuariosPorInteresse,
    loading: state.DashboardAnalytics.google.loading
  }));

  const [chartLabels, chartData] = useMemo(() => {
    const labels = [];
    const values = [];
    if (data) {
      data.rows.forEach((row) => {
        labels.push(row.dimensionValues[0].value);
        values.push(+row.metricValues[0].value);
      });
    }

    return [
      labels.reverse(),
      values.reverse(),
    ];
  }, [data]);

  let noDataPlaceholder;
  if (!loading && chartLabels.length === 0) {
    noDataPlaceholder = <DadosInsuficientes />;
  }

  return (
    <React.Fragment>
      <Col sm={12} xl={6} xxl={4}>
        <Card style={{ minHeight: '436px' }}>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Usuários por Interesse</h4>
          </CardHeader>
          <CardBody>
            {noDataPlaceholder
              ? noDataPlaceholder
              :
              <div dir="ltr">
                <UsuariosPorInteresseChart
                  seriesData={chartData}
                  seriesLabels={chartLabels}
                />
              </div>}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default UsuariosPorInteresse;
