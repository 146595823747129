import {
  GET_SUGGESTIONS_FAILURE,
  GET_SUGGESTIONS_REQUEST,
  GET_SUGGESTIONS_SUCCESS,
} from "./actionTypes";

export const getSuggestionsRequest = (data) => {
  return {
    type: GET_SUGGESTIONS_REQUEST,
    data,
  };
};

export const getSuggestionsSuccess = (data) => {
  return {
    type: GET_SUGGESTIONS_SUCCESS,
    data,
  };
};

export const getSuggestionsFailure = () => {
  return {
    type: GET_SUGGESTIONS_FAILURE,
  };
};
