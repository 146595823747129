import { useState, useEffect, useCallback } from 'react';
import ReactInputMask from "react-input-mask";
import { Col, FormFeedback, Input, Label } from "reactstrap";

export function InputMask({
  data,
  label,
  name,
  type: propType = "text",
  children,
  size = 6,
  mask: propMask,
  formatChars,
}) {
  let [mask, setMask] = useState(propMask);
  let [type, setType] = useState(propType);

  useEffect(() => {
    if (propType === 'phone-br') {
      setType('text');
      setMask('(99) 9999-9999 ');
    } else {
      setType(propType);
      setMask(propMask);
    }
  }, [propType, propMask]);

  const beforeMaskedValueChange = useCallback(
    (newState, oldState, userInput) => {
      if (propType !== 'phone-br') {
        return newState;
      }
      
      let { value } = newState;
      const lastPosition = oldState?.selection?.start ?? null;
      let newPosition = newState?.selection?.start ?? null;

      // if new input has 11 digits, set 9d mask
      // if new input has 10 digits
      //   and if and content being added in the end
      //     set 9d mask
      //     concatenate input

      // Check the length of input (numbers only) to determine the mask
      let newMask = '(99) 9999-9999';
      const numberOfDigits = newState.value.replace(/[^\d]/g, '').length;
      const appendingNinth = lastPosition === 14 && userInput?.match(/\d/);

      if (numberOfDigits === 10 && appendingNinth) {
        newMask = '(99) 99999-9999';
        value = value + userInput;
        newPosition++;
      }
      if (numberOfDigits > 10) {
        newMask = '(99) 99999-9999';
      }

      setMask(newMask)

      return {
        value,
        selection: { start: newPosition, end: newPosition },
      };
    },
    [propType],
  );

  return (
    <Col lg={size} key="descriptionField">
      <div className="form-floating">
        <Input
          defaultValue={data?.values[name]}
          name={name}
          type={type}
          className="form-control"
          onChange={data?.handleChange}
          onBlur={data?.handleBlur}
          value={data?.values[name]}
          mask={mask}
          invalid={data?.touched[name] && data?.errors[name] ? true : false}
          // style={type === "textarea" ? { minHeight: 300 } : null}
          tag={ReactInputMask}
          beforeMaskedValueChange={beforeMaskedValueChange}
        >
          {children}
        </Input>

        {data?.touched[name] && data?.errors[name] ? (
          <FormFeedback type="invalid">{data?.errors[name]}</FormFeedback>
        ) : null}

        <Label htmlFor={name}>{label}</Label>
      </div>
    </Col>
  );
}
