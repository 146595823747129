import { all, call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";
import {
  createStoreFailure,
  createStoreSuccess,
  deleteStorePhotoFailure,
  getStoreFailure,
  getStoresFailure,
  getStoresSuccess,
  getStoreSuccess,
  updateStoreFailure,
  updateStorePhotoFailure,
} from "./actions";
import {
  CREATE_STORE_REQUEST,
  DELETE_STORE_PHOTO_REQUEST,
  DELETE_STORE_REQUEST,
  GET_STORES_REQUEST,
  GET_STORE_REQUEST,
  UPDATE_STORE_PHOTO_REQUEST,
  UPDATE_STORE_REQUEST,
} from "./actionTypes";

function requestStores(data) {
  return post("/stores-by-name", { storeName: data });
}

function requestStoreById(id) {
  return post("/store", { storeId: id });
}

function deleteStoreById(id) {
  return post("/store-remove", { storeId: id });
}

function deletePhotoStore({ photoId, storeId }) {
  return post("/photo-store-remove", {
    storeId: storeId,
    photoId: photoId,
  });
}

function requestUpdateStore(id, store) {
  const {
    nome,
    telefones,
    logradouro,
    numero,
    bairro,
    cidade,
    estado,
    gps,
    url_video,
    whatsapp,
    instagram,
    email,
    url_site,
    descritivo,
    pet_friendly,
  } = store;

  return post("/store-update", {
    storeId: id,
    nome,
    telefones,
    logradouro,
    numero,
    bairro,
    cidade,
    estado,
    url_video,
    whatsapp,
    instagram,
    descritivo,
    url_site,
    email,
    gps,
    pet_friendly,
  });
}

function requestUpdateHotelPhoto({ storeId, imagem }) {
  return post("/photo-store-select", {
    storeId: storeId,
    imagem: imagem,
  });
}

function saveImage(imgs) {
  return post("/media-upload", { imgs });
}

function newStore(hotel) {
  return post("/stores", hotel);
}

function* getStores({ data }) {
  try {
    const response = yield call(requestStores, data);
    yield put(getStoreSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar hospedagem." + error);
    yield put(getStoreFailure());
  }
}

function* getStore({ data }) {
  try {
    const response = yield call(requestStoreById, data);
    yield put(getStoreSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar detalhes da loja." + error);
    yield put(getStoreFailure());
  }
}

function* deleteStore({ data }) {
  try {
    data.closeModal();

    yield call(deleteStoreById, data.storeId);
    const response = yield call(requestStores);
    yield put(getStoresSuccess(response.data));
    showNofity.success("Loja removida com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao deletar o hotel." + error);
    yield put(getStoreFailure());
  }
}

function saveFotos(img, id) {
  return post("/photos-store", {
    // url: img.url,
    nome_imagem: img.name,
    imagem: {
      __type: "File",
      name: img.name,
      url: img.url,
    },
    loja: {
      __type: "Pointer",
      className: "Loja",
      objectId: id,
    },
  });
}

function* deleteImageStore({ data }) {
  try {
    yield call(deletePhotoStore, data);
    showNofity.success("Imagem removida com sucesso.");
    const response = yield call(requestStoreById, data.storeId);
    yield put(getStoreSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao remover foto do hotel. " + error);
    put(deleteStorePhotoFailure());
  }
}

function* createStore({ data }) {
  try {
    const {
      nome,
      telefones,
      logradouro,
      numero,
      bairro,
      cidade,
      estado,
      images,
      url_video,
      whatsapp,
      instagram,
      descritivo,
      url_site,
      email,
      latitude,
      longitude,
      pet_friendly,
      imagem_principal,
    } = data.values;

    const responseImages = yield call(saveImage, images);
    const responseStore = yield call(newStore, {
      nome,
      telefones,
      logradouro,
      numero,
      bairro,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      estado,
      url_video,
      whatsapp,
      instagram,
      descritivo,
      url_site,
      email,
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
      pet_friendly,
      imagem: {
        __type: "File",
        url: responseImages.data[imagem_principal].url,
        name: responseImages.data[imagem_principal].name,
      },
    });

    yield all(
      responseImages.data.map((img) =>
        call(saveFotos, img, responseStore.data.objectId)
      )
    );

    yield put(createStoreSuccess());
    showNofity.success("Loja cadastrada com sucesso.");
    data.resetForm();
  } catch (error) {
    showNofity.error("Erro ao cadastrar loja. " + error);
    yield put(createStoreFailure());
  }
}

function* updateStore({ data }) {
  try {
    const {
      nome,
      telefones,
      logradouro,
      numero,
      bairro,
      cidade,
      estado,
      images,
      url_video,
      whatsapp,
      instagram,
      descritivo,
      url_site,
      email,
      latitude,
      longitude,
      pet_friendly,
    } = data.values;

    if (images?.length > 0) {
      const responseImages = yield call(saveImage, images);
      yield all(
        responseImages.data.map((img) => call(saveFotos, img, data.id))
      );
    }

    yield call(requestUpdateStore, data.id, {
      nome,
      telefones,
      logradouro,
      numero,
      bairro,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      estado,
      url_video,
      whatsapp,
      instagram,

      descritivo,
      url_site,
      email,
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
      pet_friendly,
    });

    const response = yield call(requestStoreById, data.id);
    yield put(getStoreSuccess(response.data));
    data.onSucess();
    showNofity.success("Loja atualizada com sucesso.");
  } catch (error) {
    yield put(updateStoreFailure());
    showNofity.error("Erro ao atualizar loja.");
  }
}

function* updateImageHotel({ data }) {
  try {
    yield call(requestUpdateHotelPhoto, {
      storeId: data.storeId,
      imagem: data.imagem,
    });

    const responseHotel = yield call(requestStoreById, data.storeId);

    yield put(getStoreSuccess(responseHotel.data));
  } catch (error) {
    showNofity.error("Erro ao atualizar foto do hotel.");
    yield put(updateStorePhotoFailure());
  }
}

function* saga() {
  yield takeLatest(GET_STORES_REQUEST, getStores);
  yield takeLatest(GET_STORE_REQUEST, getStore);
  yield takeLatest(DELETE_STORE_REQUEST, deleteStore);
  yield takeLatest(DELETE_STORE_PHOTO_REQUEST, deleteImageStore);
  yield takeLatest(CREATE_STORE_REQUEST, createStore);
  yield takeLatest(UPDATE_STORE_REQUEST, updateStore);
  yield takeLatest(UPDATE_STORE_PHOTO_REQUEST, updateImageHotel);
}

export default saga;
