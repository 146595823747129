import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Row } from "reactstrap";
import Modal from "../../Components/Common/Modal";
import Loading from "../../Components/Loading/Loading";
import { deleteFilterWhereEatRequest } from "../../store/filters/whereEat/actions";

function FiltersWhereEatTable({
  filters,
  loading,
  showModalEdit,
  idFilterWhereEat,
  setIdFilterWhereEat,
}) {
  const [modal, setModal] = useState(false);
  // const [idFilterWhereEat, setIdFilterWhereEat] = useState(null);
  const dispatch = useDispatch();

  if (loading) {
    return <Loading />;
  }

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Nome</span>,
      selector: (row) => row.nome || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Ações</span>,
      right: true,
      selector: (row) => (
        <div className="hstack gap-3 flex-wrap">
          <Link
            to="#"
            className="link-success fs-15"
            onClick={(event) => {
              event.preventDefault();
              setIdFilterWhereEat(row.objectId);
              showModalEdit();
            }}
          >
            <i className="ri-edit-2-line"></i>
          </Link>

          <Link
            to="#"
            className="link-danger fs-15"
            onClick={(event) => {
              event.preventDefault();

              setIdFilterWhereEat(row.objectId);
              setModal(true);
            }}
          >
            <i className="ri-delete-bin-line"></i>
          </Link>
        </div>
      ),
    },
  ];

  function deleteFilterWhereEat() {
    dispatch(
      deleteFilterWhereEatRequest({
        filterWhereEatId: idFilterWhereEat,
        closeModal: () => {
          setIdFilterWhereEat(null);
          setModal(false);
        },
      })
    );
  }

  return (
    <React.Fragment>
      <Row>
        <ToastContainer />
        <DataTable
          columns={columns}
          data={filters.length ? filters : []}
          pagination
          noDataComponent="Não existem dados na tabela de filtros!"
        />
      </Row>

      <Modal
        size="md"
        text="Tem certeza que deseja excluir esse filtro?"
        isOpen={modal}
        onClick={deleteFilterWhereEat}
        onCancel={() => {
          setModal(false);
          setIdFilterWhereEat(null);
        }}
      />
    </React.Fragment>
  );
}

export { FiltersWhereEatTable };
