import {
  CREATE_CITY_FAILURE,
  CREATE_CITY_REQUEST,
  CREATE_CITY_SUCCESS,
  DELETE_CITY_FAILURE,
  DELETE_CITY_PHOTO_FAILURE,
  DELETE_CITY_PHOTO_REQUEST,
  DELETE_CITY_PHOTO_SUCCESS,
  DELETE_CITY_REQUEST,
  DELETE_CITY_SUCCESS,
  GET_CITIES_FAILURE,
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_CITY_FAILURE,
  GET_CITY_REQUEST,
  GET_CITY_SUCCESS,
  UPDATE_CITY_PHOTO_FAILURE,
  UPDATE_CITY_PHOTO_REQUEST,
  UPDATE_CITY_PHOTO_SUCCESS,
  UPDATE_CITY_FAILURE,
  UPDATE_CITY_REQUEST,
  UPDATE_CITY_SUCCESS,
} from "./actionTypes";

export const getCitiesRequest = (data) => {
  return {
    type: GET_CITIES_REQUEST,
    data,
  };
};

export const getCitiesSuccess = (data) => {
  return {
    type: GET_CITIES_SUCCESS,
    data,
  };
};

export const getCitiesFailure = () => {
  return {
    type: GET_CITIES_FAILURE,
  };
};

export const getCityFailure = () => {
  return {
    type: GET_CITY_FAILURE,
  };
};

export const getCityRequest = (data) => {
  return {
    type: GET_CITY_REQUEST,
    data,
  };
};

export const getCitySuccess = (data) => {
  return {
    type: GET_CITY_SUCCESS,
    data,
  };
};

export const createCityRequest = (data) => {
  return {
    type: CREATE_CITY_REQUEST,
    data,
  };
};

export const createCitySuccess = (data) => {
  return {
    type: CREATE_CITY_SUCCESS,
    data,
  };
};

export const createCityFailure = () => {
  return {
    type: CREATE_CITY_FAILURE,
  };
};

export const deleteCityFailure = () => {
  return {
    type: DELETE_CITY_FAILURE,
  };
};

export const deleteCityRequest = (data) => {
  return {
    type: DELETE_CITY_REQUEST,
    data,
  };
};

export const deleteCitySuccess = (data) => {
  return {
    type: DELETE_CITY_SUCCESS,
    data,
  };
};

export const deleteCityPhotoFailure = () => {
  return {
    type: DELETE_CITY_PHOTO_FAILURE,
  };
};

export const deleteCityPhotoRequest = (data) => {
  return {
    type: DELETE_CITY_PHOTO_REQUEST,
    data,
  };
};

export const deleteCityPhotoSuccess = (data) => {
  return {
    type: DELETE_CITY_PHOTO_SUCCESS,
    data,
  };
};

export const updateCityFailure = () => {
  return {
    type: UPDATE_CITY_FAILURE,
  };
};

export const updateCityRequest = (data) => {
  return {
    type: UPDATE_CITY_REQUEST,
    data,
  };
};

export const updateCitySuccess = (data) => {
  return {
    type: UPDATE_CITY_SUCCESS,
    data,
  };
};

export const updateCityPhotoFailure = () => {
  return {
    type: UPDATE_CITY_PHOTO_FAILURE,
  };
};

export const updateCityPhotoRequest = (data) => {
  return {
    type: UPDATE_CITY_PHOTO_REQUEST,
    data,
  };
};

export const updateCityPhotoSuccess = (data) => {
  return {
    type: UPDATE_CITY_PHOTO_SUCCESS,
    data,
  };
};
