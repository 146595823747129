import { toast } from "react-toastify";

// const showNotify = (type, message) =>

const showNofity = {
  success(message) {
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-success text-white",
    });
  },

  error(message) {
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-danger text-white",
    });
  },
};

export default showNofity;
