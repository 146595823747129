import React from 'react';
import FeatherIcon from "feather-icons-react";

const DadosInsuficientes = () => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center m-auto text-center"
      style={{
        width: '200px',
        height: '300px',
      }}
    >
      <div className="avatar-sm flex-shrink-0">
        <span className="avatar-title bg-danger rounded-circle fs-2">
          <FeatherIcon
            icon="alert-triangle"
          />
        </span>
      </div>
      <p className="m-2">Dados insuficientes para o período</p>
    </div>
  );
};

export default DadosInsuficientes;