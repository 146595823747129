import { all, call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";
import {
  getUserIdFailure,
  getUserIdSuccess,
  getUsersFailure,
  getUsersSuccess,
  updateUserImageFailure,
} from "./actions";
import {
  GET_USERS_REQUEST,
  GET_USER_ID_REQUEST,
  UPDATE_USER_IMAGE_REQUEST,
} from "./actionTypes";

function requestUsers(data) {
  return post("/users-by-name", { userName: data });
}

function requestUserById(data) {
  return post("/user-by-id", { userId: data });
}

function* getUsers({ data }) {
  try {
    const response = yield call(requestUsers, data);
    yield put(getUsersSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar usuários. " + error);
    yield put(getUsersFailure());
  }
}

function* getUser({ data }) {
  try {
    const response = yield call(requestUserById, data);
    yield put(getUserIdSuccess(response.data.result));
  } catch (error) {
    showNofity.error("Erro ao buscar usuário. " + error);
    yield put(getUserIdFailure());
  }
}

function saveImage(imgs) {
  return post("/media-upload", { imgs });
}

function updateUserImageRequest(id, data) {
  const { foto } = data;

  return post("/user-update-image", {
    userId: id,
    foto,
  });
}

function* updateUserImage({ data }) {
  try {
    const { images } = data.values;

    const responseImages = yield call(saveImage, images);

    yield call(updateUserImageRequest, localStorage.getItem("userID"), {
      foto: {
        __type: "File",
        name: responseImages.data[0].name,
        url: responseImages.data[0].url,
      },
    });

    const response = yield call(
      requestUserById,
      localStorage.getItem("userID")
    );
    // console.log(response);
    // const response = yield call(requestUserById, data);
    yield put(getUserIdSuccess(response.data.result));
    data.resetForm();
    showNofity.success("Imagem atualizada com sucesso.");
  } catch (error) {
    // console.log(error);
    showNofity.error("Erro ao atualizar imagem.");
    yield put(updateUserImageFailure());
  }
}

function* saga() {
  yield takeLatest(GET_USERS_REQUEST, getUsers);
  yield takeLatest(GET_USER_ID_REQUEST, getUser);
  yield takeLatest(UPDATE_USER_IMAGE_REQUEST, updateUserImage);
}

export default saga;
