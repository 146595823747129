import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import noCover from "../../assets/images/No_cover.jpeg";
import Loading from "../../Components/Loading/Loading";
import {
  getDetailsPassportRequest,
  updatePassportRequest,
} from "../../store/passport/passportDetails/actions";

function PassportDetails({ objectId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetailsPassportRequest(objectId));
  }, [dispatch, objectId]);

  const { data, loading } = useSelector((state) => state.PassportDetails);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="row">
      <div className="col-4">
        <img
          src={data?.user?.foto?.url || noCover}
          alt=""
          width={150}
          height={150}
          style={{
            borderRadius: "50%",
            border: "3px solid #ccc",
            objectFit: "cover",
          }}
        />
      </div>

      <div className="col-8">
        <div>
          <label>Nome: </label> <span>{data?.nome}</span>
        </div>
        <div>
          <label>Usuário: </label> <span>{data?.user?.username}</span>
        </div>
        <div>
          <label>E-mail: </label> <span>{data?.email}</span>
        </div>
        <div>
          <label>Telefone: </label> <span>{data?.telefone}</span>
        </div>
        <div className="d-flex gap-2">
          <label>Foi emitido? </label>
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              defaultChecked={data?.emitido ? true : false}
              onClick={(event) => {
                dispatch(
                  updatePassportRequest({
                    id: objectId,
                    issued: event.target.checked,
                  })
                );
              }}
            />
            <Label>{data?.emitido ? "Sim" : "Não"}</Label>
          </FormGroup>
        </div>
      </div>
    </div>
  );
}

export default PassportDetails;
