import {
  GET_DETAILS_PASSPORT_FAILURE,
  GET_DETAILS_PASSPORT_REQUEST,
  GET_DETAILS_PASSPORT_SUCCESS,
  UPDATE_PASSPORT_FAILURE,
  UPDATE_PASSPORT_REQUEST,
  UPDATE_PASSPORT_SUCCESS,
} from "./actionTypes";

export const getDetailsPassportRequest = (data) => {
  return {
    type: GET_DETAILS_PASSPORT_REQUEST,
    data,
  };
};

export const getDetailsPassportSuccess = (data) => {
  return {
    type: GET_DETAILS_PASSPORT_SUCCESS,
    data,
  };
};

export const getDetailsPassportFailure = () => {
  return {
    type: GET_DETAILS_PASSPORT_FAILURE,
  };
};

export const updatePassportRequest = (data) => {
  return {
    type: UPDATE_PASSPORT_REQUEST,
    data,
  };
};

export const updatePassportSuccess = (data) => {
  return {
    type: UPDATE_PASSPORT_SUCCESS,
    data,
  };
};

export const updatePassportFailure = () => {
  return {
    type: UPDATE_PASSPORT_FAILURE,
  };
};
