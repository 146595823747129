import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { UsuariosPorGeneroChart } from './DashboardAnalyticsCharts';
import DadosInsuficientes from './DadosInsuficientes';

const GENDER_TRANSLATION = {
  'male': 'Masculino',
  'female': 'Feminino',
  'unknown': 'Desconhecido',
};

const UsuariosPorGenero = () => {
  const { data, loading } = useSelector((state) => ({
    data: state.DashboardAnalytics.google.data.usuariosPorGenero,
    loading: state.DashboardAnalytics.google.loading
  }));

  const [chartLabels, chartData] = useMemo(() => {
    const labels = [];
    const values = [];
    if (data) {
      data.rows.forEach((row) => {
        const gender = row.dimensionValues[0].value
        labels.push(GENDER_TRANSLATION[gender] || gender);
        values.push(+row.metricValues[0].value);
      });
    }

    return [labels, values];
  }, [data]);

  let noDataPlaceholder;
  if (!loading && chartLabels.length === 0) {
    noDataPlaceholder = <DadosInsuficientes />;
  }

  return (
    <React.Fragment>
      <Col sm={12} xl={6} xxl={4}>
        <Card style={{ minHeight: '436px' }}>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Usuários por Gênero</h4>
          </CardHeader>
          <CardBody>
            {noDataPlaceholder
              ? noDataPlaceholder
              :
              <div dir="ltr">
                <UsuariosPorGeneroChart
                  seriesData={chartData}
                  seriesLabels={chartLabels}
                  dataColors={'["--vz-secondary", "--vz-success", "--vz-warning"]'}
                />
              </div>}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
};

export default UsuariosPorGenero;
