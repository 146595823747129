import {
  GET_TOURIST_SPOT_PHOTO_USER_FAILURE,
  GET_TOURIST_SPOT_PHOTO_USER_REQUEST,
  GET_TOURIST_SPOT_PHOTO_USER_SUCCESS,
} from "./actionTypes";

export const getTouristSpotPhotoUserRequest = (data) => {
  return {
    type: GET_TOURIST_SPOT_PHOTO_USER_REQUEST,
    data,
  };
};

export const getTouristSpotPhotoUserSuccess = (data) => {
  return {
    type: GET_TOURIST_SPOT_PHOTO_USER_SUCCESS,
    data,
  };
};

export const getTouristSpotPhotoUserFailure = () => {
  return {
    type: GET_TOURIST_SPOT_PHOTO_USER_FAILURE,
  };
};
