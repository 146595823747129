import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";

import * as Yup from "yup";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import ModalLoding from "../../Components/Common/ModalLoading";

import UiContent from "../../Components/Common/UiContent";
import {
  CoverUploadGPSField,
  CoverUploadImageField,
  TextField,
} from "../../Components/Forms";
import showNofity from "../../helpers/toast";
import { getCitiesRequest } from "../../store/city/actions";
import { createWaterfallRequest } from "../../store/waterfall/actions";
import {
  isLatitude,
  isLongitude,
  parseDurationToMinutes,
} from "../../utils/formats";
import { DURATION_SCHEMA } from '../../utils/validation_schemas';

const MANDATORY_FIELD = "Este campo é obrigatório";

const WaterfallForm = () => {
  document.title = "Atrativos naturais | Ecoguias - Admin Dashboard";
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.Waterfall);
  const { data: dataCity, loading: loadingCity } = useSelector(
    (state) => state.City
  );
  const history = useHistory();

  useEffect(() => {
    dispatch(getCitiesRequest());
  }, [dispatch]);

  const waterfall = useFormik({
    enableReinitialize: true,

    initialValues: {
      nome: "",
      altitude: "",
      descritivo: "",
      images: [],
      url_video: "",
      propriedade_particular: "",
      caracteristicas: "",
      distancia_pe: "",
      tempo_carro: "",
      distancia_total: "",
      taxa_visitacao: "",
      referencia: "",
      observacoes: "",
      nivel_carro: "",
      nivel_pe: "",
      tempo_pe: "",
      // altura_queda: "",
      distancia_carro: "",
      // seguranca: "",
      imagem_principal: 0,
      cidade: "",
      arquivo_gps: "",
      altimetria: "",
      bounds_southwest_lat: "",
      bounds_southwest_lng: "",
      bounds_northwest_lat: "",
      bounds_northwest_lng: "",
      latitude: "",
      longitude: "",
    },

    validationSchema: Yup.object({
      nome: Yup.string().required(MANDATORY_FIELD),
      altitude: Yup.string().optional().notRequired(),
      descritivo: Yup.string().required(MANDATORY_FIELD),
      images: Yup.array(Yup.string()),
      url_video: Yup.string().optional().notRequired(),
      propriedade_particular: Yup.boolean(),
      caracteristicas: Yup.string().optional().notRequired(),
      distancia_pe: Yup.string().optional().notRequired(),
      tempo_carro: DURATION_SCHEMA,
      distancia_total: Yup.string().optional().notRequired(),
      taxa_visitacao: Yup.boolean().optional().notRequired(),
      referencia: Yup.string().optional().notRequired(),
      observacoes: Yup.string().optional().notRequired(),
      nivel_carro: Yup.string().optional().notRequired(),
      nivel_pe: Yup.string().optional().notRequired(),
      tempo_pe: DURATION_SCHEMA,
      // altura_queda: Yup.string().optional().notRequired(),
      distancia_carro: Yup.string().optional().notRequired(),
      // seguranca: Yup.string().optional().notRequired(),
      cidade: Yup.string().required(MANDATORY_FIELD),
      altimetria: Yup.array(),
      arquivo_gps: Yup.object(),
      bounds_southwest_lat: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      bounds_southwest_lng: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      bounds_northwest_lat: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      bounds_northwest_lng: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      latitude: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      longitude: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (values.images.length < 1) {
        showNofity.error("Adicione pelo menos uma foto do local");
        return;
      }

      values.propriedade_particular =
        String(values.propriedade_particular) === "true";
      values.taxa_visitacao = String(values.taxa_visitacao) === "true";
      values.tempo_carro = parseDurationToMinutes(values.tempo_carro);
      values.tempo_pe = parseDurationToMinutes(values.tempo_pe);
      values.latitude = values.latitude ? Number(values.latitude) : 0.0;
      values.longitude = values.longitude ? Number(values.longitude) : 0.0;
      values.altitude = values.altitude ? Number(values.altitude) : 0;
      values.distancia_pe = values.distancia_pe
        ? Number(values.distancia_pe)
        : 0;
      values.distancia_total = values.distancia_total
        ? Number(values.distancia_total)
        : 0;
      values.distancia_carro = values.distancia_carro
        ? Number(values.distancia_carro)
        : 0;

      dispatch(createWaterfallRequest({ values, resetForm }));
    },
  });

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Formulário de atrativos naturais"
            pageTitle="Atrativos naturais"
          />
        </Container>

        <Col lg={12}>
          <Card>
            <div className="p-4">
              <button
                className="btn btn-primary"
                onClick={() => history.push("waterfalls?page=1")}
              >
                Voltar
              </button>
            </div>

            <CardBody>
              <div className="live-preview">
                <Form
                  className="needs-validation"
                  onSubmit={(e) => {
                    e.preventDefault();
                    waterfall.handleSubmit();
                    return false;
                  }}
                  action="#"
                >
                  <Row className="g-3">
                    <TextField label="Nome" name="nome" data={waterfall} />

                    <TextField
                      label="Bioma"
                      name="caracteristicas"
                      data={waterfall}
                    />

                    <TextField
                      label="Observações"
                      name="observacoes"
                      data={waterfall}
                      size={6}
                    />

                    <TextField
                      type="select"
                      label="Cidade"
                      name="cidade"
                      data={waterfall}
                      size={6}
                    >
                      <option value="" selected disabled>
                        Selecione a cidade
                      </option>
                      {dataCity?.result?.length > 0 &&
                        dataCity?.result.map((item, index) => (
                          <option key={index} value={item.objectId}>
                            {item.nome}
                          </option>
                        ))}
                    </TextField>

                    <TextField
                      label="Vídeo do local (URL)"
                      name="url_video"
                      data={waterfall}
                      size={6}
                    />

                    <TextField
                      type="select"
                      label="Propriedade particular"
                      name="propriedade_particular"
                      data={waterfall}
                      size={3}
                    >
                      <option value="" selected disabled>
                        Selecione se o atrativos natural é particular
                      </option>
                      <option value={true}>Sim</option>
                      <option value={false}>Não</option>
                    </TextField>

                    {/* <TextField
                      label="Segurança"
                      name="seguranca"
                      data={waterfall}
                      size={3}
                    /> */}

                    <TextField
                      label="Distância à pé (metros)"
                      name="distancia_pe"
                      data={waterfall}
                      type="number"
                      size={3}
                      onKeyDown={(event) => {
                        ["e", "E", "+"].includes(event.key) &&
                          event.preventDefault();
                      }}
                    />

                    <TextField
                      label="Distância com carro (metros)"
                      name="distancia_carro"
                      data={waterfall}
                      type="number"
                      size={3}
                      onKeyDown={(event) => {
                        ["e", "E", "+"].includes(event.key) &&
                          event.preventDefault();
                      }}
                    />

                    <TextField
                      type="select"
                      label="Nível à pé"
                      name="nivel_pe"
                      data={waterfall}
                      size={3}
                    >
                      <option value="" selected disabled>
                        Selecione o nível
                      </option>
                      <option value="Fácil">Fácil</option>
                      <option value="Médio">Médio</option>
                      <option value="Difícil">Difícil</option>
                    </TextField>

                    <TextField
                      type="select"
                      label="Nível com carro"
                      name="nivel_carro"
                      data={waterfall}
                      size={3}
                    >
                      <option value="" selected disabled>
                        Selecione o nível
                      </option>
                      <option value="Fácil">Fácil</option>
                      <option value="Médio">Médio</option>
                      <option value="Difícil">Difícil</option>
                    </TextField>

                    <TextField
                      label="Tempo à pé"
                      name="tempo_pe"
                      data={waterfall}
                      type="duration"
                      size={3}
                    />

                    <TextField
                      label="Tempo de carro"
                      name="tempo_carro"
                      data={waterfall}
                      type="duration"
                      size={3}
                    />

                    <TextField
                      label="Distância total (metros)"
                      name="distancia_total"
                      data={waterfall}
                      type="number"
                      size={3}
                      onKeyDown={(event) => {
                        ["e", "E", "+"].includes(event.key) &&
                          event.preventDefault();
                      }}
                    />

                    <TextField
                      type="select"
                      label="Taxa de visita"
                      name="taxa_visitacao"
                      data={waterfall}
                      size={3}
                    >
                      <option value="" selected disabled>
                        Selecione se tem taxa de visita
                      </option>
                      <option value={true}>Sim</option>
                      <option value={false}>Não</option>
                    </TextField>

                    <TextField
                      label="Altitude (metros)"
                      name="altitude"
                      data={waterfall}
                      type="number"
                      size={3}
                      onKeyDown={(event) => {
                        ["e", "E", "+"].includes(event.key) &&
                          event.preventDefault();
                      }}
                    />

                    <TextField
                      label="Referência (GPS)"
                      name="referencia"
                      data={waterfall}
                      size={3}
                    />

                    <TextField
                      label="Limites sudoeste (latitude)"
                      name="bounds_southwest_lat"
                      data={waterfall}
                      size={3}
                    />
                    <TextField
                      label="Limites sudoeste (longitude)"
                      name="bounds_southwest_lng"
                      data={waterfall}
                      size={3}
                    />
                    <TextField
                      label="Limites noroeste (latitude)"
                      name="bounds_northwest_lat"
                      data={waterfall}
                      size={3}
                    />
                    <TextField
                      label="Limites noroeste (longitude)"
                      name="bounds_northwest_lng"
                      data={waterfall}
                      size={3}
                    />

                    <TextField
                      label="Latitude"
                      name="latitude"
                      data={waterfall}
                      type="number"
                      size={3}
                      onKeyDown={(event) => {
                        ["e", "E", "+", ","].includes(event.key) &&
                          event.preventDefault();
                      }}
                    />

                    <TextField
                      label="Longitude"
                      name="longitude"
                      data={waterfall}
                      type="number"
                      size={3}
                      onKeyDown={(event) => {
                        ["e", "E", "+", ","].includes(event.key) &&
                          event.preventDefault();
                      }}
                    />

                    <TextField
                      label="Descrição"
                      name="descritivo"
                      data={waterfall}
                      type="textarea"
                      size={12}
                    />

                    <CoverUploadGPSField
                      title="Arquivo de GPS (gpx ou kml)"
                      multiple={false}
                      data={waterfall}
                      name="arquivo_gps"
                      isActiveRadioSelect={false}
                    />

                    <CoverUploadImageField
                      title="Imagens do local"
                      multiple
                      data={waterfall}
                      name="images"
                    />

                    <Col lg={12}>
                      <div className="text-end">
                        <button type="submit" className="btn btn-primary">
                          Salvar
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>
      <ModalLoding loading={loading} />
    </React.Fragment>
  );
};

export default WaterfallForm;
