export const GET_FILTERS_WHERE_STAY_REQUEST = "GET_FILTERS_WHERE_STAY_REQUEST";
export const GET_FILTERS_WHERE_STAY_SUCCESS = "GET_FILTERS_WHERE_STAY_SUCCESS";
export const GET_FILTERS_WHERE_STAY_FAILURE = "GET_FILTERS_WHERE_STAY_FAILURE";

export const CREATE_FILTERS_WHERE_STAY_REQUEST =
  "CREATE_FILTERS_WHERE_STAY_REQUEST";
export const CREATE_FILTERS_WHERE_STAY_SUCCESS =
  "CREATE_FILTERS_WHERE_STAY_SUCCESS";
export const CREATE_FILTERS_WHERE_STAY_FAILURE =
  "CREATE_FILTERS_WHERE_STAY_FAILURE";

export const UPDATE_FILTERS_WHERE_STAY_REQUEST =
  "UPDATE_FILTERS_WHERE_STAY_REQUEST";
export const UPDATE_FILTERS_WHERE_STAY_SUCCESS =
  "UPDATE_FILTERS_WHERE_STAY_SUCCESS";
export const UPDATE_FILTERS_WHERE_STAY_FAILURE =
  "UPDATE_FILTERS_WHERE_STAY_FAILURE";

export const DELETE_FILTERS_WHERE_STAY_REQUEST =
  "DELETE_FILTERS_WHERE_STAY_REQUEST";
export const DELETE_FILTERS_WHERE_STAY_SUCCESS =
  "DELETE_FILTERS_WHERE_STAY_SUCCESS";
export const DELETE_FILTERS_WHERE_STAY_FAILURE =
  "DELETE_FILTERS_WHERE_STAY_FAILURE";
