import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
// import { getCitiesRequest } from "../../../store/city/actions";

const InfoWaterfall = ({ data }) => {
  // const dispatch = useDispatch();
  // const { data: dataCity } = useSelector((state) => state.City);

  // useEffect(() => {
  //   dispatch(getCitiesRequest());
  // }, [dispatch]);

  return (
    <React.Fragment>
      <Col key={data?.objectId}>
        <p className="text-justify">{data?.descritivo}</p>

        {data?.observacoes ? (
          <Row>
            <Col xl={2}>
              <i className="ri-information-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Observações:</label>
              <p>{data?.observacoes}</p>
            </Col>
          </Row>
        ) : null}

        {data?.caracteristicas ? (
          <Row>
            <Col xl={2}>
              <i className="ri-survey-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Características:</label>
              <p>{data?.caracteristicas}</p>
            </Col>
          </Row>
        ) : null}

        {data?.cidade ? (
          <Row>
            <Col xl={2}>
              <i className="ri-building-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Cidade:</label>
              <p>
                {data.cidade?.nome ?? ""}
              </p>
            </Col>
          </Row>
        ) : null}

        {data?.altitude ? (
          <Row>
            <Col xl={2}>
              <i className="ri-phone-fill fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Altitude:</label>
              <p>{data?.altitude}</p>
            </Col>
          </Row>
        ) : null}

        {data?.altura_queda ? (
          <Row>
            <Col xl={2}>
              <i className="ri-arrow-down-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Altura queda:</label>
              <p>{data?.altura_queda}</p>
            </Col>
          </Row>
        ) : null}

        {data?.distancia_carro ? (
          <Row>
            <Col xl={2}>
              <i className="ri-car-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Distância de carro:</label>
              <p>{data?.distancia_carro}</p>
            </Col>
          </Row>
        ) : null}

        {data?.distancia_pe ? (
          <Row>
            <Col xl={2}>
              <i className="ri-run-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Distância à pé:</label>
              <p>{data?.distancia_pe}</p>
            </Col>
          </Row>
        ) : null}

        {data?.distancia_total ? (
          <Row>
            <Col xl={2}>
              <i className="ri-compass-3-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Distância total:</label>
              <p>{data?.distancia_total}</p>
            </Col>
          </Row>
        ) : null}

        {data?.media_preco ? (
          <Row>
            <Col xl={2}>
              <i className="ri-money-dollar-circle-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Média de preço:</label>
              <p>{data?.media_preco}</p>
            </Col>
          </Row>
        ) : null}

        {data?.nivel_carro ? (
          <Row>
            <Col xl={2}>
              <i className="ri-car-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Nível de carro:</label>
              <p>{data?.nivel_carro}</p>
            </Col>
          </Row>
        ) : null}

        {data?.nivel_pe ? (
          <Row>
            <Col xl={2}>
              <i className="ri-run-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Nível à pé:</label>
              <p>{data?.nivel_pe}</p>
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col xl={2}>
            <i className="ri-home-2-line fs-36"></i>
          </Col>
          <Col xl={10}>
            <label>Propriedade particular:</label>
            <p>{data?.propriedade_particular === true ? "Sim" : "Não"}</p>
          </Col>
        </Row>

        {data?.referencia ? (
          <Row>
            <Col xl={2}>
              <i className="ri-pin-distance-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Referência:</label>
              <p>{data?.referencia}</p>
            </Col>
          </Row>
        ) : null}

        {data?.seguranca ? (
          <Row>
            <Col xl={2}>
              <i className="ri-shield-check-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Segurança:</label>
              <p>{data?.seguranca}</p>
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col xl={2}>
            <i className="ri-mail-line fs-36"></i>
          </Col>
          <Col xl={10}>
            <label>Taxa de visita:</label>
            <p>{data?.taxa_visitacao === true ? "Sim" : "Não"}</p>
          </Col>
        </Row>

        {data?.tempo_pe ? (
          <Row>
            <Col xl={2}>
              <i className="ri-map-pin-time-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Tempo à pé:</label>
              <p>{data?.tempo_pe}</p>
            </Col>
          </Row>
        ) : null}
      </Col>
    </React.Fragment>
  );
};

export default InfoWaterfall;
