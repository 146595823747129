import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../../boot/api";
import showNofity from "../../../helpers/toast";
import {
  createFilterWhereEatFailure,
  createFilterWhereEatSuccess,
  deleteFilterWhereEatFailure,
  deleteFilterWhereEatSuccess,
  getFiltersWhereEatFailure,
  getFiltersWhereEatSuccess,
  updateFilterWhereEatFailure,
  updateFilterWhereEatSuccess,
} from "./actions";
import {
  CREATE_FILTERS_WHERE_EAT_REQUEST,
  DELETE_FILTERS_WHERE_EAT_REQUEST,
  GET_FILTERS_WHERE_EAT_REQUEST,
  UPDATE_FILTERS_WHERE_EAT_REQUEST,
} from "./actionTypes";

function requestFiltersWhereEat(data) {
  return post("/filters-where-eat-by-name", { filterWhereEatName: data });
}

function newFilterWhereEat(filter) {
  return post("/filters-where-eat", filter);
}

function deleteFiltersWhereEatById(id) {
  return post("/filters-where-eat-remove", { filterWhereEatId: id });
}

function requestUpdateFilterWhereEat(id, filterWhereEat) {
  const { nome } = filterWhereEat;

  return post("/filters-where-eat-update", {
    filterWhereEatId: id,
    nome,
  });
}

function* getFiltersWhereEat({ data }) {
  try {
    const response = yield call(requestFiltersWhereEat, data);

    yield put(getFiltersWhereEatSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar filtros onde comer. " + error);
    yield put(getFiltersWhereEatFailure());
  }
}

function* createFilterWhereEat({ data }) {
  try {
    const { nome } = data.values;

    yield call(newFilterWhereEat, {
      nome,
    });

    yield put(createFilterWhereEatSuccess());
    data.resetForm();
    data.closeModal();
    const response = yield call(requestFiltersWhereEat);
    yield put(getFiltersWhereEatSuccess(response.data));
    showNofity.success("Filtro cadastrado com sucesso");
  } catch (error) {
    showNofity.error("Erro ao cadastrar filtro." + error);
    yield put(createFilterWhereEatFailure());
  }
}

function* updateFilterWhereEat({ data }) {
  console.log(">>>", data);
  try {
    const { nome } = data.values;

    yield call(requestUpdateFilterWhereEat, data.id, {
      nome,
    });

    yield put(updateFilterWhereEatSuccess());
    showNofity.success("Filtro atualizado com sucesso");
    data.closeModal();
    const response = yield call(requestFiltersWhereEat);
    yield put(getFiltersWhereEatSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao atualizar filtro " + error);
    yield put(updateFilterWhereEatFailure());
  }
}

function* deleteFilterWhereEat({ data }) {
  try {
    yield call(deleteFiltersWhereEatById, data.filterWhereEatId);
    yield put(deleteFilterWhereEatSuccess());
    data.closeModal();
    const response = yield call(requestFiltersWhereEat);
    yield put(getFiltersWhereEatSuccess(response.data));
    showNofity.success("Filtro removido com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao remover filtro. " + error);
    yield put(deleteFilterWhereEatFailure());
  }
}

function* saga() {
  yield takeLatest(GET_FILTERS_WHERE_EAT_REQUEST, getFiltersWhereEat);
  yield takeLatest(CREATE_FILTERS_WHERE_EAT_REQUEST, createFilterWhereEat);
  yield takeLatest(DELETE_FILTERS_WHERE_EAT_REQUEST, deleteFilterWhereEat);
  yield takeLatest(UPDATE_FILTERS_WHERE_EAT_REQUEST, updateFilterWhereEat);
}

export default saga;
