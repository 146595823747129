import { get, post, patch, destroy } from '../../boot/api'

export const getCertificateRequests = async (params = { page: '', limit: 0, search: '',}) => {
  const { data } = await get('/certificate-requests', params)
  return data
}

export const getCertificateRequest = async (id, params) => {
  const { data } = await get(`/certificate-requests/${id}`, params)
  return data
}

export const createCertificateRequest = async certificateRequest => {
  const { data } = await post('/certificate-requests', certificateRequest)
  return data
}

export const updateCertificateRequest = async (id, certificateRequest) => {
  const { data } = await patch(`/certificate-requests/${id}`, certificateRequest)
  return data
}

export const destroyCertificateRequest = async id => {
  await destroy(`/certificate-requests/${id}`)
}