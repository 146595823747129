import {
  GET_AGGREGATED_ANALYTICS_DATA,
  GET_AGGREGATED_ANALYTICS_FAILURE,
  GET_AGGREGATED_ANALYTICS_SUCCESS,
  GET_ALL_TIME_ANALYTICS_DATA,
  GET_ALL_TIME_ANALYTICS_FAILURE,
  GET_ALL_TIME_ANALYTICS_SUCCESS,
  GET_REAL_TIME_ANALYTICS_DATA,
  GET_REAL_TIME_ANALYTICS_FAILURE,
  GET_REAL_TIME_ANALYTICS_SUCCESS,
} from './actionType';

export const getAggregatedAnalyticsSuccess = (data) => {
  return {
    type: GET_AGGREGATED_ANALYTICS_SUCCESS,
    data,
  };
};

export const getAggregatedAnalyticsFailure = (data) => {
  return {
    type: GET_AGGREGATED_ANALYTICS_FAILURE,
    data,
  };
};

export const getAggregatedAnalyticsData = (options) => ({
  type: GET_AGGREGATED_ANALYTICS_DATA,
  data: options,
});

export const getAllTimeAnalyticsData = () => ({
  type: GET_ALL_TIME_ANALYTICS_DATA
});

export const getAllTimeAnalyticsSuccess = (data) => {
  return {
    type: GET_ALL_TIME_ANALYTICS_SUCCESS,
    data,
  };
};

export const getAllTimeAnalyticsFailure = (data) => {
  return {
    type: GET_ALL_TIME_ANALYTICS_FAILURE,
    data,
  };
};

export const getRealTimeAnalyticsData = () => ({
  type: GET_REAL_TIME_ANALYTICS_DATA
});

export const getRealTimeAnalyticsSuccess = (data) => {
  return {
    type: GET_REAL_TIME_ANALYTICS_SUCCESS,
    data,
  };
};

export const getRealTimeAnalyticsFailure = (data) => {
  return {
    type: GET_REAL_TIME_ANALYTICS_FAILURE,
    data,
  };
};