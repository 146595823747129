import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { UsuariosPorFaixaEtariaChart } from './DashboardAnalyticsCharts';
import DadosInsuficientes from './DadosInsuficientes';

const UsuariosPorFaixaEtaria = () => {
  const { data, loading } = useSelector((state) => ({
    data: state.DashboardAnalytics.google.data.usuariosPorFaixaEtaria,
    loading: state.DashboardAnalytics.google.loading
  }));

  const [chartLabels, chartData] = useMemo(() => {

    const labels = [];
    const values = [];
    if (data) {
      data.rows.forEach((row) => {
        let label = row.dimensionValues[0].value;
        if (label === 'unknown') {
          label = 'desconhecido';
        }
        labels.push(label);
        values.push(row.metricValues[0].value);
      });
    }

    return [labels, values];
  }, [data]);

  const dataUnavailable = !loading && !chartData.length;
  let noDataPlaceholder;
  if (dataUnavailable) {
    noDataPlaceholder = <DadosInsuficientes />;
  }

  return (
    <React.Fragment>
      <Col sm={12} xl={6} xxl={4} className="flex-1">
        <Card style={{ minHeight: '436px' }}>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Usuários por Faixa Etária</h4>
          </CardHeader>
          <CardBody>
            {dataUnavailable
              ? noDataPlaceholder
              :
              <div dir="ltr">
                <UsuariosPorFaixaEtariaChart
                  seriesData={chartData}
                  seriesLabels={chartLabels}
                />
              </div>
            }
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default UsuariosPorFaixaEtaria;
