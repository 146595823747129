import React from "react";
import { ToastContainer } from "react-toastify";
import { Card, CardHeader, Col, Row } from "reactstrap";
import ImagesGallery from "../ImagesGallery/ImagesGallery";

import PlayerVideo from "../PlayerVideo/PlayVideo";

const PlaceDetails = ({ data, images, loading, menu }) => {
  return (
    <React.Fragment>
      <ToastContainer />

      <Card className="p-4">
        <CardHeader>
          <h1>
            {(data?.nome ? data?.nome : "") +
              (data?.estado ? " / " + data?.estado : "")}
          </h1>
        </CardHeader>

        <Row>
          <Col xl={8}>
            <div className="mt-4">
              {images ? (
                <ImagesGallery
                  loading={loading}
                  images={images}
                  position="right"
                />
              ) : (
                <img src={data?.url_imagem_lista} alt={data?.nome} />
              )}
            </div>

            {data?.url_video ? (
              <Col className="mt-4">
                <h3>
                  Conheça mais sobre{" "}
                  {(data?.nome ? data?.nome : "") +
                    (data?.estado ? " / " + data?.estado : "")}
                </h3>
                <PlayerVideo url={data?.url_video} />
              </Col>
            ) : null}
          </Col>

          <Col xl={4}>
            <div className="mt-4">{menu}</div>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default PlaceDetails;
