import {
  CREATE_EVENTS_FAILURE,
  CREATE_EVENTS_REQUEST,
  CREATE_EVENTS_SUCCESS,
  DELETE_EVENT_FAILURE,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENT_FAILURE,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  REMOVE_IMAGE_EVENT_FAILURE,
  REMOVE_IMAGE_EVENT_REQUEST,
  REMOVE_IMAGE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
} from "./actionTypes";

export const getEventsRequest = (data) => {
  return {
    type: GET_EVENTS_REQUEST,
    data,
  };
};

export const getEventsSuccess = (data) => {
  return {
    type: GET_EVENTS_SUCCESS,
    data,
  };
};

export const getEventsFailure = () => {
  return {
    type: GET_EVENTS_FAILURE,
  };
};

export const getEventRequest = (data) => {
  return {
    type: GET_EVENT_REQUEST,
    data,
  };
};

export const getEventSuccess = (data) => {
  return {
    type: GET_EVENT_SUCCESS,
    data,
  };
};

export const getEventFailure = () => {
  return {
    type: GET_EVENT_FAILURE,
  };
};

export const createEventRequest = (data) => {
  return {
    type: CREATE_EVENTS_REQUEST,
    data,
  };
};

export const createEventSuccess = (data) => {
  return {
    type: CREATE_EVENTS_SUCCESS,
    data,
  };
};

export const createEventFailure = () => {
  return {
    type: CREATE_EVENTS_FAILURE,
  };
};

export const updateEventRequest = (data) => {
  return {
    type: UPDATE_EVENT_REQUEST,
    data,
  };
};

export const updateEventFailure = () => {
  return {
    type: UPDATE_EVENT_FAILURE,
  };
};

export const updateEventSuccess = (data) => {
  return {
    type: UPDATE_EVENT_SUCCESS,
    data,
  };
};

export const deleteEventFailure = () => {
  return {
    type: DELETE_EVENT_FAILURE,
  };
};

export const deleteEventRequest = (data) => {
  return {
    type: DELETE_EVENT_REQUEST,
    data,
  };
};

export const deleteEventSuccess = (data) => {
  return {
    type: DELETE_EVENT_SUCCESS,
    data,
  };
};

export const removeEventFailure = () => {
  return {
    type: REMOVE_IMAGE_EVENT_FAILURE,
  };
};

export const removeEventRequest = (data) => {
  return {
    type: REMOVE_IMAGE_EVENT_REQUEST,
    data,
  };
};

export const removeEventSuccess = (data) => {
  return {
    type: REMOVE_IMAGE_EVENT_SUCCESS,
    data,
  };
};
