import { all, call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";

import {
  createtCircuitSuccess,
  deleteCircuitFailure,
  deleteCircuitPhotoFailure,
  getCircuitFailure,
  getCircuitsFailure,
  getCircuitsSuccess,
  getCircuitSuccess,
  removeGpsCircuitFailure,
  removeMapCircuitFailure,
  updateCircuitFailure,
  updateCircuitPhotoFailure,
} from "./actions";

import {
  CREATE_CIRCUIT_REQUEST,
  DELETE_CIRCUIT_PHOTO_REQUEST,
  DELETE_CIRCUIT_REQUEST,
  GET_CIRCUITS_REQUEST,
  GET_CIRCUIT_REQUEST,
  REMOVE_GPS_CIRCUIT_REQUEST,
  REMOVE_MAP_CIRCUIT_REQUEST,
  UPDATE_CIRCUIT_PHOTO_REQUEST,
  UPDATE_CIRCUIT_REQUEST,
} from "./actionTypes";

function requestCircuits(data) {
  return post("/circuits-by-name", { circuitName: data });
}

function requestCircuitById(id) {
  return post("/circuit", { circuitId: id });
}

function deleteCircuitById(id) {
  return post("/circuit-remove", { circuitId: id });
}

function saveImage(imgs) {
  return post("/media-upload", { imgs });
}

function saveFotos(img, idCircuit) {
  return post("/photos-circuit", {
    url: img.url,
    imagem: {
      __type: "File",
      name: img.name,
      url: img.url,
    },
    nome_imagem: img.name,
    circuito: {
      __type: "Pointer",
      className: "Circuito",
      objectId: idCircuit,
    },
  });
}

function saveGps(geojson) {
  return post("/gps-upload", { geojson });
}

function newCircuit(circuit) {
  return post("/circuits", circuit);
}

function deletePhotoCircuit({ photoId, circuitId }) {
  return post("/photo-circuit-remove", {
    circuitId: circuitId,
    photoId: photoId,
  });
}

function removeMapCircuit(id) {
  return post("/map-circuit-remove", {
    circuitId: id,
  });
}

function removeGpsCircuit(id) {
  return post("/gps-circuit-remove", {
    circuitId: id,
  });
}

function requestUpdateCircuit(id, circuit) {
  const {
    titulo,
    descritivo_titulo,
    sub_titulo,
    descritivo_subtitulo,
    composicao_asfalto_distancia,
    composicao_asfalto_porcentagem,
    composicao_terra_distancia,
    composicao_terra_porcentagem,
    composicao_caminhada_distancia,
    composicao_caminhada_porcentagem,
    tempo_bicicleta,
    tempo_caminhada,
    tempo_cavalo,
    altimetria,
    arquivo_gps,
    bounds_southwest_lat,
    bounds_southwest_lng,
    bounds_northwest_lat,
    bounds_northwest_lng,
    mapa,
    url_video,
    cidades_roteiro,
  } = circuit;

  return post("/circuit-update", {
    circuitId: id,
    titulo,
    descritivo_titulo,
    sub_titulo,
    descritivo_subtitulo,
    composicao_asfalto_distancia,
    composicao_asfalto_porcentagem,
    composicao_terra_distancia,
    composicao_terra_porcentagem,
    composicao_caminhada_distancia,
    composicao_caminhada_porcentagem,
    tempo_bicicleta,
    tempo_caminhada,
    tempo_cavalo,
    altimetria,
    arquivo_gps,
    bounds_southwest_lat,
    bounds_southwest_lng,
    bounds_northwest_lat,
    bounds_northwest_lng,
    mapa,
    url_video,
    cidades_roteiro,
  });
}

function requestUpdateCircuitPhoto({ circuitId, imagem }) {
  return post("/photo-circuit-select", {
    circuitId,
    imagem,
  });
}

function* getCircuits({ data }) {
  try {
    const response = yield call(requestCircuits, data);
    yield put(getCircuitsSuccess(response.data));
  } catch (error) {
    yield put(getCircuitsFailure());
    showNofity.error("Erro ao buscar circuitos.");
  }
}

function* getCircuit({ data }) {
  try {
    const response = yield call(requestCircuitById, data);
    yield put(getCircuitSuccess(response?.data?.result));
  } catch (error) {
    yield put(getCircuitFailure());
    showNofity.error("Erro ao buscar detalhes do circuito." + error);
  }
}

function* deleteCircuit({ data }) {
  try {
    yield call(deleteCircuitById, data.circuitId);
    const response = yield call(requestCircuits);
    yield put(getCircuitsSuccess(response.data));
    showNofity.success("Circuito removido com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao deletar o circuito." + error);
    yield put(deleteCircuitFailure());
  } finally {
    data.closeModal();
  }
}

function* createCircuit({ data }) {
  try {
    const {
      titulo,
      descritivo_titulo,
      sub_titulo,
      descritivo_subtitulo,
      composicao_asfalto_distancia,
      composicao_asfalto_porcentagem,
      composicao_terra_distancia,
      composicao_terra_porcentagem,
      composicao_caminhada_distancia,
      composicao_caminhada_porcentagem,
      tempo_bicicleta,
      tempo_caminhada,
      tempo_cavalo,
      altimetria,
      arquivo_gps,
      bounds_southwest_lat,
      bounds_southwest_lng,
      bounds_northwest_lat,
      bounds_northwest_lng,
      mapa,
      images,
      imagem_principal,
      url_video,
      cidades_roteiro,
    } = data.values;

    const responseGpsSaved = yield call(saveGps, arquivo_gps);
    const responseMapImage = yield call(saveImage, mapa);
    let responseImages;
    const hasImages = images && images.length;

    const newCircuitRequestBody = {
      titulo,
      descritivo_titulo,
      sub_titulo,
      descritivo_subtitulo,
      composicao_asfalto_distancia,
      composicao_asfalto_porcentagem,
      composicao_terra_distancia,
      composicao_terra_porcentagem,
      composicao_caminhada_distancia,
      composicao_caminhada_porcentagem,
      tempo_bicicleta,
      tempo_caminhada,
      tempo_cavalo,
      mapa: {
        __type: "File",
        url: responseMapImage.data[0].url,
        name: responseMapImage.data[0].name,
      },
      altimetria,
      arquivo_gps: {
        __type: "File",
        name: responseGpsSaved?.data?.name,
        url: responseGpsSaved?.data?.url,
      },
      bounds_southwest_lat,
      bounds_southwest_lng,
      bounds_northwest_lat,
      bounds_northwest_lng,
      url_video,
      cidades_roteiro,
    };

    if (hasImages) {
        responseImages = yield call(saveImage, images);

        if (imagem_principal > 0) {
            newCircuitRequestBody.imagem = {
              __type: "File",
              url: responseImages.data[imagem_principal].url,
              name: responseImages.data[imagem_principal].name,
            };
        }
    }

    const responseCircuit = yield call(newCircuit, newCircuitRequestBody);

    if (hasImages) {
      yield all(
        responseImages?.data?.map((img) =>
          call(saveFotos, img, responseCircuit.data.objectId)
        )
      );
    }

    yield put(createtCircuitSuccess());
    showNofity.success("Circuito cadastrado com sucesso.");
    data.resetForm();
  } catch (error) {
    showNofity.error("Erro ao cadastrar circuito. " + error);
  }
}

function* updateCircuit({ data }) {
  try {
    const {
      titulo,
      descritivo_titulo,
      sub_titulo,
      descritivo_subtitulo,
      composicao_asfalto_distancia,
      composicao_asfalto_porcentagem,
      composicao_terra_distancia,
      composicao_terra_porcentagem,
      composicao_caminhada_distancia,
      composicao_caminhada_porcentagem,
      tempo_bicicleta,
      tempo_caminhada,
      tempo_cavalo,
      altimetria,
      arquivo_gps,
      bounds_southwest_lat,
      bounds_southwest_lng,
      bounds_northwest_lat,
      bounds_northwest_lng,
      mapa,
      images,
      url_video,
      cidades_roteiro,
    } = data.values;
    let responseMapImages;
    let responseImages;
    let responseGpsSaved = {};

    if (images?.length > 0) {
      const responseImages = yield call(saveImage, images);
      yield all(
        responseImages.data.map((img) => call(saveFotos, img, data.id))
      );
    }

    if (mapa?.length > 0) {
      responseMapImages = yield call(saveImage, mapa);
    }

    const body = {
      titulo,
      descritivo_titulo,
      sub_titulo,
      descritivo_subtitulo,
      composicao_asfalto_distancia,
      composicao_asfalto_porcentagem,
      composicao_terra_distancia,
      composicao_terra_porcentagem,
      composicao_caminhada_distancia,
      composicao_caminhada_porcentagem,
      tempo_bicicleta,
      tempo_caminhada,
      tempo_cavalo,
      bounds_southwest_lat,
      bounds_southwest_lng,
      bounds_northwest_lat,
      bounds_northwest_lng,
      mapa: responseMapImages?.data[0],
      arquivo_gps,
      url_video,
      cidades_roteiro,
    };

    if (arquivo_gps && arquivo_gps.__type !== "File") {
      responseGpsSaved = yield call(saveGps, arquivo_gps);

      body.arquivo_gps = {
        __type: "File",
        name: responseGpsSaved?.data?.name,
        url: responseGpsSaved?.data?.url,
      };
    }
    if (altimetria) {
      body.altimetria = altimetria;
    }

    yield call(requestUpdateCircuit, data.id, body);

    const response = yield call(requestCircuitById, data.id);
    yield put(getCircuitSuccess(response?.data?.result));
    showNofity.success("Circuito atualizado com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao atualizar circuito.");
    yield put(updateCircuitFailure());
  }
}

function* updateImageCircuit({ data }) {
  try {
    yield call(requestUpdateCircuitPhoto, {
      circuitId: data.circuitId,
      imagem: data.imagem,
    });

    const response = yield call(requestCircuitById, data.circuitId);
    showNofity.success("Imagem atualizada com sucesso.");
    yield put(getCircuitSuccess(response?.data?.result));
  } catch (error) {
    showNofity.error("Erro ao atualizar foto do circuito. " + error);
    yield put(updateCircuitPhotoFailure());
  }
}

function* deleteImageCircuit({ data }) {
  try {
    yield call(deletePhotoCircuit, data);
    showNofity.success("Imagem removida com sucesso.");
    const response = yield call(requestCircuitById, data.circuitId);
    yield put(getCircuitSuccess(response?.data?.result));
  } catch (error) {
    showNofity.error("Erro ao remover foto do circuito. " + error);
    yield put(deleteCircuitPhotoFailure());
  }
}

function* deleteMapCircuit({ data }) {
  try {
    yield call(removeMapCircuit, data.id);
    showNofity.success("Mapa removido com sucesso.");
    const response = yield call(requestCircuitById, data.id);
    yield put(getCircuitSuccess(response?.data?.result));
  } catch (error) {
    showNofity.error("Erro ao remover mapa do circuito. " + error);
    yield put(removeMapCircuitFailure());
  }
}

function* deleteGpsCircuit({ data }) {
  try {
    yield call(removeGpsCircuit, data.id);
    showNofity.success("GPS removido com sucesso.");
    const response = yield call(requestCircuitById, data.id);
    yield put(getCircuitSuccess(response?.data?.result));
  } catch (error) {
    showNofity.error("Erro ao remover GPS do circuito. " + error);
    yield put(removeGpsCircuitFailure());
  }
}

function* saga() {
  yield takeLatest(GET_CIRCUITS_REQUEST, getCircuits);
  yield takeLatest(GET_CIRCUIT_REQUEST, getCircuit);
  yield takeLatest(CREATE_CIRCUIT_REQUEST, createCircuit);
  yield takeLatest(DELETE_CIRCUIT_REQUEST, deleteCircuit);
  yield takeLatest(DELETE_CIRCUIT_PHOTO_REQUEST, deleteImageCircuit);
  yield takeLatest(REMOVE_MAP_CIRCUIT_REQUEST, deleteMapCircuit);
  yield takeLatest(REMOVE_GPS_CIRCUIT_REQUEST, deleteGpsCircuit);
  yield takeLatest(UPDATE_CIRCUIT_REQUEST, updateCircuit);
  yield takeLatest(UPDATE_CIRCUIT_PHOTO_REQUEST, updateImageCircuit);
}

export default saga;
