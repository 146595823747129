import { useEffect, useState } from 'react';
import { Col, FormFeedback, Input, Label } from "reactstrap";
import { formatMinutesToDuration, parseDurationToMinutes } from '../../../utils/formats';
import { DURATION_SCHEMA } from '../../../utils/validation_schemas';

export function TextField({
  data,
  label,
  name,
  type: propType = "text",
  children,
  size = 6,
  ...props
}) {
  let type = propType;
  let handleBlur = data?.handleBlur;

  if (type === 'duration') {
    type = 'text';
    handleBlur = async (e) => {
      data?.handleBlur(e);
  
      // Use Yup to validate the duration field directly
      try {
        await DURATION_SCHEMA.validate(e.target.value);
        // If validation succeeds, format the value
        const minutes = parseDurationToMinutes(e.target.value);
        data.setFieldValue(e.target.name, formatMinutesToDuration(minutes));
      } catch (error) {
        // If validation fails, Formik will display the error, so we don't need to do anything here
      }
    };
  }

  return (
    <Col lg={size} key="descriptionField">
      <div className="form-floating">
        <Input
          name={name}
          type={type}
          className="form-control"
          onChange={data?.handleChange}
          onBlur={handleBlur}
          value={data?.values[name]}
          invalid={data?.touched[name] && data?.errors[name] ? true : false}
          style={type === "textarea" ? { minHeight: 300 } : null}
          {...props}
        >
          {children}
        </Input>

        {data?.touched[name] && data?.errors[name] ? (
          <FormFeedback type="invalid">{data?.errors[name]}</FormFeedback>
        ) : null}

        <Label htmlFor={name}>{label}</Label>
      </div>
    </Col>
  );
}
