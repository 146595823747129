import {
  GET_FILTER_WHERE_EAT_FAILURE,
  GET_FILTER_WHERE_EAT_REQUEST,
  GET_FILTER_WHERE_EAT_SUCCESS,
} from "./actionTypes";

export const getFilterWhereEatRequest = (data) => {
  return {
    type: GET_FILTER_WHERE_EAT_REQUEST,
    data,
  };
};

export const getFilterWhereEatSuccess = (data) => {
  return {
    type: GET_FILTER_WHERE_EAT_SUCCESS,
    data,
  };
};

export const getFilterWhereEatFailure = () => {
  return {
    type: GET_FILTER_WHERE_EAT_FAILURE,
  };
};
