import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
// import { getCitiesRequest } from "../../../store/city/actions";

const InfoRestaurant = ({ data }) => {
  // const dispatch = useDispatch();

  // const { data: dataCity } = useSelector((state) => state.City);

  // useEffect(() => {
  //   dispatch(getCitiesRequest());
  // }, [dispatch]);

  return (
    <React.Fragment>
      <Col key={data?.objectId}>
        <p className="text-justify">{data?.descritivo}</p>

        {data?.logradouro ? (
          <Row>
            <Col xl={2}>
              <i className="ri-map-2-line  fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Rua:</label>
              <p>{data?.logradouro}</p>
            </Col>
          </Row>
        ) : null}

        {data?.bairro ? (
          <Row>
            <Col xl={2}>
              <i className="ri-map-2-line  fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Bairro:</label>
              <p>{data?.bairro}</p>
            </Col>
          </Row>
        ) : null}

        {data?.numero ? (
          <Row>
            <Col xl={2}>
              <i className="ri-home-7-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Número:</label>
              <p>{data?.numero}</p>
            </Col>
          </Row>
        ) : null}

        {data?.email ? (
          <Row>
            <Col xl={2}>
              <i className="ri-mail-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>E-mail:</label>
              <p>{data?.email}</p>
            </Col>
          </Row>
        ) : null}

        {data?.whatsapp ? (
          <Row>
            <Col xl={2}>
              <i className="ri-whatsapp-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>WhatsApp:</label>
              <p>{data?.whatsapp}</p>
            </Col>
          </Row>
        ) : null}

        {data?.instagram ? (
          <Row>
            <Col xl={2}>
              <i className="ri-instagram-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Instagram:</label>
              <p>{data?.instagram}</p>
            </Col>
          </Row>
        ) : null}

        {data?.telefones ? (
          <Row>
            <Col xl={2}>
              <i className="ri-phone-fill fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Telefone:</label>
              <p>{data?.telefones}</p>
            </Col>
          </Row>
        ) : null}

        {data?.url_site ? (
          <Row>
            <Col xl={2}>
              <i className="ri-global-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Site:</label>
              <p>{data?.url_site}</p>
            </Col>
          </Row>
        ) : null}

        {data?.tipo_culinaria ? (
          <Row>
            <Col xl={2}>
              <i className="ri-restaurant-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Tipo culinária:</label>
              <p>{data?.tipo_culinaria}</p>
            </Col>
          </Row>
        ) : null}

        {data?.url_video ? (
          <Row>
            <Col xl={2}>
              <i className="ri-youtube-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Vídeo:</label>
              <p>{data?.url_video}</p>
            </Col>
          </Row>
        ) : null}

        {data?.cidade ? (
          <Row>
            <Col xl={2}>
              <i className="ri-building-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Cidade:</label>
              <p>
                {data.cidade?.nome ?? ""}
              </p>
            </Col>
          </Row>
        ) : null}

        {data?.gps ? (
          <Row>
            <Col xl={2}>
              <i className="ri-map-2-line  fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>(latitude, longitude):</label>
              <p>
                {data?.gps?.latitude} / {data?.gps?.longitude}
              </p>
            </Col>
          </Row>
        ) : null}

        {data?.media_preco ? (
          <Row>
            <Col xl={2}>
              <i className="ri-money-dollar-circle-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Preço médio:</label>
              <p>{data?.media_preco}</p>
            </Col>
          </Row>
        ) : null}
      </Col>
    </React.Fragment>
  );
};

export default InfoRestaurant;
