import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Input } from "reactstrap";
import { useDebounce } from "use-debounce";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useDispatch, useSelector } from "react-redux";
import PlaceList from "../../Components/Places/PlaceList";
import { getTouristSpotsRequest } from "../../store/touristSpots/actions";

const TouristSpotList = () => {
  document.title = "Atrativo turístico | Ecoguias - Admin Dashboard";
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.TouristSpots);

  const { search } = useLocation();
  const queryStringParams = queryString.parse(search);

  const [searchInput, setSearchInput] = useState("");
  const [value] = useDebounce(searchInput, 500);

  useEffect(() => {
    dispatch(getTouristSpotsRequest(value));
  }, [dispatch, value]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Atrativo turístico" pageTitle="Locais" />
          <div className="d-flex justify-content-between pb-4 flex-wrap">
            <Input
              type="text"
              style={{ maxWidth: 250 }}
              className="form-control"
              placeholder="Pesquisar..."
              id="search-options"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={() => history.push("/tourist-spots-form")}
            >
              Cadastrar
            </button>
          </div>

          <PlaceList
            loading={loading}
            data={data?.result}
            pathPaginationName="/tourist-spots"
            page={queryStringParams.page}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TouristSpotList;
