import Dropzone from "react-dropzone";
import { Card, Col, FormGroup } from "reactstrap";
import * as turf from "@turf/turf";

import toGeoJSON from "togeojson";
import showNofity from "../../../helpers/toast";
import LazyImage from "../../LazyImage/LazyImage";

import geojsonlogo from "../../../assets/images/geojson.png";

export function CoverUploadGPSField({ data, name, title }) {
  return [
    <h3 key="placeCoverImage">{title}</h3>,
    <Dropzone
      maxFiles={1}
      key="dropzoneCoverField"
      accept=""
      multiple={false}
      onDrop={async (acceptedFiles) => {
        const typeFile = acceptedFiles[0].path.slice(-3);
        const reader = new FileReader();
        if (typeFile === "gpx") {
          reader.onload = (event) => {
            const gpx = event.target.result;

            if (!gpx) {
              return;
            }

            const geojson = toGeoJSON.gpx(
              new DOMParser().parseFromString(gpx, "text/xml")
            );

            // GERANDO ALTIMETRIA
            const coords = turf.coordAll(geojson);
            const altitudes = coords.map((coord) => turf.getCoord(coord)[2]);

            data.setFieldValue("altimetria", altitudes);
            data.setFieldValue(name, geojson);
          };
          reader.readAsText(acceptedFiles[0]);
        } else if (typeFile === "kml") {
          reader.onload = (event) => {
            const gpx = event.target.result;
            const geojson = toGeoJSON.kml(
              new DOMParser().parseFromString(gpx, "text/xml")
            );

            // GERANDO ALTIMETRIA
            const coords = turf.coordAll(geojson);
            const altitudes = coords.map((coord) => turf.getCoord(coord)[2]);

            data.setFieldValue("altimetria", altitudes);
            data.setFieldValue(name, geojson);

            data.setFieldValue(name, geojson);
          };
          reader.readAsText(acceptedFiles[0]);
        } else {
          showNofity.error("Selecione um arquivo válido");
        }
      }}
    >
      {({ getRootProps }) => (
        <div className="dropzone dz-clickable">
          <div className="dz-message needsClick" {...getRootProps()}>
            <div className="mb-3">
              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
            </div>
            <h4>Arraste o arquivo até aqui ou clique para selecionar</h4>
          </div>
        </div>
      )}
    </Dropzone>,
    <>
      {data.values[name] && (
        <div
          className="list-unstyled mb-0 d-flex flex-wrap"
          id="file-previews"
          key={Date.now()}
        >
          <FormGroup check>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: 10,
              }}
            >
              <Card className="shadow-none m-2 dz-processing dz-image-preview dz-success dz-complete">
                <Col
                  lg={3}
                  style={{
                    display: "flex",
                    position: "relative",
                    height: 100,
                    width: 100,
                  }}
                >
                  <>
                    <button
                      title="Excluir imagem"
                      className="position-absolute"
                      onClick={() => data.setFieldValue(name, "")}
                      type="button"
                      style={{
                        right: 10,
                        top: 5,
                        border: 0,
                        borderRadius: 15,
                        boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <i className="ri-delete-bin-7-line fs-16"></i>
                    </button>
                    <LazyImage
                      src={geojsonlogo}
                      width="80%"
                      // height="80%"
                      className="object-fit-cover rounded"
                    />
                  </>
                </Col>
              </Card>
            </div>
          </FormGroup>
        </div>
      )}
    </>,
  ];
}
