import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";

import * as Yup from "yup";

import ModalLoading from "../../Components/Common/ModalLoading";

import { CoverUploadImageField } from "../../Components/Forms/CoverUpload";

import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  getUserIdRequest,
  updateUserImageRequest,
} from "../../store/users/actions";
import { useFormik } from "formik";
import showNofity from "../../helpers/toast";
// import BreadCrumb from "../../Components/Common/BreadCrumb";
// import { getEventsRequest } from "../../store/events/actions";
// import { EventTable } from "./EventTable";

const ProfilePage = () => {
  document.title = "Eventos | Ecoguias - Admin Dashboard";
  const history = useHistory();

  const dispatch = useDispatch();

  const { loading, data } = useSelector((state) => state.Users);

  // const [searchInput, setSearchInput] = useState("");
  // const [value] = useDebounce(searchInput, 500);

  useEffect(() => {
    dispatch(getUserIdRequest(localStorage.getItem("userID")));
  }, [dispatch]);

  // console.log(data);

  const profile = useFormik({
    enableReinitialize: true,
    initialValues: Object.assign({
      images: [],
    }),

    validationSchema: Yup.object({
      images: Yup.array(Yup.string()).required("Este campo é obrigatório"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (values.images.length < 1) {
        showNofity.error("Adicione uma imagem");
        return;
      }
      dispatch(updateUserImageRequest({ values, resetForm }));
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Meu Perfil" pageTitle="Perfil" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Meu Perfil</h5>
                </CardHeader>
                <CardBody>
                  <center style={{ marginBottom: 100 }}>
                    <img
                      width={400}
                      height={400}
                      style={{ borderRadius: 200 }}
                      src={data?.foto?.url}
                      alt="foto de perfil do usuario"
                    />
                  </center>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      profile.handleSubmit();
                      return false;
                    }}
                    action="#"
                  >
                    <CoverUploadImageField
                      title="Imagem do usuário"
                      multiple={false}
                      data={profile}
                      name="images"
                      isActiveRadioSelect={false}
                    />

                    <Col lg={12}>
                      <div className="text-end">
                        <button type="submit" className="btn btn-primary">
                          Salvar
                        </button>
                      </div>
                    </Col>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ModalLoading loading={loading} />
    </React.Fragment>
  );
};

export default ProfilePage;
