import {
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_ID_FAILURE,
  GET_USER_ID_REQUEST,
  GET_USER_ID_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  UPDATE_USER_IMAGE_FAILURE,
  UPDATE_USER_IMAGE_REQUEST,
  UPDATE_USER_IMAGE_SUCCESS,
} from "./actionTypes";

export const getUsersRequest = (data) => {
  return {
    type: GET_USERS_REQUEST,
    data,
  };
};

export const getUsersSuccess = (data) => {
  return {
    type: GET_USERS_SUCCESS,
    data,
  };
};

export const getUsersFailure = () => {
  return {
    type: GET_USERS_FAILURE,
  };
};

export const getUserRequest = (data) => {
  return {
    type: GET_USER_REQUEST,
    data,
  };
};

export const getUserSuccess = (data) => {
  return {
    type: GET_USER_SUCCESS,
    data,
  };
};

export const getUserFailure = () => {
  return {
    type: GET_USER_FAILURE,
  };
};

export const getUserIdRequest = (data) => {
  // alert(data);
  return {
    type: GET_USER_ID_REQUEST,
    data,
  };
};

export const getUserIdSuccess = (data) => {
  return {
    type: GET_USER_ID_SUCCESS,
    data,
  };
};

export const getUserIdFailure = () => {
  return {
    type: GET_USER_ID_FAILURE,
  };
};

export const updateUserImageRequest = (data) => {
  // alert(data);
  return {
    type: UPDATE_USER_IMAGE_REQUEST,
    data,
  };
};

export const updateUserImageSuccess = (data) => {
  return {
    type: UPDATE_USER_IMAGE_SUCCESS,
    data,
  };
};

export const updateUserImageFailure = () => {
  return {
    type: UPDATE_USER_IMAGE_FAILURE,
  };
};

// export const createUSERRequest = (data) => {
//   return {
//     type: CREATE_USERS_REQUEST,
//     data,
//   };
// };

// export const createUSERSuccess = (data) => {
//   return {
//     type: CREATE_USERS_SUCCESS,
//     data,
//   };
// };

// export const createUSERFailure = () => {
//   return {
//     type: CREATE_USERS_FAILURE,
//   };
// };

// export const updateUSERRequest = (data) => {
//   return {
//     type: UPDATE_USER_REQUEST,
//     data,
//   };
// };

// export const updateUSERFailure = () => {
//   return {
//     type: UPDATE_USER_FAILURE,
//   };
// };

// export const updateUSERSuccess = (data) => {
//   return {
//     type: UPDATE_USER_SUCCESS,
//     data,
//   };
// };

// export const deleteUSERFailure = () => {
//   return {
//     type: DELETE_USER_FAILURE,
//   };
// };

// export const deleteUSERRequest = (data) => {
//   return {
//     type: DELETE_USER_REQUEST,
//     data,
//   };
// };

// export const deleteUSERSuccess = (data) => {
//   return {
//     type: DELETE_USER_SUCCESS,
//     data,
//   };
// };
