import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../../boot/api";
import showNofity from "../../../helpers/toast";
import { getFilterWhereEatFailure, getFilterWhereEatSuccess } from "./actions";
import { GET_FILTER_WHERE_EAT_REQUEST } from "./actionTypes";

function requestFilterWhereEat(id) {
  return post("/filters-where-eat-details", { filterWhereEatId: id });
}

function* getFilterWhereEat({ data }) {
  try {
    const response = yield call(requestFilterWhereEat, data);
    yield put(getFilterWhereEatSuccess(response.data.result));
  } catch (error) {
    showNofity.error("Erro ao buscar filtro. " + error);
    yield put(getFilterWhereEatFailure());
  }
}

function* saga() {
  yield takeLatest(GET_FILTER_WHERE_EAT_REQUEST, getFilterWhereEat);
}

export default saga;
