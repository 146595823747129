import React from 'react'
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
//Import Icons
import FeatherIcon from "feather-icons-react";

const UsuariosAtivos = () => {
  const usuariosAtivos = useSelector((state) => {
    const { usuariosPorPais } = state.DashboardAnalytics.realTime.data;
    const totals = usuariosPorPais?.totals?.[0]?.metricValues?.[0]?.value;
    if (totals) {
      return +totals;
    }
    return 0;
  });

  return (
    <Card>
      <CardBody>
        <p className="fw-medium text-muted text-uppercase mb-0">Usuários</p>
        <div className="d-flex justify-content-between">
          <h1 className="mt-4 ff-secondary fw-semibold">
            <span className="counter-value ms-2">
              <CountUp
                start={0}
                end={usuariosAtivos}
                duration={4}
                separator={'.'}
              />
            </span>
          </h1>
          <div className="avatar-sm flex-shrink-0 align-self-end">
            <span className="avatar-title bg-secondary rounded-circle fs-2">
              <FeatherIcon
                icon="users"
              />
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default UsuariosAtivos;