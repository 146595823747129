export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAILURE = "GET_CITIES_FAILURE";

export const GET_CITY_REQUEST = "GET_CITY_REQUEST";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_FAILURE = "GET_CITY_FAILURE";

export const CREATE_CITY_REQUEST = "CREATE_CITY_REQUEST";
export const CREATE_CITY_SUCCESS = "CREATE_CITY_SUCCESS";
export const CREATE_CITY_FAILURE = "CREATE_CITY_FAILURE";

export const DELETE_CITY_REQUEST = "DELETE_CITY_REQUEST";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";
export const DELETE_CITY_FAILURE = "DELETE_CITY_FAILURE";

export const DELETE_CITY_PHOTO_REQUEST = "DELETE_CITY_PHOTO_REQUEST";
export const DELETE_CITY_PHOTO_SUCCESS = "DELETE_CITY_PHOTO_SUCCESS";
export const DELETE_CITY_PHOTO_FAILURE = "DELETE_CITY_PHOTO_FAILURE";

export const UPDATE_CITY_REQUEST = "UPDATE_CITY_REQUEST";
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS";
export const UPDATE_CITY_FAILURE = "UPDATE_CITY_FAILURE";

export const UPDATE_CITY_PHOTO_REQUEST = "UPDATE_CITY_PHOTO_REQUEST";
export const UPDATE_CITY_PHOTO_SUCCESS = "UPDATE_CITY_PHOTO_SUCCESS";
export const UPDATE_CITY_PHOTO_FAILURE = "UPDATE_CITY_FAILURE";
