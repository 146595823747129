import {
  CREATE_FILTERS_WHERE_STAY_FAILURE,
  CREATE_FILTERS_WHERE_STAY_REQUEST,
  CREATE_FILTERS_WHERE_STAY_SUCCESS,
  DELETE_FILTERS_WHERE_STAY_FAILURE,
  DELETE_FILTERS_WHERE_STAY_REQUEST,
  DELETE_FILTERS_WHERE_STAY_SUCCESS,
  GET_FILTERS_WHERE_STAY_FAILURE,
  GET_FILTERS_WHERE_STAY_REQUEST,
  GET_FILTERS_WHERE_STAY_SUCCESS,
  UPDATE_FILTERS_WHERE_STAY_FAILURE,
  UPDATE_FILTERS_WHERE_STAY_REQUEST,
  UPDATE_FILTERS_WHERE_STAY_SUCCESS,
} from "./actionTypes";

export const getFiltersWhereStayRequest = (data) => {
  return {
    type: GET_FILTERS_WHERE_STAY_REQUEST,
    data,
  };
};

export const getFiltersWhereStaySuccess = (data) => {
  return {
    type: GET_FILTERS_WHERE_STAY_SUCCESS,
    data,
  };
};

export const getFiltersWhereStayFailure = () => {
  return {
    type: GET_FILTERS_WHERE_STAY_FAILURE,
  };
};

export const createFilterWhereStayRequest = (data) => {
  return {
    type: CREATE_FILTERS_WHERE_STAY_REQUEST,
    data,
  };
};

export const createFilterWhereStaySuccess = (data) => {
  return {
    type: CREATE_FILTERS_WHERE_STAY_SUCCESS,
    data,
  };
};

export const createFilterWhereStayFailure = () => {
  return {
    type: CREATE_FILTERS_WHERE_STAY_FAILURE,
  };
};

export const updateFilterWhereStayRequest = (data) => {
  return {
    type: UPDATE_FILTERS_WHERE_STAY_REQUEST,
    data,
  };
};

export const updateFilterWhereStayFailure = () => {
  return {
    type: UPDATE_FILTERS_WHERE_STAY_FAILURE,
  };
};

export const updateFilterWhereStaySuccess = (data) => {
  return {
    type: UPDATE_FILTERS_WHERE_STAY_SUCCESS,
    data,
  };
};

export const deleteFilterWhereStayFailure = () => {
  return {
    type: DELETE_FILTERS_WHERE_STAY_FAILURE,
  };
};

export const deleteFilterWhereStayRequest = (data) => {
  return {
    type: DELETE_FILTERS_WHERE_STAY_REQUEST,
    data,
  };
};

export const deleteFilterWhereStaySuccess = (data) => {
  return {
    type: DELETE_FILTERS_WHERE_STAY_SUCCESS,
    data,
  };
};
