import { all, call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";
import {
  createTouristReceptionFailure,
  createTouristReceptionSuccess,
  deleteTouristReceptionPhotoFailure,
  getTouristReceptionFailure,
  getTouristReceptionsFailure,
  getTouristReceptionsSuccess,
  getTouristReceptionSuccess,
  updateTouristReceptionFailure,
  updateTouristReceptionPhotoFailure,
} from "./actions";
import {
  CREATE_TOURIST_RECEPTION_REQUEST,
  DELETE_TOURIST_RECEPTION_PHOTO_REQUEST,
  DELETE_TOURIST_RECEPTION_REQUEST,
  GET_TOURIST_RECEPTIONS_REQUEST,
  GET_TOURIST_RECEPTION_REQUEST,
  UPDATE_TOURIST_RECEPTION_PHOTO_REQUEST,
  UPDATE_TOURIST_RECEPTION_REQUEST,
} from "./actionTypes";

function requestTouristReceptions(data) {
  return post("/tourism-receptive-by-name", { receptiveTourismName: data });
}

function requestTouristReception(id) {
  return post("/tourism-reception", { touristReceptionId: id });
}

function deleteTouristReceptionById(id) {
  return post("/tourism-reception-remove", { touristReceptionId: id });
}

function* getTouristReceptions({ data }) {
  try {
    const response = yield call(requestTouristReceptions, data);
    yield put(getTouristReceptionsSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar aos receptivos turísticos.");
    yield put(getTouristReceptionsFailure());
  }
}

function* getTouristReception({ data }) {
  try {
    const response = yield call(requestTouristReception, data);
    yield put(getTouristReceptionSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar o receptivo turístico." + error);
    yield put(getTouristReceptionFailure());
  }
}

function* deleteTouristReception({ data }) {
  try {
    yield call(deleteTouristReceptionById, data.touristReceptionId);
    data.closeModal();
    const response = yield call(requestTouristReceptions);
    yield put(getTouristReceptionsSuccess(response.data));
    showNofity.success("Receptivo turístico removido com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao remover o receptivo turístico." + error);
    yield put(getTouristReceptionsFailure());
  }
}

function saveImage(imgs) {
  return post("/media-upload", { imgs });
}

function newTouristPoint(point) {
  return post("/tourism-receptive", point);
}

function requestUpdateTouristReceptionPhoto({ touristReceptionId, imagem }) {
  return post("/photo-tourism-reception-select", {
    touristReceptionId: touristReceptionId,
    imagem: imagem,
  });
}

function deletePhotoTouristReception({ photoId, touristReceptionId }) {
  return post("/photo-tourism-reception-remove", {
    touristReceptionId: touristReceptionId,
    photoId: photoId,
  });
}

function requestUpdateTouristReception(id, touristReception) {
  const {
    nome,
    email,
    url_site,
    descritivo,
    url_video,
    endereco,
    telefones,
    plano,
    gps,
    cidade,
    logradouro,
    numero,
    bairro,
    whatsapp,
    instagram,
    estado,
  } = touristReception;

  return post("/tourism-reception-update", {
    touristReceptionId: id,
    nome,
    email,
    url_site,
    descritivo,
    url_video,
    endereco,
    telefones,
    plano,
    gps,
    cidade,
    logradouro,
    numero,
    bairro,
    whatsapp,
    instagram,
    estado,
  });
}

function saveFotos(img, idTouristReception) {
  return post("/photos-tourism-reception", {
    url: img.url,
    url_miniatura: img.url,

    //TO DO: Esse pointer está na tabela do receptivo turistico ao invés da de fotos.
    imagem: {
      __type: "File",
      name: img.name,
      url: img.url,
    },

    //TO DO: Realmente é necessário esse pointer? pois ele é um point de "cidade" apontando para "PontoTuristico"
    // cidade: {
    //   __type: "Pointer",
    //   className: "Cidade",
    //   objectId: cidade,
    // },

    nome_imagem: img.name,
    receptivo_turismo: {
      __type: "Pointer",
      className: "ReceptivoTurismo",
      objectId: idTouristReception,
    },
  });
}

function* createTouristReception({ data }) {
  try {
    const {
      images,
      cidade,
      nome,
      email,
      url_site,
      descritivo,
      url_video,
      telefones,
      logradouro,
      numero,
      bairro,
      whatsapp,
      instagram,
      latitude,
      longitude,
      estado,
      imagem_principal,
    } = data.values;
    const responseImages = yield call(saveImage, images);

    const responsePoints = yield call(newTouristPoint, {
      nome,
      email,
      url_site,
      descritivo,
      url_video,
      telefones,
      logradouro,
      numero,
      bairro,
      whatsapp,
      instagram,
      estado,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
      imagem: {
        __type: "File",
        url: responseImages.data[imagem_principal].url,
        name: responseImages.data[imagem_principal].name,
      },
    });

    yield all(
      responseImages.data.map((img) =>
        call(saveFotos, img, responsePoints.data.objectId)
      )
    );

    yield put(createTouristReceptionSuccess());
    showNofity.success("Turismo receptivo cadastrado com sucesso");
    data.resetForm();
  } catch (error) {
    showNofity.error("Erro ao cadastrar turismo receptivo." + error);
    yield put(createTouristReceptionFailure());
  }
}

function* deleteImageTouristReception({ data }) {
  try {
    yield call(deletePhotoTouristReception, data);
    showNofity.success("Imagem removida com sucesso.");
    const response = yield call(
      requestTouristReception,
      data.touristReceptionId
    );
    yield put(getTouristReceptionSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao remover foto da cidade. " + error);
    put(deleteTouristReceptionPhotoFailure());
  }
}

function* updateImageTouristReception({ data }) {
  try {
    yield call(requestUpdateTouristReceptionPhoto, {
      touristReceptionId: data.touristReceptionId,
      imagem: data.imagem,
    });

    const response = yield call(
      requestTouristReception,
      data.touristReceptionId
    );
    yield put(getTouristReceptionSuccess(response.data));
    showNofity.success("Imagem atualizada com sucesso.");
  } catch (error) {
    showNofity.error(
      "Não foi possível atualizar a imagem para principal. Aguarde alguns minutos e tente novamente." +
        error
    );
    yield put(updateTouristReceptionPhotoFailure());
  }
}

function* updateTouristReception({ data }) {
  try {
    const {
      images,
      cidade,
      nome,
      email,
      url_site,
      descritivo,
      url_video,
      telefones,
      logradouro,
      numero,
      bairro,
      whatsapp,
      instagram,
      latitude,
      longitude,
      estado,
    } = data.values;

    if (images?.length > 0) {
      const responseImages = yield call(saveImage, images);
      yield all(
        responseImages.data.map((img) => call(saveFotos, img, data.id))
      );
    }

    yield call(requestUpdateTouristReception, data.id, {
      nome,
      email,
      url_site,
      descritivo,
      url_video,
      telefones,
      images,
      logradouro,
      numero,
      bairro,
      whatsapp,
      instagram,
      estado,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
    });

    const response = yield call(requestTouristReception, data.id);
    yield put(getTouristReceptionSuccess(response.data));
    data.onSuccess();
    showNofity.success("Receptivo turístico atualizado com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao atualizar receptivo turístico.");
    yield put(updateTouristReceptionFailure());
  }
}

function* saga() {
  yield takeLatest(GET_TOURIST_RECEPTIONS_REQUEST, getTouristReceptions);
  yield takeLatest(GET_TOURIST_RECEPTION_REQUEST, getTouristReception);
  yield takeLatest(DELETE_TOURIST_RECEPTION_REQUEST, deleteTouristReception);
  yield takeLatest(
    DELETE_TOURIST_RECEPTION_PHOTO_REQUEST,
    deleteImageTouristReception
  );
  yield takeLatest(CREATE_TOURIST_RECEPTION_REQUEST, createTouristReception);
  yield takeLatest(
    UPDATE_TOURIST_RECEPTION_PHOTO_REQUEST,
    updateImageTouristReception
  );
  yield takeLatest(UPDATE_TOURIST_RECEPTION_REQUEST, updateTouristReception);
}

export default saga;
