import {
  GET_CONTACT_US_FAILURE,
  GET_CONTACT_US_REQUEST,
  GET_CONTACT_US_SUCCESS,
  UPDATE_ANSWER_CONTACT_US_FAILURE,
  UPDATE_ANSWER_CONTACT_US_REQUEST,
  UPDATE_ANSWER_CONTACT_US_SUCCESS,
} from "./actionTypes";

export const getContactUsRequest = (data) => {
  return {
    type: GET_CONTACT_US_REQUEST,
    data,
  };
};

export const getContactUsSuccess = (data) => {
  return {
    type: GET_CONTACT_US_SUCCESS,
    data,
  };
};

export const getContactUsFailure = () => {
  return {
    type: GET_CONTACT_US_FAILURE,
  };
};

export const updateAnswerContactUsRequest = (data) => {
  return {
    type: UPDATE_ANSWER_CONTACT_US_REQUEST,
    data,
  };
};

export const updateAnswerContactUsSuccess = (data) => {
  return {
    type: UPDATE_ANSWER_CONTACT_US_SUCCESS,
    data,
  };
};

export const updateAnswerContactUsFailure = () => {
  return {
    type: UPDATE_ANSWER_CONTACT_US_FAILURE,
  };
};
