import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Row } from "reactstrap";

// export function verifyURL(url) {
//   var expression =
//     /[-a-zA-Z0-9@:%.+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%+.~#?&//=]*)?/gi;
//   var regex = new RegExp(expression);

//   if (url.match(regex)) {
//     return true;
//   }
// }

const PlayerVideo = ({ url }) => {
  // if (verifyURL(url)) return null;
  const [errorURL, setErrorURL] = useState(false);

  if (errorURL) return null;

  return (
    <React.Fragment>
      <Row className="pl-0 my-4">
        <ReactPlayer
          url={url}
          width="100%"
          style={{ minHeight: 500 }}
          onError={() => {
            setErrorURL(true);
          }}
        />
      </Row>
    </React.Fragment>
  );
};

export default PlayerVideo;
