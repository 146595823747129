import moment from "moment";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Row } from "reactstrap";
import Modal from "../../Components/Common/Modal";
import Loading from "../../Components/Loading/Loading";
import { deleteEventRequest } from "../../store/events/actions";

function EventTable({ events, loading }) {
  const [modal, setModal] = useState(false);
  const [idEventSelected, setIdEventSelected] = useState(null);
  const dispatch = useDispatch();

  if (loading) {
    return <Loading />;
  }

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Nome</span>,
      selector: (row) => row?.nome || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Cidade</span>,
      selector: (row) => row?.cidade?.nome || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Data início</span>,
      selector: (row) =>
        moment(new Date(row.data_inicio?.iso))
          .add(3, "h")
          .format("DD/MM/YYYY") || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Hora início</span>,
      selector: (row) => row.hora_inicio || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Data término</span>,
      selector: (row) =>
        moment(new Date(row.data_termino?.iso))
          .add(3, "h")
          .format("DD/MM/YYYY") || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Hora término</span>,
      selector: (row) => row.hora_termino || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Ações</span>,
      right: true,
      selector: (row) => (
        <div className="hstack gap-3 flex-wrap">
          <Link
            to={`/events-edit/${row.objectId}`}
            className="link-success fs-15"
          >
            <i className="ri-edit-2-line"></i>
          </Link>

          <Link
            to="#"
            className="link-danger fs-15"
            onClick={(event) => {
              event.preventDefault();
              setIdEventSelected(row.objectId);
              setModal(true);
            }}
          >
            <i className="ri-delete-bin-line"></i>
          </Link>
        </div>
      ),
    },
  ];

  function deleteEvent() {
    dispatch(
      deleteEventRequest({
        eventId: idEventSelected,
        closeModal: () => {
          setIdEventSelected(null);
          setModal(false);
        },
      })
    );
  }

  return (
    <React.Fragment>
      <Row>
        <ToastContainer />
        <DataTable
          columns={columns}
          data={events.length ? events : []}
          pagination
          noDataComponent="Não existem dados na tabela de eventos!"
        />
      </Row>

      <Modal
        size="md"
        text="Tem certeza que deseja excluir esse evento?"
        isOpen={modal}
        onClick={deleteEvent}
        onCancel={() => {
          setModal(false);
          setIdEventSelected(null);
        }}
      />
    </React.Fragment>
  );
}

export { EventTable };
