export const GET_WATERFALLS_REQUEST = "GET_WATERFALLS_REQUEST";
export const GET_WATERFALLS_SUCCESS = "GET_WATERFALLS_SUCCESS";
export const GET_WATERFALLS_FAILURE = "GET_WATERFALLS_FAILURE";

export const GET_WATERFALL_REQUEST = "GET_WATERFALL_REQUEST";
export const GET_WATERFALL_SUCCESS = "GET_WATERFALL_SUCCESS";
export const GET_WATERFALL_FAILURE = "GET_WATERFALL_FAILURE";

export const CREATE_WATERFALL_REQUEST = "CREATE_WATERFALL_REQUEST";
export const CREATE_WATERFALL_SUCCESS = "CREATE_WATERFALL_SUCCESS";
export const CREATE_WATERFALL_FAILURE = "CREATE_WATERFALL_FAILURE";

export const DELETE_WATERFALL_REQUEST = "DELETE_WATERFALL_REQUEST";
export const DELETE_WATERFALL_SUCCESS = "DELETE_WATERFALL_SUCCESS";
export const DELETE_WATERFALL_FAILURE = "DELETE_WATERFALL_FAILURE";

export const DELETE_WATERFALL_PHOTO_REQUEST = "DELETE_WATERFALL_PHOTO_REQUEST";
export const DELETE_WATERFALL_PHOTO_SUCCESS = "DELETE_WATERFALL_PHOTO_SUCCESS";
export const DELETE_WATERFALL_PHOTO_FAILURE = "DELETE_WATERFALL_PHOTO_FAILURE";

export const UPDATE_WATERFALL_REQUEST = "UPDATE_WATERFALL_REQUEST";
export const UPDATE_WATERFALL_SUCCESS = "UPDATE_WATERFALL_SUCCESS";
export const UPDATE_WATERFALL_FAILURE = "UPDATE_WATERFALL_FAILURE";

export const UPDATE_WATERFALL_PHOTO_REQUEST = "UPDATE_WATERFALL_PHOTO_REQUEST";
export const UPDATE_WATERFALL_PHOTO_SUCCESS = "UPDATE_WATERFALL_PHOTO_SUCCESS";
export const UPDATE_WATERFALL_PHOTO_FAILURE = "UPDATE_WATERFALL_FAILURE";

export const REMOVE_GPS_WATERFALL_REQUEST = "REMOVE_GPS_WATERFALL_REQUEST";
export const REMOVE_GPS_WATERFALL_SUCCESS = "REMOVE_GPS_WATERFALL_SUCCESS";
export const REMOVE_GPS_WATERFALL_FAILURE = "REMOVE_GPS_WATERFALL_FAILURE";
