import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import Loading from "../../Components/Loading/Loading";
import {
  getContactUsRequest,
  updateAnswerContactUsRequest,
} from "../../store/contactsUs/contactsUsDetails/actions";

function ContactsUsDetails({ objectId }) {
  const dispatch = useDispatch();

  const { loading, data } = useSelector((state) => state.ContactsUsDetails);

  useEffect(() => {
    dispatch(getContactUsRequest(objectId));
  }, [dispatch, objectId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="d-flex flex-wrap flex-column">
      <div>
        <label>Nome: </label> <span>{data?.nome}</span>
      </div>
      <div>
        <label>Usuário: </label> <span>{data?.usuario?.username}</span>
      </div>
      <div>
        <label>E-mail: </label> <span>{data?.email}</span>
      </div>
      <div>
        <label>Telefone: </label> <span>{data?.telefone}</span>
      </div>
      <div>
        <label>Cidade: </label> <span>{data?.cidade}</span>
      </div>
      <div>
        <label>Mensagem: </label> <span>{data?.mensagem}</span>
      </div>
      <div className="d-flex gap-2">
        <label>Foi respondido? </label>
        <FormGroup switch>
          <Input
            type="switch"
            role="switch"
            defaultChecked={data?.respondido ? true : false}
            onClick={(event) => {
              dispatch(
                updateAnswerContactUsRequest({
                  id: objectId,
                  answered: event.target.checked,
                })
              );
            }}
          />
          <Label>{data?.respondido ? "Sim" : "Não"}</Label>
        </FormGroup>
      </div>
    </div>
  );
}

export default ContactsUsDetails;
