import React from "react";
import { ToastContainer } from "react-toastify";
import { Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import ServiceFormComponent from "./ServiceFormComponent";

const ServiceForm = () => {
  document.title = "Formulário de serviços";

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Formulário de serviços" pageTitle="Serviços" />
        </Container>
        <ServiceFormComponent />
      </div>
    </React.Fragment>
  );
};

export default ServiceForm;
