import queryString from "query-string";
import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Container } from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import Loading from "../../Components/Loading/Loading";
import PlaceDetails from "../../Components/Places/PlaceDetails";
import { getHotelRequest } from "../../store/hotels/actions";
import InfoHotel from "./components/InfoHotel";

const HotelDetails = () => {
  document.title = "Hotel | Ecoguias - Admin Dashboard";

  const { id } = useParams();
  const dispatch = useDispatch();

  const { data, loading } = useSelector((state) => state.Hotel);

  const { search } = useLocation();
  const history = useHistory();
  const queryStringParams = queryString.parse(search);

  useEffect(() => {
    dispatch(getHotelRequest(id));
  }, [id, dispatch]);

  function renderPlaceDetails() {
    if (!data?.result) {
      return null;
    }

    return (
      <PlaceDetails
        loading={loading}
        data={data?.result?.hotel}
        images={data?.result?.images}
        pathPaginationName="/hotels"
        page={queryStringParams.page}
        menu={<InfoHotel data={data?.result?.hotel} />}
      />
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Hotel" pageTitle="Locais" />
        </Container>

        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="p-4">
              <button
                className="btn btn-primary"
                onClick={() => history.push("/hotels?page=1")}
              >
                Voltar
              </button>
            </div>
            {renderPlaceDetails()}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default HotelDetails;
