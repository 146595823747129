import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";
import {
  createEventFailure,
  createEventSuccess,
  deleteEventFailure,
  getEventFailure,
  getEventsFailure,
  getEventsSuccess,
  getEventSuccess,
  updateEventFailure,
} from "./actions";
import {
  CREATE_EVENTS_REQUEST,
  DELETE_EVENT_REQUEST,
  GET_EVENTS_REQUEST,
  GET_EVENT_REQUEST,
  REMOVE_IMAGE_EVENT_REQUEST,
  UPDATE_EVENT_REQUEST,
} from "./actionTypes";

function requestEvents(data) {
  return post("/events-by-name", { eventName: data });
}

function deleteEventById(id) {
  return post("/event-remove", { eventId: id });
}

function requestEvent(id) {
  return post("/event", { eventId: id });
}

function saveImage(imgs) {
  return post("/media-upload", { imgs });
}

function requestRemoveImageEvent(id) {
  return post("/event-remove-image", { eventId: id });
}

function requestUpdateEvent(id, event) {
  // alert("ok");
  const {
    nome,
    descritivo,
    data_inicio,
    data_termino,
    hora_inicio,
    hora_termino,
    telefone,
    whatsapp,
    instagram,
    logradouro,
    numero,
    bairro,
    site,
    estado,
    cidade,
    url_imagem_cabecalho,
    email,
    gps,
    imagem,
  } = event;

  return post("/event-update", {
    eventId: id,
    nome,
    descritivo,
    data_inicio,
    data_termino,
    hora_inicio,
    hora_termino,
    telefone,
    whatsapp,
    instagram,
    logradouro,
    numero,
    bairro,
    estado,
    site,
    cidade,
    url_imagem_cabecalho,
    email,
    gps,
    imagem,
  });
}

function* getEvents({ data }) {
  try {
    const response = yield call(requestEvents, data);
    yield put(getEventsSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar eventos. " + error);
    yield put(getEventsFailure());
  }
}

function* getEvent({ data }) {
  try {
    const response = yield call(requestEvent, data);
    yield put(getEventSuccess(response.data.result));
  } catch (error) {
    showNofity.error("Erro ao buscar evento. " + error);
    yield put(getEventFailure());
  }
}

function newEvent(event) {
  return post("/events", event);
}

function* createEvent({ data }) {
  try {
    const {
      nome,
      descritivo,
      data_inicio,
      data_termino,
      hora_inicio,
      hora_termino,
      telefone,
      whatsapp,
      instagram,
      logradouro,
      numero,
      bairro,
      estado,
      site,
      cidade,
      url_imagem_cabecalho,
      email,
      latitude,
      longitude,
      images,
    } = data.values;

    const body = {
      nome,
      descritivo,
      data_termino: {
        __type: "Date",
        iso: data_termino,
      },
      data_inicio: {
        __type: "Date",
        iso: data_inicio,
      },
      hora_inicio,
      hora_termino,
      telefone,
      whatsapp,
      instagram,
      logradouro,
      numero,
      bairro,
      estado,
      site,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      url_imagem_cabecalho,
      email,
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
      // imagem: {
      //   __type: "File",
      //   url: responseImages.data[0]?.url,
      //   name: responseImages.data[0]?.name,
      // },
    };
    let responseImages;

    if (images.length > 0) {
      responseImages = yield call(saveImage, images);

      body.imagem = {
        __type: "File",
        name: responseImages.data[0]?.name,
        url: responseImages.data[0]?.url,
      };
    }

    yield call(newEvent, body);

    yield put(createEventSuccess());
    showNofity.success("Evento cadastrado com sucesso");
    data.resetForm();
  } catch (error) {
    showNofity.error("Erro ao cadastrar evento. " + error);
    yield put(createEventFailure());
  }
}

function* updateEvent({ data }) {
  try {
    const {
      nome,
      descritivo,
      data_inicio,
      data_termino,
      hora_inicio,
      hora_termino,
      telefone,
      whatsapp,
      instagram,
      logradouro,
      site,
      numero,
      bairro,
      estado,
      cidade,
      url_imagem_cabecalho,
      email,
      latitude,
      longitude,
      images,
    } = data.values;

    const body = {
      nome,
      descritivo,
      data_termino: {
        __type: "Date",
        iso: data_termino,
      },
      data_inicio: {
        __type: "Date",
        iso: data_inicio,
      },
      hora_inicio,
      hora_termino,
      telefone,
      whatsapp,
      instagram,
      logradouro,
      numero,
      bairro,
      site,
      estado,
      url_imagem_cabecalho,
      email,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
    };

    let responseImages;

    if (images.length > 0) {
      responseImages = yield call(saveImage, images);

      body.imagem = {
        __type: "File",
        name: responseImages.data[0]?.name,
        url: responseImages.data[0]?.url,
      };
    }

    yield call(requestUpdateEvent, data.id, body);

    const response = yield call(requestEvent, data.id);
    yield put(getEventSuccess(response.data.result));
    showNofity.success("Evento atualizado com sucesso.");
  } catch (error) {
    yield put(updateEventFailure());
    showNofity.error("Erro ao atualizar evento.");
  }
}

function* deleteEvent({ data }) {
  try {
    yield call(deleteEventById, data.eventId);
    data.closeModal();
    const response = yield call(requestEvents);
    yield put(getEventsSuccess(response.data));
    showNofity.success("Evento removido com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao remover evento. " + error);
    yield put(deleteEventFailure());
  }
}

function* removeImageEvent({ data }) {
  try {
    yield call(requestRemoveImageEvent, data.id);
    const response = yield call(requestEvent, data.id);
    yield put(getEventSuccess(response.data.result));
    showNofity.success("Imagem removida com sucesso.");
  } catch (error) {
    yield put(updateEventFailure());
    showNofity.error("Erro ao atualizar evento.");
  }
}

function* saga() {
  yield takeLatest(GET_EVENTS_REQUEST, getEvents);
  yield takeLatest(GET_EVENT_REQUEST, getEvent);
  yield takeLatest(CREATE_EVENTS_REQUEST, createEvent);
  yield takeLatest(DELETE_EVENT_REQUEST, deleteEvent);
  yield takeLatest(UPDATE_EVENT_REQUEST, updateEvent);
  yield takeLatest(REMOVE_IMAGE_EVENT_REQUEST, removeImageEvent);
}

export default saga;
