import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Row } from "reactstrap";
import Loading from "../../Components/Loading/Loading";
import ModalCustom from "../../Components/Modals/ModalCustom";
import { getPassportRequest } from "../../store/passport/actions";
import PassportDetails from "./PassportDetails";

function PassportTable({ passport, loading, searchInput }) {
  const [modal, setModal] = useState(false);
  const [passportId, setPassportId] = useState("");
  const { success } = useSelector((state) => state.PassportDetails);

  const dispatch = useDispatch();

  if (loading) {
    return <Loading />;
  }

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Nome</span>,
      selector: (row) => row?.nome || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Usuário</span>,
      selector: (row) => row?.user?.username || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">E-mail</span>,
      selector: (row) => row?.email || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Telefone</span>,
      selector: (row) => row?.telefone || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Foi emitido?</span>,
      width: "10%",
      selector: (row) =>
        row?.emitido ? (
          <span className="badge bg-success">Sim</span>
        ) : (
          <span className="badge bg-danger">Não</span> || "N/A"
        ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Ações</span>,
      width: "10%",
      right: true,
      selector: (row) => (
        <div className="hstack gap-3 flex-wrap">
          <Link
            to="#"
            className="link-success fs-15"
            onClick={() => {
              setPassportId(row?.objectId);

              setModal(true);
            }}
          >
            <i className="mdi mdi-eye"></i>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <ToastContainer />
        <DataTable
          columns={columns}
          data={passport}
          pagination
          noDataComponent="Não existem dados na tabela de passaporte!"
        />
      </Row>

      <ModalCustom
        isOpen={modal}
        labelConfirm="OK"
        onClick={() => {
          if (success) {
            dispatch(getPassportRequest(searchInput));
          }
          setModal(false);
        }}
        title="Detalhes passaporte"
      >
        <PassportDetails objectId={passportId} />
      </ModalCustom>
    </React.Fragment>
  );
}

export { PassportTable };
