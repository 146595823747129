import React from "react";

import "./assets/scss/themes.scss";

import Route from "./Routes";

import fakeBackend from "./helpers/AuthType/fakeBackend";
import { ToastContainer } from "react-toastify";

fakeBackend();

function App() {
  return (
    <React.Fragment>
      <ToastContainer />
      <Route />
    </React.Fragment>
  );
}

export default App;
