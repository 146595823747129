import { useFormik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";

import * as Yup from "yup";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import {
  CoverUploadGPSField,
  CoverUploadImageField,
  TextField,
} from "../../Components/Forms";
import ModalLoading from "../../Components/Common/ModalLoading";
import { parseDurationToMinutes } from "../../utils/formats";
import { DURATION_SCHEMA } from '../../utils/validation_schemas';

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import showNofity from "../../helpers/toast";
import { createCircuitRequest } from "../../store/circuits/actions";
import { SelectableField } from '../../Components/Forms/SelectableField';

const MANDATORY_FIELD = "Este campo é obrigatório";

const CircuitForm = () => {
  document.title = "Formulário de circuitos";

  const { loading } = useSelector((state) => state.Circuit);

  const history = useHistory();
  const dispatch = useDispatch();

  const circuit = useFormik({
    enableReinitialize: true,
    initialValues: Object.assign({
      titulo: "",
      descritivo_titulo: "",
      sub_titulo: "",
      descritivo_subtitulo: "",
      composicao_asfalto_distancia: "",
      composicao_asfalto_porcentagem: "",
      composicao_terra_distancia: "",
      composicao_terra_porcentagem: "",
      composicao_caminhada_distancia: "",
      composicao_caminhada_porcentagem: "",
      tempo_bicicleta: "",
      tempo_caminhada: "",
      tempo_cavalo: "",
      altimetria: "",
      arquivo_gps: "",
      mapa: [],
      images: [],
      url_video: "",
      cidades_roteiro: [],
    }),

    validationSchema: Yup.object({
      titulo: Yup.string().required(MANDATORY_FIELD),
      sub_titulo: Yup.string().required(MANDATORY_FIELD),
      descritivo_titulo: Yup.string().required(MANDATORY_FIELD),
      descritivo_subtitulo: Yup.string().required(MANDATORY_FIELD),
      composicao_asfalto_distancia: Yup.string().required(MANDATORY_FIELD),
      composicao_asfalto_porcentagem: Yup.string().required(MANDATORY_FIELD),
      composicao_terra_distancia: Yup.string().required(MANDATORY_FIELD),
      composicao_terra_porcentagem: Yup.string().required(MANDATORY_FIELD),
      composicao_caminhada_distancia: Yup.string().required(MANDATORY_FIELD),
      composicao_caminhada_porcentagem: Yup.string().required(MANDATORY_FIELD),
      tempo_bicicleta: DURATION_SCHEMA,
      tempo_caminhada: DURATION_SCHEMA,
      tempo_cavalo: DURATION_SCHEMA,
      altimetria: Yup.array(),
      arquivo_gps: Yup.object(),
      mapa: Yup.array(Yup.string()),
      images: Yup.array(Yup.string()),
      url_video: Yup.string().optional().notRequired(),
      cidades_roteiro: Yup.array(Yup.string()),
    }),
    onSubmit: (values, { resetForm }) => {
      if (values.mapa.length < 1) {
        showNofity.error("Adicione o mapa do circuito");
        return;
      }

      if (values.arquivo_gps.length < 1) {
        showNofity.error("Adicione o arquivo gps do circuito");
        return;
      }

      dispatch(createCircuitRequest({
        resetForm,
        values: {
          ...values,
          tempo_bicicleta: parseDurationToMinutes(values?.tempo_bicicleta),
          tempo_caminhada: parseDurationToMinutes(values?.tempo_caminhada),
          tempo_cavalo: parseDurationToMinutes(values?.tempo_cavalo),
        },
      }));
    },
  });

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Formulário de circuitos" pageTitle="Circuitos" />
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <div className="p-4">
                <button
                  className="btn btn-primary"
                  onClick={() => history.goBack()}
                >
                  Voltar
                </button>
              </div>
              <CardBody>
                <div className="live-preview">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      circuit.handleSubmit();
                      return false;
                    }}
                    action="#"
                  >
                    <Row className="g-3">
                      <TextField
                        label="Título"
                        name="titulo"
                        data={circuit}
                        size={12}
                      />
                      <TextField
                        label="Descritivo título"
                        name="descritivo_titulo"
                        data={circuit}
                        size={12}
                        type="textarea"
                      />
                      <TextField
                        label="Subtítulo"
                        name="sub_titulo"
                        data={circuit}
                        size={12}
                      />
                      <TextField
                        label="Descritivo subtítulo"
                        name="descritivo_subtitulo"
                        data={circuit}
                        size={12}
                        type="textarea"
                      />
                      <TextField
                        label="Vídeo do local (URL)"
                        name="url_video"
                        data={circuit}
                        size={12}
                      />
                      <TextField
                        label="Composicao asfalto (distância)"
                        name="composicao_asfalto_distancia"
                        data={circuit}
                      />
                      <TextField
                        label="Composicao asfalto (porcentagem)"
                        name="composicao_asfalto_porcentagem"
                        data={circuit}
                      />
                      <TextField
                        label="Composicao terra (distância)"
                        name="composicao_terra_distancia"
                        data={circuit}
                      />
                      <TextField
                        label="Composicao terra (porcentagem)"
                        name="composicao_terra_porcentagem"
                        data={circuit}
                      />
                      <TextField
                        label="Composicao caminhada (distância)"
                        name="composicao_caminhada_distancia"
                        data={circuit}
                      />
                      <TextField
                        label="Composicao caminhada (porcentagem)"
                        name="composicao_caminhada_porcentagem"
                        data={circuit}
                      />
                      <TextField
                        label="Tempo bicicleta"
                        type="duration"
                        name="tempo_bicicleta"
                        data={circuit}
                        size={4}
                      />
                      <TextField
                        label="Tempo caminhada"
                        type="duration"
                        name="tempo_caminhada"
                        data={circuit}
                        size={4}
                      />
                      <TextField
                        label="Tempo cavalo"
                        type="duration"
                        name="tempo_cavalo"
                        data={circuit}
                        size={4}
                      />
                      <SelectableField
                        label="Cidades do roteiro"
                        name="cidades_roteiro"
                        data={circuit}
                        size={6}
                      />

                      <CoverUploadGPSField
                        title="Arquivo de GPS (gpx ou kml)"
                        multiple={false}
                        data={circuit}
                        name="arquivo_gps"
                        isActiveRadioSelect={false}
                      />

                      <CoverUploadImageField
                        title="Mapa"
                        multiple={false}
                        data={circuit}
                        name="mapa"
                        isActiveRadioSelect={false}
                      />

                      <CoverUploadImageField
                        title="Imagens do local"
                        multiple
                        data={circuit}
                        name="images"
                      />

                      <Col lg={12}>
                        <div className="text-end">
                          <button type="submit" className="btn btn-primary">
                            Salvar
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalLoading loading={loading} />
    </React.Fragment>
  );
};

export default CircuitForm;
