import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
} from "reactstrap";

import * as Yup from "yup";
import { api } from "../../boot/api";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Modal from "../../Components/Common/Modal";
import ModalLoding from "../../Components/Common/ModalLoading";
import UiContent from "../../Components/Common/UiContent";
import { MANDATORY_FIELD } from "../../Components/constants/messages";
import {
  CoverUploadImageField,
  InputMask,
  TextField,
} from "../../Components/Forms";
import LazyImage from "../../Components/LazyImage/LazyImage";
import PlaceMenu from "../../Components/Places/PlaceMenu";
import showNofity from "../../helpers/toast";
import { getCitiesRequest } from "../../store/city/actions";
import { getFiltersWhereStayRequest } from "../../store/filters/whereStay/actions";
import {
  deleteHotelPhotoRequest,
  getHotelRequest,
  updateHotelPhotoRequest,
  updateHotelRequest,
} from "../../store/hotels/actions";
import { isLatitude, isLongitude } from "../../utils/formats";
import states from "../../utils/states.json";

const HotelFormEdit = () => {
  document.title = "Hospedagem | Ecoguias - Admin Dashboard";
  const dispatch = useDispatch();

  const [modalIsOpenRemove, setModalIsOpenRemove] = useState(false);
  const [modalIsOpenSelect, setModalIsOpenSelect] = useState(false);
  const [photoId, setPhotoId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [legendImage, setLegendImage] = useState([]);

  const { loading, data } = useSelector((state) => state.Hotel);
  const { data: dataCity, loading: loadingCity } = useSelector(
    (state) => state.City
  );
  const { data: dataFilter, loading: loadingFilter } = useSelector(
    (state) => state.FiltersWhereStay
  );
  const history = useHistory();

  const { id } = useParams();

  const hotelExists = data?.result?.hotel;
  const hotelResponse = data?.result?.hotel;

  useEffect(
    function handleDefaultValuesEditForm() {
      dispatch(getHotelRequest(id));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(getCitiesRequest());
    dispatch(getFiltersWhereStayRequest());
  }, [dispatch]);

  const hotel = useFormik({
    enableReinitialize: true,

    initialValues: {
      nome: hotelExists ? hotelResponse?.nome : "",
      telefones: hotelExists ? hotelResponse?.telefones : "",
      endereco: hotelExists ? hotelResponse?.endereco : "",
      logradouro: hotelExists ? hotelResponse?.logradouro : "",
      numero: hotelExists ? hotelResponse?.numero : "",
      bairro: hotelExists ? hotelResponse?.bairro : "",
      cidade: hotelExists ? hotelResponse?.cidade?.objectId : "",
      estado: hotelExists ? hotelResponse?.estado : "",
      images: hotelExists ? hotelResponse?.images : [],
      url_video: hotelExists ? hotelResponse?.url_video : "",
      whatsapp: hotelExists ? hotelResponse?.whatsapp : "",
      instagram: hotelExists ? hotelResponse?.instagram : "",
      // media_preco: hotelExists ? hotelResponse?.media_preco : "",
      descritivo: hotelExists ? hotelResponse?.descritivo : "",
      url_site: hotelExists ? hotelResponse?.url_site : "",
      email: hotelExists ? hotelResponse?.email : "",
      latitude: hotelExists ? hotelResponse?.gps?.latitude : "",
      longitude: hotelExists ? hotelResponse?.gps?.longitude : "",
      pet_friendly: hotelExists ? hotelResponse?.pet_friendly : false,
      filtro_onde_ficar: hotelExists
        ? hotelResponse?.filtro_onde_ficar?.objectId
        : "",
    },

    validationSchema: Yup.object({
      nome: Yup.string().required(MANDATORY_FIELD),
      descritivo: Yup.string().required(MANDATORY_FIELD),
      url_video: Yup.string().optional(),
      telefones: Yup.string(),
      logradouro: Yup.string(),
      numero: Yup.string(),
      bairro: Yup.string(),
      cidade: Yup.string().required(MANDATORY_FIELD),
      estado: Yup.string(),
      images: Yup.array(Yup.string()),
      whatsapp: Yup.string().optional(),
      instagram: Yup.string().optional(),
      // media_preco: Yup.string(),
      url_site: Yup.string().optional(),
      email: Yup.string(),
      latitude: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      longitude: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      // .required(MANDATORY_FIELD),
      pet_friendly: Yup.boolean().required(MANDATORY_FIELD),
      filtro_onde_ficar: Yup.string().required(MANDATORY_FIELD),
    }),
    onSubmit: async (values) => {
      values.pet_friendly = String(values.pet_friendly) === "true";
      values.latitude = values.latitude ? Number(values.latitude) : 0.0;
      values.longitude = values.longitude ? Number(values.longitude) : 0.0;

      dispatch(
        updateHotelRequest({
          id: id,
          values,
          onSucess: () => hotel.setFieldValue("images", []),
        })
      );
    },
  });

  function addLegendInState(index, txt) {
    const copyLegend = [...legendImage];
    copyLegend[index] = txt;

    setLegendImage(copyLegend);
  }

  async function updateLegend(imagemId, legenda) {
    try {
      await api.post("/photo-hotel-update-legend", { imagemId, legenda });
      showNofity.success("Legenda salva com sucesso");
    } catch (error) {
      showNofity.error("Erro ao salvar legenda");
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Editar hospedagem" pageTitle="Hospedagem" />
        </Container>
        <Col lg={12}>
          <Card>
            <div className="p-4">
              <button
                className="btn btn-primary"
                onClick={() => history.push("/hotels?page=1")}
              >
                Voltar
              </button>
            </div>

            <CardBody>
              <div className="live-preview">
                <Row className="g-3">
                  <CoverUploadImageField
                    title="Adicionar imagens"
                    multiple
                    data={hotel}
                    name="images"
                    isActiveRadioSelect={false}
                  />
                  <TextField label="Nome" name="nome" data={hotel} />

                  <TextField
                    type="select"
                    label="Filtro"
                    name="filtro_onde_ficar"
                    data={hotel}
                    size={6}
                  >
                    <option value="" selected disabled>
                      Selecione o filtro
                    </option>
                    {dataFilter?.result?.length > 0 &&
                      dataFilter?.result?.map((item, idx) => (
                        <option key={idx} value={item.objectId}>
                          {item.nome}
                        </option>
                      ))}
                  </TextField>

                  <InputMask
                    type="phone-br"
                    label="Telefone"
                    name="telefones"
                    data={hotel}
                    size={6}
                  />

                  <TextField
                    label="Logradouro"
                    name="logradouro"
                    data={hotel}
                  />

                  <TextField
                    label="Número"
                    size={2}
                    name="numero"
                    data={hotel}
                  />
                  <TextField
                    label="Bairro"
                    size={4}
                    name="bairro"
                    data={hotel}
                  />

                  <TextField
                    label="Cidade"
                    name="cidade"
                    type="select"
                    data={hotel}
                  >
                    <option value="" disabled selected>
                      Selecione uma cidade
                    </option>
                    {dataCity?.result?.length > 0 &&
                      dataCity?.result.map((item, index) => (
                        <option key={index} value={item.objectId}>
                          {item.nome}
                        </option>
                      ))}
                  </TextField>

                  <TextField
                    label="Estado"
                    name="estado"
                    type="select"
                    data={hotel}
                    size={2}
                  >
                    <option value="" disabled selected>
                      Selecione um estado
                    </option>

                    {states.map(({ sigla, id_uf }) => (
                      <option key={id_uf} value={sigla}>
                        {sigla}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    label="Latitude"
                    name="latitude"
                    data={hotel}
                    size={2}
                    type="number"
                    onKeyDown={(event) => {
                      ["e", "E", "+", ","].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <TextField
                    label="Longitude"
                    name="longitude"
                    data={hotel}
                    size={2}
                    type="number"
                    onKeyDown={(event) => {
                      ["e", "E", "+", ","].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <TextField
                    label="Vídeo do local (URL)"
                    name="url_video"
                    data={hotel}
                  />

                  {/* <TextField
                    label="Preço médio"
                    name="media_preco"
                    type="select"
                    data={hotel}
                    size={2}
                  >
                    <option value="" disabled selected></option>
                    <option value="$">$</option>
                    <option value="$$">$$</option>
                    <option value="$$$">$$$</option>
                    <option value="$$$$">$$$$$</option>
                    <option value="$$$$$$">$$$$$$</option>
                  </TextField> */}

                  <TextField
                    label="Site"
                    name="url_site"
                    data={hotel}
                    size={6}
                  />

                  <TextField label="E-mail" name="email" data={hotel} />

                  <InputMask
                    type="phone-br"
                    label="WhatsApp"
                    name="whatsapp"
                    data={hotel}
                    size={6}
                  />

                  <TextField label="Instagram" name="instagram" data={hotel} />

                  <TextField
                    type="select"
                    label="PETs Friendly"
                    name="pet_friendly"
                    data={hotel}
                  >
                    <option value="" selected disabled>
                      Selecione uma opção
                    </option>
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </TextField>

                  <TextField
                    size={12}
                    type="textarea"
                    label="Descrição"
                    name="descritivo"
                    data={hotel}
                  />

                  {data?.result?.images?.length > 0 && (
                    <>
                      <h3>Imagens do local</h3>
                      <p>
                        Escolha uma opção abaixo para excluir uma imagem ou
                        selecionar a imagem principal do local
                      </p>
                    </>
                  )}

                  {id && (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 10,
                        }}
                      >
                        {data?.result?.images?.map((item, idx) => {
                          return item.imagem?.name ? (
                            <div>
                              <Col key={idx} lg={3} style={styles.imageList}>
                                {item.imagem.url !==
                                data?.result?.hotel?.imagem.url ? (
                                  <PlaceMenu
                                    remove={() => {
                                      setPhotoId(item.objectId);
                                      setModalIsOpenRemove(true);
                                    }}
                                    select={() => {
                                      setSelectedImage(item?.imagem);
                                      setModalIsOpenSelect(true);
                                    }}
                                  />
                                ) : null}
                                <LazyImage
                                  src={item?.imagem?.url}
                                  width="100%"
                                  height="100%"
                                  className="object-fit-cover rounded"
                                  style={
                                    item.imagem.url ===
                                    data?.result?.hotel?.imagem.url
                                      ? styles.lazyImage
                                      : null
                                  }
                                />
                              </Col>
                              <div style={{ marginTop: 10 }}></div>
                              <InputGroup>
                                <Input
                                  value={item.legenda}
                                  title="Legenda"
                                  name="Legenda"
                                  placeholder="Legenda"
                                  size="sm"
                                  className="form-control"
                                  onChange={(event) =>
                                    addLegendInState(idx, event.target.value)
                                  }
                                />
                                <Button
                                  size="sm"
                                  className="primary"
                                  onClick={() =>
                                    updateLegend(
                                      item.objectId,
                                      legendImage[idx]
                                    )
                                  }
                                >
                                  <i className="ri-save-2-line fs-13"></i>
                                </Button>
                              </InputGroup>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </React.Fragment>
                  )}

                  <Col lg={12}>
                    <div className="text-end">
                      <button
                        onClick={() => hotel.handleSubmit()}
                        type="button"
                        className="btn btn-primary"
                      >
                        Salvar
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>

      <Modal
        isOpen={modalIsOpenRemove}
        text="Tem certeza que deseja remover essa imagem?"
        onClick={() => {
          dispatch(
            deleteHotelPhotoRequest({
              hotelId: id,
              photoId: photoId,
            })
          );

          setModalIsOpenRemove(false);
        }}
        onCancel={() => {
          setModalIsOpenRemove(false);
        }}
      />

      <Modal
        isOpen={modalIsOpenSelect}
        text="Tem certeza que deseja selecionar essa imagem como a principal deste hotel?"
        onClick={() => {
          dispatch(
            updateHotelPhotoRequest({
              hotelId: id,
              imagem: selectedImage,
            })
          );

          setModalIsOpenSelect(false);
        }}
        onCancel={() => {
          setModalIsOpenSelect(false);
        }}
      />
      <ModalLoding loading={loading} />
    </React.Fragment>
  );
};

const styles = {
  lazyImage: {
    borderColor: "#93bf85",
    borderWidth: 5,
    borderStyle: "solid",
    borderRadius: 10,
  },
  imageList: {
    display: "flex",
    position: "relative",
    height: 220,
    width: 260,
  },
};

export default HotelFormEdit;
