import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../../boot/api";
import showNofity from "../../../helpers/toast";
import {
  getDetailsPassportFailure,
  getDetailsPassportSuccess,
  updatePassportSuccess,
} from "./actions";
import {
  GET_DETAILS_PASSPORT_REQUEST,
  UPDATE_PASSPORT_REQUEST,
} from "./actionTypes";

function requestUpdatePassport({ id, issued }) {
  return post("/passport-update", { passportId: id, emitido: issued });
}

function requestPassportDetails(id) {
  return post("/passport-details", { passportId: id });
}

function* getDetailsPassport({ data }) {
  try {
    const response = yield call(requestPassportDetails, data);
    yield put(getDetailsPassportSuccess(response.data.result));
  } catch (error) {
    showNofity.error("Erro ao buscar detalhes do passaporte virtual. " + error);
    yield put(getDetailsPassportFailure());
  }
}

function* updatePassport({ data }) {
  try {
    const response = yield call(requestUpdatePassport, data);
    yield put(getDetailsPassportSuccess(response.data.result[0]));
    yield put(updatePassportSuccess(response.data.result[0]));
  } catch (error) {
    showNofity.error("Erro ao atualizar passaporte virtual. " + error);
    yield put(getDetailsPassportFailure());
  }
}

function* saga() {
  yield takeLatest(GET_DETAILS_PASSPORT_REQUEST, getDetailsPassport);
  yield takeLatest(UPDATE_PASSPORT_REQUEST, updatePassport);
}

export default saga;
