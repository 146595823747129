export const GET_RESTAURANTS_REQUEST = "GET_RESTAURANTS_REQUEST";
export const GET_RESTAURANTS_SUCCESS = "GET_RESTAURANTS_SUCCESS";
export const GET_RESTAURANTS_FAILURE = "GET_RESTAURANTS_FAILURE";

export const GET_RESTAURANT_REQUEST = "GET_RESTAURANT_REQUEST";
export const GET_RESTAURANT_SUCCESS = "GET_RESTAURANT_SUCCESS";
export const GET_RESTAURANT_FAILURE = "GET_RESTAURANT_FAILURE";

export const CREATE_RESTAURANT_REQUEST = "CREATE_RESTAURANT_REQUEST";
export const CREATE_RESTAURANT_SUCCESS = "CREATE_RESTAURANT_SUCCESS";
export const CREATE_RESTAURANT_FAILURE = "CREATE_RESTAURANT_FAILURE";

export const DELETE_RESTAURANT_REQUEST = "DELETE_RESTAURANT_REQUEST";
export const DELETE_RESTAURANT_SUCCESS = "DELETE_RESTAURANT_SUCCESS";
export const DELETE_RESTAURANT_FAILURE = "DELETE_RESTAURANT_FAILURE";

export const DELETE_RESTAURANT_PHOTO_REQUEST =
  "DELETE_RESTAURANT_PHOTO_REQUEST";
export const DELETE_RESTAURANT_PHOTO_SUCCESS =
  "DELETE_RESTAURANT_PHOTO_SUCCESS";
export const DELETE_RESTAURANT_PHOTO_FAILURE =
  "DELETE_RESTAURANT_PHOTO_FAILURE";

export const UPDATE_RESTAURANT_REQUEST = "UPDATE_RESTAURANT_REQUEST";
export const UPDATE_RESTAURANT_SUCCESS = "UPDATE_RESTAURANT_SUCCESS";
export const UPDATE_RESTAURANT_FAILURE = "UPDATE_RESTAURANT_FAILURE";

export const UPDATE_RESTAURANT_PHOTO_REQUEST =
  "UPDATE_RESTAURANT_PHOTO_REQUEST";
export const UPDATE_RESTAURANT_PHOTO_SUCCESS =
  "UPDATE_RESTAURANT_PHOTO_SUCCESS";
export const UPDATE_RESTAURANT_PHOTO_FAILURE = "UPDATE_RESTAURANT_FAILURE";
