export const GET_CIRCUITS_REQUEST = "GET_CIRCUITS_REQUEST";
export const GET_CIRCUITS_SUCCESS = "GET_CIRCUITS_SUCCESS";
export const GET_CIRCUITS_FAILURE = "GET_CIRCUITS_FAILURE";

export const GET_CIRCUIT_REQUEST = "GET_CIRCUIT_REQUEST";
export const GET_CIRCUIT_SUCCESS = "GET_CIRCUIT_SUCCESS";
export const GET_CIRCUIT_FAILURE = "GET_CIRCUIT_FAILURE";

export const CREATE_CIRCUIT_REQUEST = "CREATE_CIRCUIT_REQUEST";
export const CREATE_CIRCUIT_SUCCESS = "CREATE_CIRCUIT_SUCCESS";
export const CREATE_CIRCUIT_FAILURE = "CREATE_CIRCUIT_FAILURE";

export const DELETE_CIRCUIT_REQUEST = "DELETE_CIRCUIT_REQUEST";
export const DELETE_CIRCUIT_SUCCESS = "DELETE_CIRCUIT_SUCCESS";
export const DELETE_CIRCUIT_FAILURE = "DELETE_CIRCUIT_FAILURE";

export const REMOVE_MAP_CIRCUIT_REQUEST = "REMOVE_MAP_CIRCUIT_REQUEST";
export const REMOVE_MAP_CIRCUIT_SUCCESS = "REMOVE_MAP_CIRCUIT_SUCCESS";
export const REMOVE_MAP_CIRCUIT_FAILURE = "REMOVE_MAP_CIRCUIT_FAILURE";

export const REMOVE_GPS_CIRCUIT_REQUEST = "REMOVE_GPS_CIRCUIT_REQUEST";
export const REMOVE_GPS_CIRCUIT_SUCCESS = "REMOVE_GPS_CIRCUIT_SUCCESS";
export const REMOVE_GPS_CIRCUIT_FAILURE = "REMOVE_GPS_CIRCUIT_FAILURE";

export const UPDATE_CIRCUIT_REQUEST = "UPDATE_CIRCUIT_REQUEST";
export const UPDATE_CIRCUIT_SUCCESS = "UPDATE_CIRCUIT_SUCCESS";
export const UPDATE_CIRCUIT_FAILURE = "UPDATE_CIRCUIT_FAILURE";

export const DELETE_CIRCUIT_PHOTO_SUCCESS = "DELETE_CIRCUIT_PHOTO_SUCCESS";
export const DELETE_CIRCUIT_PHOTO_FAILURE = "DELETE_CIRCUIT_PHOTO_FAILURE";
export const DELETE_CIRCUIT_PHOTO_REQUEST = "DELETE_CIRCUIT_PHOTO_REQUEST";

export const UPDATE_CIRCUIT_PHOTO_FAILURE = "UPDATE_CIRCUIT_PHOTO_FAILURE";
export const UPDATE_CIRCUIT_PHOTO_REQUEST = "UPDATE_CIRCUIT_PHOTO_REQUEST";
export const UPDATE_CIRCUIT_PHOTO_SUCCESS = "UPDATE_CIRCUIT_PHOTO_SUCCESS";
