import { all, call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";
import {
  createWaterfallFailure,
  createWaterfallSuccess,
  deleteWaterfallPhotoFailure,
  deleteWaterfallSuccess,
  getWaterfallFailure,
  getWaterfallsFailure,
  getWaterfallsSuccess,
  getWaterfallSuccess,
  removeGpsWaterfallFailure,
  updateWaterfallFailure,
  updateWaterfallPhotoFailure,
} from "./actions";
import {
  CREATE_WATERFALL_REQUEST,
  DELETE_WATERFALL_PHOTO_REQUEST,
  DELETE_WATERFALL_REQUEST,
  GET_WATERFALLS_REQUEST,
  GET_WATERFALL_REQUEST,
  REMOVE_GPS_WATERFALL_REQUEST,
  UPDATE_WATERFALL_PHOTO_REQUEST,
  UPDATE_WATERFALL_REQUEST,
} from "./actionTypes";

function requestWaterfalls(data) {
  return post("/waterfalls-by-name", { waterfallName: data });
}

function requestWaterfall(id) {
  return post("/waterfall", { waterfallId: id });
}

function deleteWaterfallById(id) {
  return post("/waterfall-remove", { waterfallId: id });
}

function saveImage(imgs) {
  return post("/media-upload", { imgs });
}

function newWaterfall(waterfall) {
  return post("/waterfalls", waterfall);
}

function deletePhotoWaterfall({ photoId, waterfallId }) {
  return post("/photo-waterfall-remove", {
    waterfallId: waterfallId,
    photoId: photoId,
  });
}

function requestUpdateWaterfall(id, waterfall) {
  const {
    nome,
    altitude,
    descritivo,
    url_video,
    propriedade_particular,
    caracteristicas,
    distancia_pe,
    tempo_carro,
    distancia_total,
    taxa_visitacao,
    referencia,
    observacoes,
    nivel_carro,
    nivel_pe,
    particular,
    tempo_pe,
    // altura_queda,
    distancia_carro,
    // seguranca,
    cidade,
    altimetria,
    arquivo_gps,
    gps,
    bounds_southwest_lat,
    bounds_southwest_lng,
    bounds_northwest_lat,
    bounds_northwest_lng,
  } = waterfall;

  return post("/waterfall-update", {
    waterfallId: id,
    nome,
    altitude,
    descritivo,
    url_video,
    propriedade_particular,
    caracteristicas,
    distancia_pe,
    tempo_carro,
    distancia_total,
    taxa_visitacao,
    referencia,
    observacoes,
    nivel_carro,
    nivel_pe,
    particular,
    tempo_pe,
    // altura_queda,
    distancia_carro,
    // seguranca,
    cidade,
    altimetria,
    arquivo_gps,
    gps,
    bounds_southwest_lat,
    bounds_southwest_lng,
    bounds_northwest_lat,
    bounds_northwest_lng,
  });
}

function requestUpdateWaterfallPhoto({ waterfallId, imagem }) {
  return post("/photo-waterfall-select", {
    waterfallId: waterfallId,
    imagem: imagem,
  });
}

function removeGpsWaterfall(id) {
  return post("/gps-waterfall-remove", {
    waterfallId: id,
  });
}

function* getWaterfall({ data }) {
  try {
    const response = yield call(requestWaterfall, data);
    yield put(getWaterfallSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar atrativo natural." + error);
    yield put(getWaterfallFailure());
  }
}

function* deleteWaterfall({ data }) {
  try {
    // alert(JSON.stringify(data));
    yield call(deleteWaterfallById, data.waterfallId);
    yield put(deleteWaterfallSuccess());
    data.closeModal();
    const response = yield call(requestWaterfalls);
    yield put(getWaterfallsSuccess(response.data));
    showNofity.success("Cachoeira removida com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao remover o atrativo natural." + error);
    yield put(getWaterfallFailure());
  }
}

function* getWaterfalls({ data }) {
  try {
    const response = yield call(requestWaterfalls, data);
    yield put(getWaterfallsSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar atrativos naturais." + error);
    yield put(getWaterfallsFailure());
  }
}

function saveFotos(img, idWaterfall) {
  return post("/photos-waterfall", {
    url: img.url,
    imagem: {
      __type: "File",
      name: img.name,
      url: img.url,
    },
    nome_imagem: img.name,
    cachoeira: {
      __type: "Pointer",
      className: "Cachoeira",
      objectId: idWaterfall,
    },
  });
}

function saveGps(geojson) {
  return post("/gps-upload", { geojson });
}

function* createWaterfall({ data }) {
  try {
    const {
      nome,
      altitude,
      descritivo,
      images,
      url_video,
      propriedade_particular,
      caracteristicas,
      distancia_pe,
      tempo_carro,
      distancia_total,
      taxa_visitacao,
      referencia,
      observacoes,
      nivel_carro,
      nivel_pe,
      particular,
      tempo_pe,
      // altura_queda,
      distancia_carro,
      // seguranca,
      imagem_principal,
      cidade,
      altimetria,
      arquivo_gps,
      latitude,
      longitude,
      bounds_southwest_lat,
      bounds_southwest_lng,
      bounds_northwest_lat,
      bounds_northwest_lng,
    } = data.values;

    const responseGpsSaved = yield call(saveGps, arquivo_gps);
    const responseImages = yield call(saveImage, images);

    const waterfall = {
      nome,
      altitude,
      descritivo,
      url_video,
      propriedade_particular,
      caracteristicas,
      distancia_pe,
      tempo_carro,
      distancia_total,
      taxa_visitacao,
      referencia,
      observacoes,
      nivel_carro,
      nivel_pe,
      particular,
      tempo_pe,
      // altura_queda,
      distancia_carro,
      // seguranca,
      bounds_southwest_lat,
      bounds_southwest_lng,
      bounds_northwest_lat,
      bounds_northwest_lng,
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      imagem: {
        __type: "File",
        url: responseImages.data[imagem_principal].url,
        name: responseImages.data[imagem_principal].name,
      },

      arquivo_gps: {
        __type: "File",
        name: responseGpsSaved?.data?.name,
        url: responseGpsSaved?.data?.url,
      },
    };

    if (altimetria) {
      waterfall.altimetria = altimetria;
    }

    const responseWaterfall = yield call(newWaterfall, waterfall);

    yield all(
      responseImages.data.map((img) =>
        call(saveFotos, img, responseWaterfall.data.objectId)
      )
    );

    yield put(createWaterfallSuccess());
    showNofity.success("Atrativo natural cadastrado com sucesso.");
    data.resetForm();
  } catch (error) {
    showNofity.error("Erro ao cadastrar atrativo natural. " + error);
    yield put(createWaterfallFailure());
  }
}

function* updateWaterfall({ data }) {
  try {
    const {
      nome,
      altitude,
      descritivo,
      url_video,
      propriedade_particular,
      caracteristicas,
      distancia_pe,
      tempo_carro,
      distancia_total,
      taxa_visitacao,
      referencia,
      observacoes,
      nivel_carro,
      nivel_pe,
      particular,
      tempo_pe,
      // altura_queda,
      distancia_carro,
      // seguranca,
      images,
      cidade,
      altimetria,
      latitude,
      longitude,
      arquivo_gps,
      bounds_southwest_lat,
      bounds_southwest_lng,
      bounds_northwest_lat,
      bounds_northwest_lng,
    } = data.values;

    let responseGpsSaved = {};

    if (images?.length > 0) {
      const responseImages = yield call(saveImage, images);
      yield all(
        responseImages.data.map((img) => call(saveFotos, img, data.id))
      );
    }

    const body = {
      nome,
      altitude,
      descritivo,
      url_video,
      propriedade_particular,
      caracteristicas,
      distancia_pe,
      tempo_carro,
      distancia_total,
      taxa_visitacao,
      referencia,
      observacoes,
      nivel_carro,
      nivel_pe,
      particular,
      tempo_pe,
      // altura_queda,
      distancia_carro,
      // seguranca,
      images,
      bounds_southwest_lat,
      bounds_southwest_lng,
      bounds_northwest_lat,
      bounds_northwest_lng,
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
    };

    if (arquivo_gps && arquivo_gps.__type !== "File") {
      responseGpsSaved = yield call(saveGps, arquivo_gps);

      body.arquivo_gps = {
        __type: "File",
        name: responseGpsSaved?.data?.name,
        url: responseGpsSaved?.data?.url,
      };
    }

    if (altimetria) {
      body.altimetria = altimetria;
    }

    yield call(requestUpdateWaterfall, data.id, body);

    const response = yield call(requestWaterfall, data.id);

    yield put(getWaterfallSuccess(response.data));
    data.onSucess();
    showNofity.success("Atrativo natural atualizado com sucesso.");
  } catch (error) {
    yield put(updateWaterfallFailure());
    showNofity.error("Erro ao atualizar atrativo natural.");
  }
}

function* updateImageWaterfall({ data }) {
  try {
    yield call(requestUpdateWaterfallPhoto, {
      waterfallId: data.waterfallId,
      imagem: data.imagem,
    });

    const responseWaterfall = yield call(requestWaterfall, data.waterfallId);
    showNofity.success("Imagem atualizada com sucesso.");
    yield put(getWaterfallSuccess(responseWaterfall.data));
  } catch (error) {
    showNofity.error("Erro ao atualizar foto do atrativo natural. " + error);
    put(updateWaterfallPhotoFailure());
  }
}

function* deleteImageWaterfall({ data }) {
  try {
    yield call(deletePhotoWaterfall, data);
    showNofity.success("Imagem removida com sucesso.");
    const response = yield call(requestWaterfall, data.waterfallId);
    yield put(getWaterfallSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao remover foto da cidade. " + error);
    put(deleteWaterfallPhotoFailure());
  }
}

function* deleteGpsWaterfall({ data }) {
  try {
    yield call(removeGpsWaterfall, data.id);
    showNofity.success("GPS removido com sucesso.");
    const response = yield call(requestWaterfall, data.id);
    yield put(getWaterfallSuccess(response?.data));
  } catch (error) {
    showNofity.error("Erro ao remover GPS do atrativo natural. " + error);
    yield put(removeGpsWaterfallFailure());
  }
}

function* saga() {
  yield takeLatest(GET_WATERFALLS_REQUEST, getWaterfalls);
  yield takeLatest(GET_WATERFALL_REQUEST, getWaterfall);
  yield takeLatest(DELETE_WATERFALL_REQUEST, deleteWaterfall);
  yield takeLatest(DELETE_WATERFALL_PHOTO_REQUEST, deleteImageWaterfall);
  yield takeLatest(CREATE_WATERFALL_REQUEST, createWaterfall);
  yield takeLatest(UPDATE_WATERFALL_REQUEST, updateWaterfall);
  yield takeLatest(UPDATE_WATERFALL_PHOTO_REQUEST, updateImageWaterfall);
  yield takeLatest(REMOVE_GPS_WATERFALL_REQUEST, deleteGpsWaterfall);
}

export default saga;
