import {
  GET_TYPES_SERVICE_FAILURE,
  GET_TYPES_SERVICE_REQUEST,
  GET_TYPES_SERVICE_SUCCESS,
} from "./actionTypes";

export const getTypesServiceRequest = (data) => {
  return {
    type: GET_TYPES_SERVICE_REQUEST,
    data,
  };
};

export const getTypesServiceSuccess = (data) => {
  return {
    type: GET_TYPES_SERVICE_SUCCESS,
    data,
  };
};

export const getTypesServiceFailure = () => {
  return {
    type: GET_TYPES_SERVICE_FAILURE,
  };
};
