import { get, post, patch, destroy } from '../../boot/api'

export const getUsers = async (params = { page: '', limit: 0, search: '',}) => {
  const { data } = await get('/users', params)
  return data
}

export const getUser = async (id, params) => {
  const { data } = await get(`/users/${id}`, params)
  return data
}

export const createUser = async user => {
  const { data } = await post('/users', user)
  return data
}

export const updateUser = async (id, user) => {
  const { data } = await patch(`/users/${id}`, user)
  return data
}

export const destroyUser = async id => {
  await destroy(`/users/${id}`)
}

export const getLoggedUser = async () => {
  const { data } = await get('/users/logged-user')
  return data
}

export const changePassword = async (params) => {
  const { data } = await patch('/users/change-password', params)
  return data
}

export const forgotPassword = async email => {
  const { data } = await post('/users/forgot-password', email)
  return data
}

export const resetPassword = async params => {
  const { data } = await post('/users/reset-password', params)
  return data
}

export const closeAccount = async id => {
  await destroy('/users/close-account')
}