import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";

import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import { getCitiesRequest } from "../../store/city/actions";
import {
  createEventRequest,
  getEventRequest,
  updateEventRequest,
} from "../../store/events/actions";

import {
  CoverUploadImageField,
  InputMask,
  TextField,
} from "../../Components/Forms";
import showNofity from "../../helpers/toast";
import { isLatitude, isLongitude } from "../../utils/formats";
import states from "../../utils/states.json";
import ModalLoading from "../../Components/Common/ModalLoading";

const EventForm = () => {
  document.title = "Formulário de eventos";

  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const today = new Date();

  const { data } = useSelector((state) => state.City);
  const { loading, data: dataEvent } = useSelector((state) => state.Events);

  useEffect(() => {
    dispatch(getCitiesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      dispatch(getEventRequest(params.id));
    }
  }, [dispatch, params.id]);

  const event = useFormik({
    enableReinitialize: true,
    initialValues: Object.assign({
      nome: "",
      descritivo: "",
      data_inicio: "",
      data_termino: "",
      hora_inicio: "",
      hora_termino: "",
      telefone: "",
      site: "",
      whatsapp: "",
      instagram: "",
      logradouro: "",
      numero: "",
      bairro: "",
      estado: "",
      cidade: "",
      images: [],
      email: "",
      latitude: "",
      longitude: "",
    }),

    validationSchema: Yup.object({
      nome: Yup.string("O nome deve ser do tipo texto").required(
        "O nome do serviço é obrigatório"
      ),
      telefone: Yup.string("O telefone deve ser do tipo texto"),
      logradouro: Yup.string("O logradouro deve ser do tipo texto"),
      data_inicio: Yup.date("Informe uma data valida").required(
        "A data de início é obrigatória"
      ),
      data_termino: Yup.string("Informe uma data valida").required(
        "A data de término é obrigatória"
      ),
      cidade: Yup.string("A cidade deve ser do tipo texto").required(
        "A cidade é obrigatória"
      ),
      bairro: Yup.string("O distrito deve ser do tipo texto"),
      estado: Yup.string("O estado deve ser do tipo texto"),
      numero: Yup.string("O número deve ser do tipo texto"),
      descritivo: Yup.string("A descrição deve ser do tipo texto").required(
        "A descrição é obrigatório"
      ),
      whatsapp: Yup.string("O whatsapp deve ser do tipo texto"),
      site: Yup.string("O site deve ser do tipo texto"),
      latitude: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      longitude: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      // .required("Este campo é obrigatório"),
      images: Yup.array(Yup.string()).required("Este campo é obrigatório"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (values.images.length < 1) {
        showNofity.error("Adicione uma imagem para o evento");
        return;
      }

      values.latitude = values.latitude ? Number(values.latitude) : 0.0;
      values.longitude = values.longitude ? Number(values.longitude) : 0.0;

      if (params.id) {
        dispatch(updateEventRequest({ id: params.id, values }));
      } else {
        dispatch(createEventRequest({ values, resetForm }));
      }
    },
  });

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Formulário de eventos" pageTitle="Eventos" />
        </Container>
        <Row>
          <Col lg={12}>
            <Card>
              <div className="p-4">
                <button
                  className="btn btn-primary"
                  onClick={() => history.goBack()}
                >
                  Voltar
                </button>
              </div>
              <CardBody>
                <div className="live-preview">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      event.handleSubmit();
                      return false;
                    }}
                    action="#"
                  >
                    <Row className="g-3">
                      <TextField
                        label="Nome"
                        name="nome"
                        data={event}
                        size={12}
                      />

                      <TextField
                        type="date"
                        name="data_inicio"
                        label="Data início"
                        data={event}
                        size={3}
                      />

                      <TextField
                        type="date"
                        name="data_termino"
                        label="Data término"
                        data={event}
                        size={3}
                      />

                      <TextField
                        type="time"
                        name="hora_inicio"
                        label="Hora início"
                        data={event}
                        size={3}
                      />

                      <TextField
                        type="time"
                        name="hora_termino"
                        label="Hora término"
                        data={event}
                        size={3}
                      />

                      <InputMask
                        type="phone-br"
                        label="Telefone"
                        name="telefone"
                        data={event}
                        size={3}
                      />
                      <InputMask
                        type="phone-br"
                        label="Whatsapp"
                        name="whatsapp"
                        data={event}
                        size={3}
                      />
                      <TextField
                        label="Instagram"
                        name="instagram"
                        data={event}
                        size={6}
                      />
                      <TextField
                        label="Logradouro"
                        name="logradouro"
                        data={event}
                        size={6}
                      />
                      <TextField
                        label="Número"
                        name="numero"
                        data={event}
                        size={3}
                      />
                      <TextField
                        label="Bairro"
                        name="bairro"
                        data={event}
                        size={3}
                      />
                      <TextField
                        type="select"
                        label="Cidade"
                        name="cidade"
                        data={event}
                        size={4}
                      >
                        <option value="" selected disabled>
                          Selecione a cidade do evento
                        </option>
                        {data?.result?.length > 0 &&
                          data?.result.map((item, index) => (
                            <option key={index} value={item.objectId}>
                              {item.nome}
                            </option>
                          ))}
                      </TextField>

                      <TextField
                        type="select"
                        label="Estado"
                        name="estado"
                        data={event}
                        size={2}
                      >
                        <option value="" selected disabled>
                          Selecione um estado
                        </option>

                        {states.map(({ sigla, id_uf }) => (
                          <option key={id_uf} value={sigla}>
                            {sigla}
                          </option>
                        ))}
                      </TextField>

                      <TextField
                        label="Latitude"
                        name="latitude"
                        data={event}
                        type="number"
                        size={3}
                        onKeyDown={(event) => {
                          ["e", "E", "+", ","].includes(event.key) &&
                            event.preventDefault();
                        }}
                      />

                      <TextField
                        label="Longitude"
                        name="longitude"
                        data={event}
                        type="number"
                        size={3}
                        onKeyDown={(event) => {
                          ["e", "E", "+", ","].includes(event.key) &&
                            event.preventDefault();
                        }}
                      />

                      <TextField
                        label="E-mail"
                        name="email"
                        data={event}
                        size={6}
                      />

                      <TextField
                        label="Site"
                        name="site"
                        data={event}
                        size={6}
                      />

                      <TextField
                        label="Descrição"
                        name="descritivo"
                        type="textarea"
                        data={event}
                        size={12}
                      />

                      <CoverUploadImageField
                        title="Imagem do evento"
                        multiple={false}
                        data={event}
                        name="images"
                        isActiveRadioSelect={false}
                      />

                      <Col lg={12}>
                        <div className="text-end">
                          <button type="submit" className="btn btn-primary">
                            Salvar
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalLoading loading={loading} />
    </React.Fragment>
  );
};

export default EventForm;
