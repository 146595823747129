import React from "react";
import ImageGallery from "react-image-gallery";
import "./ImagesGallery.css";

const ImagesGallery = ({ images, loading, position }) => {
  const listImage = [];

  images?.forEach((element) => {
    listImage.push({
      original: element?.url || element?.imagem?.url || element?.Imagem?.url,
      thumbnail:
        element?.url_miniatura || element?.imagem?.url || element?.Imagem?.url,
    });
  });

  return (
    <React.Fragment>
      {listImage.length === images.length &&
      listImage[0]?.original !== undefined &&
      !loading ? (
        <ImageGallery
          items={listImage}
          showNav={loading || listImage.length === 0 ? false : true}
          showPlayButton={loading || listImage.length === 0 ? false : true}
          showFullscreenButton={
            loading || listImage.length === 0 ? false : true
          }
          thumbnailPosition={position}
          lazyLoad={loading}
        />
      ) : null}
    </React.Fragment>
  );
};

export default ImagesGallery;
