import { get, post, patch, destroy } from '../../boot/api'

export const getRates = async (params = { page: '', limit: 0, search: '',}) => {
  const { data } = await get('/rates', params)
  return data
}

export const getRate = async (id, params) => {
  const { data } = await get(`/rates/${id}`, params)
  return data
}

export const createRate = async rate => {
  const { data } = await post('/rates', rate)
  return data
}

export const updateRate = async (id, rate) => {
  const { data } = await patch(`/rates/${id}`, rate)
  return data
}

export const destroyRate = async id => {
  await destroy(`/rates/${id}`)
}