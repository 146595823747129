import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LazyImage = ({
  alt,
  src,
  height,
  width,
  style,
  className,
  effect = "blur",
}) => (
  <React.Fragment>
    <LazyLoadImage
      alt={alt}
      src={src}
      height={height}
      width={width}
      style={style}
      className={className}
      effect={effect}
    />
  </React.Fragment>
);

export default LazyImage;
