import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../../boot/api";
import showNofity from "../../../helpers/toast";
import {
  createFilterWhereStayFailure,
  createFilterWhereStaySuccess,
  deleteFilterWhereStayFailure,
  deleteFilterWhereStaySuccess,
  getFiltersWhereStayFailure,
  getFiltersWhereStaySuccess,
  updateFilterWhereStayFailure,
  updateFilterWhereStaySuccess,
} from "./actions";
import {
  CREATE_FILTERS_WHERE_STAY_REQUEST,
  DELETE_FILTERS_WHERE_STAY_REQUEST,
  GET_FILTERS_WHERE_STAY_REQUEST,
  UPDATE_FILTERS_WHERE_STAY_REQUEST,
} from "./actionTypes";

function requestFiltersWhereStay(data) {
  return post("/filters-where-stay-by-name", { filterWhereStayName: data });
}

function newFilterWhereStay(filter) {
  return post("/filters-where-stay", filter);
}

function deleteFiltersWhereStayById(id) {
  return post("/filters-where-stay-remove", { filterWhereStayId: id });
}

function requestUpdateFilterWhereStay(id, filterWhereStay) {
  const { nome } = filterWhereStay;

  return post("/filters-where-stay-update", {
    filterWhereStayId: id,
    nome,
  });
}

function* getFiltersWhereStay({ data }) {
  try {
    const response = yield call(requestFiltersWhereStay, data);

    yield put(getFiltersWhereStaySuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar filtros onde ficar. " + error);
    yield put(getFiltersWhereStayFailure());
  }
}

function* createFilterWhereStay({ data }) {
  try {
    const { nome } = data.values;

    yield call(newFilterWhereStay, {
      nome,
    });

    yield put(createFilterWhereStaySuccess());
    data.resetForm();
    data.closeModal();
    const response = yield call(requestFiltersWhereStay);
    yield put(getFiltersWhereStaySuccess(response.data));
    showNofity.success("Filtro cadastrado com sucesso");
  } catch (error) {
    showNofity.error("Erro ao cadastrar filtro." + error);
    yield put(createFilterWhereStayFailure());
  }
}

function* updateFilterWhereStay({ data }) {
  console.log(">>>", data);
  try {
    const { nome } = data.values;

    yield call(requestUpdateFilterWhereStay, data.id, {
      nome,
    });

    yield put(updateFilterWhereStaySuccess());
    showNofity.success("Filtro atualizado com sucesso");
    data.closeModal();
    const response = yield call(requestFiltersWhereStay);
    yield put(getFiltersWhereStaySuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao atualizar filtro " + error);
    yield put(updateFilterWhereStayFailure());
  }
}

function* deleteFilterWhereStay({ data }) {
  console.log(">>>", data);
  try {
    yield call(deleteFiltersWhereStayById, data.filterWhereStayId);
    yield put(deleteFilterWhereStaySuccess());
    data.closeModal();
    const response = yield call(requestFiltersWhereStay);
    yield put(getFiltersWhereStaySuccess(response.data));
    showNofity.success("Filtro removido com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao remover filtro. " + error);
    yield put(deleteFilterWhereStayFailure());
  }
}

function* saga() {
  yield takeLatest(GET_FILTERS_WHERE_STAY_REQUEST, getFiltersWhereStay);
  yield takeLatest(CREATE_FILTERS_WHERE_STAY_REQUEST, createFilterWhereStay);
  yield takeLatest(DELETE_FILTERS_WHERE_STAY_REQUEST, deleteFilterWhereStay);
  yield takeLatest(UPDATE_FILTERS_WHERE_STAY_REQUEST, updateFilterWhereStay);
}

export default saga;
