import {
  CREATE_FILTERS_SERVICE_FAILURE,
  CREATE_FILTERS_SERVICE_REQUEST,
  CREATE_FILTERS_SERVICE_SUCCESS,
  DELETE_FILTERS_SERVICE_FAILURE,
  DELETE_FILTERS_SERVICE_REQUEST,
  DELETE_FILTERS_SERVICE_SUCCESS,
  GET_FILTERS_SERVICE_FAILURE,
  GET_FILTERS_SERVICE_REQUEST,
  GET_FILTERS_SERVICE_SUCCESS,
  UPDATE_FILTERS_SERVICE_FAILURE,
  UPDATE_FILTERS_SERVICE_REQUEST,
  UPDATE_FILTERS_SERVICE_SUCCESS,
} from "./actionTypes";

export const getFiltersServiceRequest = (data) => {
  return {
    type: GET_FILTERS_SERVICE_REQUEST,
    data,
  };
};

export const getFiltersServiceSuccess = (data) => {
  return {
    type: GET_FILTERS_SERVICE_SUCCESS,
    data,
  };
};

export const getFiltersServiceFailure = () => {
  return {
    type: GET_FILTERS_SERVICE_FAILURE,
  };
};

export const createFilterServiceRequest = (data) => {
  return {
    type: CREATE_FILTERS_SERVICE_REQUEST,
    data,
  };
};

export const createFilterServiceSuccess = (data) => {
  return {
    type: CREATE_FILTERS_SERVICE_SUCCESS,
    data,
  };
};

export const createFilterServiceFailure = () => {
  return {
    type: CREATE_FILTERS_SERVICE_FAILURE,
  };
};

export const updateFilterServiceRequest = (data) => {
  return {
    type: UPDATE_FILTERS_SERVICE_REQUEST,
    data,
  };
};

export const updateFilterServiceFailure = () => {
  return {
    type: UPDATE_FILTERS_SERVICE_FAILURE,
  };
};

export const updateFilterServiceSuccess = (data) => {
  return {
    type: UPDATE_FILTERS_SERVICE_SUCCESS,
    data,
  };
};

export const deleteFilterServiceFailure = () => {
  return {
    type: DELETE_FILTERS_SERVICE_FAILURE,
  };
};

export const deleteFilterServiceRequest = (data) => {
  return {
    type: DELETE_FILTERS_SERVICE_REQUEST,
    data,
  };
};

export const deleteFilterServiceSuccess = (data) => {
  return {
    type: DELETE_FILTERS_SERVICE_SUCCESS,
    data,
  };
};
