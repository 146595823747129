import React from 'react'
import { Col, Row } from 'reactstrap';
import UsuariosAtivos from './UsuariosAtivos';
import VisualizacoesRecentes from './VisualizacoesRecentes';

const WidgetsRealtime = () => {
  return (
    <Row>
      <Col sm={6}>
        <UsuariosAtivos />
      </Col>
      <Col sm={6}>
        <VisualizacoesRecentes />
      </Col>
    </Row>
  );
};

export default WidgetsRealtime;