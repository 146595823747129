import {
  CREATE_CITY_FAILURE,
  CREATE_CITY_REQUEST,
  CREATE_CITY_SUCCESS,
  DELETE_CITY_FAILURE,
  DELETE_CITY_PHOTO_FAILURE,
  DELETE_CITY_PHOTO_REQUEST,
  DELETE_CITY_PHOTO_SUCCESS,
  DELETE_CITY_REQUEST,
  DELETE_CITY_SUCCESS,
  GET_CITIES_FAILURE,
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_CITY_FAILURE,
  GET_CITY_REQUEST,
  GET_CITY_SUCCESS,
  UPDATE_CITY_PHOTO_FAILURE,
  UPDATE_CITY_PHOTO_REQUEST,
  UPDATE_CITY_PHOTO_SUCCESS,
  UPDATE_CITY_REQUEST,
  UPDATE_CITY_FAILURE,
  UPDATE_CITY_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: false,
  loading: false,
  data: undefined,
  success: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CITIES_REQUEST:
      state = {
        ...state,
        data: [],
        loading: true,
        error: false,
      };
      break;

    case GET_CITIES_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_CITIES_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
      };
      break;

    case GET_CITY_REQUEST:
      state = {
        ...state,
        data: {},
        loading: true,
        error: false,
      };
      break;

    case GET_CITY_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_CITY_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
      };
      break;

    case CREATE_CITY_REQUEST:
      console.log("REQUESTED...");

      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case CREATE_CITY_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
      break;

    case CREATE_CITY_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    case DELETE_CITY_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_CITY_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_CITY_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case DELETE_CITY_PHOTO_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_CITY_PHOTO_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_CITY_PHOTO_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case UPDATE_CITY_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case UPDATE_CITY_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case UPDATE_CITY_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case UPDATE_CITY_PHOTO_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case UPDATE_CITY_PHOTO_SUCCESS:
      console.log("SUCCESS =)", action);

      state = {
        ...state,
        data: { ...action.data },
        success: true,
        loading: false,
      };
      break;

    case UPDATE_CITY_PHOTO_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
