import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import Vectormap from './VectorMap';
import classNames from "classnames";
import { useSelector } from 'react-redux';
import ISOCodes from './ISO-3166-1_alpha-2.json';
import PerfectScrollbar from "simplebar-react";
import "react-perfect-scrollbar/dist/css/styles.css";

const UsuariosAtivosPorPais = () => {
  const usersData = useSelector(state =>
    state.DashboardAnalytics.realTime.data.usuariosPorPais
  );

  const countryCodeAndUsers = useMemo(() => {
    const dataMatrix = [];
    const series = {};
    if (usersData) {
      usersData.rows?.forEach((row) => {
        const countryCode = row.dimensionValues[0].value.split('--')[0];
        const value = +row.metricValues[0].value;

        series[countryCode] = value;
      });
    }
    return series;
  }, [usersData]);

  let noData;
  if (Object.keys(countryCodeAndUsers).length === 0) {
    noData = (
      <tr>
        <td className="text-center" colSpan={2}>Nenhum usuário ativo</td>
      </tr>
    );
  }

  return (
    <React.Fragment>
      <Card className="card-height-100">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Usuários Ativos por País</h4>
        </CardHeader>

        <CardBody>
          <Row>
            <Col lg={9}>
              <div
                className="text-center"
                style={{ height: "300px" }}
              >
                <Vectormap
                  mapName="world_mill"
                  color="#f3f6f9"
                  data={countryCodeAndUsers}
                />
              </div>
            </Col>
            <Col lg={3}>
              <div className="table-responsive table-card" style={{ minHeight: '100px' }}>
                <Table className="table-borderless table-sm m-0">
                  <thead className="text-muted border-dashed border border-start-0 border-end-0 bg-soft-light">
                    <tr>
                      <th>País</th>
                      <th className="text-center" style={{ width: "30%" }}>Usuários</th>
                    </tr>
                  </thead>
                </Table>
                <PerfectScrollbar style={{ maxHeight: '250px' }}>
                  <Table
                    className="table-borderless table-sm table-striped align-middle"
                    style={{
                      '--vz-table-striped-bg': 'rgba(var(--vz-dark-rgb), 0.05)'
                    }}
                  >
                    <tbody className="border-0">
                      {Object.entries(countryCodeAndUsers).map(([code, count]) => (
                        <tr key={code}>
                          <td>{ISOCodes[code] || code || '(desconhecido)'}</td>
                          <td className="text-center">{count}</td>
                        </tr>
                      ))}
                      {noData}
                    </tbody>
                  </Table>
                </PerfectScrollbar>
              </div>
            </Col>
          </Row>

        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default UsuariosAtivosPorPais;