import {
  CREATE_RESTAURANT_FAILURE,
  CREATE_RESTAURANT_REQUEST,
  CREATE_RESTAURANT_SUCCESS,
  DELETE_RESTAURANT_FAILURE,
  DELETE_RESTAURANT_PHOTO_FAILURE,
  DELETE_RESTAURANT_PHOTO_REQUEST,
  DELETE_RESTAURANT_PHOTO_SUCCESS,
  DELETE_RESTAURANT_REQUEST,
  DELETE_RESTAURANT_SUCCESS,
  GET_RESTAURANTS_FAILURE,
  GET_RESTAURANTS_REQUEST,
  GET_RESTAURANTS_SUCCESS,
  GET_RESTAURANT_FAILURE,
  GET_RESTAURANT_REQUEST,
  GET_RESTAURANT_SUCCESS,
  UPDATE_RESTAURANT_FAILURE,
  UPDATE_RESTAURANT_PHOTO_FAILURE,
  UPDATE_RESTAURANT_PHOTO_REQUEST,
  UPDATE_RESTAURANT_PHOTO_SUCCESS,
  UPDATE_RESTAURANT_REQUEST,
  UPDATE_RESTAURANT_SUCCESS,
} from "./actionTypes";

export const getRestaurantsRequest = (data) => {
  return {
    type: GET_RESTAURANTS_REQUEST,
    data,
  };
};

export const getRestaurantsSuccess = (data) => {
  return {
    type: GET_RESTAURANTS_SUCCESS,
    data,
  };
};

export const getRestaurantsFailure = () => {
  return {
    type: GET_RESTAURANTS_FAILURE,
  };
};

export const getRestaurantFailure = () => {
  return {
    type: GET_RESTAURANT_FAILURE,
  };
};

export const getRestaurantRequest = (data) => {
  return {
    type: GET_RESTAURANT_REQUEST,
    data,
  };
};

export const getRestaurantSuccess = (data) => {
  return {
    type: GET_RESTAURANT_SUCCESS,
    data,
  };
};

export const createRestaurantRequest = (data) => {
  return {
    type: CREATE_RESTAURANT_REQUEST,
    data,
  };
};

export const createRestaurantSuccess = (data) => {
  return {
    type: CREATE_RESTAURANT_SUCCESS,
    data,
  };
};

export const createRestaurantFailure = () => {
  return {
    type: CREATE_RESTAURANT_FAILURE,
  };
};

export const deleteRestaurantFailure = () => {
  return {
    type: DELETE_RESTAURANT_FAILURE,
  };
};

export const deleteRestaurantRequest = (data) => {
  return {
    type: DELETE_RESTAURANT_REQUEST,
    data,
  };
};

export const deleteRestaurantSuccess = (data) => {
  return {
    type: DELETE_RESTAURANT_SUCCESS,
    data,
  };
};

export const deleteRestaurantPhotoSuccess = (data) => {
  return {
    type: DELETE_RESTAURANT_PHOTO_SUCCESS,
    data,
  };
};

export const deleteRestaurantPhotoFailure = () => {
  return {
    type: DELETE_RESTAURANT_PHOTO_FAILURE,
  };
};

export const deleteRestaurantPhotoRequest = (data) => {
  return {
    type: DELETE_RESTAURANT_PHOTO_REQUEST,
    data,
  };
};

export const updateRestaurantFailure = () => {
  return {
    type: UPDATE_RESTAURANT_FAILURE,
  };
};

export const updateRestaurantRequest = (data) => {
  return {
    type: UPDATE_RESTAURANT_REQUEST,
    data,
  };
};

export const updateRestaurantSuccess = (data) => {
  return {
    type: UPDATE_RESTAURANT_SUCCESS,
    data,
  };
};

export const updateRestaurantPhotoFailure = () => {
  return {
    type: UPDATE_RESTAURANT_PHOTO_FAILURE,
  };
};

export const updateRestaurantPhotoRequest = (data) => {
  return {
    type: UPDATE_RESTAURANT_PHOTO_REQUEST,
    data,
  };
};

export const updateRestaurantPhotoSuccess = (data) => {
  return {
    type: UPDATE_RESTAURANT_PHOTO_SUCCESS,
    data,
  };
};
