import {
  CREATE_HOTEL_FAILURE,
  CREATE_HOTEL_REQUEST,
  CREATE_HOTEL_SUCCESS,
  DELETE_HOTEL_FAILURE,
  DELETE_HOTEL_PHOTO_FAILURE,
  DELETE_HOTEL_PHOTO_REQUEST,
  DELETE_HOTEL_PHOTO_SUCCESS,
  DELETE_HOTEL_REQUEST,
  DELETE_HOTEL_SUCCESS,
  GET_HOTELS_FAILURE,
  GET_HOTELS_REQUEST,
  GET_HOTELS_SUCCESS,
  GET_HOTEL_FAILURE,
  GET_HOTEL_REQUEST,
  GET_HOTEL_SUCCESS,
  UPDATE_HOTEL_FAILURE,
  UPDATE_HOTEL_PHOTO_FAILURE,
  UPDATE_HOTEL_PHOTO_REQUEST,
  UPDATE_HOTEL_PHOTO_SUCCESS,
  UPDATE_HOTEL_REQUEST,
  UPDATE_HOTEL_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: false,
  loading: false,
  data: [],
  success: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOTELS_REQUEST:
      state = {
        ...state,
        data: [],
        loading: true,
        error: false,
      };
      break;

    case GET_HOTELS_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_HOTELS_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
      };
      break;

    case GET_HOTEL_REQUEST:
      console.log("CAHMEI HOTELLL =(");

      state = {
        ...state,
        data: {},
        loading: true,
        error: false,
      };
      break;

    case GET_HOTEL_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_HOTEL_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
      };
      break;

    case CREATE_HOTEL_REQUEST:
      console.log("REQUESTED...");

      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case CREATE_HOTEL_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
      break;

    case CREATE_HOTEL_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    case DELETE_HOTEL_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_HOTEL_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_HOTEL_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case DELETE_HOTEL_PHOTO_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_HOTEL_PHOTO_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_HOTEL_PHOTO_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case UPDATE_HOTEL_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case UPDATE_HOTEL_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case UPDATE_HOTEL_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case UPDATE_HOTEL_PHOTO_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case UPDATE_HOTEL_PHOTO_SUCCESS:
      console.log("SUCCESS =)", action);

      state = {
        ...state,
        data: { ...action.data },
        success: true,
        loading: false,
      };
      break;

    case UPDATE_HOTEL_PHOTO_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
