import {
  GET_SUGGESTION_FAILURE,
  GET_SUGGESTION_REQUEST,
  GET_SUGGESTION_SUCCESS,
} from "./actionTypes";

export const getSuggestionRequest = (data) => {
  return {
    type: GET_SUGGESTION_REQUEST,
    data,
  };
};

export const getSuggestionSuccess = (data) => {
  return {
    type: GET_SUGGESTION_SUCCESS,
    data,
  };
};

export const getSuggestionFailure = () => {
  return {
    type: GET_SUGGESTION_FAILURE,
  };
};
