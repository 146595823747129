import {
  CREATE_CIRCUIT_FAILURE,
  CREATE_CIRCUIT_REQUEST,
  CREATE_CIRCUIT_SUCCESS,
  DELETE_CIRCUIT_FAILURE,
  DELETE_CIRCUIT_PHOTO_FAILURE,
  DELETE_CIRCUIT_PHOTO_REQUEST,
  DELETE_CIRCUIT_PHOTO_SUCCESS,
  DELETE_CIRCUIT_REQUEST,
  DELETE_CIRCUIT_SUCCESS,
  GET_CIRCUITS_FAILURE,
  GET_CIRCUITS_REQUEST,
  GET_CIRCUITS_SUCCESS,
  GET_CIRCUIT_FAILURE,
  GET_CIRCUIT_REQUEST,
  GET_CIRCUIT_SUCCESS,
  REMOVE_GPS_CIRCUIT_FAILURE,
  REMOVE_GPS_CIRCUIT_REQUEST,
  REMOVE_GPS_CIRCUIT_SUCCESS,
  REMOVE_MAP_CIRCUIT_FAILURE,
  REMOVE_MAP_CIRCUIT_REQUEST,
  REMOVE_MAP_CIRCUIT_SUCCESS,
  UPDATE_CIRCUIT_FAILURE,
  UPDATE_CIRCUIT_PHOTO_FAILURE,
  UPDATE_CIRCUIT_PHOTO_REQUEST,
  UPDATE_CIRCUIT_PHOTO_SUCCESS,
  UPDATE_CIRCUIT_REQUEST,
  UPDATE_CIRCUIT_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CIRCUITS_REQUEST:
      state = {
        ...state,
        data: [],
        loading: true,
      };
      break;

    case GET_CIRCUITS_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        loading: false,
        data: action.data,
      };
      break;

    case GET_CIRCUITS_FAILURE:
      console.log("FAILURE");

      state = {
        ...state,
        loading: false,
      };
      break;

    case GET_CIRCUIT_REQUEST:
      console.log("REQUEST");
      state = {
        ...state,

        loading: true,
      };
      break;

    case GET_CIRCUIT_SUCCESS:
      console.log("SUCCESS");
      state = {
        ...state,
        data: action.data,
        loading: false,
      };
      break;

    case GET_CIRCUIT_FAILURE:
      console.log("FAILURE");
      state = {
        ...state,
        loading: false,
      };
      break;

    case CREATE_CIRCUIT_REQUEST:
      console.log("REQUESTED...");

      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_CIRCUIT_SUCCESS:
      console.log("SUCCESS");
      state = {
        ...state,
        loading: false,
      };
      break;

    case CREATE_CIRCUIT_FAILURE:
      console.log("FAILURE");
      state = {
        ...state,
        loading: false,
      };
      break;

    case DELETE_CIRCUIT_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELETE_CIRCUIT_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        loading: false,
      };
      break;

    case DELETE_CIRCUIT_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
      };
      break;

    case DELETE_CIRCUIT_PHOTO_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_CIRCUIT_PHOTO_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_CIRCUIT_PHOTO_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    case REMOVE_MAP_CIRCUIT_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case REMOVE_MAP_CIRCUIT_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case REMOVE_MAP_CIRCUIT_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    case REMOVE_GPS_CIRCUIT_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case REMOVE_GPS_CIRCUIT_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case REMOVE_GPS_CIRCUIT_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    case UPDATE_CIRCUIT_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case UPDATE_CIRCUIT_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case UPDATE_CIRCUIT_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case UPDATE_CIRCUIT_PHOTO_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        // data: {},
        loading: true,
        error: false,
      };
      break;

    case UPDATE_CIRCUIT_PHOTO_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case UPDATE_CIRCUIT_PHOTO_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        // data: {},
        loading: false,
        error: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
