import React from "react";
import { Col, Row } from "reactstrap";

const PlaceMenu = ({ remove, edit, select }) => {
  return (
    <React.Fragment>
      <Row
        className="position-absolute"
        style={{
          right: 20,
          top: 10,
          gap: 3,
        }}
      >
        {edit ? (
          <Col style={{ padding: 0 }}>
            <button
              onClick={(event) => {
                event.preventDefault();
                edit();
              }}
              style={{
                border: 0,
                borderEndStartRadius: 10,
                borderTopLeftRadius: 10,
                boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                paddingInline: 8,
              }}
            >
              <i className="ri-edit-line fs-18"></i>
            </button>
          </Col>
        ) : null}

        {select ? (
          <Col style={{ padding: 0 }}>
            <button
              title="Selecionar como imagem principal"
              onClick={(event) => {
                event.preventDefault();
                select();
              }}
              style={{
                border: 0,
                borderEndStartRadius: 10,
                borderTopLeftRadius: 10,
                boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                paddingInline: 8,
              }}
            >
              <i className="ri-check-fill fs-18"></i>
            </button>
          </Col>
        ) : null}

        {remove ? (
          <Col style={{ padding: 0 }}>
            <button
              title="Excluir imagem"
              onClick={(event) => {
                event.preventDefault();
                remove();
              }}
              style={{
                border: 0,
                borderStartEndRadius: 10,
                borderEndEndRadius: 10,
                boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                paddingInline: 8,
              }}
            >
              <i className="ri-delete-bin-7-line fs-18"></i>
            </button>
          </Col>
        ) : null}
      </Row>
    </React.Fragment>
  );
};

export default PlaceMenu;
