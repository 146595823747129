import {
  Button,
  Modal as ModalStrap,
  ModalBody,
  ModalHeader,
} from "reactstrap";

export default function ModalCustom({
  children,
  onClick,
  isOpen,
  onCancel,
  size = "md",
  labelCancel = "CANCELAR",
  labelConfirm = "CONFIRMAR",
  title,
}) {
  return (
    <ModalStrap isOpen={isOpen} centered size={size}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {children}

        <div className="d-flex flex-sm-row justify-content-end gap-3 pt-3">
          {onCancel ? (
            <button
              type="button"
              className="btn btn-default"
              onClick={onCancel}
            >
              {labelCancel}
            </button>
          ) : null}

          <Button color="primary" onClick={onClick}>
            {labelConfirm}
          </Button>
        </div>
      </ModalBody>
    </ModalStrap>
  );
}
