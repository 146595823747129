import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { RatesTable } from "./RatesTable";

const RatesList = () => {
  document.title = "Reviews | Ecoguias - Admin Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Tabela de reviews" pageTitle="Reviews" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Reviews</h5>
                </CardHeader>
                <CardBody>
                  <RatesTable style={{ width: "100%" }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RatesList;
