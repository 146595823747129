import {
  GET_AGGREGATED_ANALYTICS_DATA,
  GET_AGGREGATED_ANALYTICS_FAILURE,
  GET_AGGREGATED_ANALYTICS_SUCCESS,
  GET_ALL_TIME_ANALYTICS_DATA,
  GET_ALL_TIME_ANALYTICS_FAILURE,
  GET_ALL_TIME_ANALYTICS_SUCCESS,
  GET_REAL_TIME_ANALYTICS_DATA,
  GET_REAL_TIME_ANALYTICS_FAILURE,
  GET_REAL_TIME_ANALYTICS_SUCCESS
} from './actionType';

const initialSubState = Object.freeze({
  loading: false,
  success: false,
  data: {},
});

const initialState = {
  google: initialSubState,
  allTime: initialSubState,
  realTime: initialSubState,
};

const reduceRequest = (subState) => ({
  ...subState,
  loading: true,
  success: false,
});

const reduceSuccess = (subState, data) => ({
  ...subState,
  loading: false,
  success: true,
  data,
});

const reduceFailure = (subState) => ({
  ...subState,
  loading: false,
  success: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGGREGATED_ANALYTICS_DATA:
      state = {
        ...state,
        google: reduceRequest(state.google),
      };
      break;
    case GET_AGGREGATED_ANALYTICS_SUCCESS:
      state = {
        ...state,
        google: reduceSuccess(state.google, action.data),
      };
      break;
    case GET_AGGREGATED_ANALYTICS_FAILURE:
      state = {
        ...state,
        google: reduceFailure(state.google),
      };
      break;
    case GET_ALL_TIME_ANALYTICS_DATA:
      state = {
        ...state,
        allTime: reduceRequest(state.allTime),
      };
      break;
    case GET_ALL_TIME_ANALYTICS_SUCCESS:
      state = {
        ...state,
        allTime: reduceSuccess(state.allTime, action.data),
      };
      break;
    case GET_ALL_TIME_ANALYTICS_FAILURE:
      state = {
        ...state,
        allTime: reduceFailure(state.allTime),
      };
      break;
    case GET_REAL_TIME_ANALYTICS_DATA:
      state = {
        ...state,
        realTime: reduceRequest(state.realTime),
      };
      break;
    case GET_REAL_TIME_ANALYTICS_SUCCESS:
      state = {
        ...state,
        realTime: reduceSuccess(state.realTime, action.data),
      };
      break;
    case GET_REAL_TIME_ANALYTICS_FAILURE:
      state = {
        ...state,
        realTime: reduceFailure(state.realTime),
      }
      break;
    default:
      break;
  }
  return state
};

export default reducer;