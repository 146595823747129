import React,  { useEffect, useState }  from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { getRates, destroyRate } from '../../services/rate/rate-api'
import { toast, ToastContainer } from 'react-toastify';
import { Button, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';


function RatesTable() {
    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Número</span>,
            selector: row => row.id || 'N/A',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Descrição</span>,
            selector: row => row.description || 'N/A',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Nota</span>,
            selector: row => row.note || 'N/A',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Local</span>,
            selector: row => row.place.name || 'N/A',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Usuário</span>,
            selector: row => row.user.firstname + " " + row.user.lastname || 'N/A',
            sortable: true
        },
        {
            name: <span className="font-weight-bold fs-13">Ações</span>,
            selector: row =>
                <div className="hstack gap-3 flex-wrap">
                    <Link to="#" className="link-danger fs-15" onClick={() => openModal(true)}>
                        <i className="ri-delete-bin-line"></i>
                    </Link>
                    <Modal
                        size="md"
                        isOpen={modal}
                        toggle={() => {
                            openModal();
                        }}
                    >
                        <ModalHeader className="modal-title"
                            id="deleteModal" toggle={() => {
                                openModal();
                            }}>

                            Excluir review

                        </ModalHeader>
                        <ModalBody>
                            <h6 className="fs-15">Tem certeza que deseja excluir esse review?</h6>
                        </ModalBody>
                        <div className="modal-footer">
                            <Link to="#" className="btn btn-link link-success fw-medium" onClick={() => setmodal(false)}><i className="ri-close-line me-1 align-middle"></i> Fechar</Link>
                            <Button color="danger" onClick={() => deleteRates(row.id)}>Confirmar</Button>
                        </div>
                    </Modal>
                </div>
        }
    ];

    const errornotify = (message) => toast(message, { position: "top-center", hideProgressBar: true, closeOnClick: false, className: 'bg-danger text-white' });
    const successnotify = (message) => toast(message, { position: "top-center", hideProgressBar: true, closeOnClick: false, className: 'bg-success text-white' });
    const [services, setRates] = useState([]);
    const [modal, setmodal] = useState(false);

    useEffect(() => {
        getRatesData()
    }, []);

    function getRatesData() {
        getRates().then((data) => {
            setRates(data.items);
        })
        .catch(() => {
            errornotify("Erro ao buscar reviews.")
        });
    }

    function openModal() {
        setmodal(!modal);
    }
    
    function deleteRates(serviceId) {
        destroyRate(serviceId).then(() => {
            successnotify("Review excluido com sucesso!");

            getRatesData()
            setmodal(false)
        }).catch(() => {
            errornotify("Erro ao excluir review.")
        });
    }

    return (
        <React.Fragment>
            <Row>
                <ToastContainer />
                <DataTable
                    columns={columns}
                    data={services}
                    pagination
                />
            </Row>
        </React.Fragment>
    );
}

export { RatesTable };