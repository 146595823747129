import React, { useMemo, useState, useEffect } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';

import UsuariosAtivos from './UsuariosAtivos';
import VisualizacoesRecentes from './VisualizacoesRecentes';

import styled from 'styled-components';

const WidgetsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 16px;
  flex-grow: 1;
`;

const CARD_DEFs = [
  {
    title: 'Usuários Ativos',
    icon: 'users',
    metricName: 'activeUsers',
    iconClassName: 'bg-primary',
    countUpOptions: {
      separator: '.',
    }
  },
  {
    title: 'Novos Usuários',
    icon: 'user-plus',
    metricName: 'newUsers',
    iconClassName: 'bg-success',
    countUpOptions: {
      separator: '.',
    }
  },
  {
    title: 'Bounce Rate',
    icon: 'external-link',
    metricName: 'bounceRate',
    iconClassName: 'bg-danger',
    countUpOptions: {
      suffix: '%',
      decimals: 2,
      decimal: ','
    }
  },
  {
    title: 'Tempo por Sessão',
    icon: 'clock',
    metricName: 'averageSessionDuration',
    iconClassName: 'bg-warning',
    countUpOptions: {
      formattingFn: (value) => moment.utc(value * 1000).format('mm:ss')
    }
  },
];

const WidgetsRealtime = () => {
  const totaisBruto = useSelector((state) =>
    state.DashboardAnalytics.google.data.totais
  );

  const totais = useMemo(() => {
    if (!totaisBruto || !totaisBruto.rowCount) {
      return {};
    }
    const _totais = {};
    const { metricHeaders } = totaisBruto;
    const { metricValues } = totaisBruto.rows[0];
    for (let i = 0; i < metricHeaders.length; i++) {
      const metricName = metricHeaders[i].name;
      let metricValue = +metricValues[i].value;
      if (metricName === 'bounceRate') {
        metricValue = metricValue * 100;
      }
      _totais[metricName] = metricValue;
    }
    return _totais;
  }, [totaisBruto]);
  

  return (
    <Col sm={12} xl={6} xxl={4} className="d-flex">
      <WidgetsContainer className="mb-4">
        {CARD_DEFs.map(def => (
          <Card key={def.metricName} className="m-0 card-animate">
            <CardBody className="d-flex">
              <div className="align-items-center card-body d-flex flex-column justify-content-between">
                <div className="avatar-sm">
                  <span
                    className={`${def.iconClassName} avatar-title rounded-circle fs-2`}
                  >
                    <FeatherIcon
                      icon={def.icon}
                    />
                  </span>
                </div>
                <h1 className="ff-secondary fw-semibold m-2">
                  <span className="counter-value">
                    <CountUp
                      start={0}
                      end={totais[def.metricName]}
                      duration={2}
                      {...(def.countUpOptions || {})}
                    />
                  </span>
                </h1>
                <p className="fw-medium text-muted text-uppercase text-center mb-0">{def.title}</p>

              </div>
            </CardBody>
          </Card>
        ))}
      </WidgetsContainer>
    </Col>
  );
};

export default WidgetsRealtime;