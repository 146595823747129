import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Input } from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Modal from "../../Components/Common/Modal";
import PlaceList from "../../Components/Places/PlaceList";
import { getHotelsRequest } from "../../store/hotels/actions";
import { useDebounce } from "use-debounce";
import { getStoresRequest } from "../../store/stores/actions";

const StoreList = () => {
  document.title = "Lojas | Ecoguias - Admin Dashboard";
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, loading, success } = useSelector((state) => state.Store);

  const { search } = useLocation();
  const queryStringParams = queryString.parse(search);

  const [searchInput, setSearchInput] = useState("");
  const [value] = useDebounce(searchInput, 500);

  const [dispatchDelete, setDispatchDelete] = useState(false);

  useEffect(() => {
    dispatch(getStoresRequest(value));
  }, [dispatch, value]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Lojas" pageTitle="Locais" />
          <div className="d-flex justify-content-between pb-4 flex-wrap">
            <Input
              type="text"
              style={{ maxWidth: 250 }}
              className="form-control"
              placeholder="Pesquisar..."
              id="search-options"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={() => history.push("/stores-form")}
            >
              Cadastrar
            </button>
          </div>
          <PlaceList
            loading={loading}
            data={data?.result}
            pathPaginationName="/stores"
            page={queryStringParams.page}
            setDispatchDelete={setDispatchDelete}
          />
        </Container>
      </div>

      <Modal
        isOpen={dispatchDelete && success}
        text="Hotel apagado com sucesso!"
        onClick={() => {
          setDispatchDelete(false);
          window.location.reload();
        }}
      />
    </React.Fragment>
  );
};

export default StoreList;
