export const GET_FILTERS_TOURIST_SPOT_REQUEST =
  "GET_FILTERS_TOURIST_SPOT_REQUEST";
export const GET_FILTERS_TOURIST_SPOT_SUCCESS =
  "GET_FILTERS_TOURIST_SPOT_SUCCESS";
export const GET_FILTERS_TOURIST_SPOT_FAILURE =
  "GET_FILTERS_TOURIST_SPOT_FAILURE";

export const CREATE_FILTERS_TOURIST_SPOT_REQUEST =
  "CREATE_FILTERS_TOURIST_SPOT_REQUEST";
export const CREATE_FILTERS_TOURIST_SPOT_SUCCESS =
  "CREATE_FILTERS_TOURIST_SPOT_SUCCESS";
export const CREATE_FILTERS_TOURIST_SPOT_FAILURE =
  "CREATE_FILTERS_TOURIST_SPOT_FAILURE";

export const UPDATE_FILTERS_TOURIST_SPOT_REQUEST =
  "UPDATE_FILTERS_TOURIST_SPOT_REQUEST";
export const UPDATE_FILTERS_TOURIST_SPOT_SUCCESS =
  "UPDATE_FILTERS_TOURIST_SPOT_SUCCESS";
export const UPDATE_FILTERS_TOURIST_SPOT_FAILURE =
  "UPDATE_FILTERS_TOURIST_SPOT_FAILURE";

export const DELETE_FILTERS_TOURIST_SPOT_REQUEST =
  "DELETE_FILTERS_TOURIST_SPOT_REQUEST";
export const DELETE_FILTERS_TOURIST_SPOT_SUCCESS =
  "DELETE_FILTERS_TOURIST_SPOT_SUCCESS";
export const DELETE_FILTERS_TOURIST_SPOT_FAILURE =
  "DELETE_FILTERS_TOURIST_SPOT_FAILURE";
