import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { CertificateRequestTable } from "./CertificateRequestTable";

const CertificateRequestList = () => {
  document.title = "Solicitação de certificado | Ecoguias - Admin Dashboard";
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Tabela de solicitações de certificados"
            pageTitle="Solicitação de certificado"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    Solicitação de certificado
                  </h5>
                </CardHeader>
                <CardBody>
                  <CertificateRequestTable style={{ width: "100%" }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CertificateRequestList;
