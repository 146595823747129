import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import ForgetPassword from "./auth/forgetpwd/reducer";
import Login from "./auth/login/reducer";
import Profile from "./auth/profile/reducer";
import Account from "./auth/register/reducer";

//Calendar
import Calendar from "./calendar/reducer";
//Chat
import chat from "./chat/reducer";
//Ecommerce
import Ecommerce from "./ecommerce/reducer";

//Project
import Projects from "./projects/reducer";

// Tasks
import Tasks from "./tasks/reducer";
//Form advanced
import changeNumber from "./formAdvanced/reducer";

//Crypto
import Crypto from "./crypto/reducer";

//TicketsList
import Tickets from "./tickets/reducer";
//Crm
import Crm from "./crm/reducer";

//Invoice
import Invoice from "./invoice/reducer";

//Mailbox
import Mailbox from "./mailbox/reducer";

// Dashboard Analytics
import DashboardAnalytics from "./dashboardAnalytics/reducer";

// Dashboard CRM
import DashboardCRM from "./dashboardCRM/reducer";

// Dashboard Ecommerce
import DashboardEcommerce from "./dashboardEcommerce/reducer";

// Dashboard Cryto
import DashboardCrypto from "./dashboardCrypto/reducer";

// Dashboard Cryto
import DashboardProject from "./dashboardProject/reducer";

// Dashboard NFT
import DashboardNFT from "./dashboardNFT/reducer";

// Pages > Team
import Team from "./team/reducer";

import City from "./city/reducer";
import Events from "./events/reducer";
import Hotel from "./hotels/reducer";
import Restaurant from "./restaurants/reducer";
import Services from "./services/reducer";
import Store from "./stores/reducer";
import TouristSpotPhotosUser from "./tourisSpotPhotoUser/reducer";
import TouristReception from "./touristReceptions/reducer";
import TouristSpots from "./touristSpots/reducer";
import TypesService from "./typeService/reducer";
import Users from "./users/reducer";
import Waterfall from "./waterfall/reducer";

import Circuit from "./circuits/reducer";

import PassportDetails from "./passport/passportDetails/reducer";
import Passport from "./passport/reducer";

import Suggestions from "./suggestions/reducer";
import SuggestionsDetails from "./suggestions/suggestionsDetails/reducer";

import ContactsUsDetails from "./contactsUs/contactsUsDetails/reducer";
import ContactsUs from "./contactsUs/reducer";

//Filters
import FiltersWhereEat from "./filters/whereEat/reducer";
import FiltersWhereEatDetails from "./filters/whereEatDetails/reducer";

import FiltersWhereStay from "./filters/whereStay/reducer";
import FiltersWhereStayDetails from "./filters/whereStayDetails/reducer";

import FiltersTouristSpot from "./filters/touristSpot/reducer";
import FiltersTouristSpotDetails from "./filters/touristSpotDetails/reducer";

import FiltersService from "./filters/service/reducer";
import FiltersServiceDetails from "./filters/serviceDetails/reducer";

const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Calendar,
  chat,
  Projects,
  Ecommerce,
  Tasks,
  changeNumber,
  Crypto,
  Tickets,
  Crm,
  Invoice,
  Mailbox,
  DashboardAnalytics,
  DashboardCRM,
  DashboardEcommerce,
  DashboardCrypto,
  DashboardProject,
  DashboardNFT,
  Team,

  City,
  Waterfall,
  Restaurant,
  Hotel,
  TouristReception,
  TouristSpots,
  TouristSpotPhotosUser,
  Services,
  TypesService,
  Events,
  Store,
  Circuit,
  Passport,
  PassportDetails,
  Users,
  Suggestions,
  SuggestionsDetails,

  ContactsUs,
  ContactsUsDetails,

  FiltersWhereEat,
  FiltersWhereEatDetails,

  FiltersWhereStay,
  FiltersWhereStayDetails,

  FiltersTouristSpot,
  FiltersTouristSpotDetails,

  FiltersService,
  FiltersServiceDetails,
});

export default rootReducer;
