import {
  CREATE_FILTERS_TOURIST_SPOT_FAILURE,
  CREATE_FILTERS_TOURIST_SPOT_REQUEST,
  CREATE_FILTERS_TOURIST_SPOT_SUCCESS,
  DELETE_FILTERS_TOURIST_SPOT_FAILURE,
  DELETE_FILTERS_TOURIST_SPOT_REQUEST,
  DELETE_FILTERS_TOURIST_SPOT_SUCCESS,
  GET_FILTERS_TOURIST_SPOT_FAILURE,
  GET_FILTERS_TOURIST_SPOT_REQUEST,
  GET_FILTERS_TOURIST_SPOT_SUCCESS,
  UPDATE_FILTERS_TOURIST_SPOT_FAILURE,
  UPDATE_FILTERS_TOURIST_SPOT_REQUEST,
  UPDATE_FILTERS_TOURIST_SPOT_SUCCESS,
} from "./actionTypes";

export const getFiltersTouristSpotRequest = (data) => {
  return {
    type: GET_FILTERS_TOURIST_SPOT_REQUEST,
    data,
  };
};

export const getFiltersTouristSpotSuccess = (data) => {
  return {
    type: GET_FILTERS_TOURIST_SPOT_SUCCESS,
    data,
  };
};

export const getFiltersTouristSpotFailure = () => {
  return {
    type: GET_FILTERS_TOURIST_SPOT_FAILURE,
  };
};

export const createFilterTouristSpotRequest = (data) => {
  return {
    type: CREATE_FILTERS_TOURIST_SPOT_REQUEST,
    data,
  };
};

export const createFilterTouristSpotSuccess = (data) => {
  return {
    type: CREATE_FILTERS_TOURIST_SPOT_SUCCESS,
    data,
  };
};

export const createFilterTouristSpotFailure = () => {
  return {
    type: CREATE_FILTERS_TOURIST_SPOT_FAILURE,
  };
};

export const updateFilterTouristSpotRequest = (data) => {
  return {
    type: UPDATE_FILTERS_TOURIST_SPOT_REQUEST,
    data,
  };
};

export const updateFilterTouristSpotFailure = () => {
  return {
    type: UPDATE_FILTERS_TOURIST_SPOT_FAILURE,
  };
};

export const updateFilterTouristSpotSuccess = (data) => {
  return {
    type: UPDATE_FILTERS_TOURIST_SPOT_SUCCESS,
    data,
  };
};

export const deleteFilterTouristSpotFailure = () => {
  return {
    type: DELETE_FILTERS_TOURIST_SPOT_FAILURE,
  };
};

export const deleteFilterTouristSpotRequest = (data) => {
  return {
    type: DELETE_FILTERS_TOURIST_SPOT_REQUEST,
    data,
  };
};

export const deleteFilterTouristSpotSuccess = (data) => {
  return {
    type: DELETE_FILTERS_TOURIST_SPOT_SUCCESS,
    data,
  };
};
