import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../../boot/api";
import showNofity from "../../../helpers/toast";
import {
  getFilterWhereStayFailure,
  getFilterWhereStaySuccess,
} from "./actions";
import { GET_FILTER_WHERE_STAY_REQUEST } from "./actionTypes";

function requestFilterWhereStay(id) {
  return post("/filters-where-stay-details", { filterWhereStayId: id });
}

function* getFilterWhereStay({ data }) {
  try {
    const response = yield call(requestFilterWhereStay, data);
    yield put(getFilterWhereStaySuccess(response.data.result));
  } catch (error) {
    showNofity.error("Erro ao buscar filtro. " + error);
    yield put(getFilterWhereStayFailure());
  }
}

function* saga() {
  yield takeLatest(GET_FILTER_WHERE_STAY_REQUEST, getFilterWhereStay);
}

export default saga;
