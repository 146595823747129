import React, { useMemo } from 'react'

import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Row, Table } from 'reactstrap';
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const PaginasAcessadasRecentes = () => {
  const viewsData = useSelector(state =>
    state.DashboardAnalytics.realTime.data.visualizacoesPorPagina
  );

  const pagesAndCounts = useMemo(() => {
    const dataMatrix = [];
    const series = [];
    if (viewsData) {
      viewsData.rows?.forEach((row) => {
        const page = row.dimensionValues[0].value || '(sem nome)';
        const viewCount = +row.metricValues[0].value;

        series.push([page, viewCount]);
      });
    }
    return series;
  }, [viewsData]);

  let noData;
  if (pagesAndCounts.length === 0) {
    noData = (
      <tr>
        <td className="text-center" colSpan={2}>Nenhum usuário ativo</td>
      </tr>
    );
  }

  return (
    <Card className="flex-1" style={{ minHeight: '200px' }}>
      <CardHeader>
        <h4 className='mb-0'>Páginas Visitadas</h4>
      </CardHeader>
      <CardBody className="p-0">
        <Table className="table-borderless table-sm m-0">
          <thead className="text-muted border-dashed border border-start-0 border-end-0 bg-soft-light">
            <tr>
              <th className="ps-3">Página</th>
              <th className="text-center" style={{ width: '30%' }}>Visitas</th>
            </tr>
          </thead>
        </Table>
        <PerfectScrollbar style={{ maxHeight: '250px' }}>
          <Table
            className="table-borderless table-sm table-striped align-middle"
            style={{
              '--vz-table-striped-bg': 'rgba(var(--vz-dark-rgb), 0.05)'
            }}>
            <tbody className="border-0">
              {pagesAndCounts.map(([page, viewCount]) => (
                <tr key={page}>
                  <td className="ps-3">{page}</td>
                  <td className="text-center" style={{ width: '30%' }}>{viewCount}</td>
                </tr>
              ))}
              {noData}
            </tbody>
          </Table>
        </PerfectScrollbar>
      </CardBody>
    </Card>
  );
};

export default PaginasAcessadasRecentes;