import {
  CREATE_CIRCUIT_FAILURE,
  CREATE_CIRCUIT_REQUEST,
  CREATE_CIRCUIT_SUCCESS,
  DELETE_CIRCUIT_FAILURE,
  DELETE_CIRCUIT_REQUEST,
  DELETE_CIRCUIT_SUCCESS,
  GET_CIRCUITS_FAILURE,
  GET_CIRCUITS_REQUEST,
  GET_CIRCUITS_SUCCESS,
  GET_CIRCUIT_FAILURE,
  GET_CIRCUIT_REQUEST,
  GET_CIRCUIT_SUCCESS,
  REMOVE_GPS_CIRCUIT_FAILURE,
  REMOVE_GPS_CIRCUIT_REQUEST,
  REMOVE_GPS_CIRCUIT_SUCCESS,
  REMOVE_MAP_CIRCUIT_FAILURE,
  REMOVE_MAP_CIRCUIT_REQUEST,
  REMOVE_MAP_CIRCUIT_SUCCESS,
  UPDATE_CIRCUIT_FAILURE,
  UPDATE_CIRCUIT_REQUEST,
  UPDATE_CIRCUIT_SUCCESS,
  DELETE_CIRCUIT_PHOTO_SUCCESS,
  DELETE_CIRCUIT_PHOTO_FAILURE,
  DELETE_CIRCUIT_PHOTO_REQUEST,
  UPDATE_CIRCUIT_PHOTO_FAILURE,
  UPDATE_CIRCUIT_PHOTO_REQUEST,
  UPDATE_CIRCUIT_PHOTO_SUCCESS,
} from "./actionTypes";

export const getCircuitsRequest = (data) => {
  return {
    type: GET_CIRCUITS_REQUEST,
    data,
  };
};

export const getCircuitsSuccess = (data) => {
  return {
    type: GET_CIRCUITS_SUCCESS,
    data,
  };
};

export const getCircuitsFailure = (data) => {
  return {
    type: GET_CIRCUITS_FAILURE,
    data,
  };
};

export const getCircuitRequest = (data) => {
  return {
    type: GET_CIRCUIT_REQUEST,
    data,
  };
};

export const getCircuitSuccess = (data) => {
  return {
    type: GET_CIRCUIT_SUCCESS,
    data,
  };
};

export const getCircuitFailure = (data) => {
  return {
    type: GET_CIRCUIT_FAILURE,
    data,
  };
};

export const createCircuitRequest = (data) => {
  return {
    type: CREATE_CIRCUIT_REQUEST,
    data,
  };
};

export const createtCircuitSuccess = (data) => {
  return {
    type: CREATE_CIRCUIT_SUCCESS,
    data,
  };
};

export const createtCircuitFailure = (data) => {
  return {
    type: CREATE_CIRCUIT_FAILURE,
    data,
  };
};

export const deleteCircuitRequest = (data) => {
  return {
    type: DELETE_CIRCUIT_REQUEST,
    data,
  };
};

export const deleteCircuitSuccess = (data) => {
  return {
    type: DELETE_CIRCUIT_SUCCESS,
    data,
  };
};

export const deleteCircuitFailure = () => {
  return {
    type: DELETE_CIRCUIT_FAILURE,
  };
};

export const deleteCircuitPhotoSuccess = (data) => {
  return {
    type: DELETE_CIRCUIT_PHOTO_SUCCESS,
    data,
  };
};

export const deleteCircuitPhotoFailure = () => {
  return {
    type: DELETE_CIRCUIT_PHOTO_FAILURE,
  };
};

export const deleteCircuitPhotoRequest = (data) => {
  return {
    type: DELETE_CIRCUIT_PHOTO_REQUEST,
    data,
  };
};

export const removeMapCircuitRequest = (data) => {
  return {
    type: REMOVE_MAP_CIRCUIT_REQUEST,
    data,
  };
};

export const removeMapCircuitSuccess = (data) => {
  return {
    type: REMOVE_MAP_CIRCUIT_SUCCESS,
    data,
  };
};

export const removeMapCircuitFailure = () => {
  return {
    type: REMOVE_MAP_CIRCUIT_FAILURE,
  };
};

export const removeGpsCircuitRequest = (data) => {
  return {
    type: REMOVE_GPS_CIRCUIT_REQUEST,
    data,
  };
};

export const removeGpsCircuitSuccess = (data) => {
  return {
    type: REMOVE_GPS_CIRCUIT_SUCCESS,
    data,
  };
};

export const removeGpsCircuitFailure = () => {
  return {
    type: REMOVE_GPS_CIRCUIT_FAILURE,
  };
};

export const updateCircuitRequest = (data) => {
  return {
    type: UPDATE_CIRCUIT_REQUEST,
    data,
  };
};

export const updateCircuitFailure = () => {
  return {
    type: UPDATE_CIRCUIT_FAILURE,
  };
};

export const updateCircuitSuccess = (data) => {
  return {
    type: UPDATE_CIRCUIT_SUCCESS,
    data,
  };
};

export const updateCircuitPhotoFailure = () => {
  return {
    type: UPDATE_CIRCUIT_PHOTO_FAILURE,
  };
};

export const updateCircuitPhotoRequest = (data) => {
  return {
    type: UPDATE_CIRCUIT_PHOTO_REQUEST,
    data,
  };
};

export const updateCircuitPhotoSuccess = (data) => {
  return {
    type: UPDATE_CIRCUIT_PHOTO_SUCCESS,
    data,
  };
};
