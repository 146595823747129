import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../../boot/api";
import showNofity from "../../../helpers/toast";
import {
  createFilterServiceFailure,
  createFilterServiceSuccess,
  deleteFilterServiceFailure,
  deleteFilterServiceSuccess,
  getFiltersServiceFailure,
  getFiltersServiceSuccess,
  updateFilterServiceFailure,
  updateFilterServiceSuccess,
} from "./actions";
import {
  CREATE_FILTERS_SERVICE_REQUEST,
  DELETE_FILTERS_SERVICE_REQUEST,
  GET_FILTERS_SERVICE_REQUEST,
  UPDATE_FILTERS_SERVICE_REQUEST,
} from "./actionTypes";

function requestFiltersService(data) {
  return post("/filters-service-by-name", { filterServiceName: data });
}

function newFilterService(filter) {
  return post("/filters-service", filter);
}

function deleteFiltersServiceById(id) {
  return post("/filters-service-remove", { filterServiceId: id });
}

function requestUpdateFilterService(id, filterService) {
  const { nome } = filterService;

  return post("/filters-service-update", {
    filterServiceId: id,
    nome,
  });
}

function* getFiltersService({ data }) {
  try {
    const response = yield call(requestFiltersService, data);

    yield put(getFiltersServiceSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar filtros do serviço. " + error);
    yield put(getFiltersServiceFailure());
  }
}

function* createFilterService({ data }) {
  try {
    const { nome } = data.values;

    yield call(newFilterService, {
      nome,
    });

    yield put(createFilterServiceSuccess());
    data.resetForm();
    data.closeModal();
    const response = yield call(requestFiltersService);
    yield put(getFiltersServiceSuccess(response.data));
    showNofity.success("Filtro cadastrado com sucesso");
  } catch (error) {
    showNofity.error("Erro ao cadastrar filtro." + error);
    yield put(createFilterServiceFailure());
  }
}

function* updateFilterService({ data }) {
  try {
    const { nome } = data.values;

    yield call(requestUpdateFilterService, data.id, {
      nome,
    });

    yield put(updateFilterServiceSuccess());
    showNofity.success("Filtro atualizado com sucesso");
    data.closeModal();
    const response = yield call(requestFiltersService);
    yield put(getFiltersServiceSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao atualizar filtro " + error);
    yield put(updateFilterServiceFailure());
  }
}

function* deleteFilterService({ data }) {
  try {
    yield call(deleteFiltersServiceById, data.filterServiceId);
    yield put(deleteFilterServiceSuccess());
    data.closeModal();
    const response = yield call(requestFiltersService);
    yield put(getFiltersServiceSuccess(response.data));
    showNofity.success("Filtro removido com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao remover filtro. " + error);
    yield put(deleteFilterServiceFailure());
  }
}

function* saga() {
  yield takeLatest(GET_FILTERS_SERVICE_REQUEST, getFiltersService);
  yield takeLatest(CREATE_FILTERS_SERVICE_REQUEST, createFilterService);
  yield takeLatest(DELETE_FILTERS_SERVICE_REQUEST, deleteFilterService);
  yield takeLatest(UPDATE_FILTERS_SERVICE_REQUEST, updateFilterService);
}

export default saga;
