import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Row } from "reactstrap";
import Loading from "../../Components/Loading/Loading";
import ModalCustom from "../../Components/Modals/ModalCustom";
import { getContactsUsRequest } from "../../store/contactsUs/actions";
import { formatPhone } from "../../utils/formats";
import ContactsUsDetails from "./ContactsUsDetails";

function ContactsUsTable({ contacts, loading, searchInput }) {
  const [modal, setModal] = useState(false);
  const [contactUsId, setContactUsId] = useState(false);

  const { success } = useSelector((state) => state.ContactsUsDetails);

  const dispatch = useDispatch();

  if (loading) {
    return <Loading />;
  }

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Nome</span>,
      selector: (row) => row.nome || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Usuário</span>,
      selector: (row) => row?.usuario?.username || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">E-mail</span>,
      selector: (row) => row.email || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Telefone</span>,
      selector: (row) =>
        (row.telefone && formatPhone(row.telefone.toString())) || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Cidade</span>,
      selector: (row) => row.cidade || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Mensagem</span>,
      selector: (row) => row.mensagem || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Foi respondido?</span>,
      width: "10%",
      selector: (row) =>
        row.respondido ? (
          <span className="badge bg-success">Sim</span>
        ) : (
          <span className="badge bg-danger">Não</span> || "N/A"
        ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Ações</span>,
      width: "10%",
      right: true,
      selector: (row) => (
        <div className="hstack gap-3 flex-wrap">
          <Link
            to="#"
            className="link-success fs-15"
            onClick={() => {
              setContactUsId(row?.objectId);
              setModal(true);
            }}
          >
            <i className="mdi mdi-eye"></i>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <ToastContainer />
        <DataTable
          columns={columns}
          data={contacts}
          pagination
          noDataComponent="Não existem dados na tabela de fale conosco!"
        />

        <ModalCustom
          isOpen={modal}
          labelConfirm="OK"
          onClick={() => {
            if (success) {
              dispatch(getContactsUsRequest(searchInput));
            }
            setModal(false);
          }}
          title="Detalhes fale conosco"
        >
          <ContactsUsDetails objectId={contactUsId} />
        </ModalCustom>
      </Row>
    </React.Fragment>
  );
}

export { ContactsUsTable };
