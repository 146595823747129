import axios from "axios";
import { resetUserInLocalStorage } from "./user";

const GATEWAY_BASE_URL = process.env.REACT_APP_GATEWAY_BASE_URL || "http://localhost:3333/";

const api = axios.create({
  baseURL: GATEWAY_BASE_URL,
});

api.interceptors.request.use(
  function (config) {
    config.headers.Accept = "application/json";
    config.headers["Content-Type"] = "application/json";
    config.headers["Authorization"] = localStorage.getItem("userToken");
    config.headers["Access-Control-Allow-Headers"] = "*";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const isUserLogged = localStorage.getItem("isUserLogged");
    const userToken = localStorage.getItem("userToken");

    if (error.response?.status === 401 && (isUserLogged || userToken)) {
      resetUserInLocalStorage();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const get = async (path, params) => {
  return api.get(path, { params });
};
const show = async (path, params) => {
  return api.get(path, { params });
};
const post = async (path, params) => {
  return api.post(path, params);
};
const put = async (path, params) => {
  return api.put(path, params);
};
const patch = async (path, params) => {
  return api.patch(path, params);
};
const destroy = async (path) => {
  return api.delete(path);
};

export { api, get, show, post, put, patch, destroy };
