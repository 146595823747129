import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import ModalLoading from "../../Components/Common/ModalLoading";
import * as Yup from "yup";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import {
  CoverUploadGPSField,
  CoverUploadImageField,
  TextField,
} from "../../Components/Forms";
import {
  formatMinutesToDuration,
  parseDurationToMinutes,
} from "../../utils/formats";
import { DURATION_SCHEMA } from '../../utils/validation_schemas';

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  deleteCircuitPhotoRequest,
  getCircuitRequest,
  removeGpsCircuitRequest,
  removeMapCircuitRequest,
  updateCircuitPhotoRequest,
  updateCircuitRequest,
} from "../../store/circuits/actions";

import LazyImage from "../../Components/LazyImage/LazyImage";

import geojsonlogo from "../../../src/assets/images/geojson.png";
import Modal from "../../Components/Common/Modal";
import PlaceMenu from '../../Components/Places/PlaceMenu';
import { api } from '../../boot/api';
import showNofity from '../../helpers/toast';
import { SelectableField } from '../../Components/Forms/SelectableField';

const MANDATORY_FIELD = "Este campo é obrigatório";

const CircuitFormEdit = () => {
  document.title = "Formulário de circuitos";
  const dispatch = useDispatch();
  const params = useParams();
  const { data: circuitResponse, loading } = useSelector((state) => state.Circuit);
  const {
    circuit: circuitData = {},
    images: circuitImages = [],
  } = circuitResponse ?? {};

  const [modalIsOpenRemove, setModalIsOpenRemove] = useState(false);
  const [modalIsOpenSelect, setModalIsOpenSelect] = useState(false);
  const [selectedPhotoId, setSelectedPhotoId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [modalIsOpenMapRemove, setModalIsOpenMapRemove] = useState(false);
  const [modalIsOpenGpsRemove, setModalIsOpenGpsRemove] = useState(false);

  const history = useHistory();

  useEffect(() => {
    dispatch(getCircuitRequest(params.id));
  }, [dispatch, params.id]);

  const circuit = useFormik({
    enableReinitialize: true,
    initialValues: Object.assign(
      {
        titulo: "",
        descritivo_titulo: "",
        sub_titulo: "",
        descritivo_subtitulo: "",
        composicao_asfalto_distancia: "",
        composicao_asfalto_porcentagem: "",
        composicao_terra_distancia: "",
        composicao_terra_porcentagem: "",
        composicao_caminhada_distancia: "",
        composicao_caminhada_porcentagem: "",
        tempo_bicicleta: "",
        tempo_caminhada: "",
        tempo_cavalo: "",
        altimetria: "",
        arquivo_gps: "",
        mapa: [],
        legendas: [],
        url_video: "",
        cidades_roteiro: [],
      },
      {
        ...circuitData,
        mapa: [],
        images: [], // only new images go here
        legendas: circuitImages.map(image => image.legenda),
        tempo_bicicleta: formatMinutesToDuration(circuitData.tempo_bicicleta),
        tempo_caminhada: formatMinutesToDuration(circuitData.tempo_caminhada),
        tempo_cavalo: formatMinutesToDuration(circuitData.tempo_cavalo),
      }
    ),

    validationSchema: Yup.object({
      titulo: Yup.string().required(MANDATORY_FIELD),
      sub_titulo: Yup.string().required(MANDATORY_FIELD),
      descritivo_titulo: Yup.string().required(MANDATORY_FIELD),
      descritivo_subtitulo: Yup.string().required(MANDATORY_FIELD),
      composicao_asfalto_distancia: Yup.string().required(MANDATORY_FIELD),
      composicao_asfalto_porcentagem: Yup.string().required(MANDATORY_FIELD),
      composicao_terra_distancia: Yup.string().required(MANDATORY_FIELD),
      composicao_terra_porcentagem: Yup.string().required(MANDATORY_FIELD),
      composicao_caminhada_distancia: Yup.string().required(MANDATORY_FIELD),
      composicao_caminhada_porcentagem: Yup.string().required(MANDATORY_FIELD),
      tempo_bicicleta: DURATION_SCHEMA,
      tempo_caminhada: DURATION_SCHEMA,
      tempo_cavalo: DURATION_SCHEMA,
      altimetria: Yup.array(),
      arquivo_gps: Yup.object(),
      mapa: Yup.array(Yup.string()),
      images: Yup.array(Yup.string()),
      url_video: Yup.string().optional().notRequired(),
      cidades_roteiro: Yup.array(Yup.string()),
    }),
    onSubmit: (values) => {
      console.log(values);

      dispatch(updateCircuitRequest({
        id: params.id,
        values: {
          ...values,
          tempo_bicicleta: parseDurationToMinutes(values?.tempo_bicicleta),
          tempo_caminhada: parseDurationToMinutes(values?.tempo_caminhada),
          tempo_cavalo: parseDurationToMinutes(values?.tempo_cavalo),
        },
      }));
    },
  });

  function renderMapImage() {
    if (circuitData.mapa?.url) {
      return (
        <>
          <h2>Mapa</h2>
          <div className="position-relative" style={styles.lazyContainerImage}>
            <button
              title="Excluir imagem"
              className="position-absolute"
              onClick={() => setModalIsOpenMapRemove(true)}
              type="button"
              style={{
                right: 13,
                top: 5,
                border: 0,
                borderRadius: 15,
                boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
              }}
            >
              <i className="ri-delete-bin-7-line fs-16"></i>
            </button>
            <LazyImage
              src={circuitData.mapa?.url}
              width="100%"
              height="100%"
              className="object-fit-cover rounded"
            />
          </div>
        </>
      );
    }

    return (
      <CoverUploadImageField
        title="Mapa"
        multiple={false}
        data={circuit}
        name="mapa"
        isActiveRadioSelect={false}
      />
    );
  }

  function renderCoverGPS() {
    if (circuitData.arquivo_gps?.url) {
      return (
        <>
          <h2>GPS</h2>
          <div className="position-relative" style={styles.lazyContainerGps}>
            <button
              title="Excluir imagem"
              className="position-absolute"
              onClick={() => setModalIsOpenGpsRemove(true)}
              type="button"
              style={{
                right: 13,
                top: 5,
                border: 0,
                borderRadius: 15,
                boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
              }}
            >
              <i className="ri-delete-bin-7-line fs-16"></i>
            </button>

            <LazyImage
              src={geojsonlogo}
              width="90%"
              className="object-fit-cover rounded"
            />
            <a href={circuitData.arquivo_gps?.url} target="_blank" rel="noreferrer">
              Visualizar
            </a>
          </div>
        </>
      );
    }

    return (
      <CoverUploadGPSField
        title="GPS (gpx ou kml)"
        multiple={false}
        data={circuit}
        name="arquivo_gps"
        isActiveRadioSelect={false}
      />
    );
  }

  function renderImages() {
    if (!circuitImages.length) {
      return;
    }

    function addLegendInState(index, txt) {
      const newLegendas = [...circuit.values.legendas];
      newLegendas[index] = txt;
      circuit.setFieldValue('legendas', newLegendas);
    }

    async function updateLegend(imagemId, legenda) {
      try {
        await api.post("/photo-circuit-update-legend", { imagemId, legenda });
        showNofity.success("Legenda salva com sucesso");
      } catch (error) {
        showNofity.error("Erro ao salvar legenda");
      }
    }

    return (
      <>
        <h3>Imagens do local</h3>
        <p>
          Escolha uma opção abaixo para excluir uma imagem ou
          selecionar a imagem principal do local
        </p>
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 10,
            }}
          >
            {circuitImages.map((item, idx) => {
              if (!item.imagem?.name) {
                return null;
              }

              const isMainImage = item.imagem.url === circuitData.imagem?.url;

              return (
                <div key={item.objectId}>
                  <Col lg={3} style={styles.imageList}>
                    {!isMainImage
                      ? (
                        <PlaceMenu
                          remove={() => {
                            setSelectedPhotoId(item.objectId);
                            setModalIsOpenRemove(true);
                          }}
                          select={() => {
                            setSelectedImage(item.imagem);
                            setModalIsOpenSelect(true);
                          }}
                        />
                      )
                      : null}
                    <LazyImage
                      src={item.imagem.url}
                      width="100%"
                      height="100%"
                      className="object-fit-cover rounded"
                      style={
                        isMainImage
                          ? styles.lazyImage
                          : null
                      }
                    />
                  </Col>
                  <div style={{ marginTop: 10 }}></div>
                  <InputGroup>
                    <Input
                      value={circuit.values.legendas[idx]}
                      title="Legenda"
                      name="Legenda"
                      placeholder="Legenda"
                      size="sm"
                      className="form-control"
                      onChange={(event) =>
                        addLegendInState(idx, event.target.value)
                      }
                    />
                    <Button
                      size="sm"
                      className="primary"
                      onClick={() =>
                        updateLegend(
                          item.objectId,
                          circuit.values.legendas[idx]
                        )
                      }
                    >
                      <i className="ri-save-2-line fs-13"></i>
                    </Button>
                  </InputGroup>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      </>
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Formulário de circuitos" pageTitle="Circuitos" />
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <div className="p-4">
                <button
                  className="btn btn-primary"
                  onClick={() => history.goBack()}
                >
                  Voltar
                </button>
              </div>
              <CardBody>
                <div className="live-preview">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      circuit.handleSubmit();
                      return false;
                    }}
                    action="#"
                  >
                    <Row className="g-3">
                      <CoverUploadImageField
                        title="Adicionar imagens"
                        name="images"
                        multiple
                        data={circuit}
                        isActiveRadioSelect={false}
                      />

                      <TextField
                        label="Título"
                        name="titulo"
                        data={circuit}
                        size={12}
                      />
                      <TextField
                        label="Descritivo título"
                        name="descritivo_titulo"
                        data={circuit}
                        size={12}
                        type="textarea"
                      />
                      <TextField
                        label="Subtítulo"
                        name="sub_titulo"
                        data={circuit}
                        size={12}
                      />
                      <TextField
                        label="Descritivo subtítulo"
                        name="descritivo_subtitulo"
                        data={circuit}
                        size={12}
                        type="textarea"
                      />
                      <TextField
                        label="Vídeo do local (URL)"
                        name="url_video"
                        data={circuit}
                        size={12}
                      />
                      <TextField
                        label="Composicao asfalto (distância)"
                        name="composicao_asfalto_distancia"
                        data={circuit}
                      />
                      <TextField
                        label="Composicao asfalto (porcentagem)"
                        name="composicao_asfalto_porcentagem"
                        data={circuit}
                      />
                      <TextField
                        label="Composicao terra (distância)"
                        name="composicao_terra_distancia"
                        data={circuit}
                      />
                      <TextField
                        label="Composicao terra (porcentagem)"
                        name="composicao_terra_porcentagem"
                        data={circuit}
                      />
                      <TextField
                        label="Composicao caminhada (distância)"
                        name="composicao_caminhada_distancia"
                        data={circuit}
                      />
                      <TextField
                        label="Composicao caminhada (porcentagem)"
                        name="composicao_caminhada_porcentagem"
                        data={circuit}
                      />
                      <TextField
                        type="duration"
                        label="Tempo bicicleta"
                        name="tempo_bicicleta"
                        data={circuit}
                        size={4}
                      />
                      <TextField
                        type="duration"
                        label="Tempo caminhada"
                        name="tempo_caminhada"
                        data={circuit}
                        size={4}
                      />
                      <TextField
                        type="duration"
                        label="Tempo cavalo"
                        name="tempo_cavalo"
                        data={circuit}
                        size={4}
                      />
                      <SelectableField
                        label="Cidades do roteiro"
                        name="cidades_roteiro"
                        data={circuit}
                        size={6}
                      />

                      {renderMapImage()}
                      {renderCoverGPS()}
                      {renderImages()}

                      <Col lg={12}>
                        <div className="text-end">
                          <button type="submit" className="btn btn-primary">
                            Salvar
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        isOpen={modalIsOpenMapRemove}
        text="Tem certeza que deseja remover o mapa?"
        onClick={() => {
          dispatch(removeMapCircuitRequest({ id: params.id }));
          setModalIsOpenMapRemove(false);
        }}
        onCancel={() => {
          setModalIsOpenMapRemove(false);
        }}
      />
      <Modal
        isOpen={modalIsOpenGpsRemove}
        text="Tem certeza que deseja remover o GPS?"
        onClick={() => {
          dispatch(removeGpsCircuitRequest({ id: params.id }));
          setModalIsOpenGpsRemove(false);
        }}
        onCancel={() => {
          setModalIsOpenGpsRemove(false);
        }}
      />

      <Modal
        isOpen={modalIsOpenRemove}
        text="Tem certeza que deseja remover essa imagem?"
        onClick={() => {
          dispatch(
            deleteCircuitPhotoRequest({
              circuitId: params.id,
              photoId: selectedPhotoId,
            })
          );

          setModalIsOpenRemove(false);
        }}
        onCancel={() => {
          setModalIsOpenRemove(false);
        }}
      />

      <Modal
        isOpen={modalIsOpenSelect}
        text="Tem certeza que deseja selecionar essa imagem como a principal deste circuito?"
        onClick={() => {
          dispatch(
            updateCircuitPhotoRequest({
              circuitId: params.id,
              imagem: selectedImage,
            })
          );

          setModalIsOpenSelect(false);
        }}
        onCancel={() => {
          setModalIsOpenSelect(false);
        }}
      />

      <ModalLoading loading={loading} />
    </React.Fragment>
  );
};

const styles = {
  lazyContainerImage: {
    height: 180,
    width: 180,
    borderRadius: 10,
  },
  lazyContainerGps: {
    height: 130,
    width: 120,
    borderRadius: 10,
  },
  imageList: {
    display: "flex",
    position: "relative",
    height: 220,
    width: 260,
  },
};

export default CircuitFormEdit;
