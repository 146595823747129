import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";
import { getPassportFailure, getPassportSuccess } from "./actions";
import { GET_PASSPORT_REQUEST } from "./actionTypes";

function requestPassport(data) {
  return post("/passport-by-name", { passportName: data });
}

function* getPassport({ data }) {
  try {
    const response = yield call(requestPassport, data);
    yield put(getPassportSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar passaporte virtual. " + error);
    yield put(getPassportFailure());
  }
}

function* saga() {
  yield takeLatest(GET_PASSPORT_REQUEST, getPassport);
}

export default saga;
