import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import ForgetSaga from "./auth/forgetpwd/saga";
import AuthSaga from "./auth/login/saga";
import ProfileSaga from "./auth/profile/saga";
import AccountSaga from "./auth/register/saga";

//calendar
import calendarSaga from "./calendar/saga";
//chat
import chatSaga from "./chat/saga";
//ecommerce
import ecommerceSaga from "./ecommerce/saga";

//Project
import projectSaga from "./projects/saga";
// Task
import taskSaga from "./tasks/saga";
// Crypto
import cryptoSaga from "./crypto/saga";
//TicketsList
import ticketsSaga from "./tickets/saga";

//crm
import crmSaga from "./crm/saga";
//invoice
import invoiceSaga from "./invoice/saga";
//mailbox
import mailboxSaga from "./mailbox/saga";

// Dashboard Analytics
import dashboardAnalyticsSaga from "./dashboardAnalytics/saga";

// Dashboard CRM
import dashboardCrmSaga from "./dashboardCRM/saga";

// Dashboard Ecommerce
import dashboardEcommerceSaga from "./dashboardEcommerce/saga";

// Dashboard Crypto
import dashboardCryptoSaga from "./dashboardCrypto/saga";

// Dashboard Project
import dashboardProjectSaga from "./dashboardProject/saga";

// Dashboard NFT
import dashboardNFTSaga from "./dashboardNFT/saga";

// Pages > Team
import teamSaga from "./team/saga";

import citySaga from "./city/saga";
import eventsSaga from "./events/saga";
import hotelsSaga from "./hotels/saga";
import passportDetailsSaga from "./passport/passportDetails/saga";
import passportSaga from "./passport/saga";
import restaurantSaga from "./restaurants/saga";
import servicesSaga from "./services/saga";
import storeSaga from "./stores/saga";
import tourisSpotPhotoUserSaga from "./tourisSpotPhotoUser/saga";
import touristReceptionsSaga from "./touristReceptions/saga";
import touristSpotsSaga from "./touristSpots/saga";
import typesServiceSaga from "./typeService/saga";
import usersSaga from "./users/saga";
import waterfallSaga from "./waterfall/saga";

import circuitSaga from "./circuits/saga";
import contactsUsSagaDetails from "./contactsUs/contactsUsDetails/saga";
import contactsUsSaga from "./contactsUs/saga";
import suggestionsSaga from "./suggestions/saga";
import suggestionsSagaDetails from "./suggestions/suggestionsDetails/saga";

//Filters
import filtersWhereEat from "./filters/whereEat/saga";
import filtersWhereEatDetails from "./filters/whereEatDetails/saga";

import filtersWhereStay from "./filters/whereStay/saga";
import filtersWhereStayDetails from "./filters/whereStayDetails/saga";

import filtersTouristSpot from "./filters/touristSpot/saga";
import filtersTouristSpotDetails from "./filters/touristSpotDetails/saga";

import filtersService from "./filters/service/saga";
import filtersServiceDetails from "./filters/serviceDetails/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(chatSaga),
    fork(projectSaga),
    fork(taskSaga),
    fork(cryptoSaga),
    fork(ticketsSaga),
    fork(calendarSaga),
    fork(ecommerceSaga),
    fork(crmSaga),
    fork(invoiceSaga),
    fork(mailboxSaga),
    fork(dashboardAnalyticsSaga),
    fork(dashboardCrmSaga),
    fork(dashboardEcommerceSaga),
    fork(dashboardCryptoSaga),
    fork(dashboardProjectSaga),
    fork(dashboardNFTSaga),
    fork(teamSaga),

    fork(citySaga),
    fork(waterfallSaga),
    fork(restaurantSaga),
    fork(hotelsSaga),
    fork(touristReceptionsSaga),
    fork(touristSpotsSaga),
    fork(tourisSpotPhotoUserSaga),
    fork(servicesSaga),
    fork(typesServiceSaga),
    fork(eventsSaga),
    fork(storeSaga),
    fork(circuitSaga),
    fork(passportSaga),
    fork(passportDetailsSaga),
    fork(usersSaga),
    fork(suggestionsSaga),
    fork(suggestionsSagaDetails),
    fork(contactsUsSaga),
    fork(contactsUsSagaDetails),

    fork(filtersWhereEat),
    fork(filtersWhereEatDetails),

    fork(filtersWhereStay),
    fork(filtersWhereStayDetails),

    fork(filtersTouristSpot),
    fork(filtersTouristSpotDetails),

    fork(filtersService),
    fork(filtersServiceDetails),
  ]);
}
