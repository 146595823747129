import {
  CREATE_WATERFALL_FAILURE,
  CREATE_WATERFALL_REQUEST,
  CREATE_WATERFALL_SUCCESS,
  DELETE_WATERFALL_FAILURE,
  DELETE_WATERFALL_PHOTO_FAILURE,
  DELETE_WATERFALL_PHOTO_REQUEST,
  DELETE_WATERFALL_PHOTO_SUCCESS,
  DELETE_WATERFALL_REQUEST,
  DELETE_WATERFALL_SUCCESS,
  GET_WATERFALLS_FAILURE,
  GET_WATERFALLS_REQUEST,
  GET_WATERFALLS_SUCCESS,
  GET_WATERFALL_FAILURE,
  GET_WATERFALL_REQUEST,
  GET_WATERFALL_SUCCESS,
  UPDATE_WATERFALL_FAILURE,
  UPDATE_WATERFALL_PHOTO_FAILURE,
  UPDATE_WATERFALL_PHOTO_REQUEST,
  UPDATE_WATERFALL_PHOTO_SUCCESS,
  UPDATE_WATERFALL_REQUEST,
  UPDATE_WATERFALL_SUCCESS,
  REMOVE_GPS_WATERFALL_FAILURE,
  REMOVE_GPS_WATERFALL_REQUEST,
  REMOVE_GPS_WATERFALL_SUCCESS,
} from "./actionTypes";

export const getWaterfallsRequest = (data) => {
  return {
    type: GET_WATERFALLS_REQUEST,
    data,
  };
};

export const getWaterfallsSuccess = (data) => {
  return {
    type: GET_WATERFALLS_SUCCESS,
    data,
  };
};

export const getWaterfallsFailure = () => {
  return {
    type: GET_WATERFALLS_FAILURE,
  };
};

export const getWaterfallFailure = () => {
  return {
    type: GET_WATERFALL_FAILURE,
  };
};

export const getWaterfallRequest = (data) => {
  return {
    type: GET_WATERFALL_REQUEST,
    data,
  };
};

export const getWaterfallSuccess = (data) => {
  return {
    type: GET_WATERFALL_SUCCESS,
    data,
  };
};

export const createWaterfallRequest = (data) => {
  return {
    type: CREATE_WATERFALL_REQUEST,
    data,
  };
};

export const createWaterfallSuccess = (data) => {
  return {
    type: CREATE_WATERFALL_SUCCESS,
    data,
  };
};

export const createWaterfallFailure = () => {
  return {
    type: CREATE_WATERFALL_FAILURE,
  };
};

export const deleteWaterfallFailure = () => {
  return {
    type: DELETE_WATERFALL_FAILURE,
  };
};

export const deleteWaterfallRequest = (data) => {
  return {
    type: DELETE_WATERFALL_REQUEST,
    data,
  };
};

export const deleteWaterfallPhotoSuccess = (data) => {
  return {
    type: DELETE_WATERFALL_PHOTO_SUCCESS,
    data,
  };
};

export const deleteWaterfallPhotoFailure = () => {
  return {
    type: DELETE_WATERFALL_PHOTO_FAILURE,
  };
};

export const deleteWaterfallPhotoRequest = (data) => {
  return {
    type: DELETE_WATERFALL_PHOTO_REQUEST,
    data,
  };
};

export const deleteWaterfallSuccess = (data) => {
  return {
    type: DELETE_WATERFALL_SUCCESS,
    data,
  };
};

export const updateWaterfallFailure = () => {
  return {
    type: UPDATE_WATERFALL_FAILURE,
  };
};

export const updateWaterfallRequest = (data) => {
  return {
    type: UPDATE_WATERFALL_REQUEST,
    data,
  };
};

export const updateWaterfallSuccess = (data) => {
  return {
    type: UPDATE_WATERFALL_SUCCESS,
    data,
  };
};

export const updateWaterfallPhotoFailure = () => {
  return {
    type: UPDATE_WATERFALL_PHOTO_FAILURE,
  };
};

export const updateWaterfallPhotoRequest = (data) => {
  return {
    type: UPDATE_WATERFALL_PHOTO_REQUEST,
    data,
  };
};

export const updateWaterfallPhotoSuccess = (data) => {
  return {
    type: UPDATE_WATERFALL_PHOTO_SUCCESS,
    data,
  };
};

export const removeGpsWaterfallRequest = (data) => {
  return {
    type: REMOVE_GPS_WATERFALL_REQUEST,
    data,
  };
};

export const removeGpsWaterfallSuccess = (data) => {
  return {
    type: REMOVE_GPS_WATERFALL_SUCCESS,
    data,
  };
};

export const removeGpsWaterfallFailure = () => {
  return {
    type: REMOVE_GPS_WATERFALL_FAILURE,
  };
};
