import {
  CREATE_SERVICES_FAILURE,
  CREATE_SERVICES_REQUEST,
  CREATE_SERVICES_SUCCESS,
  DELETE_SERVICE_FAILURE,
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_SUCCESS,
  GET_SERVICES_FAILURE,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICE_FAILURE,
  GET_SERVICE_REQUEST,
  GET_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAILURE,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
} from "./actionTypes";

export const getServicesRequest = (data) => {
  return {
    type: GET_SERVICES_REQUEST,
    data,
  };
};

export const getServicesSuccess = (data) => {
  return {
    type: GET_SERVICES_SUCCESS,
    data,
  };
};

export const getServicesFailure = () => {
  return {
    type: GET_SERVICES_FAILURE,
  };
};

export const getServiceRequest = (data) => {
  return {
    type: GET_SERVICE_REQUEST,
    data,
  };
};

export const getServiceSuccess = (data) => {
  return {
    type: GET_SERVICE_SUCCESS,
    data,
  };
};

export const getServiceFailure = () => {
  return {
    type: GET_SERVICE_FAILURE,
  };
};

export const createServiceRequest = (data) => {
  return {
    type: CREATE_SERVICES_REQUEST,
    data,
  };
};

export const createServiceSuccess = (data) => {
  return {
    type: CREATE_SERVICES_SUCCESS,
    data,
  };
};

export const createServiceFailure = () => {
  return {
    type: CREATE_SERVICES_FAILURE,
  };
};

export const updateServiceRequest = (data) => {
  return {
    type: UPDATE_SERVICE_REQUEST,
    data,
  };
};

export const updateServiceFailure = () => {
  return {
    type: UPDATE_SERVICE_FAILURE,
  };
};

export const updateServiceSuccess = (data) => {
  return {
    type: UPDATE_SERVICE_SUCCESS,
    data,
  };
};

export const deleteServiceFailure = () => {
  return {
    type: DELETE_SERVICE_FAILURE,
  };
};

export const deleteServiceRequest = (data) => {
  return {
    type: DELETE_SERVICE_REQUEST,
    data,
  };
};

export const deleteServiceSuccess = (data) => {
  return {
    type: DELETE_SERVICE_SUCCESS,
    data,
  };
};
