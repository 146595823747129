import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { useDebounce } from "use-debounce";
import * as Yup from "yup";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { MANDATORY_FIELD } from "../../Components/constants/messages";
import ModalCustom from "../../Components/Modals/ModalCustom";
import {
  createFilterTouristSpotRequest,
  getFiltersTouristSpotRequest,
  updateFilterTouristSpotRequest,
} from "../../store/filters/touristSpot/actions";
import { getFilterTouristSpotRequest } from "../../store/filters/touristSpotDetails/actions";
import { default as FiltersTouristSpotForm } from "./FiltersTouristSpotForm";
import { FiltersTouristSpotTable } from "./FiltersTouristSpotTable";

const FiltersWhereTouristSpotList = () => {
  document.title = "Filtros atrativo turístico | Ecoguias - Admin Dashboard";

  const [modalAddFilter, setModalAddFilter] = useState(false);
  const [modalEditFilter, setModalEditFilter] = useState(false);
  const [idFilterTouristSpot, setIdFilterTouristSpot] = useState(null);

  const dispatch = useDispatch();

  const { loading, data } = useSelector((state) => state.FiltersTouristSpot);
  const { loading: loadingDetails, data: dataDetails } = useSelector(
    (state) => state.FiltersTouristSpotDetails
  );

  const [searchInput, setSearchInput] = useState("");
  const [value] = useDebounce(searchInput, 500);

  function closeModal() {
    return setModalAddFilter(false) || setModalEditFilter(false);
  }

  const filterTouristSpot = useFormik({
    enableReinitialize: true,
    initialValues: Object.assign({
      nome: dataDetails && modalEditFilter ? dataDetails.nome : "",
    }),

    validationSchema: Yup.object({
      nome: Yup.string("O nome do filtro deve ser do tipo texto").required(
        MANDATORY_FIELD
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        createFilterTouristSpotRequest({ values, resetForm, closeModal })
      );
    },
  });

  useEffect(() => {
    dispatch(getFiltersTouristSpotRequest(value));
  }, [dispatch, value]);

  useEffect(() => {
    if (idFilterTouristSpot && modalEditFilter) {
      dispatch(getFilterTouristSpotRequest(idFilterTouristSpot));
    }
  }, [dispatch, idFilterTouristSpot, modalEditFilter]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Tabela de filtros atrativo turístico"
            pageTitle="Atrativo turístico"
          />
          <div className="d-flex justify-content-between pb-4 flex-wrap">
            <Input
              type="text"
              style={{ maxWidth: 250 }}
              className="form-control"
              placeholder="Pesquisar..."
              id="search-options"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={(event) => {
                event.preventDefault();
                setModalAddFilter(true);
              }}
            >
              Cadastrar
            </button>
          </div>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    Filtros - Atrativo turístico
                  </h5>
                </CardHeader>

                <CardBody>
                  <FiltersTouristSpotTable
                    style={{ width: "100%" }}
                    filters={data?.result ?? []}
                    loading={loading || loadingDetails}
                    idFilterTouristSpot={idFilterTouristSpot}
                    setIdFilterTouristSpot={setIdFilterTouristSpot}
                    showModalEdit={() => {
                      setModalEditFilter(true);
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ModalCustom
        title="Adicionar filtro para o atrativo turístico"
        isOpen={modalAddFilter}
        onCancel={(event) => {
          event.preventDefault();
          setModalAddFilter(false);
        }}
        onClick={async (event) => {
          event.preventDefault();
          filterTouristSpot.handleSubmit();
          return null;
        }}
      >
        <FiltersTouristSpotForm form={filterTouristSpot} />
      </ModalCustom>

      <ModalCustom
        title="Editar filtro do atrativo turístico"
        isOpen={modalEditFilter}
        onCancel={(event) => {
          event.preventDefault();
          setModalEditFilter(false);
          setIdFilterTouristSpot(null);
        }}
        onClick={(event) => {
          event.preventDefault();
          dispatch(
            updateFilterTouristSpotRequest({
              id: idFilterTouristSpot,
              ...filterTouristSpot,
              closeModal,
            })
          );

          setIdFilterTouristSpot(null);
          return null;
        }}
      >
        <FiltersTouristSpotForm form={filterTouristSpot} />
      </ModalCustom>
    </React.Fragment>
  );
};

export default FiltersWhereTouristSpotList;
