import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Row } from "reactstrap";
import Loading from "../../Components/Loading/Loading";
import ModalCustom from "../../Components/Modals/ModalCustom";
import TouristSpotPhotosUserImage from "./TouristSpotPhotosUserImage";

function TouristSpotPhotosUserTable({ data, loading }) {
  const [modal, setModal] = useState(false);
  const [photoURL, setPhotoURL] = useState("");

  if (loading) {
    return <Loading />;
  }

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Usuário</span>,
      selector: (row) => row?.usuario?.name || "N/A",
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-13">
          Nome do atrativo turístico
        </span>
      ),
      selector: (row) => row?.ponto_turistico?.nome || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">URL</span>,
      selector: (row) => (
        <a href={row?.foto?.url} target="_blank" rel="noreferrer">
          {row?.foto?.url || "N/A"}
        </a>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Legenda</span>,
      selector: (row) => row?.legenda || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Ações</span>,
      width: "10%",
      right: true,
      selector: (row) => (
        <>
          <div className="hstack gap-3 flex-wrap">
            <Link
              to="#"
              className="link-success fs-15"
              onClick={(event) => {
                event.preventDefault();
                setPhotoURL(row?.foto?.url);
                setModal(true);
              }}
            >
              <i className="mdi mdi-eye"></i>
            </Link>
          </div>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <ToastContainer />
        <DataTable
          columns={columns}
          data={data}
          pagination
          noDataComponent="Não existem dados na tabela de fotos do atrativo turístico do usuário!"
        />
      </Row>

      <ModalCustom
        isOpen={modal}
        labelConfirm="OK"
        onClick={() => setModal(false)}
        title="Foto"
      >
        <>{<TouristSpotPhotosUserImage url={photoURL} />}</>
      </ModalCustom>
    </React.Fragment>
  );
}

export { TouristSpotPhotosUserTable };
