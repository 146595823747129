import { all, call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";
import {
  createHotelFailure,
  createHotelSuccess,
  deleteHotelPhotoFailure,
  getHotelFailure,
  getHotelsFailure,
  getHotelsSuccess,
  getHotelSuccess,
  updateHotelFailure,
  updateHotelPhotoFailure,
} from "./actions";
import {
  CREATE_HOTEL_REQUEST,
  DELETE_HOTEL_PHOTO_REQUEST,
  DELETE_HOTEL_REQUEST,
  GET_HOTELS_REQUEST,
  GET_HOTEL_REQUEST,
  UPDATE_HOTEL_PHOTO_REQUEST,
  UPDATE_HOTEL_REQUEST,
} from "./actionTypes";

function requestHotels(data) {
  return post("/hotels-by-name", { hotelName: data });
}

function requestHotelById(id) {
  return post("/hotel", { hotelId: id });
}

function deleteHotelById(id) {
  return post("/hotel-remove", { hotelId: id });
}

function deletePhotoHotel({ photoId, hotelId }) {
  return post("/photo-hotel-remove", {
    hotelId: hotelId,
    photoId: photoId,
  });
}

function requestUpdateHotel(id, hotel) {
  const {
    nome,
    telefones,
    logradouro,
    numero,
    bairro,
    cidade,
    estado,
    gps,
    url_video,
    whatsapp,
    instagram,
    email,
    // media_preco,
    url_site,
    descritivo,
    pet_friendly,
    filtro_onde_ficar,
  } = hotel;

  return post("/hotel-update", {
    hotelId: id,
    nome,
    telefones,
    logradouro,
    numero,
    bairro,
    cidade,
    estado,
    url_video,
    whatsapp,
    instagram,
    // media_preco,
    descritivo,
    url_site,
    email,
    gps,
    pet_friendly,
    filtro_onde_ficar,
  });
}

function requestUpdateHotelPhoto({ hotelId, imagem }) {
  return post("/photo-hotel-select", {
    hotelId: hotelId,
    imagem: imagem,
  });
}

function saveImage(imgs) {
  return post("/media-upload", { imgs });
}

function newHotel(hotel) {
  return post("/hotels", hotel);
}

function* getHotels({ data }) {
  try {
    const response = yield call(requestHotels, data);
    yield put(getHotelsSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar hospedagem." + error);
    yield put(getHotelsFailure());
  }
}

function* getHotel({ data }) {
  try {
    const response = yield call(requestHotelById, data);
    yield put(getHotelSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar detalhes da hospedagem." + error);
    yield put(getHotelFailure());
  }
}

function* deleteHotel({ data }) {
  try {
    yield call(deleteHotelById, data.hotelId);
    data.closeModal();
    const response = yield call(requestHotels);
    yield put(getHotelsSuccess(response.data));
    showNofity.success("Hospedagem removida com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao deletar a hospedagem." + error);
    yield put(getHotelFailure());
  }
}

function saveFotos(img, idHotel) {
  return post("/photos-hotel", {
    url: img.url,
    nome_imagem: img.name,
    imagem: {
      __type: "File",
      name: img.name,
      url: img.url,
    },
    onde_ficar: {
      __type: "Pointer",
      className: "OndeFicar",
      objectId: idHotel,
    },
  });
}

function* deleteImageHotel({ data }) {
  try {
    yield call(deletePhotoHotel, data);
    showNofity.success("Imagem removida com sucesso.");
    const response = yield call(requestHotelById, data.hotelId);
    yield put(getHotelSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao remover foto da hospedagem. " + error);
    put(deleteHotelPhotoFailure());
  }
}

function* createHotel({ data }) {
  try {
    const {
      nome,
      telefones,
      logradouro,
      numero,
      bairro,
      cidade,
      estado,
      images,
      url_video,
      whatsapp,
      instagram,
      // media_preco,
      descritivo,
      url_site,
      email,
      latitude,
      longitude,
      pet_friendly,
      imagem_principal,
      filtro_onde_ficar,
    } = data.values;

    const responseImages = yield call(saveImage, images);
    const responseHotel = yield call(newHotel, {
      nome,
      telefones,
      logradouro,
      numero,
      bairro,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      estado,
      url_video,
      whatsapp,
      instagram,
      // media_preco,
      descritivo,
      url_site,
      email,
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
      pet_friendly,
      imagem: {
        __type: "File",
        url: responseImages.data[imagem_principal].url,
        name: responseImages.data[imagem_principal].name,
      },
      filtro_onde_ficar: {
        __type: "Pointer",
        className: "FiltrosOndeFicar",
        objectId: filtro_onde_ficar,
      },
    });

    yield all(
      responseImages.data.map((img) =>
        call(saveFotos, img, responseHotel.data.objectId)
      )
    );

    yield put(createHotelSuccess());
    showNofity.success("Hospedagem cadastrada com sucesso.");
    data.resetForm();
  } catch (error) {
    showNofity.error("Erro ao cadastrar hospedagem. " + error);
    yield put(createHotelFailure());
  }
}

function* updateHotel({ data }) {
  try {
    const {
      nome,
      telefones,
      logradouro,
      numero,
      bairro,
      cidade,
      estado,
      images,
      url_video,
      whatsapp,
      instagram,
      // media_preco,
      descritivo,
      url_site,
      email,
      latitude,
      longitude,
      pet_friendly,
      filtro_onde_ficar,
    } = data.values;

    if (images?.length > 0) {
      const responseImages = yield call(saveImage, images);
      yield all(
        responseImages.data.map((img) => call(saveFotos, img, data.id))
      );
    }

    yield call(requestUpdateHotel, data.id, {
      nome,
      telefones,
      logradouro,
      numero,
      bairro,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      estado,
      url_video,
      whatsapp,
      instagram,
      // media_preco,
      descritivo,
      url_site,
      email,
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
      filtro_onde_ficar: {
        __type: "Pointer",
        className: "FiltrosOndeFicar",
        objectId: filtro_onde_ficar,
      },
      pet_friendly,
    });

    const response = yield call(requestHotelById, data.id);
    yield put(getHotelSuccess(response.data));
    data.onSucess();
    showNofity.success("Hospedagem atualizada com sucesso.");
  } catch (error) {
    yield put(updateHotelFailure());
    showNofity.error("Erro ao atualizar hospedagem.");
  }
}

function* updateImageHotel({ data }) {
  try {
    yield call(requestUpdateHotelPhoto, {
      hotelId: data.hotelId,
      imagem: data.imagem,
    });

    const responseHotel = yield call(requestHotelById, data.hotelId);

    yield put(getHotelSuccess(responseHotel.data));
  } catch (error) {
    showNofity.error("Erro ao atualizar foto da hospedagem.");
    yield put(updateHotelPhotoFailure());
  }
}

function* saga() {
  yield takeLatest(GET_HOTELS_REQUEST, getHotels);
  yield takeLatest(GET_HOTEL_REQUEST, getHotel);
  yield takeLatest(DELETE_HOTEL_REQUEST, deleteHotel);
  yield takeLatest(DELETE_HOTEL_PHOTO_REQUEST, deleteImageHotel);
  yield takeLatest(CREATE_HOTEL_REQUEST, createHotel);
  yield takeLatest(UPDATE_HOTEL_REQUEST, updateHotel);
  yield takeLatest(UPDATE_HOTEL_PHOTO_REQUEST, updateImageHotel);
}

export default saga;
