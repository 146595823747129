import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { TouristSpotPhotosUserTable } from "./TouristSpotPhotosUserTable";

import { getTouristSpotPhotoUserRequest } from "../../store/tourisSpotPhotoUser/actions";

const TouristSpotPhotosUserList = () => {
  document.title =
    "Fotos do atrativo turístico do usuário | Ecoguias - Admin Dashboard";

  const dispatch = useDispatch();

  const { loading, data } = useSelector((state) => state.TouristSpotPhotosUser);

  useEffect(() => {
    dispatch(getTouristSpotPhotoUserRequest());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Fotos enviadas pelos usuários"
            pageTitle="Tabela de fotos dos usuários"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Fotos dos usuários</h5>
                </CardHeader>
                <CardBody>
                  <TouristSpotPhotosUserTable
                    style={{ width: "100%" }}
                    data={data?.result}
                    loading={loading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TouristSpotPhotosUserList;
