import {
  GET_FILTER_WHERE_EAT_FAILURE,
  GET_FILTER_WHERE_EAT_REQUEST,
  GET_FILTER_WHERE_EAT_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: false,
  loading: false,
  data: null,
  success: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILTER_WHERE_EAT_REQUEST:
      console.log("REQUEST");
      state = {
        ...state,
        data: {},
        loading: true,
        error: false,
      };
      break;

    case GET_FILTER_WHERE_EAT_SUCCESS:
      console.log("SUCCESS");
      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_FILTER_WHERE_EAT_FAILURE:
      console.log("FAILURE");
      state = {
        ...state,
        data: {},
        loading: false,
        error: true,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
