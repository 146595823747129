import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";

import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import { getCitiesRequest } from "../../store/city/actions";
import states from "../../utils/states.json";

import {
  getEventRequest,
  removeEventRequest,
  updateEventRequest,
} from "../../store/events/actions";

import Modal from "../../Components/Common/Modal";
import {
  CoverUploadImageField,
  InputMask,
  TextField,
} from "../../Components/Forms";
import { isLatitude, isLongitude } from "../../utils/formats";

import LazyImage from "../../Components/LazyImage/LazyImage";
import ModalLoading from "../../Components/Common/ModalLoading";

const EventForm = () => {
  document.title = "Formulário de eventos";

  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [modalIsOpenRemove, setModalIsOpenRemove] = useState(false);

  const { data } = useSelector((state) => state.City);
  const { loading, data: dataEvent } = useSelector((state) => state.Events);

  console.log(dataEvent);

  useEffect(() => {
    dispatch(getCitiesRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEventRequest(params.id));
  }, [dispatch, params.id]);

  const event = useFormik({
    enableReinitialize: true,
    initialValues: Object.assign(
      {
        nome: "",
        descritivo: "",
        data_inicio: "",
        data_termino: "",
        hora_inicio: "",
        hora_termino: "",
        telefone: "",
        site: "",
        whatsapp: "",
        instagram: "",
        logradouro: "",
        numero: "",
        bairro: "",
        estado: "",
        cidade: "",
        images: [],
        email: "",
        latitude: "",
        longitude: "",
      },
      {
        ...dataEvent,
        latitude: dataEvent?.gps?.latitude,
        longitude: dataEvent?.gps?.longitude,
        cidade: dataEvent?.cidade?.objectId,
        data_inicio: dataEvent?.data_inicio?.iso?.slice(0, 10),
        data_termino: dataEvent?.data_termino?.iso?.slice(0, 10),
      }
    ),

    validationSchema: Yup.object({
      nome: Yup.string("O nome deve ser do tipo texto").required(
        "O nome do serviço é obrigatório"
      ),
      telefone: Yup.string("O telefone deve ser do tipo texto"),
      logradouro: Yup.string("O logradouro deve ser do tipo texto"),
      data_inicio: Yup.date("Informe uma data valida").required(
        "A data de início é obrigatória"
      ),
      data_termino: Yup.string("Informe uma data valida").required(
        "A data de término é obrigatória"
      ),
      cidade: Yup.string("A cidade deve ser do tipo texto").required(
        "A cidade é obrigatória"
      ),
      bairro: Yup.string("O distrito deve ser do tipo texto"),
      estado: Yup.string("O estado deve ser do tipo texto"),
      numero: Yup.string("O número deve ser do tipo texto"),
      descritivo: Yup.string("A descrição deve ser do tipo texto").required(
        "A descrição é obrigatório"
      ),
      whatsapp: Yup.string("O whatsapp deve ser do tipo texto"),
      site: Yup.string("O site deve ser do tipo texto"),
      latitude: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      longitude: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      // .required("Este campo é obrigatório"),
      images: Yup.array(Yup.string()).required("Este campo é obrigatório"),
    }),
    onSubmit: (values) => {
      values.latitude = values.latitude
        ? Number(values.latitude.replace(",", "."))
        : 0.0;
      values.longitude = values.longitude
        ? Number(values.longitude.replace(",", "."))
        : 0.0;

      dispatch(updateEventRequest({ id: params.id, values }));
    },
  });

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Formulário de eventos" pageTitle="Eventos" />
        </Container>
        <Row>
          <Col lg={12}>
            <Card>
              <div className="p-4">
                <button
                  className="btn btn-primary"
                  onClick={() => history.goBack()}
                >
                  Voltar
                </button>
              </div>
              <CardBody>
                <div className="live-preview">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      event.handleSubmit();
                      return false;
                    }}
                    action="#"
                  >
                    <Row className="g-3">
                      <TextField
                        label="Nome"
                        name="nome"
                        data={event}
                        size={12}
                      />

                      <TextField
                        type="date"
                        name="data_inicio"
                        label="Data início"
                        data={event}
                        size={3}
                      />
                      <TextField
                        type="date"
                        name="data_termino"
                        label="Data término"
                        data={event}
                        size={3}
                      />
                      <InputMask
                        mask="99:99"
                        name="hora_inicio"
                        label="Hora início"
                        data={event}
                        size={3}
                      />

                      <InputMask
                        mask="99:99"
                        name="hora_termino"
                        label="Hora término"
                        data={event}
                        size={3}
                      />

                      <InputMask
                        type="phone-br"
                        label="Telefone"
                        name="telefone"
                        data={event}
                        size={3}
                      />
                      <InputMask
                        type="phone-br"
                        label="Whatsapp"
                        name="whatsapp"
                        data={event}
                        size={3}
                      />
                      <TextField
                        label="Instagram"
                        name="instagram"
                        data={event}
                        size={6}
                      />
                      <TextField
                        label="Logradouro"
                        name="logradouro"
                        data={event}
                        size={6}
                      />
                      <TextField
                        label="Número"
                        name="numero"
                        data={event}
                        size={3}
                      />
                      <TextField
                        label="Bairro"
                        name="bairro"
                        data={event}
                        size={3}
                      />
                      <TextField
                        type="select"
                        label="Cidade"
                        name="cidade"
                        data={event}
                        size={4}
                      >
                        <option value="" selected disabled>
                          Selecione a cidade do evento
                        </option>
                        {data?.result?.length > 0 &&
                          data?.result.map((item, index) => (
                            <option key={index} value={item.objectId}>
                              {item.nome}
                            </option>
                          ))}
                      </TextField>

                      <TextField
                        type="select"
                        label="Estado"
                        name="estado"
                        data={event}
                        size={2}
                      >
                        <option value="" selected disabled>
                          Selecione um estado
                        </option>

                        {states.map(({ sigla, id_uf }) => (
                          <option key={id_uf} value={sigla}>
                            {sigla}
                          </option>
                        ))}
                      </TextField>

                      <TextField
                        label="Latitude"
                        name="latitude"
                        data={event}
                        size={3}
                        type="number"
                      />

                      <TextField
                        label="Longitude"
                        name="longitude"
                        data={event}
                        size={3}
                        type="number"
                      />

                      <TextField
                        label="E-mail"
                        name="email"
                        data={event}
                        size={6}
                      />

                      <TextField
                        label="Site"
                        name="site"
                        data={event}
                        size={6}
                      />

                      <TextField
                        label="Descrição"
                        name="descritivo"
                        type="textarea"
                        data={event}
                        size={12}
                      />

                      {!dataEvent?.imagem?.url ? (
                        <CoverUploadImageField
                          title="Imagem do evento"
                          multiple={false}
                          data={event}
                          name="images"
                          isActiveRadioSelect={false}
                        />
                      ) : (
                        <>
                          <h2>Imagem do evento</h2>
                          <div
                            className="position-relative"
                            style={styles.lazyContainerImage}
                          >
                            <button
                              title="Excluir imagem"
                              className="position-absolute"
                              onClick={() => setModalIsOpenRemove(true)}
                              type="button"
                              style={{
                                right: 13,
                                top: 5,
                                border: 0,
                                borderRadius: 15,
                                boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                              }}
                            >
                              <i className="ri-delete-bin-7-line fs-16"></i>
                            </button>
                            <LazyImage
                              src={dataEvent?.imagem?.url}
                              width="100%"
                              height="100%"
                              className="object-fit-cover rounded"
                              // style={styles.lazyImage}
                            />
                          </div>
                        </>
                      )}
                      {/*
                       */}

                      <Col lg={12}>
                        <div className="text-end">
                          <button type="submit" className="btn btn-primary">
                            Salvar
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        isOpen={modalIsOpenRemove}
        text="Tem certeza que deseja remover essa imagem?"
        onClick={() => {
          dispatch(removeEventRequest({ id: params.id }));
          setModalIsOpenRemove(false);
        }}
        onCancel={() => {
          setModalIsOpenRemove(false);
        }}
      />
      <ModalLoading loading={loading} />
    </React.Fragment>
  );
};

const styles = {
  lazyContainerImage: {
    height: 180,
    width: 180,
    borderRadius: 10,
  },
};

export default EventForm;
