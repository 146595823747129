export const GET_TOURIST_RECEPTIONS_REQUEST = "GET_TOURIST_RECEPTIONS_REQUEST";
export const GET_TOURIST_RECEPTIONS_SUCCESS = "GET_TOURIST_RECEPTIONS_SUCCESS";
export const GET_TOURIST_RECEPTIONS_FAILURE = "GET_TOURIST_RECEPTIONS_FAILURE";

export const GET_TOURIST_RECEPTION_REQUEST = "GET_TOURIST_RECEPTION_REQUEST";
export const GET_TOURIST_RECEPTION_SUCCESS = "GET_TOURIST_RECEPTION_SUCCESS";
export const GET_TOURIST_RECEPTION_FAILURE = "GET_TOURIST_RECEPTION_FAILURE";

export const CREATE_TOURIST_RECEPTION_REQUEST =
  "CREATE_TOURIST_RECEPTION_REQUEST";
export const CREATE_TOURIST_RECEPTION_SUCCESS =
  "CREATE_TOURIST_RECEPTION_SUCCESS";
export const CREATE_TOURIST_RECEPTION_FAILURE =
  "CREATE_TOURIST_RECEPTION_FAILURE";

export const DELETE_TOURIST_RECEPTION_REQUEST =
  "DELETE_TOURIST_RECEPTION_REQUEST";
export const DELETE_TOURIST_RECEPTION_SUCCESS =
  "DELETE_TOURIST_RECEPTION_SUCCESS";
export const DELETE_TOURIST_RECEPTION_FAILURE =
  "DELETE_TOURIST_RECEPTION_FAILURE";

export const DELETE_TOURIST_RECEPTION_PHOTO_REQUEST =
  "DELETE_TOURIST_RECEPTION_PHOTO_REQUEST";
export const DELETE_TOURIST_RECEPTION_PHOTO_SUCCESS =
  "DELETE_TOURIST_RECEPTION_PHOTO_SUCCESS";
export const DELETE_TOURIST_RECEPTION_PHOTO_FAILURE =
  "DELETE_TOURIST_RECEPTION_PHOTO_FAILURE";

export const UPDATE_TOURIST_RECEPTION_REQUEST =
  "UPDATE_TOURIST_RECEPTION_REQUEST";
export const UPDATE_TOURIST_RECEPTION_SUCCESS =
  "UPDATE_TOURIST_RECEPTION_SUCCESS";
export const UPDATE_TOURIST_RECEPTION_FAILURE =
  "UPDATE_TOURIST_RECEPTION_FAILURE";

export const UPDATE_TOURIST_RECEPTION_PHOTO_REQUEST =
  "UPDATE_TOURIST_RECEPTION_PHOTO_REQUEST";
export const UPDATE_TOURIST_RECEPTION_PHOTO_SUCCESS =
  "UPDATE_TOURIST_RECEPTION_PHOTO_SUCCESS";
export const UPDATE_TOURIST_RECEPTION_PHOTO_FAILURE =
  "UPDATE_TOURIST_RECEPTION_FAILURE";
