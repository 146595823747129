export const GET_HOTELS_REQUEST = "GET_HOTELS_REQUEST";
export const GET_HOTELS_SUCCESS = "GET_HOTELS_SUCCESS";
export const GET_HOTELS_FAILURE = "GET_HOTELS_FAILURE";

export const GET_HOTEL_REQUEST = "GET_HOTEL_REQUEST";
export const GET_HOTEL_SUCCESS = "GET_HOTEL_SUCCESS";
export const GET_HOTEL_FAILURE = "GET_HOTEL_FAILURE";

export const CREATE_HOTEL_REQUEST = "CREATE_HOTEL_REQUEST";
export const CREATE_HOTEL_SUCCESS = "CREATE_HOTEL_SUCCESS";
export const CREATE_HOTEL_FAILURE = "CREATE_HOTEL_FAILURE";

export const DELETE_HOTEL_REQUEST = "DELETE_HOTEL_REQUEST";
export const DELETE_HOTEL_SUCCESS = "DELETE_HOTEL_SUCCESS";
export const DELETE_HOTEL_FAILURE = "DELETE_HOTEL_FAILURE";

export const DELETE_HOTEL_PHOTO_REQUEST = "DELETE_HOTEL_PHOTO_REQUEST";
export const DELETE_HOTEL_PHOTO_SUCCESS = "DELETE_HOTEL_PHOTO_SUCCESS";
export const DELETE_HOTEL_PHOTO_FAILURE = "DELETE_HOTEL_PHOTO_FAILURE";

export const UPDATE_HOTEL_REQUEST = "UPDATE_HOTEL_REQUEST";
export const UPDATE_HOTEL_SUCCESS = "UPDATE_HOTEL_SUCCESS";
export const UPDATE_HOTEL_FAILURE = "UPDATE_HOTEL_FAILURE";

export const UPDATE_HOTEL_PHOTO_REQUEST = "UPDATE_HOTEL_PHOTO_REQUEST";
export const UPDATE_HOTEL_PHOTO_SUCCESS = "UPDATE_HOTEL_PHOTO_SUCCESS";
export const UPDATE_HOTEL_PHOTO_FAILURE = "UPDATE_HOTEL_FAILURE";
