import {
  CREATE_TOURIST_SPOT_FAILURE,
  CREATE_TOURIST_SPOT_REQUEST,
  CREATE_TOURIST_SPOT_SUCCESS,
  DELETE_TOURIST_SPOT_FAILURE,
  DELETE_TOURIST_SPOT_REQUEST,
  DELETE_TOURIST_SPOT_SUCCESS,
  GET_TOURIST_SPOTS_FAILURE,
  GET_TOURIST_SPOTS_REQUEST,
  GET_TOURIST_SPOTS_SUCCESS,
  GET_TOURIST_SPOT_FAILURE,
  GET_TOURIST_SPOT_REQUEST,
  GET_TOURIST_SPOT_SUCCESS,
  DELETE_TOURIST_SPOT_PHOTO_FAILURE,
  DELETE_TOURIST_SPOT_PHOTO_REQUEST,
  DELETE_TOURIST_SPOT_PHOTO_SUCCESS,
  UPDATE_TOURIST_SPOT_FAILURE,
  UPDATE_TOURIST_SPOT_PHOTO_FAILURE,
  UPDATE_TOURIST_SPOT_PHOTO_REQUEST,
  UPDATE_TOURIST_SPOT_PHOTO_SUCCESS,
  UPDATE_TOURIST_SPOT_REQUEST,
  UPDATE_TOURIST_SPOT_SUCCESS,
} from "./actionTypes";

export const getTouristSpotsRequest = (data) => {
  return {
    type: GET_TOURIST_SPOTS_REQUEST,
    data,
  };
};

export const getTouristSpotsSuccess = (data) => {
  return {
    type: GET_TOURIST_SPOTS_SUCCESS,
    data,
  };
};

export const getTouristSpotsFailure = () => {
  return {
    type: GET_TOURIST_SPOTS_FAILURE,
  };
};

export const getTouristSpotFailure = () => {
  return {
    type: GET_TOURIST_SPOT_FAILURE,
  };
};

export const getTouristSpotRequest = (data) => {
  return {
    type: GET_TOURIST_SPOT_REQUEST,
    data,
  };
};

export const getTouristSpotSuccess = (data) => {
  return {
    type: GET_TOURIST_SPOT_SUCCESS,
    data,
  };
};

export const createTouristSpotRequest = (data) => {
  return {
    type: CREATE_TOURIST_SPOT_REQUEST,
    data,
  };
};

export const createTouristSpotSuccess = (data) => {
  return {
    type: CREATE_TOURIST_SPOT_SUCCESS,
    data,
  };
};

export const createTouristSpotFailure = () => {
  return {
    type: CREATE_TOURIST_SPOT_FAILURE,
  };
};

export const deleteTouristSpotFailure = () => {
  return {
    type: DELETE_TOURIST_SPOT_FAILURE,
  };
};

export const deleteTouristSpotRequest = (data) => {
  return {
    type: DELETE_TOURIST_SPOT_REQUEST,
    data,
  };
};

export const deleteTouristSpotSuccess = (data) => {
  return {
    type: DELETE_TOURIST_SPOT_SUCCESS,
    data,
  };
};

export const deleteTouristSpotPhotoSuccess = (data) => {
  return {
    type: DELETE_TOURIST_SPOT_PHOTO_SUCCESS,
    data,
  };
};

export const deleteTouristSpotPhotoFailure = () => {
  return {
    type: DELETE_TOURIST_SPOT_PHOTO_FAILURE,
  };
};

export const deleteTouristSpotPhotoRequest = (data) => {
  return {
    type: DELETE_TOURIST_SPOT_PHOTO_REQUEST,
    data,
  };
};

export const updateTouristSpotFailure = () => {
  return {
    type: UPDATE_TOURIST_SPOT_FAILURE,
  };
};

export const updateTouristSpotRequest = (data) => {
  return {
    type: UPDATE_TOURIST_SPOT_REQUEST,
    data,
  };
};

export const updateTouristSpotSuccess = (data) => {
  return {
    type: UPDATE_TOURIST_SPOT_SUCCESS,
    data,
  };
};

export const updateTouristSpotPhotoFailure = () => {
  return {
    type: UPDATE_TOURIST_SPOT_PHOTO_FAILURE,
  };
};

export const updateTouristSpotPhotoRequest = (data) => {
  return {
    type: UPDATE_TOURIST_SPOT_PHOTO_REQUEST,
    data,
  };
};

export const updateTouristSpotPhotoSuccess = (data) => {
  return {
    type: UPDATE_TOURIST_SPOT_PHOTO_SUCCESS,
    data,
  };
};
