import {
  GET_PASSPORT_FAILURE,
  GET_PASSPORT_REQUEST,
  GET_PASSPORT_SUCCESS,
} from "./actionTypes";

export const getPassportRequest = (data) => {
  return {
    type: GET_PASSPORT_REQUEST,
    data,
  };
};

export const getPassportSuccess = (data) => {
  return {
    type: GET_PASSPORT_SUCCESS,
    data,
  };
};

export const getPassportFailure = () => {
  return {
    type: GET_PASSPORT_FAILURE,
  };
};
