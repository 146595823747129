import {
  GET_TOURIST_SPOT_PHOTO_USER_FAILURE,
  GET_TOURIST_SPOT_PHOTO_USER_REQUEST,
  GET_TOURIST_SPOT_PHOTO_USER_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: false,
  loading: false,
  data: [],
  success: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOURIST_SPOT_PHOTO_USER_REQUEST:
      state = {
        ...state,
        data: [],
        loading: true,
        error: false,
      };
      break;

    case GET_TOURIST_SPOT_PHOTO_USER_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_TOURIST_SPOT_PHOTO_USER_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        // data: [],
        loading: false,
        error: action.data,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
