import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { useDebounce } from "use-debounce";
import * as Yup from "yup";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { MANDATORY_FIELD } from "../../Components/constants/messages";
import ModalCustom from "../../Components/Modals/ModalCustom";
import {
  createFilterWhereEatRequest,
  getFiltersWhereEatRequest,
  updateFilterWhereEatRequest,
} from "../../store/filters/whereEat/actions";
import { getFilterWhereEatRequest } from "../../store/filters/whereEatDetails/actions";
import FiltersWhereEatForm from "./FiltersWhereEatForm";
import { FiltersWhereEatTable } from "./FiltersWhereEatTable";

const FiltersWhereEatList = () => {
  document.title = "Filtros onde comer | Ecoguias - Admin Dashboard";

  const [modalAddFilter, setModalAddFilter] = useState(false);
  const [modalEditFilter, setModalEditFilter] = useState(false);
  const [idFilterWhereEat, setIdFilterWhereEat] = useState(null);

  const dispatch = useDispatch();

  const { loading, data } = useSelector((state) => state.FiltersWhereEat);
  const { loading: loadingDetails, data: dataDetails } = useSelector(
    (state) => state.FiltersWhereEatDetails
  );

  const [searchInput, setSearchInput] = useState("");
  const [value] = useDebounce(searchInput, 500);

  function closeModal() {
    return setModalAddFilter(false) || setModalEditFilter(false);
  }

  const filterWhereEat = useFormik({
    enableReinitialize: true,
    initialValues: Object.assign({
      nome: dataDetails && modalEditFilter ? dataDetails.nome : "",
    }),

    validationSchema: Yup.object({
      nome: Yup.string("O nome do filtro deve ser do tipo texto").required(
        MANDATORY_FIELD
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(createFilterWhereEatRequest({ values, resetForm, closeModal }));
    },
  });

  useEffect(() => {
    dispatch(getFiltersWhereEatRequest(value));
  }, [dispatch, value]);

  useEffect(() => {
    if (idFilterWhereEat && modalEditFilter) {
      dispatch(getFilterWhereEatRequest(idFilterWhereEat));
    }
  }, [dispatch, idFilterWhereEat, modalEditFilter]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Tabela de filtros onde comer"
            pageTitle="Onde comer"
          />
          <div className="d-flex justify-content-between pb-4 flex-wrap">
            <Input
              type="text"
              style={{ maxWidth: 250 }}
              className="form-control"
              placeholder="Pesquisar..."
              id="search-options"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={(event) => {
                event.preventDefault();
                setModalAddFilter(true);
              }}
            >
              Cadastrar
            </button>
          </div>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Filtros - Onde Comer</h5>
                </CardHeader>

                <CardBody>
                  <FiltersWhereEatTable
                    style={{ width: "100%" }}
                    filters={data?.result ?? []}
                    loading={loading || loadingDetails}
                    idFilterWhereEat={idFilterWhereEat}
                    setIdFilterWhereEat={setIdFilterWhereEat}
                    showModalEdit={() => {
                      setModalEditFilter(true);
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ModalCustom
        title="Adicionar filtro para onde comer"
        isOpen={modalAddFilter}
        onCancel={(event) => {
          event.preventDefault();
          setModalAddFilter(false);
        }}
        onClick={async (event) => {
          event.preventDefault();
          filterWhereEat.handleSubmit();
          return null;
        }}
      >
        <FiltersWhereEatForm form={filterWhereEat} />
      </ModalCustom>

      <ModalCustom
        title="Editar filtro de onde comer"
        isOpen={modalEditFilter}
        onCancel={(event) => {
          event.preventDefault();
          setModalEditFilter(false);
          setIdFilterWhereEat(null);
        }}
        onClick={(event) => {
          event.preventDefault();
          dispatch(
            updateFilterWhereEatRequest({
              id: idFilterWhereEat,
              ...filterWhereEat,
              closeModal,
            })
          );

          setIdFilterWhereEat(null);
          return null;
        }}
      >
        <FiltersWhereEatForm form={filterWhereEat} />
      </ModalCustom>
    </React.Fragment>
  );
};

export default FiltersWhereEatList;
