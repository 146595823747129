import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
} from "reactstrap";

import * as Yup from "yup";

import geojsonlogo from "../../../src/assets/images/geojson.png";
import { api } from "../../boot/api";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Modal from "../../Components/Common/Modal";
import ModalLoding from "../../Components/Common/ModalLoading";

import UiContent from "../../Components/Common/UiContent";
import {
  CoverUploadGPSField,
  CoverUploadImageField,
  TextField,
} from "../../Components/Forms";
import LazyImage from "../../Components/LazyImage/LazyImage";
import PlaceMenu from "../../Components/Places/PlaceMenu";
import showNofity from "../../helpers/toast";
import { getCitiesRequest } from "../../store/city/actions";
import {
  deleteWaterfallPhotoRequest,
  getWaterfallRequest,
  removeGpsWaterfallRequest,
  updateWaterfallPhotoRequest,
  updateWaterfallRequest,
} from "../../store/waterfall/actions";
import {
  formatMinutesToDuration,
  isLatitude,
  isLongitude,
  parseDurationToMinutes,
} from "../../utils/formats";
import { DURATION_SCHEMA } from '../../utils/validation_schemas';

const MANDATORY_FIELD = "Este campo é obrigatório";

const WaterfallFormEdit = () => {
  document.title = "Atrativos naturais | Ecoguias - Admin Dashboard";
  const dispatch = useDispatch();
  const params = useParams();
  const { data, loading } = useSelector((state) => state.Waterfall);
  const { data: dataCity, loading: loadingCity } = useSelector(
    (state) => state.City
  );

  const [legendImage, setLegendImage] = useState([]);

  const [modalIsOpenRemove, setModalIsOpenRemove] = useState(false);
  const [modalIsOpenSelect, setModalIsOpenSelect] = useState(false);
  const [photoId, setPhotoId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalIsOpenGpsRemove, setModalIsOpenGpsRemove] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    dispatch(getCitiesRequest());
  }, [dispatch]);

  useEffect(
    function handleDefaultValuesEditForm() {
      dispatch(getWaterfallRequest(id));
    },
    [dispatch, id]
  );

  const waterfallExists = data?.result?.waterfall;
  const waterfallResponse = data?.result?.waterfall;

  const waterfall = useFormik({
    enableReinitialize: true,

    initialValues: {
      nome: waterfallExists ? waterfallResponse?.nome : "",
      descritivo: waterfallExists ? waterfallResponse?.descritivo : "",
      altitude: waterfallExists ? waterfallResponse?.altitude : "",
      images: waterfallExists ? waterfallResponse?.images : [],
      url_video: waterfallExists ? waterfallResponse?.url_video : "",
      propriedade_particular: waterfallExists
        ? waterfallResponse?.propriedade_particular
        : false,
      caracteristicas: waterfallExists
        ? waterfallResponse?.caracteristicas
        : "",
      distancia_pe: waterfallExists ? waterfallResponse?.distancia_pe : "",
      tempo_carro: waterfallExists
        ? formatMinutesToDuration(waterfallResponse?.tempo_carro)
        : "",
      distancia_total: waterfallExists
        ? waterfallResponse?.distancia_total
        : "",
      taxa_visitacao: waterfallExists
        ? waterfallResponse?.taxa_visitacao
        : false,
      referencia: waterfallExists ? waterfallResponse?.referencia : "",
      observacoes: waterfallExists ? waterfallResponse?.observacoes : "",
      nivel_carro: waterfallExists ? waterfallResponse?.nivel_carro : "",
      nivel_pe: waterfallExists ? waterfallResponse?.nivel_pe : "",
      particular: waterfallExists ? waterfallResponse?.particular : "",
      tempo_pe: waterfallExists ? formatMinutesToDuration(waterfallResponse?.tempo_pe) : "",
      // altura_queda: waterfallExists ? waterfallResponse?.altura_queda : "",
      distancia_carro: waterfallExists
        ? waterfallResponse?.distancia_carro
        : "",
      // seguranca: waterfallExists ? waterfallResponse?.seguranca : "",
      imagem_principal: waterfallExists
        ? waterfallResponse?.imagem_principal
        : 0,
      cidade: waterfallExists ? waterfallResponse?.cidade?.objectId : "",
      altimetria: "",
      arquivo_gps: waterfallResponse?.arquivo_gps
        ? waterfallResponse?.arquivo_gps
        : "",

      bounds_southwest_lat: waterfallExists
        ? waterfallResponse?.bounds_southwest_lat
        : "",
      bounds_southwest_lng: waterfallExists
        ? waterfallResponse?.bounds_southwest_lng
        : "",
      bounds_northwest_lat: waterfallExists
        ? waterfallResponse?.bounds_northwest_lat
        : "",
      bounds_northwest_lng: waterfallExists
        ? waterfallResponse?.bounds_northwest_lng
        : "",
      latitude: waterfallExists ? waterfallResponse?.gps?.latitude : "",
      longitude: waterfallExists ? waterfallResponse?.gps?.longitude : "",
    },

    validationSchema: Yup.object({
      nome: Yup.string().required(MANDATORY_FIELD),
      altitude: Yup.string().optional().notRequired(),
      descritivo: Yup.string().required(MANDATORY_FIELD),
      images: Yup.array(Yup.string()),
      url_video: Yup.string().optional().notRequired(),
      propriedade_particular: Yup.boolean(),
      caracteristicas: Yup.string().optional().notRequired(),
      distancia_pe: Yup.string().optional().notRequired(),
      tempo_carro: DURATION_SCHEMA,
      distancia_total: Yup.string().optional().notRequired(),
      taxa_visitacao: Yup.boolean().optional().notRequired(),
      referencia: Yup.string().optional().notRequired(),
      observacoes: Yup.string().optional().notRequired(),
      nivel_carro: Yup.string().optional().notRequired(),
      nivel_pe: Yup.string().optional().notRequired(),
      tempo_pe: DURATION_SCHEMA,
      // altura_queda: Yup.string().optional().notRequired(),
      distancia_carro: Yup.string().optional().notRequired(),
      // seguranca: Yup.string().optional().notRequired(),
      cidade: Yup.string().required(MANDATORY_FIELD),
      altimetria: Yup.array(),
      arquivo_gps: Yup.object(),
      bounds_southwest_lat: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      bounds_southwest_lng: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      bounds_northwest_lat: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      bounds_northwest_lng: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      latitude: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      longitude: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
    }),
    onSubmit: async (values) => {
      values.propriedade_particular =
        String(values.propriedade_particular) === "true";
      values.taxa_visitacao = String(values.taxa_visitacao) === "true";
      values.tempo_carro = parseDurationToMinutes(values.tempo_carro);
      values.tempo_pe = parseDurationToMinutes(values.tempo_pe);
      values.latitude = values.latitude ? Number(values.latitude) : 0.0;
      values.longitude = values.longitude ? Number(values.longitude) : 0.0;
      values.altitude = values.altitude ? Number(values.altitude) : 0;
      values.distancia_pe = values.distancia_pe
        ? Number(values.distancia_pe)
        : 0;
      values.distancia_total = values.distancia_total
        ? Number(values.distancia_total)
        : 0;
      values.distancia_carro = values.distancia_carro
        ? Number(values.distancia_carro)
        : 0;

      dispatch(
        updateWaterfallRequest({
          id: id,
          values,
          onSucess: () => waterfall.setFieldValue("images", []),
        })
      );
    },
  });

  function addLegendInState(index, txt) {
    const copyLegend = [...legendImage];
    copyLegend[index] = txt;

    setLegendImage(copyLegend);
  }

  async function updateLegend(imagemId, legenda) {
    try {
      await api.post("/photo-waterfall-update-legend", { imagemId, legenda });
      showNofity.success("Legenda salva com sucesso");
    } catch (error) {
      showNofity.error("Erro ao salvar legenda");
    }
  }

  function renderCoverGPS() {
    if (data?.result?.waterfall?.arquivo_gps?.url) {
      return (
        <div style={{ width: 140 }}>
          <h2>GPS</h2>
          <div className="position-relative" style={styles.lazyContainerGps}>
            <button
              title="Excluir imagem"
              className="position-absolute"
              onClick={() => setModalIsOpenGpsRemove(true)}
              type="button"
              style={{
                right: 13,
                top: 5,
                border: 0,
                borderRadius: 15,
                boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
              }}
            >
              <i className="ri-delete-bin-7-line fs-16"></i>
            </button>

            <LazyImage
              src={geojsonlogo}
              width="90%"
              className="object-fit-cover rounded"
            />
            <a
              href={data?.result?.waterfall?.arquivo_gps?.url}
              target="_blank"
              rel="noreferrer"
            >
              Visualizar
            </a>
          </div>
        </div>
      );
    }

    return (
      <CoverUploadGPSField
        title="GPS (gpx ou kml)"
        multiple={false}
        data={waterfall}
        name="arquivo_gps"
        isActiveRadioSelect={false}
      />
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Editar Atrativos naturais"
            pageTitle="Atrativos naturais"
          />
        </Container>

        <Col lg={12}>
          <Card>
            <div className="p-4">
              <button
                className="btn btn-primary"
                onClick={() => history.push("/waterfalls?page=1")}
              >
                Voltar
              </button>
            </div>

            <CardBody>
              <div className="live-preview">
                <Row className="g-3">
                  <CoverUploadImageField
                    title="Adicionar imagens"
                    name="images"
                    multiple
                    data={waterfall}
                    isActiveRadioSelect={false}
                  />

                  <TextField label="Nome" name="nome" data={waterfall} />

                  <TextField
                    label="Bioma"
                    name="caracteristicas"
                    data={waterfall}
                  />

                  <TextField
                    label="Observações"
                    name="observacoes"
                    data={waterfall}
                    size={6}
                  />

                  <TextField
                    type="select"
                    label="Cidade"
                    name="cidade"
                    data={waterfall}
                    size={6}
                  >
                    <option value="" selected disabled>
                      Selecione a cidade
                    </option>
                    {dataCity?.result?.length > 0 &&
                      dataCity?.result.map((item, index) => (
                        <option key={index} value={item.objectId}>
                          {item.nome}
                        </option>
                      ))}
                  </TextField>

                  <TextField
                    label="Vídeo do local (URL)"
                    name="url_video"
                    data={waterfall}
                    size={6}
                  />

                  <TextField
                    type="select"
                    label="Propriedade particular"
                    name="propriedade_particular"
                    data={waterfall}
                    size={3}
                  >
                    <option value="" selected disabled>
                      Selecione se o atrativo natural é particular
                    </option>
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </TextField>

                  {/* <TextField
                    label="Segurança"
                    name="seguranca"
                    data={waterfall}
                    size={3}
                  /> */}

                  <TextField
                    label="Distância à pé (metros)"
                    name="distancia_pe"
                    data={waterfall}
                    type="number"
                    size={3}
                    onKeyDown={(event) => {
                      ["e", "E", "+"].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <TextField
                    label="Distância com carro (metros)"
                    name="distancia_carro"
                    data={waterfall}
                    type="number"
                    size={3}
                    onKeyDown={(event) => {
                      ["e", "E", "+"].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <TextField
                    type="select"
                    label="Nível à pé"
                    name="nivel_pe"
                    data={waterfall}
                    size={3}
                  >
                    <option value="" selected disabled>
                      Selecione o nível
                    </option>
                    <option value="Fácil">Fácil</option>
                    <option value="Médio">Médio</option>
                    <option value="Difícil">Difícil</option>
                  </TextField>

                  <TextField
                    type="select"
                    label="Nível com carro"
                    name="nivel_carro"
                    data={waterfall}
                    size={3}
                  >
                    <option value="" selected disabled>
                      Selecione o nível
                    </option>
                    <option value="Fácil">Fácil</option>
                    <option value="Médio">Médio</option>
                    <option value="Difícil">Difícil</option>
                  </TextField>

                  <TextField
                    label="Tempo à pé"
                    name="tempo_pe"
                    data={waterfall}
                    type="duration"
                    size={3}
                  />

                  <TextField
                    label="Tempo de carro"
                    name="tempo_carro"
                    data={waterfall}
                    type="duration"
                    size={3}
                  />

                  <TextField
                    label="Distância total (metros)"
                    name="distancia_total"
                    data={waterfall}
                    type="number"
                    size={3}
                  />

                  <TextField
                    type="select"
                    label="Taxa de visita"
                    name="taxa_visitacao"
                    data={waterfall}
                    size={3}
                  >
                    <option value="" selected disabled>
                      Selecione se tem taxa de visita
                    </option>
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </TextField>

                  {/* <TextField
                    label="Altura Queda (metros)"
                    name="altura_queda"
                    data={waterfall}
                    type="number"
                    size={3}
                    onKeyDown={(event) => {
                      ["e", "E", "+"].includes(event.key) &&
                        event.preventDefault();
                    }}
                  /> */}

                  <TextField
                    label="Altitude (metros)"
                    name="altitude"
                    data={waterfall}
                    type="number"
                    size={3}
                    onKeyDown={(event) => {
                      ["e", "E", "+"].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <TextField
                    label="Referência (GPS)"
                    name="referencia"
                    data={waterfall}
                    size={3}
                  />

                  <TextField
                    label="Limites sudoeste (latitude)"
                    name="bounds_southwest_lat"
                    data={waterfall}
                    size={3}
                  />
                  <TextField
                    label="Limites sudoeste (longitude)"
                    name="bounds_southwest_lng"
                    data={waterfall}
                    size={3}
                  />
                  <TextField
                    label="Limites noroeste (latitude)"
                    name="bounds_northwest_lat"
                    data={waterfall}
                    size={3}
                  />
                  <TextField
                    label="Limites noroeste (longitude)"
                    name="bounds_northwest_lng"
                    data={waterfall}
                    size={3}
                  />

                  <TextField
                    label="Latitude"
                    name="latitude"
                    data={waterfall}
                    type="number"
                    size={3}
                    onKeyDown={(event) => {
                      ["e", "E", "+"].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <TextField
                    label="Longitude"
                    name="longitude"
                    data={waterfall}
                    type="number"
                    size={3}
                    onKeyDown={(event) => {
                      ["e", "E", "+"].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <TextField
                    label="Descrição"
                    name="descritivo"
                    data={waterfall}
                    type="textarea"
                    size={12}
                  />

                  {renderCoverGPS()}

                  {data?.result?.images?.length > 0 && (
                    <>
                      <h3>Imagens do local</h3>
                      <p>
                        Escolha uma opção abaixo para excluir uma imagem ou
                        selecionar a imagem principal do local
                      </p>
                    </>
                  )}

                  {id && (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 10,
                        }}
                      >
                        {data?.result?.images?.map((item, idx) => {
                          return item.imagem?.name ? (
                            <div>
                              <Col key={idx} lg={3} style={styles.imageList}>
                                {item.imagem.url !==
                                data?.result?.waterfall?.imagem.url ? (
                                  <PlaceMenu
                                    remove={() => {
                                      setPhotoId(item.objectId);
                                      setModalIsOpenRemove(true);
                                    }}
                                    select={() => {
                                      setSelectedImage(item?.imagem);
                                      setModalIsOpenSelect(true);
                                    }}
                                  />
                                ) : null}
                                <LazyImage
                                  src={item?.imagem?.url}
                                  width="100%"
                                  height="100%"
                                  className="object-fit-cover rounded"
                                  style={
                                    item.imagem.url ===
                                    data?.result?.waterfall?.imagem.url
                                      ? styles.lazyImage
                                      : null
                                  }
                                />
                              </Col>
                              <div style={{ marginTop: 10 }}></div>
                              <InputGroup>
                                <Input
                                  value={item.legenda}
                                  title="Legenda"
                                  name="Legenda"
                                  placeholder="Legenda"
                                  size="sm"
                                  className="form-control"
                                  onChange={(event) =>
                                    addLegendInState(idx, event.target.value)
                                  }
                                />
                                <Button
                                  size="sm"
                                  className="primary"
                                  onClick={() =>
                                    updateLegend(
                                      item.objectId,
                                      legendImage[idx]
                                    )
                                  }
                                >
                                  <i className="ri-save-2-line fs-13"></i>
                                </Button>
                              </InputGroup>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </React.Fragment>
                  )}

                  <Col lg={12}>
                    <div className="text-end">
                      <button
                        type="button"
                        onClick={() => waterfall.handleSubmit()}
                        className="btn btn-primary"
                      >
                        Salvar
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>

      <Modal
        isOpen={modalIsOpenRemove}
        text="Tem certeza que deseja remover essa imagem?"
        onClick={() => {
          dispatch(
            deleteWaterfallPhotoRequest({
              waterfallId: id,
              photoId: photoId,
            })
          );

          setModalIsOpenRemove(false);
        }}
        onCancel={() => {
          setModalIsOpenRemove(false);
        }}
      />

      <Modal
        isOpen={modalIsOpenSelect}
        text="Tem certeza que deseja selecionar essa imagem como a principal deste atrativo natural?"
        onClick={() => {
          dispatch(
            updateWaterfallPhotoRequest({
              waterfallId: id,
              imagem: selectedImage,
            })
          );

          setModalIsOpenSelect(false);
        }}
        onCancel={() => {
          setModalIsOpenSelect(false);
        }}
      />
      <Modal
        isOpen={modalIsOpenGpsRemove}
        text="Tem certeza que deseja remover o GPS?"
        onClick={() => {
          dispatch(removeGpsWaterfallRequest({ id: params.id }));
          setModalIsOpenGpsRemove(false);
        }}
        onCancel={() => {
          setModalIsOpenGpsRemove(false);
        }}
      />
      <ModalLoding loading={loading} />
    </React.Fragment>
  );
};

const styles = {
  lazyImage: {
    borderColor: "#93bf85",
    borderWidth: 5,
    borderStyle: "solid",
    borderRadius: 10,
  },
  imageList: {
    display: "flex",
    position: "relative",
    height: 220,
    width: 260,
  },
};

export default WaterfallFormEdit;
