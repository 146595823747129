import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";
import {
  createServiceFailure,
  createServiceSuccess,
  deleteServiceFailure,
  deleteServiceSuccess,
  getServiceFailure,
  getServicesFailure,
  getServicesSuccess,
  getServiceSuccess,
  updateServiceFailure,
  updateServiceSuccess,
} from "./actions";
import {
  CREATE_SERVICES_REQUEST,
  DELETE_SERVICE_REQUEST,
  GET_SERVICES_REQUEST,
  GET_SERVICE_REQUEST,
  UPDATE_SERVICE_REQUEST,
} from "./actionTypes";

function requestServices(data) {
  return post("/services-by-name", { serviceName: data });
}

function requestService(id) {
  return post("/service", { serviceId: id });
}

function newService(service) {
  return post("/services", service);
}

function deleteServiceById(id) {
  return post("/service-remove", { serviceId: id });
}

function requestUpdateService(id, service) {
  const {
    nome,
    telefone,
    estado,
    cidade,
    rua,
    bairro,
    numero,
    // complemento,
    whatsapp,
    // site,
    tipo_servico,
    gps,
    filtro_servico,
  } = service;

  return post("/service-update", {
    serviceId: id,
    nome,
    telefone,
    estado,
    cidade,
    rua,
    bairro,
    numero,
    // complemento,
    whatsapp,
    // site,
    tipo_servico,
    gps,
    filtro_servico,
  });
}

function* getServices({ data }) {
  try {
    const response = yield call(requestServices, data);
    yield put(getServicesSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar serviços. " + error);
    yield put(getServicesFailure());
  }
}

function* getService({ data }) {
  try {
    const response = yield call(requestService, data);
    yield put(getServiceSuccess(response.data.result));
  } catch (error) {
    showNofity.error("Erro ao buscar serviço. " + error);
    yield put(getServiceFailure());
  }
}

function* createService({ data }) {
  try {
    const {
      nome,
      telefone,
      estado,
      cidade,
      rua,
      bairro,
      numero,
      // complemento,
      whatsapp,
      // site,
      tipo_servico,
      latitude,
      longitude,
      filtro_servico,
    } = data.values;

    yield call(newService, {
      nome,
      telefone,
      estado,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      rua,
      bairro,
      numero,
      // complemento,
      whatsapp,
      // site,
      filtro_servico: {
        __type: "Pointer",
        className: "FiltrosServico",
        objectId: filtro_servico,
      },
      tipo_servico: {
        __type: "Pointer",
        className: "TipoServico",
        objectId: tipo_servico,
      },
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
    });

    yield put(createServiceSuccess());
    showNofity.success("Serviço cadastrado com sucesso");
    data.resetForm();
  } catch (error) {
    showNofity.error("Erro ao cadastrar serviço." + error);
    yield put(createServiceFailure());
  }
}

function* updateService({ data }) {
  try {
    const {
      nome,
      telefone,
      estado,
      cidade,
      rua,
      bairro,
      numero,
      // complemento,
      whatsapp,
      // site,
      tipo_servico,
      latitude,
      longitude,
      filtro_servico,
    } = data.values;

    yield call(requestUpdateService, data.id, {
      nome,
      telefone,
      estado,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      rua,
      bairro,
      numero,
      // complemento,
      whatsapp,
      // site,
      filtro_servico: {
        __type: "Pointer",
        className: "FiltrosServico",
        objectId: filtro_servico,
      },
      tipo_servico: {
        __type: "Pointer",
        className: "TipoServico",
        objectId: tipo_servico,
      },
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
    });

    yield put(updateServiceSuccess());
    showNofity.success("Serviço atualizado com sucesso");
  } catch (error) {
    yield put(updateServiceFailure());
    showNofity.error("Erro ao atualizar serviço");
  }
}

function* deleteService({ data }) {
  try {
    yield call(deleteServiceById, data.serviceId);
    yield put(deleteServiceSuccess());
    data.closeModal();
    const response = yield call(requestServices);
    yield put(getServicesSuccess(response.data));
    showNofity.success("Serviço removido com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao remover serviço. " + error);
    yield put(deleteServiceFailure());
  }
}

function* saga() {
  yield takeLatest(GET_SERVICES_REQUEST, getServices);
  yield takeLatest(GET_SERVICE_REQUEST, getService);
  yield takeLatest(CREATE_SERVICES_REQUEST, createService);
  yield takeLatest(UPDATE_SERVICE_REQUEST, updateService);
  yield takeLatest(DELETE_SERVICE_REQUEST, deleteService);
}

export default saga;
