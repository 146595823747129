import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";
import {
  getTouristSpotPhotoUserFailure,
  getTouristSpotPhotoUserSuccess,
} from "./actions";
import { GET_TOURIST_SPOT_PHOTO_USER_REQUEST } from "./actionTypes";

function requestTouristSpotPhotoUser() {
  return post("/photo-users-tourist-spot");
}

function* getTouristSpotPhotoUser() {
  try {
    const response = yield call(requestTouristSpotPhotoUser);
    yield put(getTouristSpotPhotoUserSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar fotos dos pontos turísticos do usuário.");
    yield put(getTouristSpotPhotoUserFailure());
  }
}

function* saga() {
  yield takeLatest(
    GET_TOURIST_SPOT_PHOTO_USER_REQUEST,
    getTouristSpotPhotoUser
  );
}

export default saga;
