import { all, call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";
import {
  createTouristSpotFailure,
  createTouristSpotSuccess,
  deleteTouristSpotPhotoFailure,
  deleteTouristSpotSuccess,
  getTouristSpotFailure,
  getTouristSpotsFailure,
  getTouristSpotsSuccess,
  getTouristSpotSuccess,
  updateTouristSpotFailure,
  updateTouristSpotPhotoFailure,
} from "./actions";
import {
  CREATE_TOURIST_SPOT_REQUEST,
  DELETE_TOURIST_SPOT_PHOTO_REQUEST,
  DELETE_TOURIST_SPOT_REQUEST,
  GET_TOURIST_SPOTS_REQUEST,
  GET_TOURIST_SPOT_REQUEST,
  UPDATE_TOURIST_SPOT_PHOTO_REQUEST,
  UPDATE_TOURIST_SPOT_REQUEST,
} from "./actionTypes";

function requestTouristSpots(data) {
  return post("/tourist-spots-by-name", { touristSpotName: data });
}

function requestTouristSpotById(id) {
  return post("/tourist-spot-details", { touristSpotId: id });
}

function deleteTouristSpotById(id) {
  return post("/tourist-spot-remove", { touristSpotId: id });
}

function saveImage(imgs) {
  return post("/media-upload", { imgs });
}

function newTouristSpot(touristSpot) {
  return post("/tourist-spots", touristSpot);
}

function deletePhotoTouristSpot({ photoId, touristSpotId }) {
  return post("/photo-tourist-spot-remove", {
    touristSpotId: touristSpotId,
    photoId: photoId,
  });
}

function requestUpdateTouristSpotPhoto({ touristSpotId, imagem }) {
  return post("/photo-tourist-spot-select", {
    touristSpotId: touristSpotId,
    imagem: imagem,
  });
}

function requestUpdateTouristSpot(id, touristSpot) {
  const {
    nome,
    telefone,
    endereco,
    descritivo,
    entre_contato,
    email,
    gps,
    cidade,
    horario_funcionamento,
    filtro_ponto_turistico,
  } = touristSpot;

  return post("/tourist-spot-update", {
    touristSpotId: id,
    nome,
    telefone,
    endereco,
    descritivo,
    entre_contato,
    email,
    gps,
    cidade,
    horario_funcionamento,
    filtro_ponto_turistico,
  });
}

function* getTouristSpots({ data }) {
  try {
    const response = yield call(requestTouristSpots, data);
    yield put(getTouristSpotsSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar pontos turísticos.");
    yield put(getTouristSpotsFailure());
  }
}

function* getTouristSpot({ data }) {
  try {
    const response = yield call(requestTouristSpotById, data);
    yield put(getTouristSpotSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar detalhes do atrativo turístico." + error);
    yield put(getTouristSpotFailure());
  }
}

function* deleteTouristSpot({ data }) {
  try {
    yield call(deleteTouristSpotById, data.touristSpotId);
    yield put(deleteTouristSpotSuccess());
    data.closeModal();
    const response = yield call(requestTouristSpots);
    yield put(getTouristSpotsSuccess(response.data));
    showNofity.success("Atrativo turístico removido com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao deletar o atrativo turístico." + error);
    yield put(getTouristSpotFailure());
  }
}

function saveFotos(img, idTouristSpot) {
  return post("/photos-tourist-spot", {
    url: img.url,
    nome_imagem: img.name,
    imagem: {
      __type: "File",
      name: img.name,
      url: img.url,
    },
    ponto_turistico: {
      __type: "Pointer",
      className: "PontoTuristico",
      objectId: idTouristSpot,
    },
  });
}

function* createTouristSpot({ data }) {
  try {
    const {
      nome,
      telefone,
      endereco,
      images,
      descritivo,
      entre_contato,
      email,
      horario_funcionamento,
      cidade,
      latitude,
      longitude,
      imagem_principal,
      filtro_ponto_turistico,
    } = data.values;

    const responseImages = yield call(saveImage, images);
    const responseTouristSpot = yield call(newTouristSpot, {
      nome,
      telefone,
      endereco,
      descritivo,
      entre_contato,
      email,
      horario_funcionamento,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
      imagem: {
        __type: "File",
        url: responseImages.data[imagem_principal].url,
        name: responseImages.data[imagem_principal].name,
      },
      filtro_ponto_turistico: {
        __type: "Pointer",
        className: "FiltrosPontoTuristico",
        objectId: filtro_ponto_turistico,
      },
    });

    yield all(
      responseImages.data.map((img) =>
        call(saveFotos, img, responseTouristSpot.data.objectId)
      )
    );

    yield put(createTouristSpotSuccess());
    showNofity.success("Atrativo turístico cadastrado com sucesso.");
    data.resetForm();
  } catch (error) {
    showNofity.error("Erro ao cadastrar atrativo turístico. " + error);
    yield put(createTouristSpotFailure());
  }
}

function* updateTouristSpot({ data }) {
  try {
    const {
      nome,
      telefone,
      endereco,
      descritivo,
      entre_contato,
      email,
      horario_funcionamento,
      images,
      cidade,
      latitude,
      longitude,
      filtro_ponto_turistico,
    } = data.values;

    if (images?.length > 1) {
      const responseImages = yield call(saveImage, images);
      yield all(
        responseImages.data.map((img) => call(saveFotos, img, data.id))
      );
    }

    yield call(requestUpdateTouristSpot, data.id, {
      nome,
      telefone,
      endereco,
      descritivo,
      entre_contato,
      email,
      horario_funcionamento,
      images,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
      filtro_ponto_turistico: {
        __type: "Pointer",
        className: "FiltrosPontoTuristico",
        objectId: filtro_ponto_turistico,
      },
    });

    const response = yield call(requestTouristSpotById, data.id);
    yield put(getTouristSpotSuccess(response.data));
    data.onSuccess();
    showNofity.success("Atrativo turístico atualizado com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao atualizar atrativo turístico.");
    yield put(updateTouristSpotFailure());
  }
}

function* updateImageTouristSpot({ data }) {
  try {
    yield call(requestUpdateTouristSpotPhoto, {
      touristSpotId: data.touristSpotId,
      imagem: data.imagem,
    });

    const responseTouristSpot = yield call(
      requestTouristSpotById,
      data.touristSpotId
    );

    yield put(getTouristSpotSuccess(responseTouristSpot.data));
  } catch (error) {
    showNofity.error("Erro ao atualizar foto do ponto turistico. " + error);
    put(updateTouristSpotPhotoFailure());
  }
}

function* deleteImageTouristSpot({ data }) {
  try {
    yield call(deletePhotoTouristSpot, data);
    showNofity.success("Imagem removida com sucesso.");
    const response = yield call(requestTouristSpotById, data.touristSpotId);
    yield put(getTouristSpotSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao remover foto do atrativo turístico. " + error);
    put(deleteTouristSpotPhotoFailure());
  }
}

function* saga() {
  yield takeLatest(GET_TOURIST_SPOTS_REQUEST, getTouristSpots);
  yield takeLatest(GET_TOURIST_SPOT_REQUEST, getTouristSpot);
  yield takeLatest(DELETE_TOURIST_SPOT_REQUEST, deleteTouristSpot);
  yield takeLatest(DELETE_TOURIST_SPOT_PHOTO_REQUEST, deleteImageTouristSpot);
  yield takeLatest(CREATE_TOURIST_SPOT_REQUEST, createTouristSpot);
  yield takeLatest(UPDATE_TOURIST_SPOT_REQUEST, updateTouristSpot);
  yield takeLatest(UPDATE_TOURIST_SPOT_PHOTO_REQUEST, updateImageTouristSpot);
}

export default saga;
