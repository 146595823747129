import {
  CREATE_FILTERS_WHERE_EAT_FAILURE,
  CREATE_FILTERS_WHERE_EAT_REQUEST,
  CREATE_FILTERS_WHERE_EAT_SUCCESS,
  DELETE_FILTERS_WHERE_EAT_FAILURE,
  DELETE_FILTERS_WHERE_EAT_REQUEST,
  DELETE_FILTERS_WHERE_EAT_SUCCESS,
  GET_FILTERS_WHERE_EAT_FAILURE,
  GET_FILTERS_WHERE_EAT_REQUEST,
  GET_FILTERS_WHERE_EAT_SUCCESS,
  UPDATE_FILTERS_WHERE_EAT_FAILURE,
  UPDATE_FILTERS_WHERE_EAT_REQUEST,
  UPDATE_FILTERS_WHERE_EAT_SUCCESS,
} from "./actionTypes";

export const getFiltersWhereEatRequest = (data) => {
  return {
    type: GET_FILTERS_WHERE_EAT_REQUEST,
    data,
  };
};

export const getFiltersWhereEatSuccess = (data) => {
  return {
    type: GET_FILTERS_WHERE_EAT_SUCCESS,
    data,
  };
};

export const getFiltersWhereEatFailure = () => {
  return {
    type: GET_FILTERS_WHERE_EAT_FAILURE,
  };
};

export const createFilterWhereEatRequest = (data) => {
  return {
    type: CREATE_FILTERS_WHERE_EAT_REQUEST,
    data,
  };
};

export const createFilterWhereEatSuccess = (data) => {
  return {
    type: CREATE_FILTERS_WHERE_EAT_SUCCESS,
    data,
  };
};

export const createFilterWhereEatFailure = () => {
  return {
    type: CREATE_FILTERS_WHERE_EAT_FAILURE,
  };
};

export const updateFilterWhereEatRequest = (data) => {
  return {
    type: UPDATE_FILTERS_WHERE_EAT_REQUEST,
    data,
  };
};

export const updateFilterWhereEatFailure = () => {
  return {
    type: UPDATE_FILTERS_WHERE_EAT_FAILURE,
  };
};

export const updateFilterWhereEatSuccess = (data) => {
  return {
    type: UPDATE_FILTERS_WHERE_EAT_SUCCESS,
    data,
  };
};

export const deleteFilterWhereEatFailure = () => {
  return {
    type: DELETE_FILTERS_WHERE_EAT_FAILURE,
  };
};

export const deleteFilterWhereEatRequest = (data) => {
  return {
    type: DELETE_FILTERS_WHERE_EAT_REQUEST,
    data,
  };
};

export const deleteFilterWhereEatSuccess = (data) => {
  return {
    type: DELETE_FILTERS_WHERE_EAT_SUCCESS,
    data,
  };
};
