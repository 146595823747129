import React from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useDispatch } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import logoWhite from "../../assets/logos/logo-white.svg";
// import
//Import config

import { login } from "../../services/auth/auth-api";
import { ToastContainer, toast } from "react-toastify";

const Login = (props) => {
  const history = useHistory();
  const errornotify = (message) =>
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-danger text-white",
    });
  const successnotify = (message) =>
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-success text-white",
    });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {},
    validationSchema: Yup.object({
      email: Yup.string().required("O email é obrigatório"),
      password: Yup.string().required("A senha é obrigatória"),
    }),
    onSubmit: (values) => {
      // dispatch(
      login(values, props.history)
        .then((res) => {
          // alert(JSON.stringify(res));
          // console.log(res);
          localStorage.setItem("isUserLogged", "true");
          localStorage.setItem("userToken", res.token);
          localStorage.setItem("userName", res.name);
          localStorage.setItem("userID", res.id);

          history.push("/dashboard");
          successnotify("Login efetuado com sucesso!");
        })
        .catch((err) => {
          errornotify("Email ou senha incorretos, verifique seus dados.");
        });
      // );
    },
  });

  document.title = "Acessar";
  return (
    <React.Fragment>
      <ToastContainer />
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div className="pt-2">
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoWhite} alt="" height="120" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Bem vindo de volta!</h5>
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Email
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              Esqueceu sua senha?
                            </Link>
                          </div>
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Senha
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type="password"
                              className="form-control pe-5"
                              placeholder="Senha"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            {/* <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                            ></button> */}
                          </div>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Entrar
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
