import { get, post, patch, destroy } from '../../boot/api'

export const login = async params => {
  const { data } = await post('/login', params)
  return data
}

export const register = async params => {
  const { data } = await post('/register', params)
  return data
}
