import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../../boot/api";
import showNofity from "../../../helpers/toast";
import {
  getFilterTouristSpotFailure,
  getFilterTouristSpotSuccess,
} from "./actions";
import { GET_FILTER_TOURIST_SPOT_REQUEST } from "./actionTypes";

function requestFilterTouristSpot(id) {
  return post("/filters-tourist-spot-details", { filterTouristSpotId: id });
}

function* getFilterTouristSpot({ data }) {
  try {
    const response = yield call(requestFilterTouristSpot, data);
    yield put(getFilterTouristSpotSuccess(response.data.result));
  } catch (error) {
    showNofity.error("Erro ao buscar filtro. " + error);
    yield put(getFilterTouristSpotFailure());
  }
}

function* saga() {
  yield takeLatest(GET_FILTER_TOURIST_SPOT_REQUEST, getFilterTouristSpot);
}

export default saga;
