import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../../boot/api";
import showNofity from "../../../helpers/toast";
import {
  createFilterTouristSpotFailure,
  createFilterTouristSpotSuccess,
  deleteFilterTouristSpotFailure,
  deleteFilterTouristSpotSuccess,
  getFiltersTouristSpotFailure,
  getFiltersTouristSpotSuccess,
  updateFilterTouristSpotFailure,
  updateFilterTouristSpotSuccess,
} from "./actions";
import {
  CREATE_FILTERS_TOURIST_SPOT_REQUEST,
  DELETE_FILTERS_TOURIST_SPOT_REQUEST,
  GET_FILTERS_TOURIST_SPOT_REQUEST,
  UPDATE_FILTERS_TOURIST_SPOT_REQUEST,
} from "./actionTypes";

function requestFiltersTouristSpot(data) {
  return post("/filters-tourist-spot-by-name", { filterTouristSpotName: data });
}

function newFilterTouristSpot(filter) {
  return post("/filters-tourist-spot", filter);
}

function deleteFiltersTouristSpotById(id) {
  return post("/filters-tourist-spot-remove", { filterTouristSpotId: id });
}

function requestUpdateFilterTouristSpot(id, filterTouristSpot) {
  const { nome } = filterTouristSpot;

  return post("/filters-tourist-spot-update", {
    filterTouristSpotId: id,
    nome,
  });
}

function* getFiltersTouristSpot({ data }) {
  try {
    const response = yield call(requestFiltersTouristSpot, data);

    yield put(getFiltersTouristSpotSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar filtros do atrativo turístico. " + error);
    yield put(getFiltersTouristSpotFailure());
  }
}

function* createFilterTouristSpot({ data }) {
  try {
    const { nome } = data.values;

    yield call(newFilterTouristSpot, {
      nome,
    });

    yield put(createFilterTouristSpotSuccess());
    data.resetForm();
    data.closeModal();
    const response = yield call(requestFiltersTouristSpot);
    yield put(getFiltersTouristSpotSuccess(response.data));
    showNofity.success("Filtro cadastrado com sucesso");
  } catch (error) {
    showNofity.error("Erro ao cadastrar filtro." + error);
    yield put(createFilterTouristSpotFailure());
  }
}

function* updateFilterTouristSpot({ data }) {
  try {
    const { nome } = data.values;

    yield call(requestUpdateFilterTouristSpot, data.id, {
      nome,
    });

    yield put(updateFilterTouristSpotSuccess());
    showNofity.success("Filtro atualizado com sucesso");
    data.closeModal();
    const response = yield call(requestFiltersTouristSpot);
    yield put(getFiltersTouristSpotSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao atualizar filtro " + error);
    yield put(updateFilterTouristSpotFailure());
  }
}

function* deleteFilterTouristSpot({ data }) {
  try {
    yield call(deleteFiltersTouristSpotById, data.filterTouristSpotId);
    yield put(deleteFilterTouristSpotSuccess());
    data.closeModal();
    const response = yield call(requestFiltersTouristSpot);
    yield put(getFiltersTouristSpotSuccess(response.data));
    showNofity.success("Filtro removido com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao remover filtro. " + error);
    yield put(deleteFilterTouristSpotFailure());
  }
}

function* saga() {
  yield takeLatest(GET_FILTERS_TOURIST_SPOT_REQUEST, getFiltersTouristSpot);
  yield takeLatest(
    CREATE_FILTERS_TOURIST_SPOT_REQUEST,
    createFilterTouristSpot
  );
  yield takeLatest(
    DELETE_FILTERS_TOURIST_SPOT_REQUEST,
    deleteFilterTouristSpot
  );
  yield takeLatest(
    UPDATE_FILTERS_TOURIST_SPOT_REQUEST,
    updateFilterTouristSpot
  );
}

export default saga;
