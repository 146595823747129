import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../../boot/api";
import showNofity from "../../../helpers/toast";
import { getFilterServiceFailure, getFilterServiceSuccess } from "./actions";
import { GET_FILTER_SERVICE_REQUEST } from "./actionTypes";

function requestFilterService(id) {
  return post("/filters-service-details", { filterServiceId: id });
}

function* getFilterService({ data }) {
  try {
    const response = yield call(requestFilterService, data);
    yield put(getFilterServiceSuccess(response.data.result));
  } catch (error) {
    showNofity.error("Erro ao buscar filtro. " + error);
    yield put(getFilterServiceFailure());
  }
}

function* saga() {
  yield takeLatest(GET_FILTER_SERVICE_REQUEST, getFilterService);
}

export default saga;
