// import {
//   DELETE_CITY_PHOTO_FAILURE,
//   DELETE_CITY_PHOTO_REQUEST,
//   DELETE_CITY_PHOTO_SUCCESS,
// } from "../city/actionTypes";
import {
  CREATE_SERVICES_FAILURE,
  CREATE_SERVICES_REQUEST,
  CREATE_SERVICES_SUCCESS,
  DELETE_SERVICE_FAILURE,
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_SUCCESS,
  GET_SERVICES_FAILURE,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICE_FAILURE,
  GET_SERVICE_REQUEST,
  GET_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAILURE,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: false,
  loading: false,
  data: null,
  success: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICES_REQUEST:
      console.log("REQUEST");
      state = {
        ...state,
        data: [],
        loading: true,
        error: false,
      };
      break;

    case GET_SERVICES_SUCCESS:
      console.log("SUCCESS");
      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_SERVICES_FAILURE:
      console.log("FAILURE");
      state = {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
      break;

    case GET_SERVICE_REQUEST:
      console.log("REQUEST");
      state = {
        ...state,
        data: {},
        loading: true,
        error: false,
      };
      break;

    case GET_SERVICE_SUCCESS:
      console.log("SUCCESS");
      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_SERVICE_FAILURE:
      console.log("FAILURE");
      state = {
        ...state,
        data: {},
        loading: false,
        error: true,
      };
      break;

    case CREATE_SERVICES_REQUEST:
      console.log("REQUESTED...");

      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case CREATE_SERVICES_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
      break;

    case CREATE_SERVICES_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    case UPDATE_SERVICE_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case UPDATE_SERVICE_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case UPDATE_SERVICE_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case DELETE_SERVICE_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_SERVICE_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_SERVICE_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
