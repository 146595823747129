import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {
  getAggregatedAnalyticsData,
  getAllTimeAnalyticsData,
  getRealTimeAnalyticsData,
} from '../../store/dashboardAnalytics/action';

// Gráficos
import CidadesMaisAcessadas from './CidadesMaisAcessadas';
import CidadesMaisBuscadas from './CidadesMaisBuscadas';
import PaginasAcessadasRecentes from './PaginasAcessadasRecentes';
import SecoesMaisAcessadas from './SecoesMaisAcessadas';
import SeletorPeriodo from './SeletorPeriodo';
import TempoMedioEngajamentoPorPagina from './TempoMedioEngajamentoPorPagina';
import UsuariosAtivos from './UsuariosAtivos';
import UsuariosAtivosPorPais from './UsuariosAtivosPorPais';
import UsuariosPorCidade from './UsuariosPorCidade';
import UsuariosPorDispositivo from './UsuariosPorDispositivo';
import UsuariosPorFaixaEtaria from './UsuariosPorFaixaEtaria';
import UsuariosPorGenero from './UsuariosPorGenero';
import UsuariosPorIdioma from './UsuariosPorIdioma';
import UsuariosPorInteresse from './UsuariosPorInteresse';
import UsuariosPorPlataforma from './UsuariosPorPlataforma';
import VisualizacoesRecentes from './VisualizacoesRecentes';
import WidgetsRealtime from './WidgetsRealtime';
import WidgetsPeriodo from './WidgetsPeriodo';

const DashboardAnalytics = () => {
  document.title = "Analytics | Ecoguias - Admin Dashboard";

  const dispatch = useDispatch();
  const loadingAggregated = useSelector((state) => state.DashboardAnalytics.google.loading);
  const [dateRange, setDateRange] = useState({
    startDate: '30daysAgo',
    endDate: 'today'
  });

  useEffect(() => {
    dispatch(getAggregatedAnalyticsData(dateRange));
  }, [dispatch, dateRange]);

  useEffect(() => {
    dispatch(getAllTimeAnalyticsData());
    dispatch(getRealTimeAnalyticsData());

    const interval = setInterval(() => {
      if (document.hidden) {
        return;
      }
      dispatch(getRealTimeAnalyticsData());
    }, 10000);

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Analytics" pageTitle="Dashboard" />
          <Row>
            <div className="d-flex align-items-baseline">
              <h3 className="text-uppercase">Atividade Recente</h3>
              <p className="fw-medium ms-3 text-muted text-uppercase">últimos 30 minutos</p>
            </div>
          </Row>
          <Row>
            <Col xl={8}>
              <UsuariosAtivosPorPais />
            </Col>
            <Col xl={4} className="d-flex flex-column">
              <WidgetsRealtime />
              <PaginasAcessadasRecentes />
            </Col>
          </Row>

          <Row>
            <div className="d-flex flex-wrap align-items-baseline justify-content-between justify-content-lg-start mb-3">
              <h3
                className="text-uppercase"
                style={{ minWidth: '150px', marginRight: '1.5rem' }}
              >
                Por Período
              </h3>
              <SeletorPeriodo
                onChange={setDateRange}
                disabled={loadingAggregated}
              />
            </div>
          </Row>
          <Row>
            <WidgetsPeriodo />
            <SecoesMaisAcessadas />
            <TempoMedioEngajamentoPorPagina />
            <UsuariosPorCidade />
            <UsuariosPorPlataforma />
            <UsuariosPorInteresse />
            <UsuariosPorIdioma />
            <UsuariosPorDispositivo />
            <UsuariosPorGenero />
            <UsuariosPorFaixaEtaria />
          </Row>
          <Row>
            <div className="d-flex align-items-baseline">
              <h3 className="text-uppercase">Ranking</h3>
              <p className="fw-medium ms-3 text-muted text-uppercase">desde o começo</p>
            </div>
          </Row>
          <Row>
            <CidadesMaisAcessadas />
            <CidadesMaisBuscadas />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardAnalytics;