import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container, Input } from "reactstrap";
import { useDebounce } from "use-debounce";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import PlaceList from "../../Components/Places/PlaceList";
import { getRestaurantsRequest } from "../../store/restaurants/actions";

const RestaurantList = () => {
  document.title = "Alimentação | Ecoguias - Admin Dashboard";

  const history = useHistory();

  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.Restaurant);

  const { search } = useLocation();
  const queryStringParams = queryString.parse(search);

  const [searchInput, setSearchInput] = useState("");
  const [value] = useDebounce(searchInput, 500);

  useEffect(() => {
    dispatch(getRestaurantsRequest(value));
  }, [dispatch, value]);

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Alimentação" pageTitle="Locais" />

          <div className="d-flex justify-content-between pb-4 flex-wrap">
            <Input
              type="text"
              style={{ maxWidth: 250 }}
              className="form-control"
              placeholder="Pesquisar..."
              id="search-options"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={() => history.push("/restaurants-form")}
            >
              Cadastrar
            </button>
          </div>

          <PlaceList
            loading={loading}
            data={data?.result}
            pathPaginationName="/restaurants"
            page={queryStringParams.page}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RestaurantList;
