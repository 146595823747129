import { Button, Col, Modal as ModalStrap, ModalBody, Row } from "reactstrap";

export default function Modal({ text, onClick, isOpen, onCancel }) {
  return (
    <ModalStrap isOpen={isOpen} centered size="sm">
      <ModalBody className="d-flex flex-column justify-content-center align-items-center">
        {text ? <h6>{text}</h6> : undefined}
        <br />

        <Row>
          <Col>
            {onCancel ? (
              <button
                type="button"
                className="btn btn-default"
                onClick={onCancel}
              >
                CANCELAR
              </button>
            ) : null}
          </Col>

          <Col>
            <Button color="primary" onClick={onClick}>
              {/* {loading ? (
                <Spinner className="m-2" size={2} color="#fff" />
              ) : ( */}
              Confirmar
              {/* )} */}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </ModalStrap>
  );
}
