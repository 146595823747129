import React,  { useEffect, useState }  from 'react';
import DataTable from 'react-data-table-component';
import { getCertificateRequests, destroyCertificateRequest, updateCertificateRequest } from '../../services/certificate-request/certificate-request-api'
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Button, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

function CertificateRequestTable() {
    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Circuito</span>,
            selector: row => row.circuit.name || 'N/A',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Usuário</span>,
            selector: row => row.user.firstname + ' ' + row.user.lastname || 'N/A',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>status</span>,
            selector: row => row.status === 'pending' ? <span className="badge bg-warning">Pendente</span> : <span className="badge bg-success">Finalizado</span> || 'N/A',
            sortable: true
        },
        {
            name: <span className="font-weight-bold fs-13">Ações</span>,
            selector: row =>
                <div className="hstack gap-3 flex-wrap">
                    {row.status === 'pending' &&
                        <Link to="#" className="link-info fs-15" onClick={() => openChangeStatusModal(true)}>
                            <i className="ri-edit-2-line"></i>
                        </Link>
                        }
                        <Modal
                            size="md"
                            isOpen={changeStatusModal}
                            toggle={() => {
                                openChangeStatusModal();
                            }}
                        >
                            <ModalHeader className="modal-title"
                                id="changeStatusModal" toggle={() => {
                                    openChangeStatusModal();
                                }}>

                                Mudar status

                            </ModalHeader>
                            <ModalBody>
                                <h6 className="fs-15">Tem certeza que deseja mudar o status dessa solicitação de certificado para finalizado?</h6>
                            </ModalBody>
                            <div className="modal-footer">
                                <Link to="#" className="btn btn-link link-success fw-medium" onClick={() => setChangeStatusModal(false)}><i className="ri-close-line me-1 align-middle"></i> Fechar</Link>
                                <Button color="success" onClick={() => updateCertificateRequestData(row.id)}>Confirmar</Button>
                            </div>
                        </Modal>
                    <Link to="#" className="link-danger fs-15" onClick={() => openModal(true)}>
                        <i className="ri-delete-bin-line"></i>
                    </Link>
                    <Modal
                        size="md"
                        isOpen={modal}
                        toggle={() => {
                            openModal();
                        }}
                    >
                        <ModalHeader className="modal-title"
                            id="deleteModal" toggle={() => {
                                openModal();
                            }}>

                            Excluir solicitação de certificado

                        </ModalHeader>
                        <ModalBody>
                            <h6 className="fs-15">Tem certeza que deseja excluir essa solicitação de certificado?</h6>
                        </ModalBody>
                        <div className="modal-footer">
                            <Link to="#" className="btn btn-link link-success fw-medium" onClick={() => setmodal(false)}><i className="ri-close-line me-1 align-middle"></i> Fechar</Link>
                            <Button color="danger" onClick={() => deleteCertificateRequest(row.id)}>Confirmar</Button>
                        </div>
                    </Modal>
                </div>
        }
    ];

    const errornotify = (message) => toast(message, { position: "top-center", hideProgressBar: true, closeOnClick: false, className: 'bg-danger text-white' });
    const successnotify = (message) => toast(message, { position: "top-center", hideProgressBar: true, closeOnClick: false, className: 'bg-success text-white' });
    const [certificateRequests, setCertificateRequests] = useState([]);
    const [modal, setmodal] = useState(false);
    const [changeStatusModal, setChangeStatusModal] = useState(false);

    useEffect(() => {
        getCertificateRequestsData()
    }, []);

    function getCertificateRequestsData() {
        getCertificateRequests()
        .then((data) => {
            setCertificateRequests(data.items);
        })
        .catch(() => {
            errornotify("Erro ao buscar solicitação de certificados.")
        });
    }

    function openModal() {
        setmodal(!modal);
    }

    function openChangeStatusModal() {
        setChangeStatusModal(!changeStatusModal);
    }

    function deleteCertificateRequest(certificateRequestId) {
        destroyCertificateRequest(certificateRequestId).then(() => {
            successnotify("Solicitação de certificado excluido com sucesso!");

            getCertificateRequestsData()
            setmodal(false)
        }).catch(() => {
            errornotify("Erro ao excluir solicitação de certificado.")
        });
    }

    function updateCertificateRequestData(certificateRequestId) {
        const values = {
            status: 'done'
        }
        updateCertificateRequest(certificateRequestId, values).then(() => {
            successnotify("Status da solicitação de certificado alterado com sucesso!");

            getCertificateRequestsData()
            setChangeStatusModal(false)
        }).catch(() => {
            errornotify("Erro ao alterar status da solicitação de certificado.")
        });
    }

    return (
        <React.Fragment>
            <Row>
                <ToastContainer />
                <DataTable
                    columns={columns}
                    data={certificateRequests}
                    pagination
                />
            </Row>
        </React.Fragment>
    );
}

export { CertificateRequestTable };