import {
  CREATE_EVENTS_FAILURE,
  CREATE_EVENTS_REQUEST,
  CREATE_EVENTS_SUCCESS,
  DELETE_EVENT_FAILURE,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENT_FAILURE,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  REMOVE_IMAGE_EVENT_FAILURE,
  REMOVE_IMAGE_EVENT_REQUEST,
  REMOVE_IMAGE_EVENT_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: false,
  loading: false,
  data: null,
  success: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_REQUEST:
      console.log("REQUEST");
      state = {
        ...state,
        data: [],
        loading: true,
        error: false,
      };
      break;

    case GET_EVENTS_SUCCESS:
      console.log("SUCCESS");
      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_EVENTS_FAILURE:
      console.log("FAILURE");
      state = {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
      break;

    case CREATE_EVENTS_REQUEST:
      console.log("REQUESTED...");

      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case CREATE_EVENTS_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
      break;

    case CREATE_EVENTS_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    case GET_EVENT_REQUEST:
      console.log("REQUEST");
      state = {
        ...state,
        data: {},
        loading: true,
        error: false,
      };
      break;

    case GET_EVENT_SUCCESS:
      console.log("SUCCESS");
      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_EVENT_FAILURE:
      console.log("FAILURE");
      state = {
        ...state,
        data: {},
        loading: false,
        error: true,
      };
      break;

    case UPDATE_EVENT_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case UPDATE_EVENT_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case UPDATE_EVENT_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case DELETE_EVENT_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_EVENT_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_EVENT_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case REMOVE_IMAGE_EVENT_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case REMOVE_IMAGE_EVENT_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case REMOVE_IMAGE_EVENT_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        // data: [],
        loading: false,
        error: true,
        success: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
