import React, { useState } from 'react';
import classNames from 'classnames';
import Flatpickr from "react-flatpickr";
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import moment from 'moment';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const mode_7D = '7D';
const mode_30D = '30D';
const mode_3M = '3M';
const mode_1A = '1A';
const mode_2A = '2A';
const mode_ESTE_ANO = 'ESTE ANO';
const mode_SELECIONAR = 'SELECIONAR';

const MODES = [
  mode_7D,
  mode_30D,
  mode_3M,
  mode_1A,
  mode_2A,
  mode_ESTE_ANO,
  mode_SELECIONAR,
];

const SeletorPeriodo = ({ disabled, onChange }) => {
  const [activeMode, setActiveMode] = useState('30D');

  const [selectedStart, setSelectedStart] = useState(() => moment().subtract(30, 'days').toDate());
  const [selectedEnd, setSelectedEnd] = useState(() => new Date());
  const [maxDate, setMaxDate] = useState(() => moment().endOf('day').toDate());

  const onClick = (mode => {
    setActiveMode(mode);

    if (mode === mode_SELECIONAR) {
      return;
    }

    let startDate;
    let endDate = 'today';

    switch (mode) {
      case mode_7D:
        startDate = moment().subtract(7, 'days');
        break;
      case mode_30D:
        startDate = moment().subtract(30, 'days');
        break;
      case mode_3M:
        startDate = moment().subtract(3, 'months');
        break;
      case mode_1A:
        startDate = moment().subtract(1, 'year');
        break;
      case mode_2A:
        startDate = moment().subtract(2, 'year');
        break;
      case mode_ESTE_ANO:
        startDate = moment().startOf('year');
        break;
      default:
        break;
    }
    setSelectedStart(startDate.toDate());
    setSelectedEnd(moment().endOf('day').toDate());

    startDate = startDate.format('YYYY-MM-DD');

    onChange({ startDate, endDate });
  });

  const onDateChange = ([startDate, endDate], dateStr, flatpickr) => {
    if (startDate && !endDate) {
      const newMax = moment(startDate);
      newMax.add(2, 'year');
      if (newMax.isAfter(new Date())) {
        setMaxDate(moment().endOf('day').toDate());
      } else {
        setMaxDate(newMax.endOf('day').toDate());
      }
      return;
    }

    if (startDate && endDate) {
      setSelectedStart(startDate);
      setSelectedEnd(endDate);
      onChange({
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      });
    }
  };

  const onDatePickerClose = (selectedDates, dateStr, instance) => {
    if (!selectedDates || selectedDates.length !== 2) {
      instance.setDate([selectedStart, selectedEnd]);
    }
  };

  let seletor = undefined;
  if (activeMode === mode_SELECIONAR) {
    seletor = (
      <div className="d-flex flex-grow-1 justify-content-end mt-1 d-sm-block ms-sm-2">
        <Flatpickr
          className="form-control w-auto"
          options={{
            mode: "range",
            dateFormat: "d-m-Y",
            locale: Portuguese,
            minDate: new Date('2021-01-01T00:00:00-03:00'),
            maxDate: maxDate,
            defaultDate: [selectedStart, selectedEnd]
          }}
          onChange={onDateChange}
          onClose={onDatePickerClose}
        />
      </div>
    );
  }

  return (
    <>
      <UncontrolledDropdown className="d-lg-none">
        <DropdownToggle tag="button" className="btn btn-soft-primary btn-sm" >
          <span className="text-uppercase">{activeMode}<i className="mdi mdi-chevron-down align-middle ms-1"></i></span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-end mb-3 ms-3 ">
          {MODES.map((mode) => (
            <DropdownItem
              key={mode}
              className={classNames({ active: mode === activeMode })}
              onClick={() => onClick(mode)}
            >
              {mode}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <div className='d-none d-lg-flex flex-shrink-0 gap-1'>
        {MODES.map((mode) => (
          <button
            key={mode}
            type="button"
            disabled={disabled}
            className={classNames("btn btn-md p-2", {
              'btn-soft-info': mode !== activeMode,
              'btn-soft-primary': mode === activeMode,
            })}
            onClick={() => onClick(mode)}
          >
            {mode}
          </button>
        ))}
      </div>
      {seletor}
    </>
  );
};

export default SeletorPeriodo;