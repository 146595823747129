import { useEffect, useState } from 'react';
import { Col, FormFeedback, Input, Label } from "reactstrap";
import { formatMinutesToDuration, parseDurationToMinutes } from '../../../utils/formats';
import { DURATION_SCHEMA } from '../../../utils/validation_schemas';
import _CreatableSelect from 'react-select/creatable';

import styled, { css } from 'styled-components';

const CreatableSelect = styled(_CreatableSelect)`
  height: unset !important;
`;

export function SelectableField({
  data,
  label,
  name,
  children,
  size = 6,
  ...props
}) {
  let handleBlur = data?.handleBlur;
  const handleChange = (options) => {
    const values = options.map(option => option.value);
    data.setFieldValue(name, values);
  };

  const selectedOptions = (data?.values?.[name] ?? [])
    .map(value => ({
      label: value,
      value: value,
    }));

  return (
    <Col lg={size} key="descriptionField">
      <div className="form-floating">
        <CreatableSelect
          components={{
            DropdownIndicator: null,
          }}
          styles={{
            control: (base) => ({
              ...base,
              border: 0,
              boxShadow: undefined,
              backgroundColor: undefined,
              transform: 'translateY(-6px)'
            }),
            input: (base) => ({
              ...base,
              margin: 0,
            }),
            valueContainer: (base) => ({
              ...base,
              padding: 0,
              paddingTop: '2px',
            }),
          }}
          name={name}
          className="form-control"
          onChange={handleChange}
          value={selectedOptions}
          isClearable
          isMulti
          noOptionsMessage={() => null}
          formatCreateLabel={(option) => `Adicionar "${option?.trim?.()}"`}
          placeholder={null}
          {...props}
        >
          {children}
        </CreatableSelect>

        {data?.touched[name] && data?.errors[name] ? (
          <FormFeedback type="invalid">{data?.errors[name]}</FormFeedback>
        ) : null}

        <Label htmlFor={name}>{label}</Label>
      </div>
    </Col>
  );
}
