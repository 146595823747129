import React from "react";
import { Row, Col, CardBody, Card, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import YupPassword from 'yup-password';
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from "react-router-dom";

//import images 
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { register } from "../../services/auth/auth-api"

const Register = () => {
    const history = useHistory();
    const errornotify = (message) => toast(message, { position: "top-center", hideProgressBar: true, closeOnClick: false, className: 'bg-danger text-white' });
    const successnotify = (message) => toast(message, { position: "top-center", hideProgressBar: true, closeOnClick: false, className: 'bg-success text-white' });
    YupPassword(Yup);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: '',
            firstname: '',
            password: '',
            confirm_password: '',
            is_admin: false
        },
        validationSchema: Yup.object({
            email: Yup.string().required("O email é obrigatório"),
            firstname: Yup.string().required("O nome é obrigatório"),
            password: Yup.string()
                .min(8,'A senha deve conter no minimo 8 digitos, uma letra maiuscula, um número e um caracter especial!')
                .minUppercase(1, 'A senha deve conter no minimo 1 letra em maiusculo')
                .minNumbers(1, 'A senha deve conter no minimo 1 número')
                .minSymbols(1, 'A senha dever conter no minimo 1 caracter especial')
                .required("A senha é obrigatória"),
            confirm_password: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "A confirmação de senha não corresponde a senha inserida"
                )
            })
        }),
        onSubmit: (values) => {
            registerUser(values)
        }
    });

    function registerUser(values) {
        register(values).then(() => {
            successnotify("Usuário registrado com sucesso!");

            history.push("/login");
        })
        .catch((e) => {
            console.log(e.response.data.statusCode);
            if (e.response.data.statusCode === 422) {
                errornotify("Este email já está registrado.")     
            }

            errornotify("Erro ao registrar usuário.")
        });
    }

    document.title = "Registrar-se";

    return (
        <React.Fragment>
            <ToastContainer />
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="20" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">

                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Criar uma nova conta</h5>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                className="needs-validation" action="#">
                                                <div className="mb-3">
                                                    <Label htmlFor="useremail" className="form-label">Email <span className="text-danger">*</span></Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                    ) : null}

                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="username" className="form-label">Nome <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="firstname"
                                                        type="text"
                                                        placeholder="Nome"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.firstname || ""}
                                                        invalid={
                                                            validation.touched.firstname && validation.errors.firstname ? true : false
                                                        }
                                                    />
                                                    {validation.touched.firstname && validation.errors.firstname ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.firstname}</div></FormFeedback>
                                                    ) : null}

                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="userpassword" className="form-label">Senha <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="password"
                                                        type="password"
                                                        placeholder="Senha"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                            validation.touched.password && validation.errors.password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                                                    ) : null}

                                                </div>

                                                <div className="mb-2">
                                                    <Label htmlFor="confirmPassword" className="form-label">Confirme sua senha <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="confirm_password"
                                                        type="password"
                                                        placeholder="Confirmação de senha"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.confirm_password || ""}
                                                        invalid={
                                                            validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.confirm_password}</div></FormFeedback>
                                                    ) : null}

                                                </div>

                                                <div className="mt-4">
                                                    <button className="btn btn-success w-100" type="submit">Registrar-se</button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">Já possui uma conta ? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Entrar agora </Link> </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Register;
