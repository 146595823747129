import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";

import * as Yup from "yup";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import states from "../../utils/states.json";
import ModalLoding from "../../Components/Common/ModalLoading";

import UiContent from "../../Components/Common/UiContent";
import {
  CoverUploadImageField,
  InputMask,
  TextField,
} from "../../Components/Forms";
import Loading from "../../Components/Loading/Loading";
import showNofity from "../../helpers/toast";
import { getCitiesRequest } from "../../store/city/actions";
import { createTouristReceptionRequest } from "../../store/touristReceptions/actions";
import { isLatitude, isLongitude, unformatPhone } from "../../utils/formats";

const MANDATORY_FIELD = "Este campo é obrigatório";

const TouristReceptionForm = () => {
  document.title = "Turismo receptivo | Ecoguias - Admin Dashboard";
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.TouristReception);
  const { data: dataCity, loading: loadingCity } = useSelector(
    (state) => state.City
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(getCitiesRequest());
  }, [dispatch]);

  const touristReception = useFormik({
    enableReinitialize: true,

    initialValues: {
      nome: "",
      email: "",
      url_site: "",
      descritivo: "",
      images: [],
      url_video: "",
      telefones: "",
      logradouro: "",
      numero: "",
      bairro: "",
      whatsapp: "",
      instagram: "",
      cidade: "",
      estado: "",
      latitude: "",
      longitude: "",
      imagem_principal: 0,
    },

    validationSchema: Yup.object({
      nome: Yup.string().required(MANDATORY_FIELD),
      email: Yup.string(),
      url_site: Yup.string().nullable().optional(),
      descritivo: Yup.string().required(MANDATORY_FIELD),
      images: Yup.array(Yup.string()),
      url_video: Yup.string().nullable().optional(),
      telefones: Yup.string(),
      cidade: Yup.string().required(MANDATORY_FIELD),
      estado: Yup.string(),
      logradouro: Yup.string(),
      numero: Yup.string(),
      bairro: Yup.string(),
      whatsapp: Yup.string().nullable().optional(),
      instagram: Yup.string().nullable().optional(),
      latitude: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      longitude: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      // .required(MANDATORY_FIELD),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (values.images.length < 1) {
        showNofity.error("Selecione uma imagem");
        return;
      }

      values.whatsapp = Number(unformatPhone(values.whatsapp));

      values.latitude = values.latitude ? Number(values.latitude) : 0.0;
      values.longitude = values.longitude ? Number(values.longitude) : 0.0;

      dispatch(createTouristReceptionRequest({ values, resetForm }));
    },
  });

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Formulário de turismo receptivo"
            pageTitle="Turismo receptivo"
          />
        </Container>

        <Col lg={12}>
          <Card>
            <div className="p-4">
              <button
                className="btn btn-primary"
                onClick={() => history.push("tourist-reception?page=1")}
              >
                Voltar
              </button>
            </div>

            <CardBody>
              <div className="live-preview">
                <Form
                  className="needs-validation"
                  onSubmit={(e) => {
                    e.preventDefault();
                    touristReception.handleSubmit();
                    return false;
                  }}
                  action="#"
                >
                  <Row className="g-3">
                    <TextField
                      label="Nome"
                      name="nome"
                      data={touristReception}
                    />

                    <TextField
                      label="Email"
                      name="email"
                      data={touristReception}
                    />

                    <TextField
                      label="Url do site"
                      name="url_site"
                      data={touristReception}
                    />

                    <TextField
                      label="Url do vídeo"
                      name="url_video"
                      data={touristReception}
                    />

                    <InputMask
                      type="phone-br"
                      label="Telefone"
                      name="telefones"
                      data={touristReception}
                      size={3}
                    />

                    <InputMask
                      type="phone-br"
                      label="Whatsapp"
                      name="whatsapp"
                      data={touristReception}
                      size={3}
                    />

                    <TextField
                      label="Instagram"
                      name="instagram"
                      data={touristReception}
                    />

                    <TextField
                      label="Logradouro"
                      name="logradouro"
                      data={touristReception}
                    />

                    <TextField
                      label="Número"
                      name="numero"
                      data={touristReception}
                      size={2}
                    />

                    <TextField
                      label="Cidade"
                      name="cidade"
                      type="select"
                      data={touristReception}
                      size={4}
                    >
                      <option value="" disabled selected>
                        Selecione uma cidade
                      </option>
                      {dataCity?.result?.length > 0 &&
                        dataCity?.result.map((item, index) => (
                          <option key={index} value={item.objectId}>
                            {item.nome}
                          </option>
                        ))}
                    </TextField>

                    <TextField
                      label="Estado"
                      name="estado"
                      type="select"
                      data={touristReception}
                      size={2}
                    >
                      <option value="" disabled selected>
                        Selecione um estado
                      </option>

                      {states.map(({ sigla, id_uf }) => (
                        <option key={id_uf} value={sigla}>
                          {sigla}
                        </option>
                      ))}
                    </TextField>

                    <TextField
                      label="Bairro"
                      name="bairro"
                      data={touristReception}
                      size={4}
                    />

                    <TextField
                      label="Latitude"
                      name="latitude"
                      data={touristReception}
                      type="number"
                      onKeyDown={(event) => {
                        ["e", "E", "+", ","].includes(event.key) &&
                          event.preventDefault();
                      }}
                      size={3}
                    />
                    <TextField
                      label="Longitude"
                      name="longitude"
                      data={touristReception}
                      type="number"
                      onKeyDown={(event) => {
                        ["e", "E", "+", ","].includes(event.key) &&
                          event.preventDefault();
                      }}
                      size={3}
                    />

                    <TextField
                      label="Descrição"
                      name="descritivo"
                      type="textarea"
                      data={touristReception}
                      size={12}
                    />

                    <CoverUploadImageField
                      title="Imagens do local"
                      multiple
                      data={touristReception}
                      name="images"
                    />

                    <Col lg={12}>
                      <div className="text-end">
                        <button type="submit" className="btn btn-primary">
                          Salvar
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>
      <ModalLoding loading={loading} />
    </React.Fragment>
  );
};

export default TouristReceptionForm;
