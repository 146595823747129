export const GET_FILTERS_SERVICE_REQUEST = "GET_FILTERS_SERVICE_REQUEST";
export const GET_FILTERS_SERVICE_SUCCESS = "GET_FILTERS_SERVICE_SUCCESS";
export const GET_FILTERS_SERVICE_FAILURE = "GET_FILTERS_SERVICE_FAILURE";

export const CREATE_FILTERS_SERVICE_REQUEST = "CREATE_FILTERS_SERVICE_REQUEST";
export const CREATE_FILTERS_SERVICE_SUCCESS = "CREATE_FILTERS_SERVICE_SUCCESS";
export const CREATE_FILTERS_SERVICE_FAILURE = "CREATE_FILTERS_SERVICE_FAILURE";

export const UPDATE_FILTERS_SERVICE_REQUEST = "UPDATE_FILTERS_SERVICE_REQUEST";
export const UPDATE_FILTERS_SERVICE_SUCCESS = "UPDATE_FILTERS_SERVICE_SUCCESS";
export const UPDATE_FILTERS_SERVICE_FAILURE = "UPDATE_FILTERS_SERVICE_FAILURE";

export const DELETE_FILTERS_SERVICE_REQUEST = "DELETE_FILTERS_SERVICE_REQUEST";
export const DELETE_FILTERS_SERVICE_SUCCESS = "DELETE_FILTERS_SERVICE_SUCCESS";
export const DELETE_FILTERS_SERVICE_FAILURE = "DELETE_FILTERS_SERVICE_FAILURE";
