import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";
import { getSuggestionsFailure, getSuggestionsSuccess } from "./actions";
import { GET_SUGGESTIONS_REQUEST } from "./actionTypes";

function requestSuggestions(data) {
  return post("/suggestions-by-name", { suggestionName: data });
}

function* getSuggestions({ data }) {
  try {
    const response = yield call(requestSuggestions, data);
    yield put(getSuggestionsSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar sugestões. " + error);
    yield put(getSuggestionsFailure());
  }
}

function* saga() {
  yield takeLatest(GET_SUGGESTIONS_REQUEST, getSuggestions);
}

export default saga;
