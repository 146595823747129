import {
  GET_FILTER_SERVICE_FAILURE,
  GET_FILTER_SERVICE_REQUEST,
  GET_FILTER_SERVICE_SUCCESS,
} from "./actionTypes";

export const getFilterServiceRequest = (data) => {
  return {
    type: GET_FILTER_SERVICE_REQUEST,
    data,
  };
};

export const getFilterServiceSuccess = (data) => {
  return {
    type: GET_FILTER_SERVICE_SUCCESS,
    data,
  };
};

export const getFilterServiceFailure = () => {
  return {
    type: GET_FILTER_SERVICE_FAILURE,
  };
};
