import { all, call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import {
  createRestaurantFailure,
  createRestaurantSuccess,
  deleteRestaurantPhotoFailure,
  getRestaurantFailure,
  getRestaurantsFailure,
  getRestaurantsSuccess,
  getRestaurantSuccess,
  updateRestaurantFailure,
  updateRestaurantPhotoFailure,
} from "./actions";
import {
  CREATE_RESTAURANT_REQUEST,
  DELETE_RESTAURANT_PHOTO_REQUEST,
  DELETE_RESTAURANT_REQUEST,
  GET_RESTAURANTS_REQUEST,
  GET_RESTAURANT_REQUEST,
  UPDATE_RESTAURANT_PHOTO_REQUEST,
  UPDATE_RESTAURANT_REQUEST,
} from "./actionTypes";

import showNofity from "../../helpers/toast";

function requestRestaurants(data) {
  return post("/restaurants-by-name", { restaurantName: data });
}

function requestRestaurant(id) {
  return post("/restaurant", { restaurantId: id });
}

function deleteRestaurantById(id) {
  return post("/restaurant-remove", { restaurantId: id });
}

function saveImage(imgs) {
  return post("/media-upload", { imgs });
}

function newRestaurant(restaurant) {
  return post("/restaurants", restaurant);
}

function deletePhotoRestaurant({ photoId, restaurantId }) {
  return post("/photo-restaurant-remove", {
    restaurantId: restaurantId,
    photoId: photoId,
  });
}

function requestUpdateRestaurant(id, restaurant) {
  const {
    nome,
    telefones,
    logradouro,
    numero,
    bairro,
    cidade,
    estado,
    gps,
    url_video,
    whatsapp,
    instagram,
    email,
    media_preco,
    url_site,
    descritivo,
    // tipo_culinaria,
    filtro_onde_comer,
  } = restaurant;

  return post("/restaurant-update", {
    restaurantId: id,
    nome,
    telefones,
    logradouro,
    numero,
    bairro,
    cidade,
    estado,
    gps,
    url_video,
    whatsapp,
    instagram,
    email,
    media_preco,
    url_site,
    descritivo,
    // tipo_culinaria,
    filtro_onde_comer,
  });
}

function requestUpdateRestaurantPhoto({ restaurantId, imagem }) {
  return post("/photo-restaurant-select", {
    restaurantId: restaurantId,
    imagem: imagem,
  });
}

function saveFotos(img, idRestaurant) {
  return post("/photos-restaurant", {
    url: img.url,
    imagem: {
      __type: "File",
      name: img.name,
      url: img.url,
    },
    onde_comer: {
      __type: "Pointer",
      className: "OndeComer",
      objectId: idRestaurant,
    },
  });
}

function* getRestaurant({ data }) {
  try {
    const response = yield call(requestRestaurant, data);
    yield put(getRestaurantSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar o local de alimentação." + error);
    yield put(getRestaurantFailure());
  }
}

function* getRestaurants({ data }) {
  try {
    const response = yield call(requestRestaurants, data);
    yield put(getRestaurantsSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar local de alimentação.");
    yield put(getRestaurantsFailure());
  }
}

function* deleteRestaurant({ data }) {
  try {
    yield call(deleteRestaurantById, data.restaurantId);
    data.closeModal();
    const response = yield call(requestRestaurants);
    yield put(getRestaurantsSuccess(response.data));
    showNofity.success("Local de alimentação removido com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao remover o local de alimentação. " + error);
    yield put(getRestaurantFailure());
  }
}

function* createRestaurant({ data }) {
  try {
    const {
      nome,
      telefones,
      logradouro,
      numero,
      bairro,
      cidade,
      estado,
      latitude,
      longitude,
      images,
      url_video,
      whatsapp,
      instagram,
      email,
      media_preco,
      url_site,
      descritivo,
      // tipo_culinaria,
      imagem_principal,
      filtro_onde_comer,
    } = data.values;

    const responseImages = yield call(saveImage, images);
    const responseRestaurant = yield call(newRestaurant, {
      nome,
      telefones,
      logradouro,
      numero,
      bairro,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      estado,
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
      filtro_onde_comer: {
        __type: "Pointer",
        className: "FiltrosOndeComer",
        objectId: filtro_onde_comer,
      },
      url_video,
      whatsapp,
      instagram,
      email,
      media_preco,
      url_site,
      descritivo,
      // tipo_culinaria,
      imagem: {
        __type: "File",
        url: responseImages.data[imagem_principal].url,
        name: responseImages.data[imagem_principal].name,
      },
    });

    yield all(
      responseImages.data.map((img) =>
        call(saveFotos, img, responseRestaurant.data.objectId)
      )
    );

    yield put(createRestaurantSuccess());
    showNofity.success("Local de alimentação cadastrado com sucesso.");
    data.resetForm();
  } catch (error) {
    showNofity.error("Erro ao cadastrar local de alimentação. " + error);
    yield put(createRestaurantFailure());
  }
}

function* updateRestaurant({ data }) {
  try {
    const {
      nome,
      telefones,
      logradouro,
      numero,
      bairro,
      cidade,
      estado,
      latitude,
      longitude,
      images,
      url_video,
      whatsapp,
      instagram,
      email,
      media_preco,
      url_site,
      descritivo,
      // tipo_culinaria,
      filtro_onde_comer,
    } = data.values;

    if (images?.length > 0) {
      const responseImages = yield call(saveImage, images);
      yield all(
        responseImages.data.map((img) => call(saveFotos, img, data.id))
      );
    }

    yield call(requestUpdateRestaurant, data.id, {
      nome,
      telefones,
      logradouro,
      numero,
      bairro,
      cidade: {
        __type: "Pointer",
        className: "Cidade",
        objectId: cidade,
      },
      estado,
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
      filtro_onde_comer: {
        __type: "Pointer",
        className: "FiltrosOndeComer",
        objectId: filtro_onde_comer,
      },
      url_video,
      whatsapp,
      instagram,
      email,
      media_preco,
      url_site,
      descritivo,
      // tipo_culinaria,
    });

    const response = yield call(requestRestaurant, data.id);
    yield put(getRestaurantSuccess(response.data));
    data.onSucess();
    showNofity.success("Local de alimentação atualizado com sucesso.");
  } catch (error) {
    yield put(updateRestaurantFailure());
    showNofity.error("Erro ao atualizar local de alimentação.");
  }
}

function* updateImageRestaurant({ data }) {
  try {
    yield call(requestUpdateRestaurantPhoto, {
      restaurantId: data.restaurantId,
      imagem: data.imagem,
    });

    const responseRestaurant = yield call(requestRestaurant, data.restaurantId);
    showNofity.success("Imagem atualizada com sucesso.");

    yield put(getRestaurantSuccess(responseRestaurant.data));
  } catch (error) {
    showNofity.error(
      "Erro ao atualizar foto do local de alimentação. " + error
    );
    put(updateRestaurantPhotoFailure());
  }
}

function* deleteImageRestaurant({ data }) {
  try {
    yield call(deletePhotoRestaurant, data);
    showNofity.success("Imagem removida com sucesso.");
    const response = yield call(requestRestaurant, data.restaurantId);
    yield put(getRestaurantSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao remover foto do local de alimentação. " + error);
    put(deleteRestaurantPhotoFailure());
  }
}

function* saga() {
  yield takeLatest(GET_RESTAURANTS_REQUEST, getRestaurants);
  yield takeLatest(GET_RESTAURANT_REQUEST, getRestaurant);
  yield takeLatest(DELETE_RESTAURANT_REQUEST, deleteRestaurant);
  yield takeLatest(DELETE_RESTAURANT_PHOTO_REQUEST, deleteImageRestaurant);
  yield takeLatest(CREATE_RESTAURANT_REQUEST, createRestaurant);
  yield takeLatest(UPDATE_RESTAURANT_REQUEST, updateRestaurant);
  yield takeLatest(UPDATE_RESTAURANT_PHOTO_REQUEST, updateImageRestaurant);
}

export default saga;
