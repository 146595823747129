import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getCircuitsRequest } from "../../store/circuits/actions";
import { CircuitTable } from "./CircuitTable";

const CircuitList = () => {
  document.title = "Circuitos | Ecoguias - Admin Dashboard";
  const history = useHistory();

  const dispatch = useDispatch();

  const { loading, data } = useSelector((state) => state.Circuit);

  const [searchInput, setSearchInput] = useState("");
  const [value] = useDebounce(searchInput, 500);

  useEffect(() => {
    dispatch(getCircuitsRequest(value));
  }, [dispatch, value]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Tabela de circuitos" pageTitle="Circuitos" />
          <div className="d-flex justify-content-between pb-4 flex-wrap">
            <Input
              type="text"
              style={{ maxWidth: 250 }}
              className="form-control"
              placeholder="Pesquisar..."
              id="search-options"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={() => history.push("/circuits-form")}
            >
              Cadastrar
            </button>
          </div>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Circuitos</h5>
                </CardHeader>
                <CardBody>
                  <CircuitTable
                    style={{ width: "100%" }}
                    circuits={data?.result ?? []}
                    loading={loading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CircuitList;
