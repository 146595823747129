import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Row } from "reactstrap";
import Loading from "../../Components/Loading/Loading";
import ModalCustom from "../../Components/Modals/ModalCustom";
import { getSuggestionRequest } from "../../store/suggestions/suggestionsDetails/actions";
import SuggestionsDetails from "./SuggestionsDetails";

function SuggestionsTable({ suggestions, loading }) {
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();

  const { loading: loadingDetails, data: dataDetails } = useSelector(
    (state) => state.SuggestionsDetails
  );

  if (loading) {
    return <Loading />;
  }

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Nome</span>,
      selector: (row) => row.nome_do_lugar || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Referência</span>,
      selector: (row) => row.ponto_de_referencia || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Estado</span>,
      selector: (row) => row.estado || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Cidade</span>,
      selector: (row) => row.cidade || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Informação Extra</span>,
      selector: (row) => row.alguma_informacao_extra || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Ações</span>,
      width: "10%",
      right: true,
      selector: (row) => (
        <div className="hstack gap-3 flex-wrap">
          <Link
            to="#"
            className="link-success fs-15"
            onClick={() => {
              dispatch(getSuggestionRequest(row.objectId));
              setModal(true);
            }}
          >
            <i className="mdi mdi-eye"></i>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <ToastContainer />
        <DataTable
          columns={columns}
          data={suggestions}
          pagination
          noDataComponent="Não existem dados na tabela de sugestões!"
        />

        <ModalCustom
          isOpen={modal}
          labelConfirm="OK"
          onClick={() => setModal(false)}
          title="Detalhes da sugestão"
        >
          <>
            {loadingDetails ? (
              <Loading />
            ) : (
              <SuggestionsDetails data={dataDetails} />
            )}
          </>
        </ModalCustom>
      </Row>
    </React.Fragment>
  );
}

export { SuggestionsTable };
