export const GET_STORES_REQUEST = "GET_STORES_REQUEST";
export const GET_STORES_SUCCESS = "GET_STORES_SUCCESS";
export const GET_STORES_FAILURE = "GET_STORES_FAILURE";

export const GET_STORE_REQUEST = "GET_STORE_REQUEST";
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS";
export const GET_STORE_FAILURE = "GET_STORE_FAILURE";

export const CREATE_STORE_REQUEST = "CREATE_STORE_REQUEST";
export const CREATE_STORE_SUCCESS = "CREATE_STORE_SUCCESS";
export const CREATE_STORE_FAILURE = "CREATE_STORE_FAILURE";

export const DELETE_STORE_REQUEST = "DELETE_STORE_REQUEST";
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";
export const DELETE_STORE_FAILURE = "DELETE_STORE_FAILURE";

export const DELETE_STORE_PHOTO_REQUEST = "DELETE_STORE_PHOTO_REQUEST";
export const DELETE_STORE_PHOTO_SUCCESS = "DELETE_STORE_PHOTO_SUCCESS";
export const DELETE_STORE_PHOTO_FAILURE = "DELETE_STORE_PHOTO_FAILURE";

export const UPDATE_STORE_REQUEST = "UPDATE_STORE_REQUEST";
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS";
export const UPDATE_STORE_FAILURE = "UPDATE_STORE_FAILURE";

export const UPDATE_STORE_PHOTO_REQUEST = "UPDATE_STORE_PHOTO_REQUEST";
export const UPDATE_STORE_PHOTO_SUCCESS = "UPDATE_STORE_PHOTO_SUCCESS";
export const UPDATE_STORE_PHOTO_FAILURE = "UPDATE_STORE_FAILURE";
