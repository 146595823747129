import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardColumns, Row } from "reactstrap";
import ModalLoding from "../../Components/Common/ModalLoading";

import { useDispatch } from "react-redux";
import noCover from "../../assets/images/No_cover.jpeg";

import { deleteCityRequest } from "../../store/city/actions";
import { deleteHotelRequest } from "../../store/hotels/actions";
import { deleteRestaurantRequest } from "../../store/restaurants/actions";
import { deleteStoreRequest } from "../../store/stores/actions";
import { deleteTouristReceptionRequest } from "../../store/touristReceptions/actions";
import { deleteTouristSpotRequest } from "../../store/touristSpots/actions";
import { deleteWaterfallRequest } from "../../store/waterfall/actions";
import Modal from "../Common/Modal";
import Loading from "../Loading/Loading";
import PlaceMenu from "./PlaceMenu";

const PlaceList = ({ data, loading, pathPaginationName }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [dispatchDelete, setDispatchDelete] = useState(false);
  const [placeId, setPlaceId] = useState(null);

  function renderNoData() {
    if (data?.length === 0 && !loading) {
      return (
        <p
          style={{
            marginTop: 200,
            fontWeight: "normal",
            color: "#999",
            fontSize: "0.8rem",
          }}
        >
          Nenhum registro encontrado
        </p>
      );
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="text-center">{renderNoData()}</div>

      <Row>
        {data?.length > 0 &&
          data?.map((item) => {
            return (
              <CardColumns
                key={item.objectId}
                className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2"
              >
                <Card>
                  <PlaceMenu
                    remove={() => {
                      setDispatchDelete(true);
                      setPlaceId(item.objectId);
                    }}
                    edit={() => {
                      switch (pathPaginationName) {
                        case "/cities":
                          history.push("/cities-edit/" + item.objectId);
                          break;
                        case "/waterfalls":
                          history.push("/waterfalls-edit/" + item.objectId);
                          break;
                        case "/restaurants":
                          history.push("/restaurants-edit/" + item.objectId);
                          break;
                        case "/hotels":
                          history.push("/hotels-edit/" + item.objectId);
                          break;
                        case "/tourist-spots":
                          history.push("/tourist-spots-edit/" + item.objectId);
                          break;
                        case "/tourist-reception":
                          history.push(
                            "/tourist-reception-edit/" + item.objectId
                          );
                          break;
                        case "/stores":
                          history.push("/stores-edit/" + item.objectId);
                          break;
                        default:
                          break;
                      }
                    }}
                  />

                  <img
                    style={{ objectFit: "cover", height: 180 }}
                    className="card-img-top img-fluid"
                    src={item?.imagem?.url ? item.imagem.url : noCover}
                    alt=""
                  />
                  <CardBody style={{ height: 120 }}>
                    <h4 className="card-title mb-2">{item.nome}</h4>
                    <p className="card-text mb-0">
                      {item?.descritivo?.substr(0, 60)}...
                    </p>
                  </CardBody>
                  <div className="card-footer">
                    <div className="d-flex flex-wrap gap-3">
                      <Link
                        to={`${pathPaginationName}/${item.objectId}`}
                        className="card-link link-secondary"
                      >
                        Detalhes
                        <i className="ri-arrow-right-s-line ms-1 align-middle lh-1"></i>
                      </Link>
                    </div>
                  </div>
                </Card>
              </CardColumns>
            );
          })}
      </Row>

      <Modal
        loading={loading}
        isOpen={dispatchDelete}
        text="Deseja excluir o item selecionado?"
        onCancel={() => {
          setDispatchDelete(false);
        }}
        onClick={() => {
          function closeModal() {
            setDispatchDelete(false);
          }

          switch (pathPaginationName) {
            case "/cities":
              dispatch(deleteCityRequest({ cityId: placeId, closeModal }));
              break;
            case "/waterfalls":
              dispatch(
                deleteWaterfallRequest({ waterfallId: placeId, closeModal })
              );
              break;
            case "/restaurants":
              dispatch(
                deleteRestaurantRequest({ restaurantId: placeId, closeModal })
              );
              break;
            case "/hotels":
              dispatch(deleteHotelRequest({ hotelId: placeId, closeModal }));
              break;
            case "/tourist-spots":
              dispatch(
                deleteTouristSpotRequest({ touristSpotId: placeId, closeModal })
              );
              break;
            case "/tourist-reception":
              dispatch(
                deleteTouristReceptionRequest({
                  touristReceptionId: placeId,
                  closeModal,
                })
              );
              break;
            case "/stores":
              dispatch(deleteStoreRequest({ storeId: placeId, closeModal }));
              break;

            default:
              break;
          }
        }}
      />
      <ModalLoding loading={loading} />
    </React.Fragment>
  );
};

export default PlaceList;
