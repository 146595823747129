import React from "react";

function SuggestionsDetails({ data }) {
  return (
    <div className="d-flex flex-wrap flex-column">
      <div>
        <label>Nome: </label> <span>{data?.nome_do_lugar}</span>
      </div>
      <div>
        <label>Referência: </label> <span>{data?.ponto_de_referencia}</span>
      </div>
      <div>
        <label>Estado: </label> <span>{data?.estado}</span>
      </div>
      <div>
        <label>Cidade: </label> <span>{data?.cidade}</span>
      </div>
      <div>
        <label>Informação Extra: </label>{" "}
        <span>{data?.alguma_informacao_extra}</span>
      </div>
    </div>
  );
}

export default SuggestionsDetails;
