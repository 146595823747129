import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Row } from "reactstrap";
import Modal from "../../Components/Common/Modal";
import Loading from "../../Components/Loading/Loading";
import { deleteCircuitRequest } from "../../store/circuits/actions";

function CircuitTable({ circuits, loading }) {
  const [modal, setModal] = useState(false);
  const [idEventSelected, setIdEventSelected] = useState(null);
  const dispatch = useDispatch();

  if (loading) {
    return <Loading />;
  }

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Título</span>,
      selector: (row) => row.titulo || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Subtítulo</span>,
      selector: (row) => row.sub_titulo || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Distância asfalto</span>,
      selector: (row) => row.composicao_asfalto_distancia || "N/A",
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-13">Distância estrada terra</span>
      ),
      selector: (row) => row.composicao_terra_distancia || "N/A",
      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13">Ações</span>,
      right: true,
      selector: (row) => (
        <div className="hstack gap-3 flex-wrap">
          <Link
            to={`/circuits-form/${row.objectId}`}
            className="link-success fs-15"
          >
            <i className="ri-edit-2-line"></i>
          </Link>
          <Link
            to="#"
            className="link-danger fs-15"
            onClick={(event) => {
              event.preventDefault();
              setIdEventSelected(row.objectId);
              setModal(true);
            }}
          >
            <i className="ri-delete-bin-line"></i>
          </Link>
        </div>
      ),
    },
  ];

  function deleteCircuit() {
    dispatch(
      deleteCircuitRequest({
        circuitId: idEventSelected,
        closeModal: () => {
          setIdEventSelected(null);
          setModal(false);
        },
      })
    );
  }

  return (
    <React.Fragment>
      <Row>
        <ToastContainer />
        <DataTable columns={columns} data={circuits ?? []} pagination />
      </Row>

      <Modal
        size="md"
        text="Tem certeza que deseja excluir esse circuito?"
        isOpen={modal}
        onClick={deleteCircuit}
        onCancel={() => {
          setModal(false);
          setIdEventSelected(null);
        }}
      />
    </React.Fragment>
  );
}

export { CircuitTable };
