import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "../../boot/api";
import showNofity from "../../helpers/toast";
import { getTypesServiceFailure, getTypesServiceSuccess } from "./actions";
import { GET_TYPES_SERVICE_REQUEST } from "./actionTypes";

function requestTypesService() {
  return get("/types-service");
}

function* getTypesService() {
  try {
    const response = yield call(requestTypesService);
    yield put(getTypesServiceSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar os tipos de serviço. " + error);
    yield put(getTypesServiceFailure());
  }
}

function* saga() {
  yield takeLatest(GET_TYPES_SERVICE_REQUEST, getTypesService);
}

export default saga;
