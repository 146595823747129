import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Card, CardBody, Col, Form, Row } from "reactstrap";
import ModalLoding from "../../Components/Common/ModalLoading";

import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { MANDATORY_FIELD } from "../../Components/constants/messages";
import { InputMask, TextField } from "../../Components/Forms";
import { getCitiesRequest } from "../../store/city/actions";
import { getFiltersServiceRequest } from "../../store/filters/service/actions";
import {
  createServiceRequest,
  getServiceRequest,
  updateServiceRequest,
} from "../../store/services/actions";
import { getTypesServiceRequest } from "../../store/typeService/actions";
import { isLatitude, isLongitude } from "../../utils/formats";
import states from "../../utils/states.json";

const ServiceFormComponente = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { loading, data: dataService } = useSelector((state) => state.Services);
  const { loading: loadingTypesService, data: dataTypesService } = useSelector(
    (state) => state.TypesService
  );
  const { data: dataFilter, loading: loadingFilter } = useSelector(
    (state) => state.FiltersService
  );

  const { data, loading: loadingCity } = useSelector((state) => state.City);

  const history = useHistory();

  useEffect(() => {
    dispatch(getCitiesRequest());
    dispatch(getTypesServiceRequest());
    dispatch(getFiltersServiceRequest());
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      dispatch(getServiceRequest(params.id));
    }
  }, [params.id, dispatch]);

  const service = useFormik({
    enableReinitialize: true,
    initialValues: {
      nome: dataService?.nome ?? "",
      telefone: dataService?.telefone ?? "",
      estado: dataService?.estado ?? "",
      cidade: dataService?.cidade?.objectId ?? "",
      rua: dataService?.rua ?? "",
      bairro: dataService?.bairro ?? "",
      numero: dataService?.numero ?? "",
      // complemento: dataService?.complemento ?? "",
      whatsapp: dataService?.whatsapp ?? "",
      // site: dataService?.site ?? "",
      tipo_servico: dataService?.tipo_servico?.objectId ?? "",
      latitude: dataService?.gps?.latitude ?? "",
      longitude: dataService?.gps?.longitude ?? "",
      filtro_servico: dataService?.filtro_servico ?? "",
    },

    validationSchema: Yup.object({
      nome: Yup.string("O nome deve ser do tipo texto").required(
        "O nome do serviço é obrigatório"
      ),
      telefone: Yup.string("O telefone deve ser do tipo texto"),
      rua: Yup.string("A rua deve ser do tipo texto"),
      cidade: Yup.string("A cidade deve ser do tipo texto").required(
        "A cidade é obrigatória"
      ),
      bairro: Yup.string("O distrito deve ser do tipo texto"),
      estado: Yup.string("O estado deve ser do tipo texto"),
      numero: Yup.string("O número deve ser do tipo texto"),
      // complemento: Yup.string("O compemento deve ser do tipo texto").required(
      //   "O complemento é obrigatório"
      // ),
      whatsapp: Yup.string("O whatsapp deve ser do tipo texto"),
      // site: Yup.string("O site deve ser do tipo texto").required(
      //   "O site é obrigatório"
      // ),
      tipo_servico: Yup.string("O tipo deve ser do tipo texto"),
      latitude: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      longitude: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      filtro_servico: Yup.string().required(
        "O filtro de serviço é obrigatório"
      ),
    }),

    onSubmit: (values, { resetForm }) => {
      values.latitude = values.latitude ? Number(values.latitude) : 0.0;
      values.longitude = values.longitude ? Number(values.longitude) : 0.0;

      if (params.id) {
        dispatch(updateServiceRequest({ id: params.id, values }));
      } else {
        dispatch(createServiceRequest({ values, resetForm }));
      }
    },
  });

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <div className="p-4">
            <button
              className="btn btn-primary"
              onClick={() => history.goBack()}
            >
              Voltar
            </button>
          </div>
          <CardBody>
            <div className="live-preview">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  service.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Row className="g-3">
                  <TextField
                    label="Nome"
                    name="nome"
                    data={service}
                    size={12}
                  />

                  <InputMask
                    type="phone-br"
                    label="Telefone"
                    name="telefone"
                    data={service}
                  />

                  <InputMask
                    type="phone-br"
                    label="Whatsapp"
                    name="whatsapp"
                    data={service}
                    size={6}
                  />

                  <TextField label="Rua" name="rua" data={service} />

                  <TextField
                    label="Bairro"
                    name="bairro"
                    data={service}
                    size={6}
                  />

                  <TextField
                    type="select"
                    label="Cidade"
                    name="cidade"
                    data={service}
                  >
                    <option value="" selected disabled>
                      Selecione a cidade do serviço
                    </option>
                    {data?.result?.length > 0 &&
                      data?.result.map((item, index) => (
                        <option key={index} value={item.objectId}>
                          {item.nome}
                        </option>
                      ))}
                  </TextField>

                  <TextField
                    type="select"
                    label="Estado"
                    name="estado"
                    data={service}
                    size={3}
                  >
                    <option value="" selected disabled>
                      Selecione um estado
                    </option>

                    {states.map(({ sigla, id_uf }) => (
                      <option key={id_uf} value={sigla}>
                        {sigla}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    label="Número"
                    name="numero"
                    data={service}
                    size={3}
                  />

                  {/* <TextField
                    label="Complemento"
                    name="complemento"
                    data={service}
                    size={6}
                  /> */}

                  {/* <TextField label="Site" name="site" data={service} /> */}

                  <TextField
                    type="select"
                    label="Tipo"
                    name="tipo_servico"
                    data={service}
                    size={6}
                  >
                    <option value="" selected disabled>
                      Selecione o tipo do serviço
                    </option>
                    {dataTypesService?.results?.length > 0 &&
                      dataTypesService?.results?.map((item, idx) => (
                        <option key={idx} value={item.objectId}>
                          {item.nome}
                        </option>
                      ))}
                  </TextField>

                  <TextField
                    type="select"
                    label="Filtro"
                    name="filtro_servico"
                    data={service}
                    size={6}
                  >
                    <option value="" selected disabled>
                      Selecione o filtro
                    </option>
                    {dataFilter?.result?.length > 0 &&
                      dataFilter?.result?.map((item, idx) => (
                        <option key={idx} value={item.objectId}>
                          {item.nome}
                        </option>
                      ))}
                  </TextField>

                  <TextField
                    label="Latitude"
                    name="latitude"
                    data={service}
                    type="number"
                    size={3}
                    onKeyDown={(event) => {
                      ["e", "E", "+", ","].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <TextField
                    label="Longitude"
                    name="longitude"
                    data={service}
                    type="number"
                    size={3}
                    onKeyDown={(event) => {
                      ["e", "E", "+", ","].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <Col lg={12}>
                    <div className="text-end">
                      <button type="submit" className="btn btn-primary">
                        Salvar
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
      <ModalLoding loading={loading} />
    </Row>
  );
};

export default ServiceFormComponente;
