import React from "react";
import { Redirect } from "react-router-dom";

// Suggestions
import SuggestionsList from "../pages/Suggestions/SuggestionsList";

// Passport
import PassportList from "../pages/Passport/PassportList";

// Rates
import RatesList from "../pages/Rates/RatesList";

// Services
import ServiceForm from "../pages/Services/ServiceForm";
import ServiceList from "../pages/Services/ServiceList";

// Certificate Request
import CertificateRequestList from "../pages/CertificateRequest/CertificateRequestList";

// Users
import UserForm from "../pages/User/UserForm";
import UserList from "../pages/User/UserList";

// Events
import EventForm from "../pages/Events/EventForm";
import EventFormEdit from "../pages/Events/EventFormEdit";
import EventList from "../pages/Events/EventList";

// Places
import CityDetails from "../pages/Cities/CityDetails";
import CityForm from "../pages/Cities/CityForm";
import CityFormEdit from "../pages/Cities/CityFormEdit";
import CityList from "../pages/Cities/CityList";

// Circuits
import CircuitForm from "../pages/Circuits/CircuitForm";
import CircuitFormEdit from "../pages/Circuits/CircuitFormEdit";
import CircuitList from "../pages/Circuits/CircuitList";

//ContactsUS
import ContactsUsList from "../pages/ContactsUs/ContactsUsList";

//Waterfalls
import WaterfallDetails from "../pages/Waterfalls/WaterfallDetails";
import WaterfallForm from "../pages/Waterfalls/WaterFallForm";
import WaterFallFormEdit from "../pages/Waterfalls/WaterFallFormEdit";
import WaterfallList from "../pages/Waterfalls/WaterFallList";

//Restaurants
import RestaurantDetails from "../pages/Restaurants/RestaurantDetails";
import RestaurantForm from "../pages/Restaurants/RestaurantForm";
import RestaurantList from "../pages/Restaurants/RestaurantList";

//Hotels
import HotelDetails from "../pages/Hotels/HotelDetails";
import HotelForm from "../pages/Hotels/HotelForm";
import HotelFormEdit from "../pages/Hotels/HotelFormEdit";
import HotelList from "../pages/Hotels/HotelList";

//Tourist Spots
import TouristSpotPhotosUserList from "../pages/TouristSpotPhotosUser/TouristSpotPhotosUserList";
import TouristSpotDetails from "../pages/TouristSpots/TouristSpotDetails";
import TouristSpotForm from "../pages/TouristSpots/TouristSpotForm";
import TouristSpotFormEdit from "../pages/TouristSpots/TouristSpotFormEdit";
import TouristSpotList from "../pages/TouristSpots/TouristSpotList";

//Tourist reception
import TouristReceptionDetails from "../pages/TouristReceptions/TouristReceptionDetails";
import TouristReceptionForm from "../pages/TouristReceptions/TouristReceptionForm";
import TouristReceptionFormEdit from "../pages/TouristReceptions/TouristReceptionFormEdit";
import TouristReceptionList from "../pages/TouristReceptions/TouristReceptionList";

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import RestaurantFormEdit from "../pages/Restaurants/RestaurantFormEdit";
import StoreDetails from "../pages/Stores/StoreDetails";
import StoreForm from "../pages/Stores/StoreForm";
import StoreFormEdit from "../pages/Stores/StoreFormEdit";
import StoreList from "../pages/Stores/StoreList";

//Filters
import FiltersServiceList from "../pages/FiltersService/FiltersServiceList";
import FiltersTouristSpotList from "../pages/FiltersTouristSpot/FiltersTouristSpotList";
import FiltersWhereEatList from "../pages/FiltersWhereEat/FiltersWhereEatList";
import FiltersWhereStayList from "../pages/FiltersWhereStay/FiltersWhereStayList";
import ProfilePage from "../pages/Profile";

import DashboardAnalytics from '../pages/DashboardAnalytics';

const authProtectedRoutes = [
  // Suggestions
  { path: "/suggestions", component: SuggestionsList },

  // Passport
  { path: "/passport", component: PassportList },

  // ContactsUs
  { path: "/contacts-us", component: ContactsUsList },

  // Rates
  { path: "/rates", component: RatesList },

  // Services
  { path: "/services", component: ServiceList },
  { path: "/services-form", component: ServiceForm },
  { path: "/services-form/:id", component: ServiceForm },

  // Certificate Request
  { path: "/certificate-requests", component: CertificateRequestList },

  // Users
  { path: "/users", component: UserList },
  { path: "/users-form", component: UserForm },
  { path: "/users-form/:id", component: UserForm },

  // Circuits
  { path: "/circuits", component: CircuitList },
  { path: "/circuits-form", component: CircuitForm },
  { path: "/circuits-form/:id", component: CircuitFormEdit },

  // Events
  { path: "/events", component: EventList },
  { path: "/events-form", component: EventForm },
  { path: "/events-edit/:id", component: EventFormEdit },

  //Places
  { path: "/cities", component: CityList },
  { path: "/cities/:id", component: CityDetails },
  { path: "/cities-form", component: CityForm },
  { path: "/cities-edit/:id", component: CityFormEdit },

  //Waterfall
  { path: "/waterfalls", component: WaterfallList },
  { path: "/waterfalls/:id", component: WaterfallDetails },
  { path: "/waterfalls-form", component: WaterfallForm },
  { path: "/waterfalls-edit/:id", component: WaterFallFormEdit },

  //Restaurants
  { path: "/restaurants", component: RestaurantList },
  { path: "/restaurants-form", component: RestaurantForm },
  { path: "/restaurants-edit/:id", component: RestaurantFormEdit },
  { path: "/restaurants/:id", component: RestaurantDetails },

  //Tourist spots
  { path: "/tourist-spots", component: TouristSpotList },
  { path: "/users-photos", component: TouristSpotPhotosUserList },
  { path: "/tourist-spots-form", component: TouristSpotForm },
  { path: "/tourist-spots/:id", component: TouristSpotDetails },
  { path: "/tourist-spots-edit/:id", component: TouristSpotFormEdit },

  //Tourist spots
  { path: "/tourist-reception", component: TouristReceptionList },
  { path: "/tourist-reception-form", component: TouristReceptionForm },
  { path: "/tourist-reception/:id", component: TouristReceptionDetails },
  {
    path: "/tourist-reception-edit/:id",
    component: TouristReceptionFormEdit,
  },

  //Hotels
  { path: "/hotels", component: HotelList },
  { path: "/hotels-form", component: HotelForm },
  { path: "/hotels-edit/:id", component: HotelFormEdit },
  { path: "/hotels/:id", component: HotelDetails },

  //Stores
  { path: "/stores", component: StoreList },
  { path: "/stores-form", component: StoreForm },
  { path: "/stores-edit/:id", component: StoreFormEdit },
  { path: "/stores/:id", component: StoreDetails },

  //Filtros
  //Onde comer
  { path: "/filters-where-eat", component: FiltersWhereEatList },
  //Onde ficar
  { path: "/filters-where-stay", component: FiltersWhereStayList },
  //Atrativo turístico
  { path: "/filters-tourist-spot", component: FiltersTouristSpotList },
  //Serviço
  { path: "/filters-service", component: FiltersServiceList },

  //Profile
  { path: "/profile", component: ProfilePage },

  { path: "/dashboard", component: DashboardAnalytics },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
