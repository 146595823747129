import {
  GET_CONTACTS_US_FAILURE,
  GET_CONTACTS_US_REQUEST,
  GET_CONTACTS_US_SUCCESS,
} from "./actionTypes";

export const getContactsUsRequest = (data) => {
  return {
    type: GET_CONTACTS_US_REQUEST,
    data,
  };
};

export const getContactsUsSuccess = (data) => {
  return {
    type: GET_CONTACTS_US_SUCCESS,
    data,
  };
};

export const getContactsUsFailure = () => {
  return {
    type: GET_CONTACTS_US_FAILURE,
  };
};
