import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import ModalLoding from "../../Components/Common/ModalLoading";
import * as Yup from "yup";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import states from "../../utils/states.json";

import UiContent from "../../Components/Common/UiContent";
import { MANDATORY_FIELD } from "../../Components/constants/messages";
import {
  CoverUploadImageField,
  InputMask,
  TextField,
} from "../../Components/Forms";
import Loading from "../../Components/Loading/Loading";
import showNofity from "../../helpers/toast";
import { getCitiesRequest } from "../../store/city/actions";
import { getFiltersWhereEatRequest } from "../../store/filters/whereEat/actions";
import { createRestaurantRequest } from "../../store/restaurants/actions";
import { isLatitude, isLongitude } from "../../utils/formats";

const RestaurantForm = () => {
  document.title = "Restaurante | Ecoguias - Admin Dashboard";
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.Restaurant);
  const { data: dataCity } = useSelector((state) => state.City);

  const { loading: loadingFilter, data: dataFilter } = useSelector(
    (state) => state.FiltersWhereEat
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(getCitiesRequest());
    dispatch(getFiltersWhereEatRequest());
  }, [dispatch]);

  const restaurant = useFormik({
    enableReinitialize: true,

    initialValues: {
      nome: "",
      telefones: "",
      logradouro: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      latitude: "",
      longitude: "",
      images: [],
      url_video: "",
      whatsapp: "",
      instagram: "",
      email: "",
      media_preco: "",
      url_site: "",
      descritivo: "",
      // tipo_culinaria: "",
      filtro_onde_comer: "",
      imagem_principal: 0,
    },

    validationSchema: Yup.object({
      nome: Yup.string().required(MANDATORY_FIELD),
      telefones: Yup.string(),
      logradouro: Yup.string(),
      numero: Yup.string(),
      bairro: Yup.string(),
      cidade: Yup.string().required(MANDATORY_FIELD),
      estado: Yup.string(),
      latitude: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      longitude: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      // .required(MANDATORY_FIELD),
      images: Yup.array(Yup.string()),
      url_video: Yup.string(),
      whatsapp: Yup.string(),
      instagram: Yup.string(),
      email: Yup.string(),
      media_preco: Yup.string().notRequired(),
      url_site: Yup.string(),
      descritivo: Yup.string().required(MANDATORY_FIELD),
      // tipo_culinaria: Yup.string().required(MANDATORY_FIELD),
      filtro_onde_comer: Yup.string().required(MANDATORY_FIELD),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (values.images.length < 1) {
        showNofity.error("Selecione uma imagem");
        return;
      }

      values.latitude = values.latitude ? Number(values.latitude) : 0.0;
      values.longitude = values.longitude ? Number(values.longitude) : 0.0;

      dispatch(createRestaurantRequest({ values, resetForm }));
    },
  });

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />

      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Formulário de Alimentação"
            pageTitle="Alimentação"
          />
        </Container>

        <Col lg={12}>
          <Card>
            <div className="p-4">
              <button
                className="btn btn-primary"
                onClick={() => history.push("/restaurants?page=1")}
              >
                Voltar
              </button>
            </div>

            <CardBody>
              <div className="live-preview">
                <Form
                  className="needs-validation"
                  onSubmit={(e) => {
                    e.preventDefault();
                    restaurant.handleSubmit();
                    return false;
                  }}
                  action="#"
                >
                  <Row className="g-3">
                    <TextField label="Nome" name="nome" data={restaurant} />

                    {/* <TextField
                      label="Tipo de culinária"
                      name="tipo_culinaria"
                      data={restaurant}
                    /> */}

                    <TextField
                      type="select"
                      label="Filtro"
                      name="filtro_onde_comer"
                      data={restaurant}
                      size={6}
                    >
                      <option value="" selected disabled>
                        Selecione o filtro
                      </option>
                      {dataFilter?.result?.length > 0 &&
                        dataFilter?.result?.map((item, idx) => (
                          <option key={idx} value={item.objectId}>
                            {item.nome}
                          </option>
                        ))}
                    </TextField>

                    <InputMask
                      type="phone-br"
                      label="Telefone"
                      name="telefones"
                      data={restaurant}
                    />

                    <TextField
                      label="Logradouro"
                      name="logradouro"
                      data={restaurant}
                    />

                    <TextField
                      label="Número"
                      name="numero"
                      data={restaurant}
                      size={2}
                    />
                    <TextField
                      label="Bairro"
                      size={4}
                      name="bairro"
                      data={restaurant}
                    />

                    <TextField
                      label="Cidade"
                      name="cidade"
                      type="select"
                      data={restaurant}
                      size={4}
                    >
                      <option value="" selected disabled>
                        Selecione a cidade
                      </option>
                      {dataCity?.result?.length > 0 &&
                        dataCity?.result.map((item, index) => (
                          <option key={index} value={item.objectId}>
                            {item.nome}
                          </option>
                        ))}
                    </TextField>

                    <TextField
                      label="Estado"
                      name="estado"
                      type="select"
                      data={restaurant}
                      size={2}
                    >
                      <option value="" selected disabled>
                        Selecione o estado
                      </option>

                      {states.map(({ sigla, id_uf }) => (
                        <option key={id_uf} value={sigla}>
                          {sigla}
                        </option>
                      ))}
                    </TextField>

                    <TextField
                      label="Latitude"
                      name="latitude"
                      data={restaurant}
                      type="number"
                      size={2}
                      onKeyDown={(event) => {
                        ["e", "E", "+", ","].includes(event.key) &&
                          event.preventDefault();
                      }}
                    />
                    <TextField
                      label="Longitude"
                      name="longitude"
                      data={restaurant}
                      type="number"
                      size={2}
                      onKeyDown={(event) => {
                        ["e", "E", "+", ","].includes(event.key) &&
                          event.preventDefault();
                      }}
                    />

                    <TextField
                      label="Preço médio"
                      name="media_preco"
                      type="select"
                      data={restaurant}
                      size={2}
                    >
                      <option value="" selected disabled>
                        Selecione o preço
                      </option>
                      <option value="$">$</option>
                      <option value="$$">$$</option>
                      <option value="$$$">$$$</option>
                      <option value="$$$$">$$$$$</option>
                      <option value="$$$$$$">$$$$$$</option>
                    </TextField>

                    <TextField
                      label="Vídeo do local (URL)"
                      name="url_video"
                      data={restaurant}
                    />

                    <InputMask
                      type="phone-br"
                      label="WhatsApp"
                      name="whatsapp"
                      data={restaurant}
                    />

                    <TextField
                      label="Instagram"
                      name="instagram"
                      data={restaurant}
                    />

                    <TextField label="E-mail" name="email" data={restaurant} />

                    <TextField label="Site" name="url_site" data={restaurant} />

                    <TextField
                      size={12}
                      type="textarea"
                      label="Descrição"
                      name="descritivo"
                      data={restaurant}
                    />

                    <CoverUploadImageField
                      title="Imagens do local"
                      multiple
                      data={restaurant}
                      name="images"
                    />

                    <Col lg={12}>
                      <div className="text-end">
                        <button type="submit" className="btn btn-primary">
                          Salvar
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>
      <ModalLoding loading={loading} />
    </React.Fragment>
  );
};

export default RestaurantForm;
