import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container } from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import Loading from "../../Components/Loading/Loading";
import PlaceDetails from "../../Components/Places/PlaceDetails";
import { getStoreRequest } from "../../store/stores/actions";
import InfoStore from "./components/InfoStore";

const StoreDetails = () => {
  document.title = "Lojas | Ecoguias - Admin Dashboard";

  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data, loading } = useSelector((state) => state.Store);

  useEffect(() => {
    dispatch(getStoreRequest(id));
  }, [id, dispatch]);

  function renderPlaceDetails() {
    if (!data.result) {
      return null;
    }

    return (
      <PlaceDetails
        loading={loading}
        data={data?.result?.store}
        images={data?.result?.images}
        pathPaginationName="/stores"
        menu={<InfoStore data={data?.result?.store} />}
      />
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Loja" pageTitle="Locais" />
        </Container>

        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="pb-4">
              <button
                className="btn btn-primary"
                onClick={() => history.push("/stores")}
              >
                Voltar
              </button>
            </div>

            {renderPlaceDetails()}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default StoreDetails;
