import {
  CREATE_WATERFALL_FAILURE,
  CREATE_WATERFALL_REQUEST,
  CREATE_WATERFALL_SUCCESS,
  DELETE_WATERFALL_FAILURE,
  DELETE_WATERFALL_REQUEST,
  DELETE_WATERFALL_SUCCESS,
  GET_WATERFALLS_FAILURE,
  GET_WATERFALLS_REQUEST,
  GET_WATERFALLS_SUCCESS,
  GET_WATERFALL_FAILURE,
  GET_WATERFALL_REQUEST,
  GET_WATERFALL_SUCCESS,
  UPDATE_WATERFALL_FAILURE,
  UPDATE_WATERFALL_PHOTO_FAILURE,
  UPDATE_WATERFALL_PHOTO_REQUEST,
  UPDATE_WATERFALL_PHOTO_SUCCESS,
  UPDATE_WATERFALL_REQUEST,
  UPDATE_WATERFALL_SUCCESS,
  DELETE_WATERFALL_PHOTO_FAILURE,
  DELETE_WATERFALL_PHOTO_REQUEST,
  DELETE_WATERFALL_PHOTO_SUCCESS,
  REMOVE_GPS_WATERFALL_FAILURE,
  REMOVE_GPS_WATERFALL_REQUEST,
  REMOVE_GPS_WATERFALL_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: false,
  loading: false,
  data: [],
  success: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WATERFALLS_REQUEST:
      console.log("REQUESTED...");
      state = {
        ...state,
        data: null,
        loading: true,
        error: false,
      };
      break;

    case GET_WATERFALLS_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_WATERFALLS_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        // data: [],
        loading: false,
        error: action.data,
      };
      break;

    case GET_WATERFALL_REQUEST:
      console.log("REQUESTED...");
      state = {
        ...state,
        data: {},
        loading: true,
        error: false,
      };
      break;

    case GET_WATERFALL_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_WATERFALL_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        // data: [],
        loading: false,
        error: true,
      };
      break;

    case CREATE_WATERFALL_REQUEST:
      console.log("REQUESTED...");

      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case CREATE_WATERFALL_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
      break;

    case CREATE_WATERFALL_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    case DELETE_WATERFALL_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_WATERFALL_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_WATERFALL_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    case DELETE_WATERFALL_PHOTO_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_WATERFALL_PHOTO_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_WATERFALL_PHOTO_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    case UPDATE_WATERFALL_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;

    case UPDATE_WATERFALL_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case UPDATE_WATERFALL_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        // data: {},
        loading: false,
        error: true,
      };
      break;

    case UPDATE_WATERFALL_PHOTO_REQUEST:
      state = {
        ...state,
        // data: {},
        loading: true,
        error: false,
      };
      break;

    case UPDATE_WATERFALL_PHOTO_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case UPDATE_WATERFALL_PHOTO_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        // data: {},
        loading: false,
        error: true,
      };
      break;

    case REMOVE_GPS_WATERFALL_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case REMOVE_GPS_WATERFALL_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case REMOVE_GPS_WATERFALL_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
