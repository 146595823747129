import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import ModalLoding from "../../Components/Common/ModalLoading";
import PlaceMenu from "../../Components/Places/PlaceMenu";

import * as Yup from "yup";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import Modal from "../../Components/Common/Modal";
import UiContent from "../../Components/Common/UiContent";
import {
  CoverUploadImageField,
  InputMask,
  TextField,
} from "../../Components/Forms";
import LazyImage from "../../Components/LazyImage/LazyImage";
import Loading from "../../Components/Loading/Loading";
import {
  deleteCityPhotoRequest,
  getCityRequest,
  updateCityPhotoRequest,
  updateCityRequest,
} from "../../store/city/actions";
import { isLatitude, isLongitude } from "../../utils/formats";
import { api } from "../../boot/api";
import showNofity from "../../helpers/toast";

const MANDATORY_FIELD = "Este campo é obrigatório";

const CityFormEdit = () => {
  document.title = "Cidades | Ecoguias - Admin Dashboard";
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.City);
  const [modalIsOpenRemove, setModalIsOpenRemove] = useState(false);
  const [modalIsOpenSelect, setModalIsOpenSelect] = useState(false);
  const [photoId, setPhotoId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [legendImage, setLegendImage] = useState([]);

  const { id } = useParams();
  const history = useHistory();

  useEffect(
    function handleDefaultValuesEditForm() {
      dispatch(getCityRequest(id));
    },
    [dispatch, id]
  );

  const isCityExists = data?.result?.city;
  const cityResponse = data?.result?.city;

  const city = useFormik({
    enableReinitialize: true,

    initialValues: {
      nome: isCityExists ? cityResponse.nome : "",
      descritivo: isCityExists ? cityResponse.descritivo : "",
      telefone: isCityExists ? cityResponse.telefone : "",
      site: isCityExists ? cityResponse.site : "",
      email: isCityExists ? cityResponse.email : "",
      populacao: isCityExists ? cityResponse.populacao : 0,
      images: isCityExists ? cityResponse.images : [],
      url_video: isCityExists ? cityResponse.url_video : "",
      imagem_principal: isCityExists ? cityResponse.imagem_principal : 0,
      latitude: isCityExists ? cityResponse.gps?.latitude : "",
      longitude: isCityExists ? cityResponse.gps?.longitude : "",
    },

    validationSchema: Yup.object({
      images: Yup.array(Yup.string()),
      nome: Yup.string().required(MANDATORY_FIELD),
      descritivo: Yup.string().required(MANDATORY_FIELD),
      telefone: Yup.string(MANDATORY_FIELD),
      site: Yup.string("O website deve ser do tipo texto"),
      url_video: Yup.string("A url do video deve ser do tipo texto"),
      email: Yup.string("O e-mail deve ser do tipo texto").email(
        "O campo email deve ser valido"
      ),
      populacao: Yup.number("O N° de habitantes deve ser numérico").min(
        0,
        "O N° de habitantes deve ser no minimo zero"
      ),
      latitude: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      longitude: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      // .required(MANDATORY_FIELD),
    }),
    onSubmit: async (values) => {
      values.populacao = Number(values.populacao);

      values.latitude = Number.isNaN(values.latitude)
        ? 0.0
        : Number(values.latitude);

      values.longitude = Number.isNaN(values.longitude)
        ? 0.0
        : Number(values.longitude);

      dispatch(
        updateCityRequest({
          id: id,
          values,
          onSucess: () => city.setFieldValue("images", []),
        })
      );
    },
  });

  function addLegendInState(index, txt) {
    const copyLegend = [...legendImage];
    copyLegend[index] = txt;

    setLegendImage(copyLegend);
  }

  async function updateLegend(imagemId, legenda) {
    try {
      await api.post("/photo-city-update-legend", { imagemId, legenda });
      showNofity.success("Legenda salva com sucesso");
    } catch (error) {
      showNofity.error("Erro ao salvar legenda");
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />

      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Editar cidade" pageTitle="Cidades" />
        </Container>

        <Col lg={12}>
          <Card>
            <div className="p-4">
              <button
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Voltar
              </button>
            </div>
            <CardBody>
              <div className="live-preview">
                <Row className="g-3">
                  <CoverUploadImageField
                    title="Adicionar imagens"
                    multiple
                    data={city}
                    name="images"
                    isActiveRadioSelect={false}
                  />
                  <TextField label="Nome" name="nome" data={city} size={12} />

                  <TextField label="Website" name="site" data={city} />

                  <InputMask
                    type="phone-br"
                    label="Telefone"
                    name="telefone"
                    data={city}
                    size={3}
                  />

                  <TextField label="E-mail" name="email" data={city} size={3} />

                  <TextField
                    label="N° de habitantes"
                    name="populacao"
                    data={city}
                    type="number"
                    size={2}
                    onKeyDown={(event) => {
                      ["e", "E", "+"].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <TextField
                    label="Latitude"
                    name="latitude"
                    data={city}
                    type="number"
                    size={2}
                    onKeyDown={(event) => {
                      ["e", "E", "+", ","].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <TextField
                    label="Longitude"
                    name="longitude"
                    data={city}
                    type="number"
                    size={2}
                    onKeyDown={(event) => {
                      ["e", "E", "+", ","].includes(event.key) &&
                        event.preventDefault();
                    }}
                  />

                  <TextField
                    label="Vídeo do local (URL)"
                    name="url_video"
                    data={city}
                  />

                  <TextField
                    label="Descrição"
                    name="descritivo"
                    data={city}
                    size={12}
                    type="textarea"
                  />
                  {data?.result?.images?.length > 0 && (
                    <>
                      <h3>Imagens do local</h3>
                      <p>
                        Escolha uma opção abaixo para excluir uma imagem ou
                        selecionar a imagem principal do local
                      </p>
                    </>
                  )}

                  {id && (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 10,
                        }}
                      >
                        {data?.result?.images?.map((item, idx) => {
                          return item.imagem?.name ? (
                            <div key={item.imagem.url}>
                              <Col lg={3} style={styles.imageList}>
                                {item.imagem.url !==
                                data?.result?.city?.imagem.url ? (
                                  <PlaceMenu
                                    remove={() => {
                                      setPhotoId(item.objectId);
                                      setModalIsOpenRemove(true);
                                    }}
                                    select={() => {
                                      setSelectedImage(item?.imagem);
                                      setModalIsOpenSelect(true);
                                    }}
                                  />
                                ) : null}
                                <div>
                                  <LazyImage
                                    src={item?.imagem?.url}
                                    width="100%"
                                    height="100%"
                                    className="object-fit-cover rounded"
                                    style={
                                      item.imagem.url ===
                                      data?.result?.city?.imagem.url
                                        ? styles.lazyImage
                                        : null
                                    }
                                  />
                                </div>
                              </Col>
                              <div style={{ marginTop: 10 }}></div>
                              <InputGroup>
                                <Input
                                  value={item.legenda}
                                  title="Legenda"
                                  name="Legenda"
                                  placeholder="Legenda"
                                  size="sm"
                                  className="form-control"
                                  onChange={(event) =>
                                    addLegendInState(idx, event.target.value)
                                  }
                                />
                                <Button
                                  size="sm"
                                  className="primary"
                                  onClick={() =>
                                    updateLegend(
                                      item.objectId,
                                      legendImage[idx]
                                    )
                                  }
                                >
                                  <i className="ri-save-2-line fs-13"></i>
                                </Button>
                              </InputGroup>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </React.Fragment>
                  )}

                  <Col lg={12}>
                    <div className="text-end">
                      <button
                        onClick={() => city.handleSubmit()}
                        type="button"
                        className="btn btn-primary"
                      >
                        Salvar
                      </button>
                    </div>
                  </Col>
                </Row>
                {/* </Form> */}
              </div>
            </CardBody>
          </Card>
        </Col>
        <ModalLoding loading={loading} />
      </div>

      <Modal
        isOpen={modalIsOpenRemove}
        text="Tem certeza que deseja remover essa imagem?"
        onClick={() => {
          dispatch(
            deleteCityPhotoRequest({
              cityId: id,
              photoId: photoId,
            })
          );

          setModalIsOpenRemove(false);
        }}
        onCancel={() => {
          setModalIsOpenRemove(false);
        }}
      />

      <Modal
        isOpen={modalIsOpenSelect}
        text="Tem certeza que deseja selecionar essa imagem como a principal desta cidade?"
        onClick={() => {
          dispatch(
            updateCityPhotoRequest({
              cityId: id,
              imagem: selectedImage,
            })
          );

          setModalIsOpenSelect(false);
        }}
        onCancel={() => {
          setModalIsOpenSelect(false);
        }}
      />
    </React.Fragment>
  );
};

const styles = {
  lazyImage: {
    borderColor: "#93bf85",
    borderWidth: 5,
    borderStyle: "solid",
    borderRadius: 10,
  },
  imageList: {
    display: "flex",
    position: "relative",
    height: 220,
    width: 260,
  },
};

export default CityFormEdit;
