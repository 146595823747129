export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const GET_USER_ID_REQUEST = "GET_USER_ID_REQUEST";
export const GET_USER_ID_SUCCESS = "GET_USER_ID_SUCCESS";
export const GET_USER_ID_FAILURE = "GET_USER_ID_FAILURE";

export const UPDATE_USER_IMAGE_REQUEST = "UPDATE_USER_IMAGE_REQUEST";
export const UPDATE_USER_IMAGE_SUCCESS = "UPDATE_USER_IMAGE_SUCCESS";
export const UPDATE_USER_IMAGE_FAILURE = "UPDATE_USER_IMAGE_FAILURE";

// export const CREATE_USERS_REQUEST = "CREATE_USERS_REQUEST";
// export const CREATE_USERS_SUCCESS = "CREATE_USERS_SUCCESS";
// export const CREATE_USERS_FAILURE = "CREATE_USERS_FAILURE";

// export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
// export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
// export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

// export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
// export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
// export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
