import {
  GET_FILTER_WHERE_STAY_FAILURE,
  GET_FILTER_WHERE_STAY_REQUEST,
  GET_FILTER_WHERE_STAY_SUCCESS,
} from "./actionTypes";

export const getFilterWhereStayRequest = (data) => {
  return {
    type: GET_FILTER_WHERE_STAY_REQUEST,
    data,
  };
};

export const getFilterWhereStaySuccess = (data) => {
  return {
    type: GET_FILTER_WHERE_STAY_SUCCESS,
    data,
  };
};

export const getFilterWhereStayFailure = () => {
  return {
    type: GET_FILTER_WHERE_STAY_FAILURE,
  };
};
