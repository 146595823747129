import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import ModalLoding from "../../Components/Common/ModalLoading";
import * as Yup from "yup";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import UiContent from "../../Components/Common/UiContent";
import {
  CoverUploadImageField,
  InputMask,
  TextField,
} from "../../Components/Forms";
import showNofity from "../../helpers/toast";
import { createCityRequest } from "../../store/city/actions";
import { isLatitude, isLongitude } from "../../utils/formats";

const CityForm = () => {
  document.title = "Cidades | Ecoguias - Admin Dashboard";
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.City);
  const history = useHistory();
  const { state } = useLocation();

  const city = useFormik({
    enableReinitialize: true,

    initialValues: {
      nome: "",
      descritivo: "",
      telefone: "",
      site: "",
      email: "",
      populacao: 0,
      images: [],
      url_video: "",
      imagem_principal: 0,
      latitude: "",
      longitude: "",
    },

    validationSchema: Yup.object({
      images: Yup.array(Yup.string()).required("Este campo é obrigatório"),
      nome: Yup.string().required("Este campo é obrigatório"),
      descritivo: Yup.string().required("Este campo é obrigatório"),
      telefone: Yup.string("Este campo é obrigatório"),
      site: Yup.string("O website deve ser do tipo texto"),
      url_video: Yup.string("A url do video deve ser do tipo texto"),
      email: Yup.string("O e-mail deve ser do tipo texto").email(
        "O campo email deve ser valido"
      ),
      populacao: Yup.number("O N° de habitantes deve ser numérico").min(
        0,
        "O N° de habitantes deve ser no minimo zero"
      ),
      latitude: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      longitude: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      // .required("Este campo é obrigatório"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (values.images.length < 1) {
        showNofity.error("Selecione uma imagem");
        return;
      }

      values.populacao = Number(values.populacao);
      values.latitude = values.latitude ? Number(values.latitude) : 0.0;
      values.longitude = values.longitude ? Number(values.longitude) : 0.0;
      dispatch(createCityRequest({ values, resetForm }));
    },
  });

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Formulário de cidades" pageTitle="Cidades" />
        </Container>
        <Col lg={12}>
          <Card>
            <div className="p-4">
              <button
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Voltar
              </button>
            </div>
            <CardBody>
              <div className="live-preview">
                <Form
                  className="needs-validation"
                  onSubmit={(e) => {
                    e.preventDefault();
                    city.handleSubmit();
                    return false;
                  }}
                  action="#"
                >
                  <Row className="g-3">
                    <TextField label="Nome" name="nome" data={city} size={12} />

                    <TextField label="Website" name="site" data={city} />

                    <InputMask
                      type="phone-br"
                      label="Telefone"
                      name="telefone"
                      data={city}
                      size={3}
                    />

                    <TextField
                      label="E-mail"
                      name="email"
                      data={city}
                      size={3}
                    />

                    <TextField
                      label="N° de habitantes"
                      name="populacao"
                      data={city}
                      size={2}
                      type="number"
                      onKeyDown={(event) => {
                        ["e", "E", "+"].includes(event.key) &&
                          event.preventDefault();
                      }}
                    />

                    <TextField
                      label="Latitude"
                      name="latitude"
                      data={city}
                      type="number"
                      size={2}
                      onKeyDown={(event) => {
                        ["e", "E", "+", ","].includes(event.key) &&
                          event.preventDefault();
                      }}
                    />

                    <TextField
                      label="Longitude"
                      name="longitude"
                      data={city}
                      type="number"
                      size={2}
                      onKeyDown={(event) => {
                        ["e", "E", "+", ","].includes(event.key) &&
                          event.preventDefault();
                      }}
                    />

                    <TextField
                      label="Vídeo do local (URL)"
                      name="url_video"
                      data={city}
                    />

                    <TextField
                      label="Descrição"
                      name="descritivo"
                      data={city}
                      size={12}
                      type="textarea"
                    />

                    <CoverUploadImageField
                      title="Imagens do local"
                      multiple
                      data={city}
                      name="images"
                    />

                    <Col lg={12}>
                      <div className="text-end">
                        <button type="submit" className="btn btn-primary">
                          Salvar
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>
      <ModalLoding loading={loading} />
    </React.Fragment>
  );
};

export default CityForm;
