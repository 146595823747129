import React from "react";

import { Col, Row } from "reactstrap";
import { TextField } from "../../Components/Forms";

const FiltersWhereStayForm = ({ form }) => {
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Row className="g-3">
            <TextField label="Nome" name="nome" data={form} size={12} />
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FiltersWhereStayForm;
