import { Modal, ModalBody, Spinner } from "reactstrap";

export default function ModalLoading({ loading, text }) {
  return (
    <Modal isOpen={loading} centered size="sm">
      <ModalBody className="d-flex flex-column justify-content-center align-items-center">
        <Spinner className="m-5" color="primary">
          Loading...
        </Spinner>
        {text ? <h6>{text}</h6> : undefined}
      </ModalBody>
    </Modal>
  );
}
