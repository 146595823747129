import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { useDebounce } from "use-debounce";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getContactsUsRequest } from "../../store/contactsUs/actions";
import { ContactsUsTable } from "./ContactsUsTable";

const ContactsUsList = () => {
  document.title = "Fale conosco | Ecoguias - Admin Dashboard";

  const dispatch = useDispatch();

  const { loading, data } = useSelector((state) => state.ContactsUs);

  const [searchInput, setSearchInput] = useState("");
  const [value] = useDebounce(searchInput, 500);

  useEffect(() => {
    dispatch(getContactsUsRequest(value));
  }, [dispatch, value]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Tabela Fale conosco" pageTitle="Fale conosco" />
          <div className="d-flex justify-content-between pb-4 flex-wrap">
            <Input
              type="text"
              style={{ maxWidth: 250 }}
              className="form-control"
              placeholder="Pesquisar..."
              id="search-options"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Fale conosco</h5>
                </CardHeader>
                <CardBody>
                  <ContactsUsTable
                    searchInput={value}
                    style={{ width: "100%" }}
                    contacts={data?.result}
                    loading={loading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactsUsList;
