import { all, call, put, takeLatest } from "redux-saga/effects";

import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";

import {
  createCityFailure,
  createCitySuccess,
  deleteCityFailure,
  deleteCityPhotoFailure,
  getCitiesFailure,
  getCitiesSuccess,
  getCityFailure,
  getCitySuccess,
  updateCityFailure,
  updateCityPhotoFailure,
} from "./actions";
import {
  CREATE_CITY_REQUEST,
  DELETE_CITY_PHOTO_REQUEST,
  DELETE_CITY_REQUEST,
  GET_CITIES_REQUEST,
  GET_CITY_REQUEST,
  UPDATE_CITY_PHOTO_REQUEST,
  UPDATE_CITY_REQUEST,
} from "./actionTypes";

function requestCities(data) {
  return post("/cities-by-name", { cityName: data });
}

function requestCityById(id) {
  return post("/city", { cityId: id });
}

function newCity(city) {
  return post("/cities", city);
}

function saveImage(imgs) {
  return post("/media-upload", { imgs });
}

function deleteCityById(id) {
  return post("/city-remove", { cityId: id });
}

function deletePhotoCity({ photoId, cityId }) {
  return post("/photo-city-remove", {
    cityId: cityId,
    photoId: photoId,
  });
}

function requestUpdateCity(id, city) {
  const { descritivo, email, nome, populacao, site, telefone, gps, url_video } =
    city;

  return post("/city-update", {
    cityId: id,
    descritivo,
    email,
    nome,
    populacao,
    site,
    telefone,
    url_video,
    gps,
  });
}

function requestUpdateCityPhoto({ cityId, imagem }) {
  return post("/photo-city-select", {
    cityId: cityId,
    imagem: imagem,
  });
}

function saveFotos(img, idCity) {
  return post("/photos-cities", {
    url: img.url,
    imagem: {
      __type: "File",
      name: img.name,
      url: img.url,
    },
    nome_imagem: img.name,
    cidade: { __type: "Pointer", className: "Cidade", objectId: idCity },
  });
}

function* getCities({ data }) {
  try {
    const response = yield call(requestCities, data);
    yield put(getCitiesSuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar cidades." + error);
    yield put(getCitiesFailure());
  }
}

function* getCity({ data }) {
  try {
    const response = yield call(requestCityById, data);
    yield put(getCitySuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao buscar detalhes da cidade." + error);
    yield put(getCityFailure());
  }
}

function* deleteCity({ data }) {
  try {
    yield call(deleteCityById, data.cityId);
    data.closeModal();
    const response = yield call(requestCities);
    yield put(getCitiesSuccess(response.data));
    showNofity.success("Cidade removida com sucesso.");
  } catch (error) {
    showNofity.error("Erro ao remover a cidade. " + error);
    yield put(deleteCityFailure());
  }
}

function* deleteImageCity({ data }) {
  try {
    yield call(deletePhotoCity, data);
    showNofity.success("Imagem removida com sucesso");
    const response = yield call(requestCityById, data.cityId);
    yield put(getCitySuccess(response.data));
  } catch (error) {
    showNofity.error("Erro ao remover foto da cidade. " + error);
    put(deleteCityPhotoFailure());
  }
}

function* createCity({ data }) {
  console.log(":::", data);
  try {
    const {
      images,
      nome,
      descritivo,
      telefone,
      site,
      email,
      populacao,
      url_video,
      latitude,
      longitude,
      imagem_principal,
    } = data.values;

    const responseImages = yield call(saveImage, images);

    const responseCity = yield call(newCity, {
      nome,
      descritivo,
      telefone,
      site,
      email,
      populacao,
      url_video,
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
      imagem: {
        __type: "File",
        url: responseImages.data[imagem_principal].url,
        name: responseImages.data[imagem_principal].name,
      },
    });

    yield all(
      responseImages.data.map((img) =>
        call(saveFotos, img, responseCity.data.objectId)
      )
    );

    yield put(createCitySuccess());
    showNofity.success("Cidade cadastrada com sucesso");
    data.resetForm();
  } catch (error) {
    showNofity.error("Erro ao cadastrar cidade." + error);
    yield put(createCityFailure());
  }
}

function* updateCity({ data }) {
  try {
    const {
      descritivo,
      email,
      nome,
      populacao,
      site,
      telefone,
      url_video,
      images,
      latitude,
      longitude,
    } = data.values;

    if (images?.length > 0) {
      const responseImages = yield call(saveImage, images);
      yield all(
        responseImages.data.map((img) => call(saveFotos, img, data.id))
      );
    }

    yield call(requestUpdateCity, data.id, {
      descritivo,
      email,
      nome,
      populacao,
      site,
      telefone,
      url_video,
      images,
      gps: {
        __type: "GeoPoint",
        latitude: latitude,
        longitude: longitude,
      },
    });

    const response = yield call(requestCityById, data.id);
    yield put(getCitySuccess(response.data));
    data.onSucess();
    showNofity.success("Cidade atualizada com sucesso");
  } catch (error) {
    yield put(updateCityFailure());
    showNofity.error("Erro ao atualizar cidade");
  }
}

function* updateImageCity({ data }) {
  try {
    // alert(JSON.stringify(data));
    yield call(requestUpdateCityPhoto, {
      cityId: data.cityId,
      imagem: data.imagem,
    });

    const responseCity = yield call(requestCityById, data.cityId);
    showNofity.success("Imagem atualizada com sucesso.");
    yield put(getCitySuccess(responseCity.data));
  } catch (error) {
    yield put(updateCityPhotoFailure());
    showNofity.error("Erro ao atualizar a foto da cidade.");
  }
}

function* saga() {
  yield takeLatest(GET_CITIES_REQUEST, getCities);
  yield takeLatest(GET_CITY_REQUEST, getCity);
  yield takeLatest(CREATE_CITY_REQUEST, createCity);
  yield takeLatest(DELETE_CITY_REQUEST, deleteCity);
  yield takeLatest(DELETE_CITY_PHOTO_REQUEST, deleteImageCity);
  yield takeLatest(UPDATE_CITY_REQUEST, updateCity);
  yield takeLatest(UPDATE_CITY_PHOTO_REQUEST, updateImageCity);
}

export default saga;
