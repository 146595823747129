import Dropzone from "react-dropzone";
import { Card, Col, FormGroup, Input } from "reactstrap";
import Resizer from "react-image-file-resizer";

import showNofity from "../../../helpers/toast";
import LazyImage from "../../LazyImage/LazyImage";

// const convertImageToBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1280,
      720,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export function CoverUploadImageField({
  data = [],
  name,
  title,
  multiple = false,
  isActiveRadioSelect = true,
}) {
  function removeImage(index) {
    const filteredImages = data.values[name].filter((_, idx) => index !== idx);
    data.setFieldValue(name, filteredImages);
  }

  const hasImages = !!data.values?.[name]?.length;

  return [
    <h3 key="placeCoverImage">{title}</h3>,
    <Dropzone
      key="dropzoneCoverField"
      accept="image/png, image/jpeg"
      multiple={multiple}
      onDrop={async (acceptedFiles) => {
        if (
          data.values[name]?.length > 7 ||
          acceptedFiles?.length > 7 ||
          data.values[name]?.length + acceptedFiles?.length > 7
        ) {
          showNofity.error(
            "Não é possivel cadastrar mais de 7 imagens simultaneamente."
          );
          return;
        }

        const promises = acceptedFiles.map((file) => resizeFile(file));

        const response = await Promise.all(promises);

        if (!multiple) {
          data.setFieldValue(name, response);
        } else if (data.values[name]?.length > 0) {
          const copyImages = [...data.values[name], ...response];
          data.setFieldValue(name, copyImages);
        } else {
          data.setFieldValue(name, response);
        }
      }}
    >
      {({ getRootProps }) => (
        <div className="dropzone dz-clickable">
          <div className="dz-message needsClick" {...getRootProps()}>
            <div className="mb-3">
              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
            </div>
            <h4>
              Arraste a(s) imagem(ns) de cover até aqui ou clique para
              selecionar
            </h4>
          </div>
        </div>
      )}
    </Dropzone>,
    <>
      {hasImages && isActiveRadioSelect && (
        <>
          <h4>Selecione a imagem principal</h4>
        </>
      )}

      {hasImages && (
        <div
          className="list-unstyled mb-0 d-flex flex-wrap"
          id="file-previews"
          key="previewCoverImage"
        >
          <FormGroup check>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: 10,
              }}
            >
              {data?.values[name]?.map((item, index) => {
                return (
                  <Card
                    className="shadow-none m-2 dz-processing dz-image-preview dz-success dz-complete"
                    key={index + "-file"}
                  >
                    <Col
                      lg={3}
                      style={{
                        display: "flex",
                        position: "relative",
                        height: 180,
                        width: 180,
                      }}
                    >
                      {isActiveRadioSelect && (
                        <Input
                          style={{
                            marginRight: 10,
                            border: "1px solid #444",
                          }}
                          value={data.values.imagem_principal}
                          type="radio"
                          name={"img"}
                          onChange={() => {
                            data.setFieldValue("imagem_principal", index);
                          }}
                        />
                      )}
                      <>
                        <button
                          title="Excluir imagem"
                          className="position-absolute"
                          onClick={() => removeImage(index)}
                          type="button"
                          style={{
                            right: 10,
                            top: 5,
                            border: 0,
                            borderRadius: 15,
                            boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          <i className="ri-delete-bin-7-line fs-16"></i>
                        </button>
                        <LazyImage
                          onChange={() => {
                            data.setFieldValue("imagem_principal", index);
                          }}
                          src={item}
                          width="100%"
                          height="100%"
                          className="object-fit-cover rounded"
                        />
                      </>
                    </Col>
                  </Card>
                );
              })}
            </div>
          </FormGroup>
        </div>
      )}
    </>,
  ];
}
