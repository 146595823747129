import React from "react";

import { Col, Row } from "reactstrap";

const InfoTouristSpot = ({ data }) => {

  return (
    <React.Fragment>
      <Col key={data?.objectId}>
        <p className="text-justify">{data?.descritivo}</p>

        {data?.endereco ? (
          <Row>
            <Col xl={2}>
              <i className="ri-map-pin-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Endereço:</label>
              <p>{data?.endereco}</p>
            </Col>
          </Row>
        ) : null}

        {data?.cidade ? (
          <Row>
            <Col xl={2}>
              <i className="ri-building-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Cidade:</label>
              <p>
                {data.cidade?.nome ?? ""}
              </p>
            </Col>
          </Row>
        ) : null}

        {data?.gps ? (
          <Row>
            <Col xl={2}>
              <i className="ri-map-2-line  fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>(latitude, longitude):</label>
              <p>
                {data?.gps?.latitude} / {data?.gps?.longitude}
              </p>
            </Col>
          </Row>
        ) : null}

        {data?.entre_contato ? (
          <Row>
            <Col xl={2}>
              <i className="ri-phone-fill fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Entre em contato:</label>
              <p>{data?.entre_contato}</p>
            </Col>
          </Row>
        ) : null}

        {data?.telefone ? (
          <Row>
            <Col xl={2}>
              <i className="ri-phone-fill fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Telefone:</label>
              <p>{data?.telefone}</p>
            </Col>
          </Row>
        ) : null}

        {data?.email ? (
          <Row>
            <Col xl={2}>
              <i className="ri-mail-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>E-mail:</label>
              <p>{data?.email}</p>
            </Col>
          </Row>
        ) : null}

        {data?.horario_funcionamento ? (
          <Row>
            <Col xl={2}>
              <i className="ri-time-line  fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Horário funcionamento:</label>
              <p>{data?.horario_funcionamento}</p>
            </Col>
          </Row>
        ) : null}
      </Col>
    </React.Fragment>
  );
};

export default InfoTouristSpot;
