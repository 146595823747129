import queryString from "query-string";
import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Container } from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import Loading from "../../Components/Loading/Loading";
import PlaceDetails from "../../Components/Places/PlaceDetails";
import { getTouristSpotRequest } from "../../store/touristSpots/actions";
import InfoTouristSpot from "./components/InfoTouristSpot";

const TouristSpotDetails = () => {
  document.title = "Atrativo turístico | Ecoguias - Admin Dashboard";

  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.TouristSpots);
  const history = useHistory();

  const { search } = useLocation();
  const queryStringParams = queryString.parse(search);

  useEffect(() => {
    dispatch(getTouristSpotRequest(id));
  }, [id, dispatch]);

  function renderPlaceDetails() {
    if (!data?.result) {
      return null;
    }

    return (
      <PlaceDetails
        loading={loading}
        data={data?.result?.touristSpot}
        images={data?.result?.images}
        pathPaginationName="/cities"
        page={queryStringParams.page}
        menu={<InfoTouristSpot data={data?.result?.touristSpot} />}
      />
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Atrativo turístico" pageTitle="Locais" />
        </Container>

        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="p-4">
              <button
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Voltar
              </button>
            </div>
            {renderPlaceDetails()}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default TouristSpotDetails;
