import {
  GET_FILTER_TOURIST_SPOT_FAILURE,
  GET_FILTER_TOURIST_SPOT_REQUEST,
  GET_FILTER_TOURIST_SPOT_SUCCESS,
} from "./actionTypes";

export const getFilterTouristSpotRequest = (data) => {
  return {
    type: GET_FILTER_TOURIST_SPOT_REQUEST,
    data,
  };
};

export const getFilterTouristSpotSuccess = (data) => {
  return {
    type: GET_FILTER_TOURIST_SPOT_SUCCESS,
    data,
  };
};

export const getFilterTouristSpotFailure = () => {
  return {
    type: GET_FILTER_TOURIST_SPOT_FAILURE,
  };
};
