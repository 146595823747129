import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../boot/api";
import showNofity from "../../helpers/toast";
import {
  GET_AGGREGATED_ANALYTICS_DATA,
  GET_ALL_TIME_ANALYTICS_DATA,
  GET_REAL_TIME_ANALYTICS_DATA,
} from './actionType'
import {
  getAggregatedAnalyticsFailure,
  getAggregatedAnalyticsSuccess,
  getAllTimeAnalyticsFailure,
  getAllTimeAnalyticsSuccess,
  getRealTimeAnalyticsFailure,
  getRealTimeAnalyticsSuccess,
} from './action';

function requestAggregatedAnalytics(options) {
  const params = {};
  if (options) {
    if (options.startDate) {
      params.startDate = options.startDate
    }
    if (options.endDate) {
      params.endDate = options.endDate;
    }
  }
  return post("analytics", params);
}

function requestAllTimeAnalytics() {
  return post("analytics-alltime");
}

function requestRealTimeAnalytics() {
  return post("analytics-realtime");
}

function* getAggregatedAnalytics({ data }) {
  try {
    const response = yield call(requestAggregatedAnalytics, data);
    yield put(getAggregatedAnalyticsSuccess(response.data.result));
  } catch (e) {
    console.error(e);
    yield put(getAggregatedAnalyticsFailure());
    showNofity.error("Erro ao buscar dados de Analytics.");
  }
}

function* getAllTimeAnalytics() {
  try {
    const response = yield call(requestAllTimeAnalytics);
    yield put(getAllTimeAnalyticsSuccess(response.data.result));
  } catch (e) {
    console.error(e);
    yield put(getAllTimeAnalyticsFailure());
    showNofity.error("Erro ao buscar dados de Analytics.");
  }
}

function* getRealTimeAnalytics() {
  try {
    const response = yield call(requestRealTimeAnalytics);
    yield put(getRealTimeAnalyticsSuccess(response.data.result));
  } catch (e) {
    console.error(e);
    yield put(getRealTimeAnalyticsFailure());
    /*
     * comentado a pedido do Pedro; investigação da causa real pendente
     * por dificuldade de reproduzir o erro
     */
    // showNofity.error("Erro ao buscar dados de Analytics em tempo real.");
  }
}

function* saga() {
  yield takeLatest(GET_AGGREGATED_ANALYTICS_DATA, getAggregatedAnalytics);
  yield takeLatest(GET_ALL_TIME_ANALYTICS_DATA, getAllTimeAnalytics);
  yield takeLatest(GET_REAL_TIME_ANALYTICS_DATA, getRealTimeAnalytics);
}

export default saga;
