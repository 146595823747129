import {
  CREATE_TOURIST_RECEPTION_FAILURE,
  CREATE_TOURIST_RECEPTION_REQUEST,
  CREATE_TOURIST_RECEPTION_SUCCESS,
  DELETE_TOURIST_RECEPTION_FAILURE,
  DELETE_TOURIST_RECEPTION_PHOTO_FAILURE,
  DELETE_TOURIST_RECEPTION_PHOTO_REQUEST,
  DELETE_TOURIST_RECEPTION_PHOTO_SUCCESS,
  DELETE_TOURIST_RECEPTION_REQUEST,
  DELETE_TOURIST_RECEPTION_SUCCESS,
  GET_TOURIST_RECEPTIONS_FAILURE,
  GET_TOURIST_RECEPTIONS_REQUEST,
  GET_TOURIST_RECEPTIONS_SUCCESS,
  GET_TOURIST_RECEPTION_FAILURE,
  GET_TOURIST_RECEPTION_REQUEST,
  GET_TOURIST_RECEPTION_SUCCESS,
  UPDATE_TOURIST_RECEPTION_FAILURE,
  UPDATE_TOURIST_RECEPTION_PHOTO_FAILURE,
  UPDATE_TOURIST_RECEPTION_PHOTO_REQUEST,
  UPDATE_TOURIST_RECEPTION_PHOTO_SUCCESS,
  UPDATE_TOURIST_RECEPTION_REQUEST,
  UPDATE_TOURIST_RECEPTION_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: false,
  loading: false,
  data: [],
  success: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOURIST_RECEPTIONS_REQUEST:
      state = {
        ...state,
        data: [],
        loading: true,
        error: false,
      };
      break;

    case GET_TOURIST_RECEPTIONS_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_TOURIST_RECEPTIONS_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        // data: [],
        loading: false,
        error: action.data,
      };
      break;

    case GET_TOURIST_RECEPTION_REQUEST:
      state = {
        ...state,
        // data: {},
        loading: true,
        error: false,
      };
      break;

    case GET_TOURIST_RECEPTION_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_TOURIST_RECEPTION_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        // data: [],
        loading: false,
        error: action.data,
      };
      break;

    case CREATE_TOURIST_RECEPTION_REQUEST:
      console.log("REQUESTED...");

      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case CREATE_TOURIST_RECEPTION_SUCCESS:
      console.log("SUCCESS =)");

      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
      break;

    case CREATE_TOURIST_RECEPTION_FAILURE:
      console.log("ERROR =(");

      state = {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      break;

    case DELETE_TOURIST_RECEPTION_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_TOURIST_RECEPTION_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_TOURIST_RECEPTION_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        // data: [],
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case DELETE_TOURIST_RECEPTION_PHOTO_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case DELETE_TOURIST_RECEPTION_PHOTO_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case DELETE_TOURIST_RECEPTION_PHOTO_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        data: [],
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case UPDATE_TOURIST_RECEPTION_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case UPDATE_TOURIST_RECEPTION_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case UPDATE_TOURIST_RECEPTION_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    case UPDATE_TOURIST_RECEPTION_PHOTO_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case UPDATE_TOURIST_RECEPTION_PHOTO_SUCCESS:
      console.log("SUCCESS =)", action);

      state = {
        ...state,
        data: { ...action.data },
        success: true,
        loading: false,
      };
      break;

    case UPDATE_TOURIST_RECEPTION_PHOTO_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
