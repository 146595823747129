import {
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_ID_FAILURE,
  GET_USER_ID_REQUEST,
  GET_USER_ID_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  UPDATE_USER_IMAGE_FAILURE,
  UPDATE_USER_IMAGE_REQUEST,
  UPDATE_USER_IMAGE_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: false,
  loading: false,
  data: null,
  success: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      console.log("REQUEST");
      state = {
        ...state,
        data: [],
        loading: true,
        error: false,
      };
      break;

    case GET_USERS_SUCCESS:
      console.log("SUCCESS");
      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_USERS_FAILURE:
      console.log("FAILURE");
      state = {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
      break;

    case GET_USER_REQUEST:
      console.log("REQUEST");
      state = {
        ...state,
        data: {},
        loading: true,
        error: false,
      };
      break;

    case GET_USER_SUCCESS:
      console.log("SUCCESS");
      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_USER_FAILURE:
      console.log("FAILURE");
      state = {
        ...state,
        data: {},
        loading: false,
        error: true,
      };
      break;

    case GET_USER_ID_REQUEST:
      console.log("REQUEST");
      state = {
        ...state,
        data: {},
        loading: true,
        error: false,
      };
      break;

    case GET_USER_ID_SUCCESS:
      console.log("SUCCESS");
      state = {
        ...state,
        data: action.data,
        error: false,
        loading: false,
      };
      break;

    case GET_USER_ID_FAILURE:
      console.log("FAILURE");
      state = {
        ...state,
        data: {},
        loading: false,
        error: true,
      };
      break;

    case UPDATE_USER_IMAGE_REQUEST:
      console.log("REQUEST =)");
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
      break;

    case UPDATE_USER_IMAGE_SUCCESS:
      console.log("SUCCESS =)");
      state = {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
      break;

    case UPDATE_USER_IMAGE_FAILURE:
      console.log("ERROR =(");
      state = {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
