// export function isLatitude(latitude) {
//   const lat = parseFloat(latitude);
//   return !isNaN(lat) && lat >= -90 && lat <= 90;
// }

export function isLatitude(latitude) {
  if (latitude.includes(",")) {
    return false;
  }
  const lat = parseFloat(latitude);
  return !isNaN(lat) && lat >= -90 && lat <= 90;
}

export function isLongitude(longitude) {
  if (longitude.includes(",")) {
    return false;
  }
  const lng = parseFloat(longitude);
  return !isNaN(lng) && lng >= -180 && lng <= 180;
}

export function hoursForMin(time) {
  const splitTime = time.split(":");

  const hoursInMin = parseInt(splitTime[0]) * 60;
  const min = parseInt(splitTime[1]);
  const total = hoursInMin + min;

  return total;
}

export function minForHours(min) {
  const hours = Math.floor(min / 60);
  const minRemaining = min % 60;
  const hoursStr = hours.toString().padStart(2, "0");
  const minStr = minRemaining.toString().padStart(2, "0");

  return hoursStr + ":" + minStr;
}

export function unformatPhone(phoneFormatted) {
  return phoneFormatted?.replace(/[^0-9]+/g, "");
}

export function formatPhone(phone) {
  console.log(">>>", phone);
  phone = phone.replace(/\D/g, "");
  phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2");
  phone = phone.replace(/(\d)(\d{4})$/, "$1-$2");
  return phone;
}

export function formatMinutesToDuration(minutes)  {
  const days = Math.floor(minutes / 1440);
  const hours = Math.floor((minutes % 1440) / 60);
  const mins = minutes % 60;

  return `${days > 0 ? `${days}d ` : ''}${hours > 0 ? `${hours}h ` : ''}${mins > 0 ? `${mins}m` : ''}`.trim();
}

export function parseDurationToMinutes(duration) {
    const regex = /(?:(\d+)d)?\s*(?:(\d+)h)?\s*(?:(\d+)m)?/;
    const match = duration.match(regex);
    if (!match) return 0;

    const days = parseInt(match[1] || 0, 10);
    const hours = parseInt(match[2] || 0, 10);
    const minutes = parseInt(match[3] || 0, 10);

    return days * 1440 + hours * 60 + minutes;
}

export const DURATION_REGEX = /^(?:(\d+)d\s*)?(?:(\d+)h\s*)?(?:(\d+)m\s*)?$/;
