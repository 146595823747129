import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import { Row } from "reactstrap";
import Loading from "../../Components/Loading/Loading";
import ModalCustom from "../../Components/Modals/ModalCustom";

function UserTable({ users, loading }) {
  const [modal, setModal] = useState(false);

  if (loading) {
    return <Loading />;
  }

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Nome</span>,
      selector: (row) => row.name || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">E-mail</span>,
      selector: (row) => row.email || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Usuário</span>,
      selector: (row) => row.username || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Cidade</span>,
      selector: (row) => row.cidade || "N/A",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Estado</span>,
      width: "10%",
      selector: (row) => row.estado || "N/A",
      sortable: true,
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <ToastContainer />
        <DataTable
          columns={columns}
          data={users}
          pagination
          noDataComponent="Não existem dados na tabela de usuários!"
        />

        <ModalCustom
          isOpen={modal}
          labelConfirm="OK"
          onClick={() => setModal(false)}
        >
          Em Breve você poderá visualizar detalhes deste usuário!
        </ModalCustom>
      </Row>
    </React.Fragment>
  );
}

export { UserTable };
