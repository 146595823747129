import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../../boot/api";
import showNofity from "../../../helpers/toast";
import {
  getContactUsFailure,
  getContactUsSuccess,
  updateAnswerContactUsFailure,
  updateAnswerContactUsSuccess,
} from "./actions";
import {
  GET_CONTACT_US_REQUEST,
  UPDATE_ANSWER_CONTACT_US_REQUEST,
} from "./actionTypes";

function requestContactUs(id) {
  return post("/contact-us", { contactsUsId: id });
}

function requestUpdateContactUs({ id, answered }) {
  return post("/contact-us-update", { contactsUsId: id, respondido: answered });
}

function* getContactUs({ data }) {
  try {
    const response = yield call(requestContactUs, data);
    yield put(getContactUsSuccess(response.data.result));
  } catch (error) {
    showNofity.error("Erro ao buscar informação. " + error);
    yield put(getContactUsFailure());
  }
}

function* updateContactUs({ data }) {
  try {
    const response = yield call(requestUpdateContactUs, data);
    yield put(getContactUsSuccess(response.data.result[0]));
    yield put(updateAnswerContactUsSuccess(response.data.result[0]));
  } catch (error) {
    showNofity.error("Erro ao atualizar se foi respondido. " + error);
    yield put(updateAnswerContactUsFailure());
  }
}

function* saga() {
  yield takeLatest(GET_CONTACT_US_REQUEST, getContactUs);
  yield takeLatest(UPDATE_ANSWER_CONTACT_US_REQUEST, updateContactUs);
}

export default saga;
