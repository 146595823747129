import {
  CREATE_HOTEL_FAILURE,
  CREATE_HOTEL_REQUEST,
  CREATE_HOTEL_SUCCESS,
  DELETE_HOTEL_FAILURE,
  DELETE_HOTEL_PHOTO_FAILURE,
  DELETE_HOTEL_PHOTO_REQUEST,
  DELETE_HOTEL_PHOTO_SUCCESS,
  DELETE_HOTEL_REQUEST,
  DELETE_HOTEL_SUCCESS,
  GET_HOTELS_FAILURE,
  GET_HOTELS_REQUEST,
  GET_HOTELS_SUCCESS,
  GET_HOTEL_FAILURE,
  GET_HOTEL_REQUEST,
  GET_HOTEL_SUCCESS,
  UPDATE_HOTEL_FAILURE,
  UPDATE_HOTEL_PHOTO_FAILURE,
  UPDATE_HOTEL_PHOTO_REQUEST,
  UPDATE_HOTEL_PHOTO_SUCCESS,
  UPDATE_HOTEL_REQUEST,
  UPDATE_HOTEL_SUCCESS,
} from "./actionTypes";

export const getHotelsRequest = (data) => {
  return {
    type: GET_HOTELS_REQUEST,
    data,
  };
};

export const getHotelsSuccess = (data) => {
  return {
    type: GET_HOTELS_SUCCESS,
    data,
  };
};

export const getHotelsFailure = () => {
  return {
    type: GET_HOTELS_FAILURE,
  };
};

export const getHotelFailure = () => {
  return {
    type: GET_HOTEL_FAILURE,
  };
};

export const getHotelRequest = (data) => {
  return {
    type: GET_HOTEL_REQUEST,
    data,
  };
};

export const getHotelSuccess = (data) => {
  return {
    type: GET_HOTEL_SUCCESS,
    data,
  };
};

export const createHotelRequest = (data) => {
  return {
    type: CREATE_HOTEL_REQUEST,
    data,
  };
};

export const createHotelSuccess = (data) => {
  return {
    type: CREATE_HOTEL_SUCCESS,
    data,
  };
};

export const createHotelFailure = () => {
  return {
    type: CREATE_HOTEL_FAILURE,
  };
};

export const deleteHotelFailure = () => {
  return {
    type: DELETE_HOTEL_FAILURE,
  };
};

export const deleteHotelRequest = (data) => {
  return {
    type: DELETE_HOTEL_REQUEST,
    data,
  };
};

export const deleteHotelSuccess = (data) => {
  return {
    type: DELETE_HOTEL_SUCCESS,
    data,
  };
};

export const deleteHotelPhotoFailure = () => {
  return {
    type: DELETE_HOTEL_PHOTO_FAILURE,
  };
};

export const deleteHotelPhotoRequest = (data) => {
  return {
    type: DELETE_HOTEL_PHOTO_REQUEST,
    data,
  };
};

export const deleteHotelPhotoSuccess = (data) => {
  return {
    type: DELETE_HOTEL_PHOTO_SUCCESS,
    data,
  };
};

export const updateHotelFailure = () => {
  return {
    type: UPDATE_HOTEL_FAILURE,
  };
};

export const updateHotelRequest = (data) => {
  return {
    type: UPDATE_HOTEL_REQUEST,
    data,
  };
};

export const updateHotelSuccess = (data) => {
  return {
    type: UPDATE_HOTEL_SUCCESS,
    data,
  };
};

export const updateHotelPhotoFailure = () => {
  return {
    type: UPDATE_HOTEL_PHOTO_FAILURE,
  };
};

export const updateHotelPhotoRequest = (data) => {
  return {
    type: UPDATE_HOTEL_PHOTO_REQUEST,
    data,
  };
};

export const updateHotelPhotoSuccess = (data) => {
  return {
    type: UPDATE_HOTEL_PHOTO_SUCCESS,
    data,
  };
};
