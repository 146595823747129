import {
  CREATE_STORE_FAILURE,
  CREATE_STORE_REQUEST,
  CREATE_STORE_SUCCESS,
  DELETE_STORE_FAILURE,
  DELETE_STORE_PHOTO_FAILURE,
  DELETE_STORE_PHOTO_REQUEST,
  DELETE_STORE_PHOTO_SUCCESS,
  DELETE_STORE_REQUEST,
  DELETE_STORE_SUCCESS,
  GET_STORES_FAILURE,
  GET_STORES_REQUEST,
  GET_STORES_SUCCESS,
  GET_STORE_FAILURE,
  GET_STORE_REQUEST,
  GET_STORE_SUCCESS,
  UPDATE_STORE_FAILURE,
  UPDATE_STORE_PHOTO_FAILURE,
  UPDATE_STORE_PHOTO_REQUEST,
  UPDATE_STORE_PHOTO_SUCCESS,
  UPDATE_STORE_REQUEST,
  UPDATE_STORE_SUCCESS,
} from "./actionTypes";

export const getStoresRequest = (data) => {
  return {
    type: GET_STORES_REQUEST,
    data,
  };
};

export const getStoresSuccess = (data) => {
  return {
    type: GET_STORES_SUCCESS,
    data,
  };
};

export const getStoresFailure = () => {
  return {
    type: GET_STORES_FAILURE,
  };
};

export const getStoreFailure = () => {
  return {
    type: GET_STORE_FAILURE,
  };
};

export const getStoreRequest = (data) => {
  return {
    type: GET_STORE_REQUEST,
    data,
  };
};

export const getStoreSuccess = (data) => {
  return {
    type: GET_STORE_SUCCESS,
    data,
  };
};

export const createStoreRequest = (data) => {
  return {
    type: CREATE_STORE_REQUEST,
    data,
  };
};

export const createStoreSuccess = (data) => {
  return {
    type: CREATE_STORE_SUCCESS,
    data,
  };
};

export const createStoreFailure = () => {
  return {
    type: CREATE_STORE_FAILURE,
  };
};

export const deleteStoreFailure = () => {
  return {
    type: DELETE_STORE_FAILURE,
  };
};

export const deleteStoreRequest = (data) => {
  return {
    type: DELETE_STORE_REQUEST,
    data,
  };
};

export const deleteStoreSuccess = (data) => {
  return {
    type: DELETE_STORE_SUCCESS,
    data,
  };
};

export const deleteStorePhotoFailure = () => {
  return {
    type: DELETE_STORE_PHOTO_FAILURE,
  };
};

export const deleteStorePhotoRequest = (data) => {
  return {
    type: DELETE_STORE_PHOTO_REQUEST,
    data,
  };
};

export const deleteStorePhotoSuccess = (data) => {
  return {
    type: DELETE_STORE_PHOTO_SUCCESS,
    data,
  };
};

export const updateStoreFailure = () => {
  return {
    type: UPDATE_STORE_FAILURE,
  };
};

export const updateStoreRequest = (data) => {
  return {
    type: UPDATE_STORE_REQUEST,
    data,
  };
};

export const updateStoreSuccess = (data) => {
  return {
    type: UPDATE_STORE_SUCCESS,
    data,
  };
};

export const updateStorePhotoFailure = () => {
  return {
    type: UPDATE_STORE_PHOTO_FAILURE,
  };
};

export const updateStorePhotoRequest = (data) => {
  return {
    type: UPDATE_STORE_PHOTO_REQUEST,
    data,
  };
};

export const updateStorePhotoSuccess = (data) => {
  return {
    type: UPDATE_STORE_PHOTO_SUCCESS,
    data,
  };
};
