import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { VectorMap } from "react-jvectormap";
import ISOCodes from './ISO-3166-1_alpha-2.json';
import "./jquery-jvectormap.scss";

const mapRef = React.createRef(null);

const cleanupTooltips = () => {
  const tooltipEls = document.querySelectorAll('.jvectormap-tip');
  for (let i = 0; i < tooltipEls.length - 1; i++) {
    tooltipEls.item(i).remove();
  }
};

const Vectormap = (props) => {
  const series = {
    regions: [{
      values: props.data,
      scale: ['#b4aed5', '#4b38b3'],
      normalizeFunction: 'polynomial',
    }],
  };

  const onRegionTipShow = (event, element, code) => {
    const countryName = ISOCodes[code] || element.text();
    const value = props.data[code] || 0;

    element.html(`${countryName} - ${value}`);
  };

  useEffect(cleanupTooltips, [props.data]);

  return (
    <div style={{ width: props.width, height: 350 }} onMouseLeave={cleanupTooltips}>
      <VectorMap
        map={props.mapName}
        zoomOnScroll={false}
        zoomButtons={false}
        backgroundColor="transparent"
        ref={mapRef}
        series={series}
        containerStyle={{
          width: "100%",
          height: "80%",
        }}
        regionStyle={{
          initial: {
            stroke: "#9599ad",
            strokeWidth: 0.25,
            fill: "#e5e5e5",
            fillOpacity: 1,
          },
        }}
        lineStyle={{
          animation: true,
          strokeDasharray: "6 3 6",
        }}
        onRegionTipShow={onRegionTipShow}
      />
    </div>
  );
};

Vectormap.propTypes = {
  color: PropTypes.string,
  mapName: PropTypes.any,
  width: PropTypes.any,
  data: PropTypes.array,
};

export default Vectormap;
