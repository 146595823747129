import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { useDebounce } from "use-debounce";
import * as Yup from "yup";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { MANDATORY_FIELD } from "../../Components/constants/messages";
import ModalCustom from "../../Components/Modals/ModalCustom";
import {
  createFilterServiceRequest,
  getFiltersServiceRequest,
  updateFilterServiceRequest,
} from "../../store/filters/service/actions";
import { getFilterServiceRequest } from "../../store/filters/serviceDetails/actions";
import FiltersServiceForm from "./FiltersServiceForm";
import { FiltersServiceTable } from "./FiltersServiceTable";

const FiltersServiceList = () => {
  document.title = "Filtros serviço | Ecoguias - Admin Dashboard";

  const [modalAddFilter, setModalAddFilter] = useState(false);
  const [modalEditFilter, setModalEditFilter] = useState(false);
  const [idFilterService, setIdFilterService] = useState(null);

  const dispatch = useDispatch();

  const { loading, data } = useSelector((state) => state.FiltersService);
  const { loading: loadingDetails, data: dataDetails } = useSelector(
    (state) => state.FiltersServiceDetails
  );

  const [searchInput, setSearchInput] = useState("");
  const [value] = useDebounce(searchInput, 500);

  function closeModal() {
    return setModalAddFilter(false) || setModalEditFilter(false);
  }

  const filterService = useFormik({
    enableReinitialize: true,
    initialValues: Object.assign({
      nome: dataDetails && modalEditFilter ? dataDetails.nome : "",
    }),

    validationSchema: Yup.object({
      nome: Yup.string("O nome do filtro deve ser do tipo texto").required(
        MANDATORY_FIELD
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(createFilterServiceRequest({ values, resetForm, closeModal }));
    },
  });

  useEffect(() => {
    dispatch(getFiltersServiceRequest(value));
  }, [dispatch, value]);

  useEffect(() => {
    if (idFilterService && modalEditFilter) {
      dispatch(getFilterServiceRequest(idFilterService));
    }
  }, [dispatch, idFilterService, modalEditFilter]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Tabela de filtros serviço" pageTitle="Serviço" />
          <div className="d-flex justify-content-between pb-4 flex-wrap">
            <Input
              type="text"
              style={{ maxWidth: 250 }}
              className="form-control"
              placeholder="Pesquisar..."
              id="search-options"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={(event) => {
                event.preventDefault();
                setModalAddFilter(true);
              }}
            >
              Cadastrar
            </button>
          </div>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Filtros - Serviço</h5>
                </CardHeader>

                <CardBody>
                  <FiltersServiceTable
                    style={{ width: "100%" }}
                    filters={data?.result ?? []}
                    loading={loading || loadingDetails}
                    idFilterService={idFilterService}
                    setIdFilterService={setIdFilterService}
                    showModalEdit={() => {
                      setModalEditFilter(true);
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ModalCustom
        title="Adicionar filtro para o serviço"
        isOpen={modalAddFilter}
        onCancel={(event) => {
          event.preventDefault();
          setModalAddFilter(false);
        }}
        onClick={async (event) => {
          event.preventDefault();
          filterService.handleSubmit();
          return null;
        }}
      >
        <FiltersServiceForm form={filterService} />
      </ModalCustom>

      <ModalCustom
        title="Editar filtro do serviço"
        isOpen={modalEditFilter}
        onCancel={(event) => {
          event.preventDefault();
          setModalEditFilter(false);
          setIdFilterService(null);
        }}
        onClick={(event) => {
          event.preventDefault();
          dispatch(
            updateFilterServiceRequest({
              id: idFilterService,
              ...filterService,
              closeModal,
            })
          );

          setIdFilterService(null);
          return null;
        }}
      >
        <FiltersServiceForm form={filterService} />
      </ModalCustom>
    </React.Fragment>
  );
};

export default FiltersServiceList;
