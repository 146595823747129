import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
// import { getCitiesRequest } from "../../../store/city/actions";

const InfoTouristReception = ({ data }) => {
  // const dispatch = useDispatch();

  // const { data: dataCity } = useSelector((state) => state.City);

  // useEffect(() => {
  //   dispatch(getCitiesRequest());
  // }, [dispatch]);

  return (
    <React.Fragment>
      <Col key={data?.objectId}>
        <p className="text-justify">{data?.descritivo}</p>

        {data?.endereco ? (
          <Row>
            <Col xl={2}>
              <i className="ri-map-pin-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Endereço:</label>
              <p>{data?.endereco}</p>
            </Col>
          </Row>
        ) : null}

        {data?.cidade ? (
          <Row>
            <Col xl={2}>
              <i className="ri-building-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Cidade:</label>
              <p>
                {data.cidade?.nome ?? ""}
                {/* {dataCity?.length > 0 &&
                  dataCity?.find(
                    ({ objectId }) => objectId === data.cidade.objectId
                  )?.nome} */}
              </p>
            </Col>
          </Row>
        ) : null}

        {data?.gps ? (
          <Row>
            <Col xl={2}>
              <i className="ri-map-2-line  fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>(latitude, longitude):</label>
              <p>
                {data?.gps?.latitude} / {data?.gps?.longitude}
              </p>
            </Col>
          </Row>
        ) : null}

        {data?.url_site ? (
          <Row>
            <Col xl={2}>
              <i className="ri-global-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Site:</label>
              <p>{data?.url_site}</p>
            </Col>
          </Row>
        ) : null}

        {data?.telefones ? (
          <Row>
            <Col xl={2}>
              <i className="ri-phone-fill fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Telefone:</label>
              <p>{data?.telefones}</p>
            </Col>
          </Row>
        ) : null}

        {data?.email ? (
          <Row>
            <Col xl={2}>
              <i className="ri-mail-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>E-mail:</label>
              <p>{data?.email}</p>
            </Col>
          </Row>
        ) : null}

        {data?.url_video ? (
          <Row>
            <Col xl={2}>
              <i className="ri-youtube-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Vídeo:</label>
              <p>{data?.url_video}</p>
            </Col>
          </Row>
        ) : null}

        {data?.plano ? (
          <Row>
            <Col xl={2}>
              <i className="ri-file-line  fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Plano:</label>
              <p>{data?.plano}</p>
            </Col>
          </Row>
        ) : null}
      </Col>
    </React.Fragment>
  );
};

export default InfoTouristReception;
