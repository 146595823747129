import React from "react";
import { Col, Row } from "reactstrap";

const InfoCity = ({ data }) => {
  return (
    <React.Fragment>
      <Col key={data?.objectId}>
        <p className="text-justify">{data?.descritivo}</p>

        {data?.populacao ? (
          <Row>
            <Col xl={2}>
              <i className="ri-team-fill fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>População:</label>
              <p>{data?.populacao} habitantes</p>
            </Col>
          </Row>
        ) : null}

        {data?.site ? (
          <Row>
            <Col xl={2}>
              <i className="ri-global-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Site:</label>
              <p>{data?.site}</p>
            </Col>
          </Row>
        ) : null}

        {data?.telefone ? (
          <Row>
            <Col xl={2}>
              <i className="ri-phone-fill fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>Telefone:</label>
              <p>{data?.telefone}</p>
            </Col>
          </Row>
        ) : null}

        {data?.email ? (
          <Row>
            <Col xl={2}>
              <i className="ri-mail-line fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>E-mail:</label>
              <p>{data?.email}</p>
            </Col>
          </Row>
        ) : null}

        {data?.gps ? (
          <Row>
            <Col xl={2}>
              <i className="ri-map-2-line  fs-36"></i>
            </Col>
            <Col xl={10}>
              <label>(latitude, longitude):</label>
              <p>
                {data?.gps?.latitude} / {data?.gps?.longitude}
              </p>
            </Col>
          </Row>
        ) : null}
      </Col>
    </React.Fragment>
  );
};

export default InfoCity;
