import { call, put, takeLatest } from "redux-saga/effects";
import { post } from "../../../boot/api";
import showNofity from "../../../helpers/toast";
import { getSuggestionFailure, getSuggestionSuccess } from "./actions";
import { GET_SUGGESTION_REQUEST } from "./actionTypes";

function requestSuggestion(id) {
  return post("/suggestion", { suggestionId: id });
}

function* getSuggestion({ data }) {
  try {
    const response = yield call(requestSuggestion, data);
    yield put(getSuggestionSuccess(response.data.result));
  } catch (error) {
    showNofity.error("Erro ao buscar sugestão. " + error);
    yield put(getSuggestionFailure());
  }
}

function* saga() {
  yield takeLatest(GET_SUGGESTION_REQUEST, getSuggestion);
}

export default saga;
