import React, {
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import ReactApexChart from "react-apexcharts";
import ReactEcharts from "echarts-for-react";

import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import moment from 'moment';

const BAR_COLOR = '#64a8eb';

const barChartWithInternalLabelsOption = ({
    seriesLabels,
    seriesData,
    textWidth,
}) => {
    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
        },
        legend: {
            textStyle: { //The style of the legend text
                color: '#858d98',
            },
        },
        grid: {
            left: '16',
            right: '16',
            bottom: '5',
            top: '0%',
            containLabel: true,
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        yAxis: {
            type: 'category',
            data: seriesLabels,
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                },
            },
            axisLabel: {
                inside: true,
                color: '#555',
            },
            z: 100,
        },
        textStyle: {
            fontFamily: 'Poppins, sans-serif'
        },
        color: [BAR_COLOR],
        series: {
            type: 'bar',
            data: seriesData
        },
        dataZoom: getDataZoomForBarChart(seriesData.length),
    };
    if (textWidth) {
        options.yAxis.axisLabel.overflow = 'breakAll';
        options.yAxis.axisLabel.width = textWidth - 68;
    }
    return options;
};

const SecoesMaisAcessadasChart = ({ seriesData, seriesLabels, textWidth }) => {
    const chartRef = useRef(null);
    const [chartWidth, setChardWidth] = useState();
    useLayoutEffect(() => {
        if (chartRef.current) {
            const { width } = chartRef.current.ele.getBoundingClientRect();
            setChardWidth(width);
        }
    }, []);

    const options = barChartWithInternalLabelsOption({
        seriesData,
        seriesLabels,
        textWidth: chartWidth,
    });

    return (
        <React.Fragment>
            <ReactEcharts
                ref={chartRef}
                style={{ height: "350px" }}
                option={options}
            />
        </React.Fragment>
    );
};

const CidadesMaisAcessadasChart = ({ seriesData, seriesLabels }) => {
    var option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            textStyle: { //The style of the legend text
                color: '#858d98',
            },
        },
        grid: {
            left: '4%',
            right: '4%',
            bottom: '5',
            top: '0%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        yAxis: {
            type: 'category',
            data: seriesLabels,
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            },
        },
        textStyle: {
            fontFamily: 'Poppins, sans-serif'
        },
        color: [BAR_COLOR],
        series: {
            type: 'bar',
            data: seriesData
        },
        dataZoom: getDataZoomForBarChart(seriesData.length),
    };

    return (
        <React.Fragment>
            <ReactEcharts style={{ height: "350px" }} option={option} />
        </React.Fragment>
    );
};

const CidadesMaisBuscadasChart = ({ seriesData, seriesLabels }) => {
    var option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            textStyle: { //The style of the legend text
                color: '#858d98',
            },
        },
        grid: {
            left: '4%',
            right: '4%',
            bottom: '5',
            top: '0%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        yAxis: {
            type: 'category',
            data: seriesLabels,
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        textStyle: {
            fontFamily: 'Poppins, sans-serif'
        },
        color: [BAR_COLOR],
        series: {
            type: 'bar',
            data: seriesData
        },
        dataZoom: getDataZoomForBarChart(seriesData.length),
    };

    return (
        <React.Fragment>
            <ReactEcharts style={{ height: "350px" }} option={option} />
        </React.Fragment>
    );
};

const secondsToMinuteSeconds = (secondsStr) => moment.utc(+secondsStr * 1000).format('mm:ss');

const TempoMedioEngajamentoPorPaginaChart = ({ seriesData, seriesLabels }) => {
    const chartRef = useRef(null);
    const [chartWidth, setChardWidth] = useState();
    useLayoutEffect(() => {
        if (chartRef.current) {
            const { width } = chartRef.current.ele.getBoundingClientRect();
            setChardWidth(width);
        }
    }, []);

    const options = barChartWithInternalLabelsOption({
        seriesData,
        seriesLabels,
        textWidth: chartWidth,
    });
    options.tooltip.valueFormatter = secondsToMinuteSeconds;
    options.xAxis.axisLabel = { formatter: secondsToMinuteSeconds };

    return (
        <React.Fragment>
            <ReactEcharts
                ref={chartRef}
                style={{ height: "350px" }}
                option={options}
            />
        </React.Fragment>
    );
};

const UsuariosPorCidadeChart = ({ dataColors, seriesData, seriesLabels }) => {
    // var chartBarLabelRotationColors = getChartColorsArray(dataColors);
    var option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
        },
        legend: {
            textStyle: { //The style of the legend text
                color: '#858d98',
            },
        },
        grid: {
            left: '16',
            right: '4%',
            bottom: '5',
            containLabel: true
        },
        yAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        xAxis: {
            type: 'category',
            data: seriesLabels,
            axisLabel: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        textStyle: {
            fontFamily: 'Poppins, sans-serif'
        },
        color: [BAR_COLOR],
        series: {
            type: 'bar',
            data: seriesData,
            label: {
                show: true,
                align: 'left',
                position: 'insideBottom',
                verticalAlign: 'middle',
                rotate: 90,
                formatter: function (params) {
                    return seriesLabels[params.dataIndex];
                },
                color: '#fff',
                textShadowBlur: 2,
                textShadowColor: '#000',
                textShadowOffsetX: 0,
                textShadowOffsetY: 0,
            },
        },
        dataZoom: [{
            type: 'slider',
            xAxisIndex: 0,
            minValueSpan: 9,
            maxValueSpan: 50,
            startValue: 0,
            endValue: Math.min(seriesData.length - 1, 9),
            showDetail: false,
            top: 'top',
            height: 10,
        }],
    };

    return (
        <React.Fragment>
            <ReactEcharts style={{ height: "350px" }} option={option} />
        </React.Fragment>
    );
};

const UsuariosPorFaixaEtariaChart = ({ seriesData, seriesLabels }) => {
    var option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
        },
        legend: {
            textStyle: { //The style of the legend text
                color: '#858d98',
            },
        },
        grid: {
            top: '16',
            left: '0',
            right: '16',
            bottom: '0',
            containLabel: true
        },
        yAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        xAxis: {
            type: 'category',
            data: seriesLabels,
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        textStyle: {
            fontFamily: 'Poppins, sans-serif'
        },
        color: [BAR_COLOR],
        series: {
            type: 'bar',
            data: seriesData,
        },
        dataZoom: getDataZoomForBarChart(seriesData.length),
    };

    return (
        <React.Fragment>
            <ReactEcharts style={{ height: "350px" }} option={option} />
        </React.Fragment>
    );
};

const getDataZoomForBarChart = (seriesLength) => {
    if (seriesLength <= 10) {
        return;
    }

    return [{
        type: 'slider',
        yAxisIndex: 0,
        minValueSpan: 9,
        maxValueSpan: 50,
        startValue: seriesLength - 10,
        endValue: seriesLength - 1,
        showDetail: false,
        width: 10,
        right: 16,
        moveHandleSize: 16,
    }];
};

const UsuariosPorIdiomaChart = ({ seriesData, seriesLabels }) => {
    var option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
        },
        legend: {
            textStyle: { //The style of the legend text
                color: '#858d98',
            },
        },
        grid: {
            left: '4%',
            right: '4%',
            bottom: '5',
            top: '0%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        yAxis: {
            type: 'category',
            data: seriesLabels,
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        textStyle: {
            fontFamily: 'Poppins, sans-serif'
        },
        color: [BAR_COLOR],
        series: {
            type: 'bar',
            data: seriesData
        },
        dataZoom: getDataZoomForBarChart(seriesData.length),
    };

    return (
        <React.Fragment>
            <ReactEcharts style={{ height: "350px" }} option={option} />
        </React.Fragment>
    );
};

const UsuariosPorDispositivoChart = ({ dataColors, seriesData, seriesLabels }) => {
    const colors = getChartColorsArray( dataColors);

    const options = {
        labels: seriesLabels,
        chart: {
            type: "donut",
            height: 219,
        },
        plotOptions: {
            pie: {
                size: 100,
                donut: {
                    size: "60%",
                },
                expandOnClick: false,
            },
        },
        dataLabels: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 20,
                height: 6,
                radius: 2,
            },
            itemMargin: {
                horizontal: 12,
                vertical: 0
            },
        },
        stroke: {
            width: 0
        },
        yaxis: {
            tickAmount: 4,
            min: 0
        },
        colors,
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={seriesData}
                type="donut"
                height="350"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const colorsByPlatform = (platforms) => {
    const androidColor = '#3DDC84';
    const iOSColor = '#0A84FF';

    if (platforms.length <= 2) {
        const colors = [];

        platforms.forEach((platform) => {
            const platformLowerCase = platform.toLowerCase();
            if (platformLowerCase === 'android') {
                colors.push(androidColor);
            } else if (platformLowerCase === 'ios') {
                colors.push(iOSColor);
            }
        });

        if (colors.length === platforms.length) {
            return colors;
        }
    }

    // falls back to default from template
    return getChartColorsArray('["--vz-primary", "--vz-warning", "--vz-info"]');
};

const UsuariosPorPlataformaChart = ({ seriesLabels, seriesData }) => {
    const options = {
        labels: seriesLabels,
        chart: {
            type: "donut",
            height: 219,
        },
        plotOptions: {
            pie: {
                size: 100,
                donut: {
                    size: "60%",
                },
                expandOnClick: false,
            },
        },
        dataLabels: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 20,
                height: 6,
                radius: 2,
            },
            itemMargin: {
                horizontal: 12,
                vertical: 0
            },
        },
        stroke: {
            width: 0
        },
        yaxis: {
            tickAmount: 4,
            min: 0
        },
        colors: colorsByPlatform(seriesLabels),
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={seriesData}
                type="donut"
                height="350"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const UsuariosPorInteresseChart = ({ seriesLabels, seriesData }) => {
    const chartRef = useRef(null);
    const [chartWidth, setChardWidth] = useState();
    useLayoutEffect(() => {
        if (chartRef.current) {
            const { width } = chartRef.current.ele.getBoundingClientRect();
            setChardWidth(width);
        }
    }, []);

    const options = barChartWithInternalLabelsOption({
        seriesData,
        seriesLabels,
        textWidth: chartWidth,
    });

    return (
        <React.Fragment>
            <ReactEcharts
                ref={chartRef}
                style={{ height: "350px" }}
                option={options}
            />
        </React.Fragment>
    );
};

const UsuariosPorGeneroChart = ({ dataColors, seriesLabels, seriesData }) => {
    const colors = getChartColorsArray(dataColors);
    const options = {
        labels: seriesLabels,
        chart: {
            type: "donut",
            height: 219,
        },
        plotOptions: {
            pie: {
                size: 100,
                donut: {
                    size: "60%",
                },
                expandOnClick: false,
            },
        },
        dataLabels: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 20,
                height: 6,
                radius: 2,
            },
            itemMargin: {
                horizontal: 12,
                vertical: 0
            },
        },
        stroke: {
            width: 0
        },
        yaxis: {
            tickAmount: 4,
            min: 0
        },
        colors: colors,
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={seriesData}
                type="donut"
                height="350"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export {
    CidadesMaisAcessadasChart,
    CidadesMaisBuscadasChart,
    SecoesMaisAcessadasChart,
    TempoMedioEngajamentoPorPaginaChart,
    UsuariosPorCidadeChart,
    UsuariosPorDispositivoChart,
    UsuariosPorGeneroChart,
    UsuariosPorFaixaEtariaChart,
    UsuariosPorIdiomaChart,
    UsuariosPorInteresseChart,
    UsuariosPorPlataformaChart,
};