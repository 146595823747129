import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import ModalLoding from "../../Components/Common/ModalLoading";
import * as Yup from "yup";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Modal from "../../Components/Common/Modal";

import UiContent from "../../Components/Common/UiContent";
import { MANDATORY_FIELD } from "../../Components/constants/messages";
import {
  CoverUploadImageField,
  InputMask,
  TextField,
} from "../../Components/Forms";
import LazyImage from "../../Components/LazyImage/LazyImage";
import Loading from "../../Components/Loading/Loading";
import PlaceMenu from "../../Components/Places/PlaceMenu";
import { getCitiesRequest } from "../../store/city/actions";
import { getFiltersTouristSpotRequest } from "../../store/filters/touristSpot/actions";
import {
  deleteTouristSpotPhotoRequest,
  getTouristSpotRequest,
  updateTouristSpotPhotoRequest,
  updateTouristSpotRequest,
} from "../../store/touristSpots/actions";
import { isLatitude, isLongitude } from "../../utils/formats";
import { api } from "../../boot/api";
import showNofity from "../../helpers/toast";

const TouristSpotForm = () => {
  document.title = "Atrativo turístico | Ecoguias - Admin Dashboard";
  const dispatch = useDispatch();

  const { loading, data } = useSelector((state) => state.TouristSpots);
  const { data: dataCity, loading: loadingCity } = useSelector(
    (state) => state.City
  );
  const { data: dataFilter, loadingFilter } = useSelector(
    (state) => state.FiltersTouristSpot
  );
  const [modalIsOpenRemove, setModalIsOpenRemove] = useState(false);
  const [modalIsOpenSelect, setModalIsOpenSelect] = useState(false);
  const [photoId, setPhotoId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [legendImage, setLegendImage] = useState([]);

  const { id } = useParams();
  const history = useHistory();

  useEffect(
    function handleDefaultValuesEditForm() {
      dispatch(getTouristSpotRequest(id));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(getCitiesRequest());
    dispatch(getFiltersTouristSpotRequest());
  }, [dispatch]);

  const spotExists = data?.result?.touristSpot;
  const spotResponse = data?.result?.touristSpot;

  const touristSpot = useFormik({
    enableReinitialize: true,

    initialValues: {
      nome: spotExists ? spotResponse?.nome : "",
      descritivo: spotExists ? spotResponse?.descritivo : "",
      entre_contato: spotExists ? spotResponse?.entre_contato : "",
      endereco: spotExists ? spotResponse?.endereco : "",
      horario_funcionamento: spotExists
        ? spotResponse?.horario_funcionamento
        : "",
      email: spotExists ? spotResponse?.email : "",
      telefone: spotExists ? spotResponse?.telefone : "",
      images: spotExists ? spotResponse?.images : [],
      latitude: spotExists ? spotResponse?.gps?.latitude : "",
      longitude: spotExists ? spotResponse?.gps?.longitude : "",
      cidade: spotExists ? spotResponse?.cidade?.objectId : "",
      imagem_principal: 0,
      filtro_ponto_turistico: spotExists
        ? spotResponse?.filtro_ponto_turistico?.objectId
        : "",
    },

    validationSchema: Yup.object({
      nome: Yup.string().required(MANDATORY_FIELD),
      descritivo: Yup.string().required(MANDATORY_FIELD),
      telefone: Yup.string(),
      entre_contato: Yup.string(),
      cidade: Yup.string(),
      endereco: Yup.string(),
      horario_funcionamento: Yup.string(),
      email: Yup.string(),
      latitude: Yup.string()
        .nullable()
        .test("is-latitude", "Insira uma latitude válida", (value) =>
          value ? isLatitude(value) : true
        ),
      longitude: Yup.string()
        .nullable()
        .test("is-longitude", "Insira uma longitude válida", (value) =>
          value ? isLongitude(value) : true
        ),
      // .required(MANDATORY_FIELD),
      images: Yup.array(Yup.string()),
      filtro_ponto_turistico: Yup.string().required(MANDATORY_FIELD),
    }),
    onSubmit: async (values) => {
      values.latitude = values.latitude ? Number(values.latitude) : 0.0;
      values.longitude = values.longitude ? Number(values.longitude) : 0.0;
      dispatch(
        updateTouristSpotRequest({
          id: id,
          values,
          onSuccess: () => touristSpot.setFieldValue("images", []),
        })
      );
    },
  });

  function addLegendInState(index, txt) {
    const copyLegend = [...legendImage];
    copyLegend[index] = txt;

    setLegendImage(copyLegend);
  }

  async function updateLegend(imagemId, legenda) {
    try {
      await api.post("/photo-tourist-spot-update-legend-admin", {
        imagemId,
        legenda,
      });
      showNofity.success("Legenda salva com sucesso");
    } catch (error) {
      showNofity.error("Erro ao salvar legenda");
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Editar atrativo turístico"
            pageTitle="Atrativo turístico"
          />
        </Container>

        <Col lg={12}>
          <Card>
            <div className="p-4">
              <button
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Voltar
              </button>
            </div>

            <CardBody>
              <div className="live-preview">
                <Form
                  className="needs-validation"
                  onSubmit={(e) => {
                    e.preventDefault();
                    touristSpot.handleSubmit();
                    return false;
                  }}
                  action="#"
                >
                  <Row className="g-3">
                    <CoverUploadImageField
                      title="Adicionar imagens"
                      multiple
                      data={touristSpot}
                      name="images"
                      isActiveRadioSelect={false}
                    />

                    <TextField label="Nome" name="nome" data={touristSpot} />

                    <TextField
                      type="select"
                      label="Filtro"
                      name="filtro_ponto_turistico"
                      data={touristSpot}
                      size={6}
                    >
                      <option value="" selected disabled>
                        Selecione o filtro
                      </option>
                      {dataFilter?.result?.length > 0 &&
                        dataFilter?.result?.map((item, idx) => (
                          <option key={idx} value={item.objectId}>
                            {item.nome}
                          </option>
                        ))}
                    </TextField>

                    <TextField
                      label="Horário de funcionamento"
                      name="horario_funcionamento"
                      data={touristSpot}
                    />

                    <InputMask
                      type="phone-br"
                      size={3}
                      label="Contato"
                      name="entre_contato"
                      data={touristSpot}
                    />

                    <InputMask
                      type="phone-br"
                      label="Whatsapp"
                      name="telefone"
                      data={touristSpot}
                      size={3}
                    />

                    <TextField
                      size={3}
                      label="E-mail"
                      name="email"
                      data={touristSpot}
                    />

                    <TextField
                      size={3}
                      label="Endereço"
                      name="endereco"
                      data={touristSpot}
                    />

                    <TextField
                      size={6}
                      label="Cidade"
                      name="cidade"
                      type="select"
                      data={touristSpot}
                    >
                      <option value="" disabled selected>
                        Selecione uma cidade
                      </option>
                      {dataCity?.result?.length > 0 &&
                        dataCity?.result.map((item, index) => (
                          <option key={index} value={item.objectId}>
                            {item.nome}
                          </option>
                        ))}
                    </TextField>

                    <TextField
                      size={3}
                      label="Latitude"
                      name="latitude"
                      data={touristSpot}
                      type="number"
                      onKeyDown={(event) => {
                        ["e", "E", "+", ","].includes(event.key) &&
                          event.preventDefault();
                      }}
                    />
                    <TextField
                      size={3}
                      label="Longitude"
                      name="longitude"
                      data={touristSpot}
                      type="number"
                      onKeyDown={(event) => {
                        ["e", "E", "+", ","].includes(event.key) &&
                          event.preventDefault();
                      }}
                    />

                    <TextField
                      size={12}
                      label="Descrição"
                      name="descritivo"
                      data={touristSpot}
                      type="textarea"
                    />

                    {data?.result?.images?.length > 0 && (
                      <>
                        <h3>Imagens do local</h3>
                        <p>
                          Escolha uma opção abaixo para excluir uma imagem ou
                          selecionar a imagem principal do local
                        </p>
                      </>
                    )}

                    {id && (
                      <React.Fragment>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 10,
                          }}
                        >
                          {data?.result?.images?.map((item, idx) => {
                            return item.imagem?.name ? (
                              <div>
                                <Col key={idx} lg={3} style={styles.imageList}>
                                  {item.imagem.url !==
                                  data?.result?.touristSpot?.imagem.url ? (
                                    <PlaceMenu
                                      remove={() => {
                                        setPhotoId(item.objectId);
                                        setModalIsOpenRemove(true);
                                      }}
                                      select={() => {
                                        setSelectedImage(item?.imagem);
                                        setModalIsOpenSelect(true);
                                      }}
                                    />
                                  ) : null}
                                  <LazyImage
                                    src={item?.imagem?.url}
                                    width="100%"
                                    height="100%"
                                    className="object-fit-cover rounded"
                                    style={
                                      item.imagem.url ===
                                      data?.result?.touristSpot?.imagem.url
                                        ? styles.lazyImage
                                        : null
                                    }
                                  />
                                </Col>
                                <div style={{ marginTop: 10 }}></div>
                                <InputGroup>
                                  <Input
                                    value={item.legenda}
                                    title="Legenda"
                                    name="Legenda"
                                    placeholder="Legenda"
                                    size="sm"
                                    className="form-control"
                                    onChange={(event) =>
                                      addLegendInState(idx, event.target.value)
                                    }
                                  />
                                  <Button
                                    size="sm"
                                    className="primary"
                                    onClick={() =>
                                      updateLegend(
                                        item.objectId,
                                        legendImage[idx]
                                      )
                                    }
                                  >
                                    <i className="ri-save-2-line fs-13"></i>
                                  </Button>
                                </InputGroup>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </React.Fragment>
                    )}

                    <Col lg={12}>
                      <div className="text-end">
                        <button type="submit" className="btn btn-primary">
                          Salvar
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>

      <Modal
        isOpen={modalIsOpenRemove}
        text="Tem certeza que deseja remover essa imagem?"
        onClick={() => {
          dispatch(
            deleteTouristSpotPhotoRequest({
              touristSpotId: id,
              photoId: photoId,
            })
          );

          setModalIsOpenRemove(false);
        }}
        onCancel={() => {
          setModalIsOpenRemove(false);
        }}
      />

      <Modal
        isOpen={modalIsOpenSelect}
        text="Tem certeza que deseja selecionar essa imagem como a principal deste atrativo turístico?"
        onClick={() => {
          dispatch(
            updateTouristSpotPhotoRequest({
              touristSpotId: id,
              imagem: selectedImage,
            })
          );

          setModalIsOpenSelect(false);
        }}
        onCancel={() => {
          setModalIsOpenSelect(false);
        }}
      />
      <ModalLoding loading={loading} />
    </React.Fragment>
  );
};

const styles = {
  lazyImage: {
    borderColor: "#93bf85",
    borderWidth: 5,
    borderStyle: "solid",
    borderRadius: 10,
  },
  imageList: {
    display: "flex",
    position: "relative",
    height: 220,
    width: 260,
  },
};

export default TouristSpotForm;
